export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 21 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.49272 1.00003C2.74857 0.998554 3.00219 1.04759 3.23904 1.14433C3.47589 1.24108 3.69131 1.38362 3.87296 1.5638L19.425 17.1158C19.6072 17.2965 19.7518 17.5115 19.8505 17.7484C19.9492 17.9853 20 18.2394 20 18.496C20 18.7527 19.9492 19.0068 19.8505 19.2437C19.7518 19.4806 19.6072 19.6956 19.425 19.8763L3.87296 35.4283C3.5069 35.7943 3.01041 36 2.49272 36C1.97503 36 1.47855 35.7943 1.11248 35.4283C0.74642 35.0622 0.540768 34.5657 0.540768 34.048C0.540768 33.5304 0.74642 33.0339 1.11248 32.6678L15.3037 18.496L1.11248 4.32427C0.930275 4.14355 0.785648 3.92855 0.686954 3.69165C0.588261 3.45476 0.537449 3.20066 0.537449 2.94403C0.537449 2.6874 0.588261 2.43331 0.686954 2.19641C0.785648 1.95952 0.930275 1.74451 1.11248 1.5638C1.29413 1.38362 1.50955 1.24108 1.7464 1.14433C1.98326 1.04759 2.23688 0.998554 2.49272 1.00003Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
};
