import { Fragment } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { URLRoutes } from "URLRoutes";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { ROLES } from "utils/Enums";


export const sidebarLinks = [
  { to: URLRoutes.clients.faq, text: "FAQs", iconName: CUSTOM_SVG_ICON.FAQ },
  { to: URLRoutes.clients.help, text: "Help", iconName: CUSTOM_SVG_ICON.Help },
];


export const brandNavLinks = [
  {
    to: URLRoutes.clients.influencerList,
    text: "Discovery Users",
    iconName: CUSTOM_SVG_ICON.Manage,
  },
  {
    to: URLRoutes.clients.campaignList,
    text: "Campaigns",
    iconName: CUSTOM_SVG_ICON.Campaigns,
  },
  { to:URLRoutes.clients.profile, text: "Profile", iconName: CUSTOM_SVG_ICON.Profile },
];

export const influencerNavLinks =  [
  {
    to:  URLRoutes.clients.instaDashboard,
    text: "Dashboard",
    iconName: CUSTOM_SVG_ICON.Dashboard,
  },
  {
    to: URLRoutes.clients.campaignList,
    text: "Campaigns",
    iconName: CUSTOM_SVG_ICON.Campaigns,
  },
  { to:URLRoutes.clients.profile, text: "Profile", iconName: CUSTOM_SVG_ICON.Profile },
];

export const adminHOBNavLinks = [
  {
    to: URLRoutes.clients.users.replace(":userType", ROLES.INFLUENCER),
    text: "Manage Users",
    iconName: CUSTOM_SVG_ICON.Dashboard,
  },
  {
    to: URLRoutes.clients.influencerList,
    text: "Discovery Users",
    iconName: CUSTOM_SVG_ICON.Manage,
  },
  {
    to: "",
    text: "Campaigns",
    iconName: CUSTOM_SVG_ICON.Campaigns,
    children: [
      {
        to: URLRoutes.clients.createCampaign,
        text: "Create Campaign",
        iconName: "",
      },
      { to: URLRoutes.clients.campaignList, text: "Manage Campaigns", iconName: "" },
    ],
  },
  { to: URLRoutes.clients.profile, text: "Profile", iconName: CUSTOM_SVG_ICON.Profile },
  { to: "", text: "Brands", iconName: CUSTOM_SVG_ICON.Brand1 , 
  children: [
    {
      to: URLRoutes.clients.createBrand,
      text: "Create Brand",
      iconName: "",
    },
    { to: URLRoutes.clients.brandList, text: "Manage Brand", iconName: "" },
  ],
   },
  { to: "", text: "Brand Users", iconName: CUSTOM_SVG_ICON.BrandUser , 
  children: [
    {
      to: URLRoutes.clients.createBrandUser,
      text: "Create Brand Users",
      iconName: "",
    },
    { to: URLRoutes.clients.brandUsersList, text: "Manage Brand Users", iconName: "" },
  ],
   },
   
];

interface NavLinkItem {
  to: string;
  text: string;
  iconName: string;
  children?: NavLinkItem[];
  onClick?: () => void; 
}

export default function NavLinkComponent({
  link,
  index,
  activeIndex,
  setActiveIndex,
}: {
  link: NavLinkItem;
  index: number;
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
}) {
  const isActive = index === activeIndex;

  const handleParentClick = () => {
    setActiveIndex(isActive ? -1 : index);
  };

  
  return (
    <Fragment>
      {link.children ? (
        <div
          className="flex flex-align-center text-secondary-color margin-b-4 padding-1 nav-link"
          onClick={handleParentClick}
        >
          <SvgIcon
            svgType={SVGType.CUSTOM}
            name={link.iconName}
            size={"huge"}
            baseclassname={"margin-l-3 margin-r-2"}
          />
          {link.text}
          
          <SvgIcon
            svgType={SVGType.CUSTOM}
            name={
              isActive ? CUSTOM_SVG_ICON.CaretDown : CUSTOM_SVG_ICON.CaretUp
            }
            size={"small"}
            baseclassname={"margin-l-9 text-align-center"}
          />
        </div>
      ) : (
        <NavLink
          to={link.to}
          className="flex flex-align-center text-secondary-color margin-b-4 padding-1 nav-link"
          onClick={link.onClick}
        >
          <SvgIcon
            svgType={SVGType.CUSTOM}
            name={link.iconName}
            size={"huge"}
            baseclassname={"margin-l-3 margin-r-2 "}
          />
          {link.text}
        </NavLink>
      )}

      {isActive && link.children && (
        <div className="flex flex-column border border-left2 margin-l-7 margin-b-2">
          {link.children.map((childLink, childIndex) => (
            <NavLink
              key={childIndex}
              to={childLink.to}
              className="flex flex-align-center text-secondary-color margin-l-2 padding-2 child-link"
            >
              {childLink.text}
            </NavLink>
          ))}
        </div>
      )}
    </Fragment>
  );
}



// function createBreadcrumbs(
//   role: string,
//   campaignId?: string,
//   updatedUrl?: string
// ) {
//   const breadcrumbs = [
//     {
//       text: "Dashboard",
//       url: getDashboardUrl(role),
//     },
//   ];

//   if (campaignId) {
//     breadcrumbs.push(
//       {
//         text: "Campaign",
//         url: `${URLRoutes.clients.campaignList}?campaign_id=${campaignId}`,
//       },
//       {
//         text: "Update Campaign",
//         url: updatedUrl || "",
//       }
//     );
//   } else {
//     breadcrumbs.push({
//       text: "Create Campaign",
//       url: URLRoutes.clients.createCampaign,
//     });
//   }

//   return breadcrumbs;
// }