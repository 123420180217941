export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 0C4.93429 0 0 4.93429 0 11C0 17.0657 4.93429 22 11 22C17.0657 22 22 17.0657 22 11C22 4.93429 17.0657 0 11 0ZM5.44029 18.5979C5.54164 15.6153 7.99307 13.2181 11 13.2181C14.0069 13.2181 16.4584 15.6145 16.5597 18.5979C14.9985 19.7426 13.0798 20.4286 11 20.4286C8.92021 20.4286 7.0015 19.7426 5.44029 18.5979ZM11 11C9.69807 11 8.63893 9.94086 8.63893 8.63893C8.63893 7.337 9.69807 6.27786 11 6.27786C12.3019 6.27786 13.3611 7.337 13.3611 8.63893C13.3611 9.94086 12.3019 11 11 11ZM17.985 17.3101C17.4468 14.7628 15.5477 12.7129 13.0861 11.9594C14.1916 11.2624 14.9325 10.0391 14.9325 8.63893C14.9325 6.47036 13.1686 4.70643 11 4.70643C8.83143 4.70643 7.0675 6.47036 7.0675 8.63893C7.0675 10.0391 7.80843 11.2624 8.91393 11.9594C6.45229 12.7129 4.55321 14.7628 4.015 17.3101C2.50171 15.6373 1.57143 13.4279 1.57143 11C1.57143 5.80093 5.80093 1.57143 11 1.57143C16.1991 1.57143 20.4286 5.80093 20.4286 11C20.4286 13.4279 19.4983 15.6373 17.985 17.3101Z"
        fill="currentColor"
      />
    </svg>
  );
};
