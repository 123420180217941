import classNames from "classnames";
import moment from "moment";
import { Popup } from "semantic-ui-react";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { CAMPAIGN_COLLABORATION_STATUS, IStatus } from "utils/Enums";
import { findLabelById } from "utils/utils";

interface IProps {
  iconName?: CUSTOM_SVG_ICON;
  title: string;
  subTitle: any;
  baseClassName?: string;
  contentClassName?: string;
}

interface TitleWithContentProps {
  title: string;
  content: any;
}

export const ordinalNumbers = [
  "First",
  "Second",
  "Third",
  "Fourth",
  "Fifth",
  "Sixth",
  "Seventh",
  "Eight",
  "Ninth",
  "Tenth",
];

export enum SortingOptions {
  AtoZ = "atoz",
  ZtoA = "ztoa",
  Oldest = "oldest",
  Latest = "latest",
}

export const sortingOptions = [
  { label: "Select All", value: undefined!  },
  { label: "A to Z", value: SortingOptions.AtoZ , icon: "sort alphabet down" },
  { label: "Z to A", value: SortingOptions.ZtoA , icon: "sort alphabet up"},
  { label: "Oldest to Latest", value: SortingOptions.Oldest , icon: "sort content descending" },
  { label: "Latest to Oldest", value: SortingOptions.Latest , icon: "sort content ascending"},
];

export function formatStartEndDate(dateString: string) {
  const formattedDate = moment(dateString).format("DD-MM-YYYY");
  return dateString ? formattedDate : "NA";
}

const formatStatusString = (status: string) => {
  return status
    ?.replace(/_/g, " ")
    .toLowerCase()
    .replace(/\b\w/g, (c) => c.toUpperCase());
};

export const IconWithTitle = ({
  iconName,
  title,
  subTitle,
  baseClassName,
  contentClassName
}: IProps) => {
  return (
    <div
      className={classNames([
        "flex  padding-t-4 padding-b-4",
        baseClassName,
      ])}
    >
      {iconName && (
        <SvgIcon
          name={iconName}
          svgType={SVGType.CUSTOM}
          size={"huge"}
          baseclassname={"text-grey-shade-4 margin-r-2"}
        />
      )}
      <div className="flex flex-column flex-justify-center">
        <div className="text-default-color icon-title margin-b-2">{title}</div>
        <span className="text-grey-shade-2 icon-subtitle">{subTitle}</span>
      </div>
    </div>
  );
};

export const TitleWithContent = ({ title, content }: TitleWithContentProps) => {
  return (
    <div className="title-with-content margin-b-2">
      <div className="text-bold text-3">{title}</div>
      <div className="text-3 text-grey-shade-4">{content || "NA"}</div>
    </div>
  );
};

export const InfluencerSummaryCard = ({
  iconName,
  count,
  text,
  baseClassName,
}: any) => {
  return (
    <div
      className={classNames([
        "flex flex-column width-30 padding-4 margin-r-2 influencer-summary-card",
        baseClassName,
      ])}
    >
      <div className="margin-b-6">
      <SvgIcon
        name={iconName}
        svgType={SVGType.CUSTOM}
        size={"huge"}
        // baseclassname={"margin-b-6"}
      />
      </div>
      <div className="influencer-summary-text2">{count}</div>
      <div className="influencer-summary-text3">{text}</div>
    </div>
  );
};

export function StatusInfo({ campaignStatus, baseClassName }: any) {
  return (
    <div
      className={classNames([
        "campaign-status text-bold text-align-center",
        {
          "background-green-shade4 text-green-shade2 border-1 border-green-shade-3 campaign-status-active":
            campaignStatus === IStatus.ACTIVE ||
            campaignStatus === IStatus.IN_PROGRESS,
        },
        {
          "background-orange-shade2 text-orange-shade3 border-1 border-orange-shade-3 campaign-status-progress":
            campaignStatus === IStatus.LIVE ||
            campaignStatus === IStatus.APPLIED,
        },
        {
          "background-red-shade1 text-danger-color border-1 border-danger-color campaign-status-reject":
            campaignStatus === IStatus.INACTIVE,
        },
        {
          "background-red-shade1 text-danger-color border-1 border-danger-color campaign-status-reject":
            campaignStatus === IStatus.REJECTED ||
            campaignStatus === IStatus.REJECTED_BY_HOB || campaignStatus === IStatus.APPLICATION_UNSUCCESSFUL,
        },
        baseClassName || "",
      ])}
    >
      {formatStatusString(campaignStatus)}
    </div>
  );
}

export  const influencerStatusMap : {[key in CAMPAIGN_COLLABORATION_STATUS]: IStatus} = {
  [CAMPAIGN_COLLABORATION_STATUS.POST_TO_INFLUENCER]: IStatus.ACTIVE,
  [CAMPAIGN_COLLABORATION_STATUS.APPLY_CAMPAIGN]: IStatus.APPLIED,
  [CAMPAIGN_COLLABORATION_STATUS.DECLINE_CAMPAIGN]: IStatus.REJECTED,
  [CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_BRAND]: IStatus.IN_PROGRESS,
  [CAMPAIGN_COLLABORATION_STATUS.DECLINE_BY_BRAND]: IStatus.IN_PROGRESS,
  [CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_HOB]: IStatus.IN_PROGRESS,
  [CAMPAIGN_COLLABORATION_STATUS.DECLINE_BY_HOB]: IStatus.APPLICATION_UNSUCCESSFUL,
  [CAMPAIGN_COLLABORATION_STATUS.PENDING]: IStatus.IN_PROGRESS,
  [CAMPAIGN_COLLABORATION_STATUS.IN_PROGRESS]: IStatus.IN_PROGRESS,
};

export const renderGenres = (options: any) => {
  if (!options || options.length === 0) return null;

  const visibleGenres = options.slice(0, 2);
  const hiddenGenres = options.slice(2);

  return (
    <div className="flex flex-wrap discovery-geners">
      {visibleGenres.map((genre: string, index:number) => (
        <div
          key={`genre${index}`}
          className="flex flex-align-center text-align-center text-grey-shade-4 border-1 border-grey-shade-1 margin-1 influencer-genres"
        >
          {options(options , genre)}
        </div>
      ))}
      {hiddenGenres.length > 0 && (
        <Popup
          content={(<div className="width-95 flex flex-wrap flex-justify-center">{hiddenGenres.map((genre:string) =><div className="flex flex-align-center flex-justify-center text-3  text-grey-shade-4 border-1 border-grey-shade-1 margin-1 profile-genres profile-genres-hidden text-align-center">{findLabelById(options , genre)}</div>)}</div>)}
          trigger={<div className=" text-grey-shade-4 border-1 border-grey-shade-1  margin-1 influencer-genres">+{hiddenGenres.length}</div>}
          position="bottom center"
        />
      )}
    </div>
  );
};

export function renderArray (items: any[] , maxVisibleItems: number , changeItemLabel: any) {
  if (!items || items.length === 0) return null;

  const visibleItems = items.slice(0, maxVisibleItems);
  const hiddenItems = items.slice(maxVisibleItems);

  return (
    <div className="flex flex-wrap">
      {visibleItems.map((item, index) => (
        <div
          key={`item${index}`}
          className="flex flex-align-center text-align-center text-grey-shade-4 border-1 border-grey-shade-1 margin-1 array-items text-word-break"
        >
          {typeof changeItemLabel === "function" ? changeItemLabel(item) : item}
        </div>
      ))}
      {hiddenItems.length > 0 && (
        <Popup
          content={
            <div className="width-95 flex flex-wrap flex-justify-center">
              {hiddenItems.map((item: string , index:number) => (
                <div key={index} className="flex flex-align-center flex-justify-center text-3  text-grey-shade-4 border-1 border-grey-shade-1 margin-1 profile-genres profile-genres-hidden text-align-center">
                    {typeof changeItemLabel === "function" ? changeItemLabel(item) : item}
                </div>
              ))}
            </div>
          }
          trigger={
            <div className="text-grey-shade-4 border-1 border-grey-shade-1 margin-1 array-items">
              +{hiddenItems.length}
            </div>
          }
          // position="bottom center"
        />
      )}
    </div>
  );
};


export const renderDeliverables = (
  deliverables: Array<{ id: string; category: string; deliverable: string }>
) => {
  return deliverables.map(({ category, deliverable, id }, index) => (
    <div className="flex flex-column width-100 " key={id}>
      <div className="flex flex-row  flex-align-center flex-justify-between width-100 margin-b-3 text-grey-shade-4 icon-subtitle">
        <div className="width-30">{index + 1}. {category}</div>
        <div className="width-70">{deliverable}</div>
      </div>
    </div>
  ));
};