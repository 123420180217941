export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.288 12L0.264 6.72V6.016H0.616C1.18133 6.016 1.656 5.96267 2.04 5.856C2.43467 5.74933 2.744 5.57333 2.968 5.328C3.192 5.08267 3.32533 4.74667 3.368 4.32H0.264V3.296H3.352C3.27733 2.90133 3.128 2.58133 2.904 2.336C2.68 2.08 2.376 1.89333 1.992 1.776C1.61867 1.65867 1.16 1.6 0.616 1.6H0.264V0.575999H6.888V1.6H3.864C4.10933 1.80267 4.312 2.04267 4.472 2.32C4.632 2.59733 4.73333 2.92267 4.776 3.296H6.888V4.32H4.808C4.73333 5.09867 4.43467 5.70667 3.912 6.144C3.4 6.57067 2.71733 6.84267 1.864 6.96L4.92 12H3.288Z"
        fill="currentColor"
      />
    </svg>
  );
};
