export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.96 15.17V0.329999H9.165V15.17H6.96ZM0.45 8.8V6.735H15.675V8.8H0.45Z"
        fill="currentColor"
      />
    </svg>
  );
};
