import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { formatDate, formatNumber } from "utils/utils";
import { Placeholder } from "semantic-ui-react";
import TooltipText from "components/TooltipText";
import "./style.scss";

interface IPostCard {
  data: any;
  isLoading: boolean;
}

export default function PostInfoCard({ data, isLoading }: IPostCard) {
  const {
    comments_count,
    timestamp,
    permalink,
    media_type,
    media_url,
    like_count,
  } = data || {};

  const handleClick = () => {
    window.open(permalink, "_blank");
  };
  // if (!media_url) {
  //   return null;
  // }

  return (
    <div className="flex flex-column post-container" onClick={handleClick}>
      <div className="margin-4 post-container-box">
        {!isLoading && (
          <div className="post-container-img padding-1">
            {media_type === "VIDEO" ? (
              <video style={{ width: "100%", height: "100%" }} controls>
                <source src={media_url} type="video/mp4" />
                <source src="movie.ogg" type="video/ogg" />
                <img src="/images/WhiteBg.png" />
              </video>
            ) : (
              <img
                src={media_url || "/images/WhiteBg.png"}
                style={{ width: "100%", height: "100%" }}
                alt="Img"
              />
            )}
          </div>
        )}
        {isLoading && (
          <Placeholder>
            <Placeholder.Image square />
          </Placeholder>
        )}
        <div className="flex text-grey-shade2  post-container-text  padding-1">
          {isLoading ? (
            <Placeholder>
              <Placeholder.Line length="short" />
              <Placeholder.Line length="very short" />
              <Placeholder.Line length="medium" />
            </Placeholder>
          ) : (
            <>
              <div className="flex flex-align-center margin-l-1">
                <SvgIcon
                  svgType={SVGType.CUSTOM}
                  name={CUSTOM_SVG_ICON.Like1}
                  size={"small"}
                  baseclassname={"margin-r-1 margin-b-1"}
                />
                <div className="post-like-text">{like_count ? formatNumber(like_count) : 0}</div>
              </div>
              <div className="flex flex-align-center margin-l-1">
                <SvgIcon
                  svgType={SVGType.CUSTOM}
                  name={CUSTOM_SVG_ICON.Comment1}
                  size={"small"}
                  baseclassname={"margin-r-1 margin-b-1"}
                />
                <div className="post-like-text">
                  {comments_count ? formatNumber(comments_count) : 0}
                </div>
              </div>
              {/* <div className="post-like-text text-overflow-ellipses padding-l-2">
                {formatDate(timestamp)}
              </div> */}
              <div className="flex  margin-l-1">
                <SvgIcon
                  svgType={SVGType.CUSTOM}
                  name={CUSTOM_SVG_ICON.CalendarSmall}
                  size={"small"}
                  baseclassname={"margin-r-1"}
                />
                {/* <div className="post-like-text text-align-center text-overflow-ellipses"> */}
                  <TooltipText value={timestamp ? formatDate(timestamp) : "-"} baseClassName="post-like-text text-align-center"/>
                {/* </div> */}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
