import React, { Fragment, useRef, useState } from 'react';
import "./style.scss"

function CustomOTPInputs({ length, onChange, label }: any) {
  const inputRefs = useRef<Array<HTMLInputElement | null>>(new Array(length));
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleChange = (index: number, value: string) => {
    if (/^\d*$/.test(value)) {
      setErrorMessage(''); 
      if (index < inputRefs.current.length - 1 && value !== '') {
        inputRefs.current[index + 1]?.focus();
      }
      const newOTP = [...inputRefs.current.map(input => input?.value || '')];
      onChange(newOTP.join(''));
    } else {
      setErrorMessage('Please enter only numeric digits.');
    }
  };

  const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && index > 0 && event.currentTarget.value === '') {
      inputRefs.current[index - 1]?.focus();
    }
  };

  return (
    <Fragment>
      <label className='input-label'>{label}</label>
      <div className='flex flex-row width-100'>
        {[...Array(length)].map((_, index) => (
          <input
            key={index}
            ref={(el) => (inputRefs.current[index] = el)}
            name={`otp-${index}`}
            className="otp-input width-25"
            onChange={(event) => handleChange(index, event.currentTarget.value)}
            onKeyDown={(event) => handleKeyDown(index, event)}
            maxLength={1}
            pattern="[0-9]*"
          />
        ))}
      </div>
      {errorMessage && <div className="text-3 text-danger-color padding-2">{errorMessage}</div>}
    </Fragment>
  );
}

export default CustomOTPInputs;
