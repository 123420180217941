import { Fragment, useState } from "react";
import { Controller } from "react-hook-form";
import classNames from "classnames";
import { Dropdown, Flag } from "semantic-ui-react";
import { countries } from "./countries";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";

interface HookPhoneInputProps {
  label?: string;
  labelClassName?: string;
  baseClassName?: string;
  name: string;
  validation?: any;
  control: any;
  errors: any;
  placeholder?: string;
  autoFocus?: boolean;
  id?: string;
  triggerOnChange?: Function;
  inputClassName?: string;
  dropdownClassName?: string;
  required?: boolean;
}

const useCountryOptions = () => {
  return countries.map((country: any) => ({
    key: country.countryCode,
    value: country.countryCode,
    flag: country.countryCode.toLowerCase(),
    text: country.name,
  }));
};


export function HookPhoneInput({
  label,
  labelClassName,
  baseClassName,
  name,
  validation,
  control,
  errors,
  placeholder,
  autoFocus,
  id,
  triggerOnChange,
  inputClassName,
  dropdownClassName , 
  required
}: HookPhoneInputProps) {
  const countryOptions = useCountryOptions();
  const defaultCountry = countries.find((country) => country.countryCode === "in");
  const [selectedCountry, setSelectedCountry] = useState<any>(defaultCountry);

  const flagRenderer = (item: any) => <Flag name={item} />;

  const getErrorMessage = (field: string) => {
    const errorPath = field.split('.');
    let currentError = errors as any;
  
    for (const path of errorPath) {
      const arrayMatch = path.match(/(\w+)\[(\d+)\]/);
      if (arrayMatch) {
        const [ , arrayName, index ] = arrayMatch;
        if (currentError && Array.isArray(currentError[arrayName])) {
          currentError = currentError[arrayName][parseInt(index, 10)];
        } else {
          currentError = undefined;
          break;
        }
      } else if (currentError && currentError[path] !== undefined) {
        currentError = currentError[path];
      } else {
        currentError = undefined;
        break;
      }
    }
  
    if (currentError && typeof currentError === 'object' && 'message' in currentError) {
      return currentError.message;
    }
  
    return '';
  };
  return (
    <div
      className={classNames([
        "flex flex-column custom-input position-relative",
        baseClassName,
      ])}
    >
      {label && (
        <div
          className={classNames(["input-label padding-b-1", labelClassName])}
        >
          {label}
          { required && <span className={classNames(["position-absolute input-asterik text-danger-color "])}>&#8727;</span>}
        </div>
      )}
      <Controller
        control={control}
        name={name}
        rules={validation || {}}
        render={({ field: { onChange, value } }) => (
          <Fragment>
            <Dropdown
              floating
              labeled
              button
              defaultValue={selectedCountry.value}
              value={selectedCountry ? selectedCountry.value : undefined}
              trigger={
                selectedCountry?.dialCode ? (
                  flagRenderer(selectedCountry.countryCode)
                ) : (
                  <SvgIcon
                    name={CUSTOM_SVG_ICON.Phone}
                    svgType={SVGType.CUSTOM}
                    size={"small"}
                    baseclassname={"margin-r-2 text-default-color"}
                  />
                )
              }
              className={classNames([
                "country-dropdown",
                dropdownClassName,
              ])}
              options={countryOptions}
            >
              <Dropdown.Menu className="country-dropdown-menu">
                <Dropdown.Menu scrolling>
                  {countryOptions.map((option:any) => (
                    <Dropdown.Item
                      key={option.key}
                      {...option}
                      selected={selectedCountry?.countryCode === option.value}
                      onClick={(e, data) => {
                        const countryCode = data.value;
                        const selectedCountry = countries.find(
                          (country: any) => country.countryCode === countryCode
                        );
                        setSelectedCountry(selectedCountry);
                        if (selectedCountry) {
                          onChange(selectedCountry.dialCode); 
                        }
                      }}
                    />
                  ))}
                </Dropdown.Menu>
              </Dropdown.Menu>
            </Dropdown>
            <input
              type="text"
              placeholder={placeholder}
              autoFocus={autoFocus}
              value={value || selectedCountry.dialCode || ""}
              id={id}
              className={classNames(["padding-l-15", inputClassName || ""])}
              onChange={(e) => {
                onChange(e.target.value);
                if (typeof triggerOnChange === "function") {
                  triggerOnChange(e.target.value);
                }
              }}
            />
          </Fragment>
        )}
      />
      {errors && getErrorMessage(name) && (
        <div className="text-3 text-danger-color padding-b-1 padding-l-1">
          {getErrorMessage(name)}
        </div>
      )}
       {/* {errors[name] && (
        <div className="text-3 text-danger-color padding-b-1 padding-l-1">
          {errors[name].message}
        </div>
      )} */}
    </div>
  );
}
