import { Modal } from "semantic-ui-react";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import CustomButton from "components/CustomButton";
import { ICON_POSITION } from "components/CustomButton/CustomButton";

interface IModalHeader {
  title: string;
  toggle: any;
  subTitle?:string;
}

export const ModalHeader = ({ title, toggle , subTitle }: IModalHeader) => {
  return (
    <Modal.Header>
      <div className="flex width-100  flex-justify-between flex-align-center common-header padding-2">
        <div className="text-blue-shade-2 text-align-center text-5 padding-l-2 line-h-4 text-bold">{title}</div>
        {subTitle && <div className="text-grey-shade-4 text-3 line-h-3">{subTitle}</div>}
        {toggle ? <CustomButton handleClick={toggle}
          greyButton
          transparent
          iconPosition={ICON_POSITION.LEFT} iconProps={{
          svgType: SVGType.CUSTOM,
          name: CUSTOM_SVG_ICON.Cross,
          size: "small",
          baseclassname:"header-close-button"
        }}
        noOutline
        />
        :
        <div/>
        }
      </div>
    </Modal.Header>
  );
};

