import { useEffect } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { URLRoutes } from "URLRoutes";
import AuthLayout from "components/AuthLayout";
import CustomButton from "components/CustomButton";
import { HookFormInput } from "components/FormInputs";
import { requestForgetPassword } from "store/rext";
import {
  hookEmailPatternRequired,
  hookPatternRequired,
  hookformRequired,
} from "utils/FormValidations";
import { getForgetPasswordState } from "store/selectors";
import UsePrevious from "HOC/UsePrevious";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { ICON_POSITION } from "components/CustomButton/CustomButton";

const requiredValidation = {
  ...hookformRequired("Required"),
  ...hookEmailPatternRequired("Invalid email address"),
};

function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, fetching, error, message } = useSelector(
    getForgetPasswordState
  );
  const wasfecthing = UsePrevious(fetching);
  const {
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    control,
  } = useForm({});

  const handleBackButton = () => {
    navigate(-1);
  };

  const handleOnSubmit = (data: any) => {
    if (data.password !== data.confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }

    dispatch(requestForgetPassword(data));
  };

  useEffect(() => {
    if (isSubmitSuccessful && !fetching && !error) {
      toast.success(message);
      setTimeout(() => {
        navigate(`/verifyOTP/${data.userId}/FORGOT_PASSWORD`);
      }, 2000);
    } else if (isSubmitSuccessful && error) {
      toast.error(message);
    }
  }, [fetching, error, message]);

  useEffect(() => {
    toast.dismiss();
  }, []);

  return (
    <AuthLayout
      image="/images/womanSunglasses.png"
      title="Discover the world’s top Influencers."
      titlePosition="top"
      contentClassName="flex-justify-center flex-align-center"
      bannerClassName="background-linear1"
      imagePosition="right"
    >
      <div className="width-45 margin-b-10">
        <img src="images/logo.png" style={{ width: "100%", height: "100%" }} />
      </div>
      <form onSubmit={handleSubmit(handleOnSubmit)} className="width-55">
        <HookFormInput
          control={control}
          errors={errors}
          label="Registered Email"
          id="email"
          name="email"
          placeholder="Enter your registered email here..."
          baseClassName={"margin-b-2"}
          validation={requiredValidation}
          required
        />

        <CustomButton
          buttonText="SEND OTP"
          type="submit"
          primaryButton
          round
          baseclassname={"width-100"}
          loading={fetching}
        />
      </form>
      <div className="text-3 margin-t-4">
        Remember password?
        <Link
          to={URLRoutes.clients.login}
          className="text-danger-color margin-l-1"
        >
          Sign In
        </Link>
      </div>
    </AuthLayout>
  );
}

export default ForgotPassword;
