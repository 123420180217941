export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 23.5625H6.5C5.42256 23.5625 4.38925 23.1345 3.62738 22.3726C2.86551 21.6107 2.4375 20.5774 2.4375 19.5V8.12498C2.4375 7.9095 2.5231 7.70283 2.67548 7.55046C2.82785 7.39809 3.03451 7.31248 3.25 7.31248H19.5C20.5774 7.31248 21.6108 7.7405 22.3726 8.50236C23.1345 9.26423 23.5625 10.2975 23.5625 11.375V19.5C23.5625 20.5774 23.1345 21.6107 22.3726 22.3726C21.6108 23.1345 20.5774 23.5625 19.5 23.5625ZM4.0625 8.93748V19.5C4.0625 20.1465 4.31931 20.7664 4.77643 21.2236C5.23355 21.6807 5.85353 21.9375 6.5 21.9375H19.5C20.1465 21.9375 20.7665 21.6807 21.2236 21.2236C21.6807 20.7664 21.9375 20.1465 21.9375 19.5V11.375C21.9375 10.7285 21.6807 10.1085 21.2236 9.65141C20.7665 9.19429 20.1465 8.93748 19.5 8.93748H4.0625Z"
        fill="currentColor"
      />
      <path
        d="M21.1251 8.93747C20.9096 8.93747 20.7029 8.85187 20.5506 8.6995C20.3982 8.54712 20.3126 8.34046 20.3126 8.12497V5.8906C20.3282 5.60597 20.2792 5.3215 20.1695 5.05842C20.0598 4.79533 19.892 4.56043 19.6788 4.37122C19.4873 4.2277 19.2655 4.12969 19.0304 4.08464C18.7953 4.0396 18.553 4.04868 18.322 4.11122L4.69634 7.20685C4.51306 7.24813 4.34974 7.35164 4.23419 7.49978C4.11864 7.64792 4.058 7.83153 4.06259 8.01935C4.06259 8.23484 3.97699 8.4415 3.82461 8.59387C3.67224 8.74625 3.46558 8.83185 3.25009 8.83185C3.0346 8.83185 2.82794 8.74625 2.67557 8.59387C2.52319 8.4415 2.43759 8.23484 2.43759 8.01935C2.43282 7.46392 2.6179 6.92351 2.96218 6.48762C3.30646 6.05173 3.78929 5.7465 4.33071 5.62247L17.9645 2.52685C18.4334 2.40828 18.9232 2.39863 19.3964 2.49862C19.8696 2.59861 20.3136 2.8056 20.6945 3.10372C21.0972 3.44549 21.4179 3.87336 21.6331 4.35573C21.8483 4.83809 21.9523 5.36263 21.9376 5.8906V8.12497C21.9376 8.34046 21.852 8.54712 21.6996 8.6995C21.5472 8.85187 21.3406 8.93747 21.1251 8.93747Z"
        fill="currentColor"
      />
      <path
        d="M22.75 18.6875H17.0625C16.2005 18.6875 15.3739 18.3451 14.7644 17.7356C14.1549 17.1261 13.8125 16.2994 13.8125 15.4375C13.8125 14.5755 14.1549 13.7489 14.7644 13.1394C15.3739 12.5299 16.2005 12.1875 17.0625 12.1875H22.75C22.9655 12.1875 23.1722 12.2731 23.3245 12.4255C23.4769 12.5778 23.5625 12.7845 23.5625 13V17.875C23.5625 18.0905 23.4769 18.2971 23.3245 18.4495C23.1722 18.6019 22.9655 18.6875 22.75 18.6875ZM17.0625 13.8125C16.6315 13.8125 16.2182 13.9837 15.9135 14.2884C15.6087 14.5932 15.4375 15.0065 15.4375 15.4375C15.4375 15.8685 15.6087 16.2818 15.9135 16.5865C16.2182 16.8913 16.6315 17.0625 17.0625 17.0625H21.9375V13.8125H17.0625Z"
        fill="currentColor"
      />
    </svg>
  );
};
