// src/hooks/useForegroundMessaging.ts
import { useEffect } from "react";
import { onMessage, Messaging } from "firebase/messaging";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { URLRoutes } from "URLRoutes";
import { useUserDetail } from "./useUserDetail";
import { ROLES } from "utils/Enums";


const ToastifyNotification = ({ title, body,campaign_id , navigate, type}: any) => {
  const {role} = useUserDetail();
  const handleBody=()=> {
    role === ROLES.INFLUENCER ?     navigate(`${URLRoutes.clients.campaignList}?campaign_id=${campaign_id}`)
 : navigate(URLRoutes.clients.influencerDetail.replace(":campaignId", campaign_id))
  }
  return(
    <div className="push-notification flex flex-column">
      <div className="flex">
        <div className="margin-r-2" style={{width: 40}}>
          <img src={"/favicon.png"} style={{ width: "100%" }} />
        </div>
        <div className="flex flex-column">
        <div className="text-bold text-4 text-blue-shade-2">{title}</div>
          {type && type === 'CHAT' && <div onClick={handleBody} className="text-3 text-default-color">{body}</div>}
          {!type && <div className="text-3 text-default-color">{body}</div>}
      </div>
        </div>
    </div>
      )
};

export const useForegroundMessaging = (messaging: Messaging | null) => {
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!messaging) return;

    const unsubscribe = onMessage(messaging, (payload) => {
      console.log("Foreground message received: ", payload);
      const { notification: { title, body  }, data } = payload as any;
      toast(<ToastifyNotification title={title} body={body} navigate={navigate} type={data?.type} campaign_id={data?.campaign_id}/>, {
        closeButton: true, 
        closeOnClick: true,
        autoClose: 30000, 
        // style:{width: 550}
      });
    });

    return () => {
      unsubscribe();
    };
  }, [messaging]);
};
