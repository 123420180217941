export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.6576 0H9.37548C4.18413 0 0 4.18471 0 9.35213V20.583C0 25.7508 4.18413 30 9.37548 30H20.6576C25.849 30 30.129 25.7508 30.129 20.583V9.35213C30.129 4.18471 25.849 0 20.6576 0ZM27.2903 20.583C27.2903 24.1952 24.2863 27.1613 20.6576 27.1613H9.37548C5.74652 27.1613 2.77419 24.1952 2.77419 20.583V9.35213C2.77419 5.74019 5.74652 2.77419 9.37548 2.77419H20.6576C24.2863 2.77419 27.2903 5.74019 27.2903 9.35213V20.583Z"
        fill="url(#paint0_linear_4123_74)"
      />
      <path
        d="M15.0165 7.48047C10.8628 7.48047 7.49515 10.8328 7.49515 14.9679C7.49515 19.1024 10.8628 22.4547 15.0165 22.4547C19.1702 22.4547 22.5378 19.1024 22.5378 14.9679C22.5378 10.8328 19.1702 7.48047 15.0165 7.48047ZM15.0165 19.6472C12.4253 19.6472 10.3159 17.5471 10.3159 14.9679C10.3159 12.3862 12.4253 10.2883 15.0165 10.2883C17.6076 10.2883 19.7172 12.3863 19.7172 14.9679C19.7172 17.5471 17.6076 19.6472 15.0165 19.6472Z"
        fill="url(#paint1_linear_4123_74)"
      />
      <path
        d="M23.1019 5.9212C22.5483 5.9212 22.0995 6.36791 22.0995 6.91882C22.0995 7.46978 22.5483 7.91669 23.1019 7.91669C23.655 7.91669 24.104 7.46978 24.104 6.91882C24.104 6.36791 23.655 5.9212 23.1019 5.9212Z"
        fill="url(#paint2_linear_4123_74)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4123_74"
          x1="2.76799"
          y1="27.2566"
          x2="27.3134"
          y2="2.71117"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFC107" />
          <stop offset="0.2211" stop-color="#F98D1C" />
          <stop offset="0.5137" stop-color="#F1423B" />
          <stop offset="0.8329" stop-color="#B93088" />
          <stop offset="0.9944" stop-color="#9C27B0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4123_74"
          x1="9.71035"
          y1="20.2739"
          x2="20.3228"
          y2="9.66148"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFC107" />
          <stop offset="0.2211" stop-color="#F98D1C" />
          <stop offset="0.5137" stop-color="#F1423B" />
          <stop offset="0.8329" stop-color="#B93088" />
          <stop offset="0.9944" stop-color="#9C27B0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4123_74"
          x1="22.3947"
          y1="7.62598"
          x2="23.8088"
          y2="6.21187"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFC107" />
          <stop offset="0.2211" stop-color="#F98D1C" />
          <stop offset="0.5137" stop-color="#F1423B" />
          <stop offset="0.8329" stop-color="#B93088" />
          <stop offset="0.9944" stop-color="#9C27B0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
