import classNames from "classnames";
import PostInfoCard from "../PostInfoCard";
import "./style.scss";

interface InstagramViewProps {
  followers: any;
  avgLikes: any;
  avgComments: any;
  avgReelViews: any;
  engageRatio: any;
  instaHandle: string;
  media: any[];
  profilePictureUrl: string;
  isLoadingPosts: boolean;
  baseClassName: string;
  numberOfPosts: string;
  hobScore: string;
  impressions: string;
  showMetrices: boolean;
}

interface IInfo {
  title: string;
  value: any;
  subTitle?: boolean;
}

const InfoCard = ({ title, value, subTitle }: IInfo) => {
  return (
    <div className="flex flex-column flex-align-center width-20 padding-2">
      <div className="info-value">{value}</div>
      <div className="info-title">{title}</div>
      {subTitle && (
        <div className="info-subTitle text-grey-shade4">{"(last 30 days)"}</div>
      )}
    </div>
  );
};

export function InstagramView(props: InstagramViewProps) {
  const {
    followers,
    avgComments,
    avgLikes,
    avgReelViews,
    engageRatio,
    hobScore,
    numberOfPosts,
    instaHandle,
    media,
    profilePictureUrl,
    isLoadingPosts,
    baseClassName,
    impressions,
    showMetrices,
  } = props;
  return (
    <div
      className={classNames([
        "background-secondary-color height-100",
        baseClassName || "",
      ])}
    >
      <div className="width-100 flex flex-justify-between border-bottom border-grey-shade-1 height-20 padding-2">
        <InfoCard title="Followers" value={followers} />
        <InfoCard title="Average Likes" value={avgLikes} subTitle />
        <InfoCard title="Average Comments" value={avgComments} subTitle />
        {showMetrices && (
          <>
            <InfoCard
              title="Average Reel Views"
              value={avgReelViews}
              subTitle
            />
            <InfoCard
              title="Engagement Ratio"
              value={engageRatio ? `${engageRatio}%` : "-"}
              subTitle
            />
            <InfoCard title="No of Post" value={numberOfPosts} subTitle />
            {/* <InfoCard title="HOB Score" value={hobScore} subTitle /> */}
            <InfoCard title="Impressions" value={impressions} subTitle />
          </>
        )}
      </div>
      {media?.length > 0 && (
        <div className="height-80 overflow-auto flex flex-column">
          <div className="text-grey-shade-6 padding-b-2 padding-t-3 padding-l-6  text-bold  text-4">
            Recent Posts
          </div>
          <div className="flex flex-wrap posts-wrapper width-100">
            {media?.map((item: any, index: number) => {
              return (
                <PostInfoCard
                  key={`Post-${index}`}
                  data={item}
                  isLoading={isLoadingPosts}
                />
              );
            })}
          </div>
        </div>
      )}
      {media?.length === 0 && (
        <div className="margin-t-4 margin-b-4 flex flex-justify-center text-5 text-grey-shade-5">
          No Post Available
        </div>
      )}
    </div>
  );
}
