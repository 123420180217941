import classNames from "classnames";
import CustomButton from "components/CustomButton";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import { URLRoutes } from "URLRoutes";

import "./AuthLayout.scss";

interface Props {
  children?: any;
  title: string;
  image: string;
  titlePosition: "top" | "bottom";
  imagePosition: "right" | "center";
  contentClassName: string;
  bannerClassName: string;
  isLoginPage?: boolean;
  GoBack?: boolean;
}

function AuthLayout(props: Props) {
  const navigate = useNavigate();
  const { image, title, titlePosition, children, contentClassName, bannerClassName, imagePosition , isLoginPage = false , GoBack } = props;
  return (
    <div className="width-100 flex flex-row height-100 position-relative">
      <div className="width-50 padding-4 position-relative">
        <div className={classNames(['width-100 height-100 auth-banner-section position-relative', bannerClassName || "" ])}>
          {/* <div className={classNames(['padding-10 text-secondary-color', {"width-50 auth-banner-section-text-top": titlePosition === "top", "width-65 auth-banner-section-text-bottom": titlePosition === "bottom"}])}>{title}</div> */}
          {/* <div className={classNames(['height-96 position-absolute', {"auth-banner-section-img-right": imagePosition === "right", "auth-banner-section-img-center": imagePosition === "center"}])}>
            <img src={image} style={{ width: "100%", height: "100%" }} />
          </div> */}
        </div>
      </div>
      <div className={classNames(["width-50 flex flex-column padding-12", contentClassName || ""])}>
     {!isLoginPage && !GoBack && <CustomButton
        iconProps={{
          svgType: SVGType.CUSTOM,
          name: CUSTOM_SVG_ICON.LeftArrow,
          size: "huge",
        }}
        iconPosition={ICON_POSITION.LEFT}
        buttonText="Go Back to Login"
        handleClick={() => navigate(URLRoutes.clients.login)}
        whiteBlueButton
        noOutline
        transparent
        baseclassname={"auth-banner-section-back cursor-pointer"}
        textSmall
        circular
      />}
      {GoBack && (
        <CustomButton
        iconProps={{
          svgType: SVGType.CUSTOM,
          name: CUSTOM_SVG_ICON.LeftArrow,
          size: "huge",
        }}
        iconPosition={ICON_POSITION.LEFT}
        buttonText="Go Back"
        handleClick={() => navigate(-1)}
        whiteBlueButton
        noOutline
        transparent
        baseclassname={"auth-banner-section-back cursor-pointer"}
        textSmall
        circular
      />
      )}
        {children}
      </div>
    </div>
  );
}

export default AuthLayout;
