export default () => {
    return (
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.3333 14.6666H12L16.3867 10.28C16.5116 10.156 16.6108 10.0085 16.6785 9.84606C16.7462 9.68358 16.7811 9.50931 16.7811 9.33329C16.7811 9.15727 16.7462 8.983 16.6785 8.82052C16.6108 8.65804 16.5116 8.51057 16.3867 8.38662C16.1368 8.13829 15.7989 7.9989 15.4467 7.9989C15.0944 7.9989 14.7565 8.13829 14.5067 8.38662L8.78667 14.12C8.28588 14.6178 8.00298 15.2938 8 15.9999C8.00649 16.7014 8.28913 17.3721 8.78667 17.8666L14.5067 23.5999C14.631 23.7234 14.7784 23.8211 14.9405 23.8876C15.1026 23.9541 15.2762 23.988 15.4514 23.9873C15.6266 23.9867 15.7999 23.9516 15.9615 23.884C16.1232 23.8164 16.2699 23.7176 16.3933 23.5933C16.5168 23.469 16.6145 23.3215 16.681 23.1594C16.7475 22.9974 16.7814 22.8238 16.7807 22.6486C16.7801 22.4734 16.745 22.3 16.6774 22.1384C16.6098 21.9768 16.511 21.8301 16.3867 21.7066L12 17.3333H25.3333C25.687 17.3333 26.0261 17.1928 26.2761 16.9428C26.5262 16.6927 26.6667 16.3536 26.6667 15.9999C26.6667 15.6463 26.5262 15.3072 26.2761 15.0571C26.0261 14.8071 25.687 14.6666 25.3333 14.6666Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  