import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import CustomButton from "components/CustomButton";
import { HookFormInput } from "components/FormInputs";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import "./style.scss";

function Form2({ control, errors , handleBack, fetching }: any) {
  return (
    <Fragment>
      <div className="input-label text-align-center">Add Prices(INR)</div>
      <HookFormInput
        control={control}
        errors={errors}
        label="Reel"
        id="reelprice"
        name="reelprice"
        placeholder="Price per reel..."
        iconProps={{
          name: CUSTOM_SVG_ICON.Cost,
          svgType: SVGType.CUSTOM,
          size: "small",
          baseclassname: "text-default-color",
        }}
      />
      <HookFormInput
        // validation={requiredValidation}
        control={control}
        errors={errors}
        label="Static Post"
        id="staticpostprice"
        name="staticpostprice"
        placeholder="Price per static post..."
        iconProps={{
          name: CUSTOM_SVG_ICON.Cost,
          svgType: SVGType.CUSTOM,
          size: "small",
          baseclassname: "text-default-color",
        }}
      />

      <HookFormInput
        // validation={requiredValidation}
        control={control}
        errors={errors}
        label="Carousel Post"
        id="carouselpostprice"
        name="carouselpostprice"
        placeholder="Price per carousel post..."
        iconProps={{
          name: CUSTOM_SVG_ICON.Cost,
          svgType: SVGType.CUSTOM,
          size: "small",
          baseclassname: "text-default-color",
        }}
      />
      <HookFormInput
        // validation={requiredValidation}
        control={control}
        errors={errors}
        label="Story"
        id="storyprice"
        name="storyprice"
        placeholder="Price per story..."
        iconProps={{
          name: CUSTOM_SVG_ICON.Cost,
          svgType: SVGType.CUSTOM,
          size: "small",
          baseclassname: "text-default-color",
        }}
      />
      <HookFormInput
        control={control}
        errors={errors}
        label="Swipe-Up Story"
        id="swipeupstoryprice"
        name="swipeupstoryprice"
        placeholder="Price per story..."
        iconProps={{
          name: CUSTOM_SVG_ICON.Cost,
          svgType: SVGType.CUSTOM,
          size: "small",
          baseclassname: "text-default-color",
        }}
      />
      <div className="text-3 text-align-center padding-b-4 width-100">
        By creating an account you agree with our
        <Link to="/" className="text-default-color text-underline"> Terms of Service , </Link>
        <Link to={"/"} className="text-default-color text-underline">Privacy Policy</Link>, and our default
        <Link to={"/"} className="text-default-color text-underline"> Notification Settings</Link>.
      </div>
      <CustomButton
        type={"submit"}
        buttonText="SKIP AND CONTINUE"
        primaryButton
        round
        baseclassname={"width-100"}
        loading={fetching}
      />
      <CustomButton 
      buttonText="Back"
      handleClick={handleBack}
      greyButton
      transparent
      textSmall
      buttonTextClass={"text-underline"}
      baseclassname={"width-100"}
      />
    </Fragment>
  );
}

export default Form2;
