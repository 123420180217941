import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { URLRoutes } from "URLRoutes";
import { useFieldArray, useForm } from "react-hook-form";
import CustomButton from "components/CustomButton";
import {
  HookFormInput,
  HookFormSelect,
  HookPhoneInput,
} from "components/FormInputs";
import HeaderLayout from "components/HeaderLayout";
import { getBrandListState, getBrandUserState } from "store/selectors";
import {
  requestBrandUserDetail,
  requestBrandsList,
  requestCreateBrandUser,
  requestUpdateBrandUser,
} from "store/rext";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import {
  hookPhoneNumberValidation,
  hookformRequired,
} from "utils/FormValidations";
import { transformOptionsToKeyValue } from "utils/utils";
import { toast } from "react-toastify";
import UsePrevious from "HOC/UsePrevious";
import Loading from "components/Loading";
import { useUserDetail } from "HOC/useUserDetail";
import { ROLES } from "utils/Enums";

const requiredValidation: any = {
  ...hookformRequired("Required"),
};

const phoneNumberValidation = {
  ...hookformRequired("Required"),
  ...hookPhoneNumberValidation(),
};

const DEFAULT_VALUE: any = {
  brand_id: "",
  userList: [
    {
      email: "",
      name: "",
      number: "",
      user_role: "Manager",
    },
  ],
};

function BrandUserForm() {
  const { brandUserId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role } = useUserDetail();
  const { data: brandsList } = useSelector(getBrandListState);
  const updatedUrl = URLRoutes.clients.updateBrandUser.replace(
    ":brandUserId",
    brandUserId as string
  );
  const {
    data: brandDetail,
    fetching,
    message = "",
    error = "",
  } = useSelector(getBrandUserState);
  const wasfecthing = UsePrevious(fetching);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    trigger,
  } = useForm({
    defaultValues: useMemo(() => {
      return brandDetail.records || DEFAULT_VALUE;
    }, [brandDetail.records]),
  });

  const { fields, append, remove } = useFieldArray({
    name: `userList`,
    control,
    rules: {
      required: "At least one user is required",
    },
  });

  const handleAppend = () => {
    append({
      email: "",
      name: "",
      number: "",
      user_role: "Manager",
    });
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  const handleOnSubmit = async (data: any) => {
    await trigger();
    if (brandUserId) {
      dispatch(requestUpdateBrandUser(data, { id: brandUserId }));
    } else {
      dispatch(requestCreateBrandUser(data));
    }
  };
  const handleCancelClick = () => {
    if (brandUserId) {
      reset();
      navigate(URLRoutes.clients.brandUsersList);
    } else {
      navigate(URLRoutes.clients.brandUsersList);
    }
  };

  useEffect(() => {
    if (message.length > 0) {
      if (wasfecthing && !fetching && !error) {
        if (brandDetail?.existingUser?.length) {
          let emailId = "";
          brandDetail.existingUser.map((user: any) => (emailId += ` ${user} `));
          toast.info(
            "Can not create Brand user(s) with below email(s) as they are already added as an Influencers: " +
              emailId
          );
        } else {
          toast.success(message);
          setTimeout(() => {
            navigate(URLRoutes.clients.brandUsersList);
          }, 3000);
        }
        // if (brandDetail?.invalidPhone?.length) {
        //   let mobile = "";
        //   brandDetail.invalidPhone.map((user: any) => (mobile += ` ${user} `));
        //   toast.info(
        //     "Below phone number(s) not valid as we are not able to send notifications: " +
        //       mobile
        //   );
        // }
      } else if (error) {
        toast.error(message);
      }
    }
  }, [fetching, error, message]);

  useEffect(() => {
    if (brandUserId) {
      reset(brandDetail.records);
    } else {
      reset(DEFAULT_VALUE);
    }
  }, [reset, brandUserId, brandDetail]);

  useEffect(() => {
    if (brandUserId) {
      dispatch(requestBrandUserDetail({ id: brandUserId }));
    }
  }, [brandUserId]);

  useEffect(() => {
    dispatch(
      requestBrandsList({
        page: 1,
        pageSize: 50,
      })
    );
  }, []);

  return (
    <HeaderLayout
      title={brandUserId ? "Update Brand User" : "Create Brand User"}
      breadcrumbs={[
        {
          text: "Dashboard",
          url:
            role === ROLES.HOB
              ? URLRoutes.clients.users.replace(":userType", ROLES.INFLUENCER)
              : role === ROLES.INFLUENCER
              ? URLRoutes.clients.instaDashboard
              : URLRoutes.clients.profile,
        },
        {
          text: brandUserId ? "Update Brand User" : "Create Brand User",
          url: brandUserId ? updatedUrl : URLRoutes.clients.createBrandUser,
        },
      ]}
      contentClassName="overflow-auto"
    >
      <div className="text-4 text-grey-shade2 padding-8">
        Kindly complete the form below with the necessary details to initiate
        the creation of your Brand.
      </div>
      {!fetching && (
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          className="width-85 margin-l-8"
        >
          <HookFormSelect
            control={control}
            errors={errors}
            options={transformOptionsToKeyValue(brandsList.records)}
            label="Brand Name"
            id="brand_id"
            name="brand_id"
            placeholder="Enter Name"
            baseClassName="width-60"
            validation={requiredValidation}
            required
          />
          <label className="input-label padding-l-1 padding-b-1">
            Add Brand User
          </label>
          <CustomButton
            type="button"
            transparent
            greyButton
            iconProps={{
              name: CUSTOM_SVG_ICON.Plus,
              svgType: SVGType.CUSTOM,
              size: "small",
              baseclassname: "text-default-color",
            }}
            outline
            circular
            iconPosition={ICON_POSITION.LEFT}
            handleClick={handleAppend}
          />
          <div className="flex flex-column flex-wrap width-100 ">
            {fields.map((field: any, index: number) => {
              return (
                <div
                  key={field.id}
                  className="flex flex-row  flex-align-center width-100 flex-wrap  user-rows"
                >
                  <div className="width-95 flex flex-row flex-justify-center  flex-align-center">
                    <HookFormInput
                      control={control}
                      errors={errors}
                      id="name"
                      name={`userList[${index}].name`}
                      placeholder="Enter Name"
                      baseClassName="width-25 margin-r-2"
                      validation={requiredValidation}
                      required
                    />
                    <HookFormInput
                      control={control}
                      errors={errors}
                      id="email"
                      name={`userList[${index}].email`}
                      placeholder="Enter Email"
                      baseClassName="width-25 margin-r-2"
                      validation={requiredValidation}
                      required
                    />
                    <HookPhoneInput
                      control={control}
                      errors={errors}
                      id="number"
                      name={`userList[${index}].number`}
                      placeholder="Enter Phone Number"
                      baseClassName="width-25 margin-r-2"
                      validation={phoneNumberValidation}
                      dropdownClassName="users-phoneInput"
                      required
                    />
                
                    <HookFormInput
                      control={control}
                      errors={errors}
                      id="user_role"
                      name={`userList[${index}].user_role`}
                      placeholder="Enter User Role"
                      baseClassName="width-25 margin-r-2"
                      disabled
                    />
                  </div>
                  {index > 0 && (
                    <CustomButton
                      type="button"
                      transparent
                      greyButton
                      iconProps={{
                        name: CUSTOM_SVG_ICON.Delete,
                        svgType: SVGType.CUSTOM,
                        size: "small",
                        baseclassname: "text-default-color",
                      }}
                      outline
                      circular
                      iconPosition={ICON_POSITION.LEFT}
                      handleClick={() => handleRemove(index)}
                    />
                  )}
                  {/* {index === fields.length - 1 ? (
                  <CustomButton
                    type="button"
                    transparent
                    greyButton
                    iconProps={{
                      name: CUSTOM_SVG_ICON.Plus,
                      svgType: SVGType.CUSTOM,
                      size: "small",
                      baseclassname: "text-default-color",
                    }}
                    outline
                    circular
                    iconPosition={ICON_POSITION.LEFT}
                    handleClick={handleAppend}
                  />
                ) : (
                  <CustomButton
                    type="button"
                    transparent
                    greyButton
                    iconProps={{
                      name: CUSTOM_SVG_ICON.Delete,
                      svgType: SVGType.CUSTOM,
                      size: "small",
                      baseclassname: "text-default-color",
                    }}
                    outline
                    circular
                    iconPosition={ICON_POSITION.LEFT}
                    handleClick={() => handleRemove(index)}
                  />
                )} */}
                </div>
              );
            })}
          </div>

          <div className="padding-t-4 padding-b-6">
            <CustomButton
              buttonText="Cancel"
              secondaryButton
              handleClick={handleCancelClick}
              baseclassname={"cursor-pointer margin-r-4"}
              textSmall
              circular
            />
            <CustomButton
              type="submit"
              buttonText={brandUserId ? "Update" : "Submit"}
              primaryButton
              baseclassname={" cursor-pointer"}
              textSmall
              circular
            />
          </div>
        </form>
      )}
      {fetching && <Loading />}
    </HeaderLayout>
  );
}

export default BrandUserForm;
