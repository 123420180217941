import CustomButton, {
  ICON_POSITION,
} from "components/CustomButton/CustomButton";
import { SVGType } from "components/SvgIcon";
import moment from "moment";

const getYears = (startYear: number, endYear: number) => {
  const options: number[] = [];
  for (let year = startYear; year <= endYear; year++) {
    options.push(year);
  }
  return options;
};

const years = getYears(1900, 2030);
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const DateRangeHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: any) => {
  const renderOptions = (option: any) => (
    <option key={option} value={option}>
      {option}
    </option>
  );

  return (
    <div className="flex flex-row flex-justify-between flex-align-center">
      <CustomButton
        handleClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        iconPosition={ICON_POSITION.LEFT}
        iconProps={{
          name: "caret left",
          svgType: SVGType.SEMANTIC,
          size: "small",
        }}
        transparent
        greyButton
        noOutline
      />

      <div>
        <select
          value={moment(date).format("yyyy")}
          onChange={({ target: { value } }: any) =>
            changeYear(parseInt(value, 10))
          }
          className="margin-r-4"
        >
          {years.map(renderOptions)}
        </select>

        <select
          value={moment(date).format("MMMM")}
          onChange={({ target: { value } }: any) => {
            changeMonth(months.indexOf(value));
          }}
        >
          {months.map(renderOptions)}
        </select>
      </div>

      <CustomButton
        handleClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        iconPosition={ICON_POSITION.RIGHT}
        iconProps={{
          name: "caret right",
          svgType: SVGType.SEMANTIC,
          size: "small",
        }}
        transparent
        greyButton
        noOutline
      />
    </div>
  );
};

export default DateRangeHeader;