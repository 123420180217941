import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { requestLocationMatrixDetails } from "store/rext";
import { getAllMatrixState } from "store/selectors";
import Loading from "components/Loading";
import CustomButton from "components/CustomButton";

enum LocationTypes {
  CITY = "city",
  STATE = "state",
  COUNTRY = "country",
}

const StatsCard = ({ title, name , value }: {title: string , name: string , value: string}) => (
  <div className="flex flex-column width-30 margin-b-2 margin-r-2">
    <div className="text-3 line-h-5 text-grey-shade-4">{title}</div>
    <div className="text-4 line-h-5 text-bold">{name}</div>
    <div className="text-4 text-grey-shade-4">{value ? `${value}%` : "-"}</div>
  </div>
);

function getLocationMatrix(data: any[], locationType: LocationTypes) {
  const counts: { [key: string]: number } = {};
  data?.forEach((item) => {
    const key = item[locationType];
    if (key) {
      counts[key] = (counts[key] || 0) + parseInt(item.count, 10);
    }
  });

  const categories = Object.keys(counts);
  const totalCounts = categories.reduce((sum, category) => sum + counts[category], 0);

  const dataArray = categories.map((category) => Math.round((counts[category] / totalCounts) * 100));
  return { categories, dataArray };
}

export function FollowersByLocation({ instaHandle }: { instaHandle: string }) {
  const dispatch = useDispatch();
  const { data, fetching, error, message } = useSelector(getAllMatrixState);
  const { ageGroupData , topCityName , topCityPercentage , topCountryName , topCountryPercentage , topStateName , topStatePercentage} = data || {};
  const [selectedLocationType, setSelectedLocationType] =
    useState<LocationTypes>(LocationTypes.COUNTRY);
  const { categories, dataArray } = getLocationMatrix( ageGroupData, selectedLocationType );

  const ageGroupChartOptions: Highcharts.Options = {
    chart: {
      type: "column",
    },
    title: {
      text: undefined,
    },
    xAxis: {
      // categories: [
      //   "Delhi",
      //   "Chandigarh",
      //   "Haryana",
      //   "Mumbai",
      //   "Gurgaon",
      //   "Kullu",
      //   "Shimla",
      //   "Jaipur",
      // ],
      categories: categories,
    },
    yAxis: {
      title: {
        text: undefined,
      },
      labels: {
        format: "{value}%",
      }, 
      max: 100 
    },
    series: [
      {
        type: "column",
        name: "Location",
        // data: [70, 30, 15, 40, 50, 35, 5, 20],
        data: dataArray,
      },
    ],
    plotOptions: {
      column: {
        pointWidth: 30,
      },
    },
    tooltip: {
      enabled: true ,
      valueSuffix: '%'
   },
    credits: {
      enabled: false,
    },
  };

  const handleLocationChange = (locationType: LocationTypes) => {
    setSelectedLocationType(locationType);
  };

  useEffect(() => {
    dispatch(
      requestLocationMatrixDetails(
        {
          instaHandle,
          locationType: selectedLocationType,
        },
        { handle: instaHandle }
      )
    );
  }, [selectedLocationType]);

  return (
    <div className="width-100 flex flex-column flex-align-center padding-2">
      {!fetching && data?.ageGroupData?.length > 0 && (
        <div className="flex flex-row flex-justify-evenly flex-align-center width-100">
        <div className="flex flex-justify-center width-70 margin-t-2 margin-b-2">
          <StatsCard title="TOP CITY" value={topCityPercentage} name={topCityName} />
          <StatsCard title="TOP STATE" value={topStatePercentage} name={topStateName} />
          <StatsCard title="TOP COUNTRY" value={topCountryPercentage} name={topCountryName} />
          </div>
          <div className="flex flex-justify-end width-30 margin-b-2">
          <CustomButton
            buttonText="City"
            whiteBlueButton
            outline={selectedLocationType !== LocationTypes.CITY}
            textSmall
            handleClick={() => handleLocationChange(LocationTypes.CITY)}
          />
          <CustomButton
            buttonText="State"
            whiteBlueButton
            outline={selectedLocationType !== LocationTypes.STATE}
            textSmall
            handleClick={() => handleLocationChange(LocationTypes.STATE)}
          />
          <CustomButton
            buttonText="Country"
            whiteBlueButton
            outline={selectedLocationType !== LocationTypes.COUNTRY}
            textSmall
            handleClick={() => handleLocationChange(LocationTypes.COUNTRY)}
          />
          </div>
        </div>
      )}
      {fetching && <Loading inverted />}
      {!fetching && data?.ageGroupData?.length > 0 && (
        <HighchartsReact
          highcharts={Highcharts}
          options={ageGroupChartOptions}
          containerProps={{ className: "height-50 width-90 margin-t-3" }}
        />
      )}
      {!fetching && (data?.ageGroupData?.length === undefined || error) && (
        <div className="padding-t-4 padding-b-4 text-4 text-bold text-grey-shade4">
          {message}
        </div>
      )}
    </div>
  );
}
