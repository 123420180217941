export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.0005 2.25H18.75V0.75C18.75 0.33525 18.4147 0 18 0C17.5853 0 17.25 0.33525 17.25 0.75V2.25H12.75V0.75C12.75 0.33525 12.414 0 12 0C11.586 0 11.25 0.33525 11.25 0.75V2.25H6.75V0.75C6.75 0.33525 6.414 0 6 0C5.586 0 5.25 0.33525 5.25 0.75V2.25H2.00025C0.8955 2.25 0 3.14475 0 4.2495V21.9998C0 23.1045 0.8955 24 2.00025 24H22.0005C23.1052 24 24 23.1045 24 21.9998V4.2495C24 3.14475 23.1052 2.25 22.0005 2.25ZM22.5 21.9998C22.5 22.2757 22.2757 22.5 22.0005 22.5H2.00025C1.72425 22.5 1.5 22.2757 1.5 21.9998V4.2495C1.5 3.97425 1.72425 3.75 2.00025 3.75H5.25V5.25C5.25 5.66475 5.586 6 6 6C6.414 6 6.75 5.66475 6.75 5.25V3.75H11.25V5.25C11.25 5.66475 11.586 6 12 6C12.414 6 12.75 5.66475 12.75 5.25V3.75H17.25V5.25C17.25 5.66475 17.5853 6 18 6C18.4147 6 18.75 5.66475 18.75 5.25V3.75H22.0005C22.2757 3.75 22.5 3.97425 22.5 4.2495V21.9998Z"
        fill="currentColor"
      />
      <path d="M8.25 9H5.25V11.25H8.25V9Z" fill="currentColor" />
      <path d="M8.25 12.75H5.25V15H8.25V12.75Z" fill="currentColor" />
      <path d="M8.25 16.5H5.25V18.75H8.25V16.5Z" fill="currentColor" />
      <path d="M13.5 16.5H10.5V18.75H13.5V16.5Z" fill="currentColor" />
      <path d="M13.5 12.75H10.5V15H13.5V12.75Z" fill="currentColor" />
      <path d="M13.5 9H10.5V11.25H13.5V9Z" fill="currentColor" />
      <path d="M18.75 16.5H15.75V18.75H18.75V16.5Z" fill="currentColor" />
      <path d="M18.75 12.75H15.75V15H18.75V12.75Z" fill="currentColor" />
      <path d="M18.75 9H15.75V11.25H18.75V9Z" fill="currentColor" />
    </svg>
  );
};
