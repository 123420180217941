export const clients = {
    home:"/" , 
    login:"/login",
    forgotPassword:"/forgotPassword",
    mobileVerify:"/mobileVerify/:id",
    resetPassword:"/resetPassword/:token",
    changePassword:"/changePassword/:token",
    verifyOTP:"/verifyOTP/:id/:otpType",
    signUp:"/signUp",
    signUpWithId:"/signUp/:id",
    signUpEmail: "/signUp/email",
    signUpEmailWithId: "/signUp/email/:id",
    registration: "/registration/:id",
    inviteRegister: "/registration/:id/:referrer",
    users: "/users/:userType",
    userInvite: "/user/invite",
    instaDashboard: "/insta-dashboard",
    instaDashboardDetail: "/dashboard/:handle",
    profile: "/profile", 
    profilePage: "/profile-page/:id", 
    createCampaign: "/campaign/create",
    updateCampaign: "/campaign/update/:id",
    campaignList: "/campaign/list",
    faq : "/faq",
    help :"/help",
    influencerDetail: "/influencer/detail/:campaignId",
    notAllowed:"/not-allowed",
    createBrand:"/brand/create",
    updateBrand: "/brand/update/:brandId",
    brandList: "/brand/list",
    createBrandUser: "/brand-users/create",
    updateBrandUser: "/brand-users/update/:brandUserId",
    brandUsersList: "/brand-users/list", 
    notifications: "/notifications",
    chatNotifications: "/chat-notifications", 
    influencerList: "/influencersList" , 
    facebookAuth: "/facebookAuth/:id" 
  }