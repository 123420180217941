export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.55097 1.65335C10.3444 0.782218 11.715 0.782218 12.5084 1.65335L13.5286 2.77358C13.9295 3.21385 14.5058 3.45255 15.1007 3.42475L16.6142 3.35402C17.7911 3.29901 18.7604 4.26824 18.7054 5.44521L18.6346 6.95872C18.6068 7.55355 18.8455 8.12983 19.2858 8.53078L20.406 9.55097C21.2772 10.3444 21.2772 11.715 20.406 12.5084L19.2858 13.5286C18.8455 13.9295 18.6068 14.5058 18.6346 15.1007L18.7054 16.6142C18.7604 17.7911 17.7911 18.7604 16.6142 18.7054L15.1007 18.6346C14.5058 18.6068 13.9295 18.8455 13.5286 19.2858L12.5084 20.406C11.715 21.2772 10.3444 21.2772 9.55097 20.406L8.53078 19.2858C8.12983 18.8455 7.55355 18.6068 6.95872 18.6346L5.4452 18.7054C4.26824 18.7604 3.29901 17.7911 3.35402 16.6142L3.42475 15.1007C3.45255 14.5058 3.21385 13.9295 2.77358 13.5286L1.65335 12.5084C0.782218 11.715 0.782218 10.3444 1.65335 9.55097L2.77358 8.53078C3.21385 8.12983 3.45255 7.55355 3.42475 6.95872L3.35402 5.4452C3.29901 4.26824 4.26824 3.29901 5.44521 3.35402L6.95872 3.42475C7.55355 3.45255 8.12983 3.21385 8.53078 2.77358L9.55097 1.65335Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M10.48 6.324C11.352 6.324 12.036 6.504 12.532 6.864C13.036 7.216 13.288 7.768 13.288 8.52C13.288 8.976 13.184 9.372 12.976 9.708C12.768 10.044 12.476 10.308 12.1 10.5C11.732 10.692 11.296 10.8 10.792 10.824L10.624 10.224C11.208 10.24 11.732 10.344 12.196 10.536C12.66 10.728 13.028 10.996 13.3 11.34C13.572 11.684 13.708 12.092 13.708 12.564C13.708 12.996 13.628 13.368 13.468 13.68C13.308 13.984 13.092 14.236 12.82 14.436C12.548 14.628 12.24 14.772 11.896 14.868C11.552 14.956 11.196 15 10.828 15H8.248V6.324H10.48ZM10.72 10.128C11.2 10.128 11.548 9.996 11.764 9.732C11.98 9.46 12.088 9.132 12.088 8.748C12.088 8.292 11.948 7.96 11.668 7.752C11.388 7.544 11.012 7.44 10.54 7.44H9.412V10.128H10.72ZM10.768 13.92C11.088 13.92 11.38 13.872 11.644 13.776C11.916 13.68 12.128 13.532 12.28 13.332C12.44 13.132 12.52 12.88 12.52 12.576C12.52 12.24 12.428 11.976 12.244 11.784C12.068 11.592 11.84 11.456 11.56 11.376C11.28 11.288 10.992 11.244 10.696 11.244H9.412V13.92H10.768Z"
        fill="currentColor"
      />
    </svg>
  );
};
