export enum ModalsTypes {
  AlertModal = "AlertModalView",
  DetailedTermModal = "DetailedTermModalView"
  
}

export interface IModalOptions {
  toggle?: any;
  title: string,
  message: string,
  handleSuccessClick?: (data?: any) => void;
  handleCancelClick?: (data?: any) => void;
  primaryBtnTitle?: string;
  secondaryBtnTitle?: string;
  successButtonClass?: any;
  cancelButtonClass?: string;
  hideCancelButton?: boolean;
  hideSuccessButton?: boolean;
}

export interface IModal {
  type: ModalsTypes,
  options: IModalOptions,
  otherOptions: any
}

let modals: Array<IModal> = [];
let listeners: Array<any> = [];

export const modalStore = {
  showModal(type: ModalsTypes, options: IModalOptions, otherOptions?: any) {
    modals = [{
      type,
      options,
      otherOptions: otherOptions || {}
    }]
    emitChange();
  },
  removeModal(index: number) {
    modals.splice(index, 1);
    modals = [...modals];
    emitChange();
  },
  subscribe(listener: any) {
    listeners = [...listeners, listener];
    return () => {
      listeners = listeners.filter(l => l !== listener);
    };
  },
  getSnapshot() {
    return modals;
  }
};

function emitChange() {
  for (let listener of listeners) {
    listener();
  }
}