export default () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25 50C11.195 50 0 38.805 0 25C0 11.195 11.195 0 25 0C38.805 0 50 11.195 50 25C50 38.805 38.805 50 25 50Z" fill="#3B579D" />
      <path
        d="M31.5 50V30.635H38L38.975 23.09H31.5V18.27C31.5 16.085 32.105 14.595 35.24 14.595H39.235V7.84999C38.545 7.75999 36.17 7.55499 33.41 7.55499C27.65 7.55499 23.705 11.07 23.705 17.53V23.095H17.19V30.64H23.705V50H31.5Z"
        fill="white"
      />
    </svg>
  );
};
