export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0C5.3828 0 0 5.3833 0 12C0 18.6167 5.3828 24 12 24C18.6172 24 24 18.6167 24 12C24 5.3833 18.6172 0 12 0ZM12 22C6.4863 22 2 17.5142 2 12C2 6.4858 6.4863 2 12 2C17.5137 2 22 6.4858 22 12C22 17.5142 17.5137 22 12 22ZM16.707 8.707L13.4141 12L16.7071 15.293C17.0977 15.6836 17.0977 16.3164 16.7071 16.7071C16.5117 16.9023 16.2559 17 16 17C15.7441 17 15.4883 16.9023 15.293 16.707L12 13.4141L8.707 16.7071C8.5117 16.9023 8.2559 17 8 17C7.7441 17 7.4883 16.9023 7.293 16.707C6.9024 16.3164 6.9024 15.6836 7.293 15.2929L10.5859 12L7.2929 8.707C6.9023 8.3164 6.9023 7.6836 7.2929 7.2929C7.6835 6.9022 8.3163 6.9023 8.707 7.2929L12 10.5859L15.293 7.2929C15.6836 6.9023 16.3164 6.9023 16.7071 7.2929C17.0978 7.6835 17.0977 8.3164 16.707 8.707Z"
        fill="currentColor"
      />
    </svg>
  );
};
