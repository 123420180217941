import { Modal } from "semantic-ui-react";
import CustomButton from "components/CustomButton";
import { ModalHeader } from "./Common";

interface Props {
  hideModal: () => void;
  onClose: () => void;
  onSave: () => void;
  title: string;
  message: string;
  primaryBtnTitle?: string;
  secondaryBtnTitle?: string;
}

function AlertModal(props: Props) {
  const {
    message,
    title,
    secondaryBtnTitle,
    primaryBtnTitle,
    hideModal,
    onSave,
    onClose
  } = props;

  return (
    <Modal
      size="mini"
      dimmer
      open
      onClose={onClose}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <ModalHeader title={title} toggle={hideModal} />
      <Modal.Content>
        <div className="flex flex-column text-align-center flex-align-center width-100">
          {message}
          <div className="flex flex-justify-between flex-align-center margin-t-6">
            <CustomButton
              textSmall
              primaryButton
              buttonText={primaryBtnTitle || "OK"}
              handleClick={onSave}
            />
            {onClose && secondaryBtnTitle &&  <CustomButton
              textSmall
              secondaryButton
              buttonText={secondaryBtnTitle || "Cancel"}
              handleClick={onClose}
              baseclassname={"margin-l-2"}
            />}
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}
export default AlertModal;
