import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { URLRoutes } from "URLRoutes";
import classNames from "classnames";
import ShortlistedInfluencer from "./ShortlistedInfluencer";
import FinalisedInfluencers from "./FinalisedInfluencers";
import CustomButton from "components/CustomButton";
import { modalOperation } from "store/actions";
import { ModalsType } from "containers/ModalManager/ModalManager";
import { StatusInfo } from "containers/Campaigns/utils";
import HeaderLayout from "components/HeaderLayout";
import {
  postToBrands,
  postToInfluencers,
  requestCampaignDetail,
} from "store/rext";
import {
  getAuthState,
  getCampaignDetailState,
  getCampaignInfluencerListState,
  getPostedInfluencersState,
} from "store/selectors";
import {
  CAMPAIGN_COLLABORATION_ACTIONS,
  CAMPAIGN_COLLABORATION_STATUS,
  ROLES,
} from "utils/Enums";
import { toast } from "react-toastify";
import UsePrevious from "HOC/UsePrevious";
import Loading from "components/Loading";
import { useUserDetail } from "HOC/useUserDetail";
import { formatStatusString } from "utils/utils";
import "./style.scss";

enum IVIEWS {
  Shortlisted = "Shortlisted Influencers",
  Finalised = "Finalised Influencers",
}

export const checkBudgets = (selectedUsersData: any[], records: any[]) => {
  return selectedUsersData.every((influencerId) => {
    const item = records.find(
      (record: any) => record.influencerDetails.id === influencerId
    );
    return item && item.budget;
  });
};

const alreadyPostedInfluencers = (selectedUsersData: any[], records: any[]) => {
  return selectedUsersData.filter((influencerId) => {
    const item = records.find(
      (record: any) => record.influencerDetails.id === influencerId
    );
    return (
      item &&
      item.collaboration_status ===
        CAMPAIGN_COLLABORATION_STATUS.POST_TO_INFLUENCER
    );
  });
};

function InfluencerDetail() {
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const { role, features } = useUserDetail();
  const [selectedUsersData, setSelectedUsersData] = useState<any[]>([]);
  const [currentView, setCurrentView] = useState<IVIEWS>(IVIEWS.Shortlisted);
  const { data: campaignDetail, fetching: campaignFetching } = useSelector(
    getCampaignDetailState
  );
  const {
    data: posted,
    message,
    fetching,
    error,
  } = useSelector(getPostedInfluencersState);
  const wasfecthing = UsePrevious(fetching);
  const { data: campaignInfluencers , fetching: influencersFetching } = useSelector(getCampaignInfluencerListState);
  const { records } = campaignInfluencers || {};
  const renderView = (view: IVIEWS) => {
    switch (view) {
      case IVIEWS.Shortlisted:
        return (
          <ShortlistedInfluencer
            campaignId={campaignId}
            handleCheckBoxChange={handleCheckBoxChange}
            role={role}
            features={features}
            validationMessage={message}
            setSelectedUsersdata={setSelectedUsersData}
          />
        );
      case IVIEWS.Finalised:
        return (
          <FinalisedInfluencers
            campaignId={campaignId}
            features={features}
            role={role}
            campaignType={campaignDetail.campaigntype}
                        setSelectedUsersdata={setSelectedUsersData}
          />
        );
      default:
        return null;
    }
  };

  const handleAddInfluencer = () => {
    dispatch(
      modalOperation.showModal(ModalsType.AddInfluencerModal, {
        onSave: (data: any) => {
          dispatch(modalOperation.hideModal());
        },
        onClose: () => {
          dispatch(modalOperation.hideModal());
        },
        campaignId: campaignId,
        categories:  campaignDetail?.category || []
      })
    );
  };

  const handlePostToInfluencer = () => {
    if (alreadyPostedInfluencers(selectedUsersData, records).length > 0) {
      // setSelectedUsersData([]);
      toast.warn("Selected User(s) are already posted to this campaign.", {
        autoClose: 30000,
        position: "top-center",
        style: { width: 600 },
      });
      return;
    }

    // if (!checkBudgets(selectedUsersData, records)) {
    //   toast.warn(
    //     "Please fill in the budget for the selected influencer(s) before posting.",
    //     {
    //       closeButton: true,
    //       closeOnClick: true,
    //       autoClose: 30000,
    //       position: "top-center",
    //       style: { width: 600 },
    //     }
    //   );
    //   return;
    // }

    dispatch(
      postToInfluencers({
        campaignId: campaignId,
        influencerIds: selectedUsersData,
        action: CAMPAIGN_COLLABORATION_ACTIONS.POST_TO_INFLUENCER,
      })
    );
    setSelectedUsersData([]);
  };
  
  const handlePostToBrand = () => {
    // if (!checkBudgets(selectedUsersData, records)) {
    //   toast.warn(
    //     "Please fill in the budget for the selected influencer(s) before posting.",
    //     {
    //       closeButton: true,
    //       closeOnClick: true,
    //       autoClose: 30000,
    //       position: "top-center",
    //       style: { width: 600 },
    //     }
    //   );
    //   return;
    // }

    dispatch(
      postToBrands({
        campaignId: campaignId,
        influencerIds: selectedUsersData,
      })
    );
  };

  const handleCheckBoxChange = (influencerId: any) => {
    const isSelected = selectedUsersData.includes(influencerId);

    if (isSelected) {
      setSelectedUsersData(
        selectedUsersData.filter((id) => id !== influencerId)
      );
    } else {
      setSelectedUsersData([...selectedUsersData, influencerId]);
    }
  };

  useEffect(() => {
    if (wasfecthing && !fetching && !error) {
      toast.success(message);
    } else if (error) {
      toast.error(message);
    }
  }, [fetching, error, message]);

  useEffect(() => {
    dispatch(
      requestCampaignDetail(
        { id: campaignId },
        role === ROLES.INFLUENCER
          ? URLRoutes.server.campaignInfluencerDetail
          : URLRoutes.server.campaignDetail
      )
    );
  }, [campaignId]);

  return (
    <HeaderLayout
      title="Influencer Detail"
      breadcrumbs={[
        {
          text: "Dashboard",
          url:
            role === ROLES.HOB
              ? URLRoutes.clients.users.replace(":userType", ROLES.INFLUENCER)
              : role === ROLES.INFLUENCER
              ? URLRoutes.clients.instaDashboard
              : URLRoutes.clients.profile,
        },
        {
          text: "Manage Campaign",
          url: `${URLRoutes.clients.campaignList}?campaign_id=${campaignId}`,
        },
        {
          text: "Manage Influencers",
          url: URLRoutes.clients.influencerDetail.replace(
            ":campaignId",
            campaignId as string
          ),
        },
      ]}
      contentClassName="flex flex-column width-100"
    >
      <div className="width-100  flex padding-3 background-light-blue">
        <div className="header-image flex flex-align-center flex-justify-center padding-1">
          <img
            src={campaignDetail.brandInfo?.logo_url || "/images/No_Image.png"}
            className="width-100 height-100"
            alt="campaign"
          />
        </div>
        <div className="header-content flex flex-row flex-align-center flex-justify-between campaign-info padding-l-2">
          {/* <div className="flex flex-justify-between padding-b-2"> */}
          <div className="header-name width-60">
            {campaignDetail.name}
            <br />
            <span className="header-subName">
              {campaignDetail.brandInfo?.name}
            </span>
          </div>
          <div className="width-30 flex flex-justify-end flex-align-center">
            <div className="margin-r-3 influencer-campaign">
              Campaign type:
              <span className="text-bold margin-l-1">
                {formatStatusString(campaignDetail.campaigntype)}
              </span>
            </div>
            <StatusInfo campaignStatus={campaignDetail.status} />
          </div>
          {/* </div> */}
        </div>
      </div>
      <div className="padding-l-6 padding-r-6 height-84">
        <div className="width-100 flex flex-row border-bottom-2 padding-t-2">
          <div className="width-50 flex flex-row">
            {Object.values(IVIEWS).map((view) => (
              <div
                key={view}
                className={classNames(
                  ["width-40 cursor-pointer flex flex-align-center flex-justify-center",
                  { "text-grey-shade2 ": view !== currentView },
                  { "text-orange-shade1 border-bottom-3": view === currentView }],
                  {"padding-2" : view === IVIEWS.Finalised}
                )}
                onClick={() => setCurrentView(view)}
              >
                {view}
              </div>
            ))}
          </div>
          {currentView === IVIEWS.Shortlisted && (
            <div className="width-50 flex flex-align-center flex-justify-end padding-b-2">
              {features.getInfluencerDetailFeature().postToBrand && (
                <CustomButton
                  buttonText="Post to Brand"
                  whiteBlueButton
                  textSmall
                  handleClick={handlePostToBrand}
                  // loading={fetching}
                  disabled={!selectedUsersData.length}
                />
              )}
              {features.getInfluencerDetailFeature().postToInfluencers && (
                <CustomButton
                  buttonText="Post to Influencer"
                  whiteBlueButton
                  textSmall
                  handleClick={handlePostToInfluencer}
                  // loading={fetching}
                  disabled={!selectedUsersData.length}
                />
              )}
              {features.getInfluencerDetailFeature().addInfluencer && 
              <CustomButton
                buttonText="Add Influencer"
                primaryButton
                textSmall
                circular
                handleClick={handleAddInfluencer}
              />}
            </div>
          )}
        </div>
        {(!influencersFetching || !campaignFetching) && renderView(currentView)}
        {(influencersFetching && campaignFetching) && <Loading isGlobal />}
      </div>
    </HeaderLayout>
  );
}

export default InfluencerDetail;
