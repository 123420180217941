// import React, { useEffect, useState, useCallback } from "react";
// import classNames from "classnames";
// import SvgIcon, { SVGType } from "components/SvgIcon";
// import { IconSizeProp } from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";
// import { debounce } from "utils/utils";

// import "./style.scss"


// interface IICONPROPS {
//   svgType: SVGType;
//   circular?: boolean;
//   name: any;
//   size?: IconSizeProp;
//   baseclassname?: any;
//   inverted?: boolean;
// }

// interface IProps {
//   label?: string;
//   labelClassName?: string;
//   baseClassName?: string;
//   onChange?: (name: string, value: any) => void;
//   inputClassName?: string;
//   id: string;
//   placeholder: string;
//   iconProps?: IICONPROPS;
//   disabled?: boolean;
//   onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
//   isDebounced?: boolean;
//   name: string;
//   initialValue?: any;
//   ref?: any;
//   iconRight?: boolean;
//   type?:string;
// }

// export function CustomInput(props: IProps) {
//   const {
//     label,
//     labelClassName,
//     baseClassName,
//     onChange,
//     inputClassName,
//     id,
//     placeholder,
//     iconProps,
//     disabled,
//     onBlur,
//     name,
//     initialValue,
//     ref,
//     iconRight,
//     type
//   } = props;

//   const [inputValue, setInputValue] = useState<string>(initialValue || "");

//   const debouncedOnChange = useCallback(
//     debounce((value: string) => {
//       if (typeof onChange === "function") {
//         onChange(name, value);
//       }
//     }, 1000),
//     [onChange, name]
//   );

//   const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const { value } = event.target;
//     setInputValue(value);
//     debouncedOnChange(value);
//   };

//   useEffect(() => {
//       setInputValue(initialValue);
//   }, [initialValue]);

//   return (
//     <div
//       className={classNames([
//         "flex flex-column customInput position-relative",
//         baseClassName,
//       ])}
//     >
//       {label && (
//         <div
//           className={classNames(["input-label padding-b-1", labelClassName])}
//         >
//           {label}
//         </div>
//       )}
//       {iconProps && (
//         <SvgIcon
//           {...iconProps}
//           baseclassname={classNames([
//             "input-search",
//             { "input-search-right": iconRight },
//           ])}
//         />
//       )}
//       <input
//         ref={ref || undefined!}
//         type={type || "text"}
//         value={inputValue || ""}
//         id={id}
//         name={name}
//         placeholder={placeholder}
//         disabled={disabled}
//         className={classNames(
//           { "padding-l-10": iconProps },
//           { "padding-l-4": !iconProps },
//           { "padding-r-6": iconRight },
//           inputClassName || ""
//         )}
//         onBlur={onBlur}
//         onChange={handleInputChange}
//       />
//     </div>
//   );
// }


import React, { useEffect, useState, useCallback } from "react";
import classNames from "classnames";
import SvgIcon, { SVGType } from "components/SvgIcon";
import { IconSizeProp } from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";
import { debounce } from "utils/utils";

import "./style.scss";

interface IICONPROPS {
  svgType: SVGType;
  circular?: boolean;
  name: any;
  size?: IconSizeProp;
  baseclassname?: any;
  inverted?: boolean;
}

interface IProps {
  label?: string;
  labelClassName?: string;
  baseClassName?: string;
  onChange?: (name: string, value: any) => void;
  inputClassName?: string;
  id: string;
  placeholder: string;
  iconProps?: IICONPROPS;
  disabled?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  isDebounced?: boolean;
  name: string;
  initialValue?: any;
  ref?: any;
  iconRight?: boolean;
  type?: string;
  validate?: boolean;
}

export function CustomInput(props: IProps) {
  const {
    label,
    labelClassName,
    baseClassName,
    onChange,
    inputClassName,
    id,
    placeholder,
    iconProps,
    disabled,
    onBlur,
    name,
    initialValue,
    ref,
    iconRight,
    type,
    validate
  } = props;

  const [inputValue, setInputValue] = useState<string>(initialValue || "");
  const [error, setError] = useState<string | null>(null);

  const debouncedOnChange = useCallback(
    debounce((value: string) => {
      if (typeof onChange === "function") {
        onChange(name, value);
      }
    }, 1000),
    [onChange, name]
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const regex = /^(?!\s)[a-zA-Z0-9\s]*$/

    if (validate && value && !regex.test(value)) {
      setError("Name must not start or end with whitespace and should only contain alphanumeric characters and spaces between words.");
      return;
    }
    setError(null);

    setInputValue(value);
    debouncedOnChange(value);
  };

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  return (
    <div
      className={classNames([
        "flex flex-column customInput position-relative",
        baseClassName,
      ])}
    >
      {label && (
        <div
          className={classNames(["input-label padding-b-1", labelClassName])}
        >
          {label}
        </div>
      )}
      {iconProps && (
        <SvgIcon
          {...iconProps}
          baseclassname={classNames([
            "input-search",
            { "input-search-right": iconRight },
          ])}
        />
      )}
      <input
        ref={ref || undefined!}
        type={type || "text"}
        value={inputValue || ""}
        id={id}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        className={classNames(
          { "padding-l-10": iconProps },
          { "padding-l-4": !iconProps },
          { "padding-r-6": iconRight },
          inputClassName || ""
        )}
        onBlur={onBlur}
        onChange={handleInputChange}
      />
      {error && <div className="input-error text-danger-color text-3">{error}</div>}
    </div>
  );
}
