import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Button,
  Form,
  Message,
  List,
  FormGroup,
} from "semantic-ui-react";
import { app, db } from "firebaseConfig";
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  where,
  deleteDoc,
  doc,
  Firestore,
} from "firebase/firestore";
import { useUserDetail } from "HOC/useUserDetail";
import { chatNotificationsRead, chatNotifications } from "store/rext";
import { getCampaignDetailState } from "store/selectors";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getDatabase,
  ref,
  onValue,
  push,
  onDisconnect,
  set,
  serverTimestamp,
} from "firebase/database";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import CustomButton from "components/CustomButton";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import "./style.scss";

interface Props {
  hideModal: () => void;
  onClose: () => void;
  onSave: (data: any) => void;
  title?: string;
  toggle?: any;
  influencerData: any;
}
interface ReceiverStatus {
  state: string;
  last_changed?: any;
}
function ChatModal(props: Props) {
  const { onClose, onSave, title, toggle, influencerData } = props;
  const database = getDatabase(app);
  const [receiverStatus, setReceiverStatus] = useState<ReceiverStatus | null>(
    null
  );
  const hostname = window.location.hostname;
  let dbase = "";
  if (hostname === "app.highonbuzz.com") {
    dbase = "-prod";
  }
  const dispatch = useDispatch();
  const { user } = useUserDetail();
  const { data: campaignDetail } = useSelector(getCampaignDetailState);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const handleEmojiSelect = (emoji: any) => {
    setMessage((prevMessage) => prevMessage + emoji.native);
  };
  const emojiPickerRef = useRef<HTMLDivElement | null>(null);
  const formatDateLastSeen = (date: any) => {
    const dateToFormat = moment(date);
    return dateToFormat.fromNow();
  };
  const formatDate = (seconds: any) => {
    const dateToFormat = moment.unix(seconds); // Convert seconds to a Moment.js date
    //return dateToFormat.fromNow(); // format("Do MMMM, YYYY hh:mm A");
    const today = moment().startOf("day");
    const yesterday = moment().subtract(1, "days").startOf("day");

    if (dateToFormat.isSame(today, "d")) {
      return "Today";
    } else if (dateToFormat.isSame(yesterday, "d")) {
      return "Yesterday";
    } else {
      return dateToFormat.format("Do MMMM, YYYY hh:mm A");
    }
  };
  // const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState<
    Array<{
      id: string;
      text: string;
      sender_id: string;
      sender_name: string;
      receiver_id: string;
      receiver_name: string;
      campaign_id: string;
      campaign_name: string;
      sender_avatar: string;
      receiver_avatar: string;
      createdAt: string;
    }>
  >([]);
  const loginUserId = user?.id;
  const endUserName = influencerData?.influencerDetails?.name || "HOB";
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const handleSendMessage = async () => {
    let sender_name = "",
      sender_avatar = "";
    let receiver_id = "",
      receiver_name = "",
      receiver_avatar = "";
    let campaign_id = "",
      campaign_name = campaignDetail?.name;
    let uniqueKey = "";
    if (user?.role === "HOB") {
      sender_name = "HOB";

      //sender_avatar = '/images/InfluencerProfile.png';
      receiver_id = influencerData?.influencerDetails?.userid;
      uniqueKey = user?.id + "__" + receiver_id;
      receiver_name = influencerData?.influencerDetails?.name;
      //receiver_avatar=influencerData?.influencerDetails?.profile_picture_url;
      campaign_id = influencerData?.campaign_id;
    } else if (user?.role === "INFLUENCER") {
      sender_name = user?.name || "Influencer";
      //sender_avatar = user?.instaDetails[0].profile_picture_url;
      receiver_id = user?.HOBdetail?.id;
      uniqueKey = receiver_id + "__" + user?.id;
      receiver_name = "HOB";
      //receiver_avatar='/images/InfluencerProfile.png';
      campaign_id = influencerData?.id;
    } else if (user?.role === "BRAND") {
      sender_name = user?.brandDetails.name || "Brand Manager";
      //sender_avatar = '/images/profile.png';
      receiver_id = user?.HOBdetail?.id;
      uniqueKey = receiver_id + "__" + user?.id;
      receiver_name = "HOB";
      //receiver_avatar='/images/InfluencerProfile.png';
      //campaign_id = influencerData?.campaignMappingDetails?.campaign_id;
    }
    if (message.trim()) {
      await addDoc(collection(db, "Chat" + dbase, uniqueKey, "HOB"), {
        text: message,
        createdAt: new Date(),
        sender_id: user?.id,
        sender_name: sender_name,
        sender_avatar: sender_avatar,
        receiver_id: receiver_id,
        receiver_name: receiver_name,
        receiver_avatar: receiver_avatar,
        campaign_id: campaign_id,
        campaign_name: campaign_name,
      });
      dispatch(
        chatNotifications({
          campaignId: campaign_id,
          campaignname: campaign_name || "",
          receiverId: receiver_id,
          receiverName: receiver_name,
          senderId: user?.id,
          senderName: sender_name,
          role: user?.role,
          msg_status: "sent",
          mark_read: false,
        })
      );
      setMessage("");
    }
  };

  const handleModalClose = () => {
    if (user?.id) {
      const userStatusDatabaseRef = ref(database, `/status/${user.id}`);
      const isOfflineForDatabase = {
        state: "offline",
        last_changed: serverTimestamp(),
      };
      set(userStatusDatabaseRef, isOfflineForDatabase).then(() => {
        onClose();
      });
    } else {
      onClose();
    }
  };
  const handleDeleteChat = async (messageId: string) => {
    try {
      if (window.confirm("Are you sure you want to delete this message?")) {
        let receiver_id = "";
        let uniqueKey = "";

        if (user?.role === "HOB") {
          receiver_id = influencerData?.influencerDetails?.userid;
          uniqueKey = user?.id + "__" + receiver_id;
        } else if (user?.role === "INFLUENCER") {
          receiver_id = user?.HOBdetail?.id;
          uniqueKey = receiver_id + "__" + user?.id;
        } else if (user?.role === "BRAND") {
          receiver_id = user?.HOBdetail?.id;
          uniqueKey = receiver_id + "__" + user?.id;
        }

        //const messageDoc = doc(db, 'Chat', uniqueKey, 'HOB', messageId);
        await deleteDoc(doc(db, "Chat" + dbase, uniqueKey, "HOB", messageId));
      }
    } catch (error) {
      console.error("Error deleting message: ", error);
    }
  };
  const handleClickOutside = (event: any) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      setShowEmojiPicker(false);
    }
  };

  const handleImageError = (e: any) => {
    e.target.onerror = null;
    e.target.src = "/images/NoProfile.png";
  };

  //// Set Status Online
  useEffect(() => {
    const userStatusDatabaseRef = ref(database, `/status/${user?.id}`);

    const isOfflineForDatabase = {
      state: "offline",
      last_changed: serverTimestamp(),
    };

    const isOnlineForDatabase = {
      state: "online",
      last_changed: serverTimestamp(),
    };

    onValue(ref(database, ".info/connected"), (snapshot) => {
      if (snapshot.val() === false) {
        return;
      }

      onDisconnect(userStatusDatabaseRef)
        .set(isOfflineForDatabase)
        .then(() => {
          set(userStatusDatabaseRef, isOnlineForDatabase);
        });
    });
  }, [user, database]);

  /// Get Status Online
  useEffect(() => {
    let receiver_id = "";
    if (user?.role === "HOB") {
      receiver_id = influencerData?.influencerDetails?.userid;
    } else if (user?.role === "INFLUENCER") {
      receiver_id = user?.HOBdetail?.id;
    } else if (user?.role === "BRAND") {
      receiver_id = user?.HOBdetail?.id;
    }
    const receiverStatusRef = ref(database, `/status/${receiver_id}`);

    onValue(receiverStatusRef, (snapshot) => {
      if (snapshot.exists()) {
        setReceiverStatus(snapshot.val() as ReceiverStatus);
      } else {
        setReceiverStatus({ state: "offline" });
      }
    });
  }, [user, database, influencerData]);

  useEffect(() => {
    //let sender_id_1 = user?.id;
    let campaign_id_1 = "";
    let receiver_id_1 = "",
      receiver_avatar = "",
      sender_avatar = "";
    let uniqueKey = "";
    if (user?.role === "HOB") {
      campaign_id_1 = influencerData?.campaign_id;
      receiver_id_1 = influencerData?.influencerDetails?.userid;
      receiver_avatar = "/favicon.png";
      sender_avatar = influencerData?.influencerDetails?.profile_picture_url;
      //endUserName=influencerData?.influencerDetails?.name;
      uniqueKey = user?.id + "__" + receiver_id_1;
    } else if (user?.role === "INFLUENCER") {
      campaign_id_1 = influencerData?.id;
      receiver_id_1 = user?.HOBdetail?.id;
      sender_avatar = "/favicon.png";
      receiver_avatar = influencerData?.influencerDetails?.profile_picture_url;
      uniqueKey = receiver_id_1 + "__" + user?.id;
    } else if (user?.role === "BRAND") {
      campaign_id_1 = influencerData?.campaignMappingDetails?.campaign_id;
      receiver_id_1 = user?.HOBdetail?.id;
      receiver_avatar = "/images/profile.png";
      sender_avatar = "/favivon.png";

      uniqueKey = receiver_id_1 + "__" + user?.id;
    }
    dispatch(
      chatNotificationsRead({
        campaignId: campaign_id_1,
        receiverId: user?.id,
        senderId: receiver_id_1,
      })
    );
    const q = query(
      collection(db, "Chat" + dbase, uniqueKey, "HOB"),
      where("campaign_id", "in", [campaign_id_1])
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messages = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        text: doc.data().text,
        sender_id: doc.data().sender_id,
        sender_name: doc.data().sender_name,
        receiver_id: doc.data().receiver_id,
        receiver_name: doc.data().receiver_name,
        campaign_id: doc.data().campaign_id,
        campaign_name: doc.data().campaign_name,
        name: doc.data().name,
        sender_avatar: sender_avatar,
        receiver_avatar: receiver_avatar,
        createdAt: doc.data().createdAt.seconds,
      }));
      const sortedMessages = messages.sort((a, b) => a.createdAt - b.createdAt);
      setMessages(sortedMessages);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [messages]);

  useEffect(() => {
    if (showEmojiPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showEmojiPicker]);

  return (
    <Modal className="chat-modal" dimmer open onClose={handleModalClose}>
      <Modal.Header className="header-style">
        {receiverStatus?.state === "online" ? "🟢" : "🔴"}{" "}
        <span className="header-style-font">
          {endUserName} - {campaignDetail?.name}
        </span>
        {receiverStatus?.state === "offline" &&
          receiverStatus?.last_changed && (
            <div className="header-lastseen">
              {"last seen " + formatDateLastSeen(receiverStatus?.last_changed)}
            </div>
          )}
        <CustomButton
          handleClick={() => {
            if (user?.id) {
              const userStatusDatabaseRef = ref(database, `/status/${user.id}`);
              const isOfflineForDatabase = {
                state: "offline",
                last_changed: serverTimestamp(),
              };
              set(userStatusDatabaseRef, isOfflineForDatabase).then(() => {
                onClose();
              });
            } else {
              onClose();
            }
          }}
          greyButton
          transparent
          iconPosition={ICON_POSITION.LEFT}
          iconProps={{
            svgType: SVGType.CUSTOM,
            name: CUSTOM_SVG_ICON.Cross,
            size: "huge",
          }}
          noOutline
          baseclassname={"add-influencer-modal-toggle-icon"}
        />
      </Modal.Header>
      <Modal.Content>
        <List>
          {messages.map((msg) => (
            <List.Item
              key={msg.id}
              className={`chat-item ${
                msg.sender_id === loginUserId ? "right-message" : "left-message"
              }`}
            >
              <div
                className={`chat-bubble-date ${
                  msg.sender_id === loginUserId
                    ? "right-message"
                    : "left-message"
                }`}
              >
                {formatDate(msg.createdAt)}
              </div>
              {msg.sender_id === loginUserId && (
                <div className="chat-bubble">
                  <div className="chat-bubble__right content-style">
                    {/* <SvgIcon
                      name={CUSTOM_SVG_ICON.Delete}
                      svgType={SVGType.CUSTOM}
                      size={"small"}
                      baseclassname={"delete-icon text-default-color text-align-center"}
                      onClick={() => handleDeleteChat(msg.id)}
                    /> */}
                    <p className="user-name">{msg.sender_name}</p>
                    <p className="user-message">{msg.text}</p>
                  </div>
                </div>
              )}
              {msg.sender_id !== loginUserId && (
                <div className="chat-bubble">
                  <img
                    className="chat-bubble__image margin-r-2 content-style"
                    //src={`${msg.sender_id === loginUserId ? msg.sender_avatar : msg.receiver_avatar}`}
                    src={msg.sender_avatar || "/images/NoProfile.png"}
                    alt="user avatar"
                    onError={handleImageError}
                  />
                  <div className="chat-bubble__right">
                    <p className="user-name">{msg.sender_name}</p>
                    <p className="user-message">{msg.text}</p>
                  </div>
                </div>
              )}
            </List.Item>
          ))}
          <div ref={messagesEndRef} />
        </List>
      </Modal.Content>

      <Modal.Actions>
        <div className="flex">
          <Button
            icon="smile"
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
          />
          <Form onSubmit={handleSendMessage} style={{ flex: 1 }}>
            <Form.Input
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message"
              action={{ icon: "send" }}
            />
          </Form>
        </div>
        {showEmojiPicker && (
          <div className="modal-smaile" ref={emojiPickerRef}>
            <Picker data={data} onEmojiSelect={handleEmojiSelect} />
          </div>
        )}
      </Modal.Actions>
    </Modal>
  );
}

export default ChatModal;