import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import moment from "moment";
import { toast } from "react-toastify";
import { useUserDetail } from "HOC/useUserDetail";
import { URLRoutes } from "URLRoutes";
import HeaderLayout from "components/HeaderLayout";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import {
  getNotificationCount,
  requestNotificationList,
  updateAllReadNotification,
  updateReadNotification,
} from "store/rext";
import {
  getNotificationListState,
  getNotificationState,
} from "store/selectors";
import { ROLES } from "utils/Enums";
import Loading from "components/Loading";
import CustomButton from "components/CustomButton";
import UsePrevious from "HOC/UsePrevious";
import "./style.scss";

function Notifications() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role } = useUserDetail();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { data, fetching } = useSelector(getNotificationListState);
  const { records, paginationInfo } = data || {};
  const {
    data: notification,
    message: notificationMessage,
    fetching: notificationFetching,
    error: notificationError,
  } = useSelector(getNotificationState);
  const wasFetching = UsePrevious(notificationFetching);

  // const handleNavigate = (id: any) => {
  //   // window.open(`${URLRoutes.clients.campaignList}?campaign_id=${id}`, "_blank");
  //   window.open("http://localhost:3000/campaign/list?campaign_id=c4b4f66e-1d55-4560-8a8e-1fa2fae10b3a" , "_blank")
  // };

  const renderNotifications = (record: any, index: number) => {
    const {
      title: notificationTitle,
      body: notificationBody,
      notified_on: notificationTime,
      id: notificationId,
      is_read,
      campaign_id,
    } = record;

    const renderTextWithBoldQuotes = (text: string) => {
      const parts = text.split(/(".*?")/);
      return parts.map((part, index) => {
        if (part.startsWith('"') && part.endsWith('"')) {
          return (
            <span
              key={index}
              className="text-bold text-blue-shade1"
              // onClick={() => handleNavigate(campaign_id)}
            >
              {part}
            </span>
          );
        } else {
          return <span key={index}>{part}</span>;
        }
      });
    };

    return (
      <div
        key={`Notification-${index}`}
        className="flex flex-row  width-100 border-bottom border-grey-shade-1 cursor-pointer"
        onClick={() => {
          if (!is_read) {
            handleReadNotification(notificationId);
          }
        }}      >
        <div className="width-5">
          {!is_read && (
            <SvgIcon
              name={CUSTOM_SVG_ICON.Ellipse}
              svgType={SVGType.CUSTOM}
              size={"small"}
              baseclassname={"text-blue-color margin-l-3"}
            />
          )}
        </div>
        <div className={classNames(["flex flex-column width-95"])}>
          <div className="flex flex-row flex-justify-between width-100 flex-align-center padding-2">
            <div className="text-bold notification-title">
              {notificationTitle}
            </div>
            <div className="notification-date">
              {moment(notificationTime).format("MMMM DD, YYYY h:mm A")}
            </div>
          </div>
          <div className="notification-subTitle text-3 line-h-4 padding-b-2 text-word-break">
            {renderTextWithBoldQuotes(notificationBody)}
          </div>
        </div>
      </div>
    );
  };

  const handlePaginationChange = (e: any, { activePage }: any) => {
    setCurrentPage(activePage);
    dispatch(
      requestNotificationList({
        page: activePage,
        pageSize: 10,
      })
    );
  };

  const handleReadNotification = (id: any) => {
    dispatch(updateReadNotification({ id }));
    dispatch(
      requestNotificationList({
        page: currentPage,
        pageSize: 10,
      })
    );
    dispatch(getNotificationCount());
  };

  const handleReadAll = () => {
    dispatch(updateAllReadNotification());
    dispatch(
      requestNotificationList({
        page: currentPage,
        pageSize: 10,
      })
    );
    dispatch(getNotificationCount());
  };

  useEffect(() => {
    dispatch(
      requestNotificationList({
        page: currentPage,
        pageSize: 10,
      })
    );
    dispatch(getNotificationCount());
  }, [dispatch , notificationMessage]);

  useEffect(() => {
    if (wasFetching && !notificationFetching && !notificationError) {
      toast.success(notificationMessage);
    } else if (notificationError) {
      toast.error(notificationMessage);
    }
  }, [notificationFetching, notificationError, notificationMessage]);

  return (
    <HeaderLayout
      title="Notifications"
      breadcrumbs={[
        {
          text: "Dashboard",
          url:
            role === ROLES.HOB
              ? URLRoutes.clients.users.replace(":userType", ROLES.INFLUENCER)
              : role === ROLES.INFLUENCER
              ? URLRoutes.clients.instaDashboard
              : URLRoutes.clients.profile,
        },
        { text: "Notifications", url: URLRoutes.clients.notifications },
      ]}
      contentClassName="flex flex-column width-100 padding-6"
      showPagination={true}
      paginationProps={{
        totalPages: paginationInfo?.totalPages,
        currentPage: currentPage,
        paginationChange: handlePaginationChange,
      }}
    >
      <div className="flex flex-justify-between width-80 margin-b-4 flex-align-center">
        <div className=" text-3 line-h-3">
          Total Notifications:
          <span className="text-bold margin-l-2">
            {paginationInfo?.totalItems}
          </span>
        </div>
        <div className="flex flex-justify-end flex-align-center">
          <div className="text-3 line-h-3">
            Unread:
            <span className="text-bold margin-l-2">{notification.count}</span>
          </div>
          <CustomButton
            buttonText="Mark All Read"
            handleClick={handleReadAll}
            textSmall
            transparent
            whiteBlueButton
            noOutline
            buttonTextClass={"text-underline text-bold"}
          />
        </div>
      </div>
      {!fetching && (
        <div className="width-80 overflow-auto chat-records">{records?.map(renderNotifications)}</div>
      )}
      {fetching && <Loading isGlobal />}
    </HeaderLayout>
  );
}

export default Notifications;
