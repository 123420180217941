export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.40741 6.42857C4.40741 7.36971 4.61644 8.25943 4.97282 9.06557L0 14.1429C0 14.1429 0 15.0069 0 16.0714C0 17.136 1.1283 18 2.51852 18H5.03704V15.4286H7.55556V12.8571C7.55556 12.8571 10.4884 12.8571 10.7037 12.8571C14.1818 12.8571 17 9.97971 17 6.42857C17 2.87743 14.1818 0 10.7037 0C7.22563 0 4.40741 2.87743 4.40741 6.42857ZM10.7037 9C9.31348 9 8.18518 7.848 8.18518 6.42857C8.18518 5.00786 9.31348 3.85457 10.7037 3.85457C12.0952 3.85457 13.2222 5.00657 13.2222 6.42857C13.2222 7.848 12.0952 9 10.7037 9Z"
        fill="currentColor"
      />
    </svg>
  );
};
