// const emailRegx =
//   /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const passwordRegx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/;
const emailRegx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const hookformRequired = (message?: string) => {
  return {
    required: message || "required",
  };
};

export const hookMaxValue = (value?: number, message?: string) => {
  return {
    maxLength: {
      value,
      message: `Must be less than ${value}` || message,
    },
  };
};
export const hookMinValue = (value?: number, message?: string) => {
  return {
    minLength: {
      value,
      message: message || `Must be greater than ${value}`,
    },
  };
};
export const hookPatternRequired = (pattern: any, message?: string) => {
  return {
    pattern: {
      value: pattern,
      message: message || "Enter Valid Data",
    },
  };
};

export const validateDateRange = (startDate: any, endDate: any) => {
  return startDate <= endDate;
};

export const validateDateWithinRange = (
  startDate: Date,
  endDate: Date,
  dateToValidate: Date
) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const date = new Date(dateToValidate);

  end.setHours(23, 59, 59, 999);
  
  return date >= start && date <= end;
};

export const hookEmailPatternRequired = (message?: string) => {
  return hookPatternRequired(emailRegx, message || "Enter Valid Email");
};

export const hookPasswordPatternRequired = (message?: string) => {
  return hookPatternRequired(
    passwordRegx,
    message ||
      "Password must be 6 to 15 characters long and contain at least one digit, one lowercase letter, and one uppercase letter."
  );
};

export const hookformPasswordsMatch = (password: string, message?: string) => {
  return {
    validate: (value: string) =>
      value === password || message || "Passwords do not match",
  };
};

export const hookPhoneNumberValidation = () => {
  return {
    pattern: {
      value: /^\+\d{1,4}(?:-\d{1,4}){0,2}\d{10}$/,
      message: "Invalid phone number format",
    },
  };
};
