import { useState, useEffect } from "react";
import classNames from "classnames";
import { CustomInput } from "components/CustomInputs";
import CustomButton from "components/CustomButton";
import SvgIcon, { SVGType } from "components/SvgIcon";
import useClickOutside from "HOC/useClickOutside";
import "./style.scss";

interface Option {
  value: string;
  text: string;
}

interface CustomDropdownProps {
  placeholder: string;
  onChange: (value: string, customValues?: { min?: number; max?: number }) => void;
  value: string;
  options: any[];
  minValue?: string;
  maxValue?: string;
}

export function FollowersDropdown({
  placeholder,
  onChange,
  value,
  options
}: CustomDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string>(value);
  const [minValue, setMinValue] = useState<number>();
  const [maxValue, setMaxValue] = useState<number>();

  const renderCustomRangeInputs = () => (
    <div className="flex flex-column width-100 flex-align-center padding-b-3">
      <CustomInput
        id="min"
        type="number"
        placeholder="Min"
        name="minFollowers"
        onChange={handleMinInputChange}
        initialValue={minValue}
        baseClassName="width-80 range-inputs"
      />
      <CustomInput
        id="max"
        type="number"
        placeholder="Max"
        name="maxFollowers"
        onChange={handleMaxInputChange}
        initialValue={maxValue}
        baseClassName="width-80 range-inputs margin-b-3"
      />
      <CustomButton
        buttonText="Apply"
        textSmall
        greenButton
        handleClick={handleApplyCustomRange}
        baseclassname={""}
        disabled={!maxValue && !minValue && isOpen}
      />
    </div>
  );

  const handleOptionClick = (option: Option) => {
    if (option.value === selectedValue) {
      setSelectedValue("");
      setMinValue(undefined!);
      setMaxValue(undefined!);
      setIsOpen(false);
      onChange("");
    } else {
      setSelectedValue(option.value);
      if (option.value === "custom") {
        setIsOpen(true);
      } else {
        setIsOpen(false);
        onChange(option.value);
      }
    }
  };

  const handleMinInputChange = (name: string , value: string) => {
    setMinValue(Number(value));
  };

  const handleMaxInputChange = (name: string , value: string) => {
    setMaxValue(Number(value));
  };

  const handleApplyCustomRange = () => {
    if(minValue && maxValue) {
      setIsOpen(false);
      onChange("custom", { min: minValue, max: maxValue });
    }
  };

  const handleClickOutside = () => {
    setIsOpen(false);
  };

  const dropdownClickRef = useClickOutside(handleClickOutside);

  useEffect(() => {
    if(value) {
      setSelectedValue(value);
    } else {
      setSelectedValue("")
      setMinValue(undefined!);
      setMaxValue(undefined!);
    }
  }, [value]);

  return (
    <div className={classNames(["custom-dropdown"])} ref={dropdownClickRef}>
      <div
        className={classNames(["dropdown-toggle", { "dropdown-toggle-open": isOpen }])}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div>
        {selectedValue ? (
          options.find((opt) => opt.value === selectedValue)?.text || (
            <span className="placeholder-text">{placeholder}</span>
          )
        ) : (
          <span className="placeholder-text">{placeholder}</span>
        )}
        </div>
      </div>
      {!selectedValue && (
          <SvgIcon
            name={"dropdown"}
            svgType={SVGType.SEMANTIC}
            size={"small"}
            baseclassname={"custom-dropdown-icon"}
            onClick={() => setIsOpen(!isOpen)}
          />
        )}
        {selectedValue && (
          <SvgIcon
            name={"close"}
            svgType={SVGType.SEMANTIC}
            size={"small"}
            baseclassname={"custom-dropdown-icon"}
            onClick={() => handleOptionClick({ value: selectedValue, text: "" })}
          />
        )}
      {isOpen && (
        <div className={classNames(["dropdown-options", { "dropdown-options-open": isOpen }])}>
          {options.map((option) => (
            <div
              key={option.value}
              className={`option ${selectedValue === option.value ? "selected text-bold" : ""}`}
              onClick={() => handleOptionClick(option)}
            >
              {option.text}
            </div>
          ))}
          {selectedValue === "custom" && renderCustomRangeInputs()}
        </div>
      )}
    </div>
  );
}
