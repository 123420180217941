import { Controller } from 'react-hook-form';
import { Dropdown } from 'semantic-ui-react';
import classNames from "classnames";
import "./HookForms.scss";

interface HookFormSelectProps {
  label?: string;
  labelClassName?: string;
  baseClassName?: string;
  name: string;
  validation?: Record<string, any>;
  control: any;
  errors: any; 
  inputClassName?: string;
  id: string;
  options: { label: string; value: any }[];
  triggerOnChange?: (value: any) => void;
  placeholder?: string;
  multiSelect?: boolean;
  isDisabled?:boolean;
  required?:boolean;
  loading?: boolean;
}

export function HookFormSelect(props: HookFormSelectProps) {
  const {
    label,
    labelClassName,
    baseClassName,
    name,
    validation,
    control,
    errors,
    inputClassName,
    id,
    triggerOnChange,
    options,
    placeholder,
    multiSelect,
    isDisabled ,
    required,
    loading
  } = props;
  
  const dropdownOptions = options?.map(({ label, value }: any, index: number) => ({
    key: value,
    text: label,
    value: value
  }));
  
  return (
    <div
      className={classNames([
        "flex flex-column flex-justify-center custom-input position-relative",
        baseClassName,
      ])}
    >
      {label && (
        <label
          className={classNames(["input-label padding-b-1", labelClassName])}
        >
          {label}
          {required && (
            <span
              className={classNames([
                "position-absolute input-asterik text-danger-color ",
              ])}
            >
              &#8727;
            </span>
          )}
        </label>
      )}
      {control && (
        <Controller
          control={control}
          name={name}
          rules={validation || {}}
          render={({ field: { onChange, value, ref } }) => (
            <Dropdown
              selection
              className={classNames(inputClassName || "")}
              value={multiSelect ? value || [] : value || ""}
              placeholder={placeholder}
              id={id}
              options={dropdownOptions || [{}]}
              onChange={(event, data) => {
                if (multiSelect) {
                  onChange((data.value as Array<any>) || []);
                } else {
                  onChange(data.value);
                }
                if (typeof triggerOnChange === "function") {
                  triggerOnChange(data.value);
                }
              }}
              multiple={multiSelect}
              disabled={options?.length === 0 || isDisabled}
              loading={loading || false}
              // defaultValue={value}
            />
          )}
        />
      )}
      {errors[name] && (
        <div className="text-3 text-danger-color padding-b-1 padding-l-1">
          {errors[name].message}
        </div>
      )}
    </div>
  );
}
