export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9333 1H2.06667C1.78377 1 1.51246 1.12967 1.31242 1.36048C1.11238 1.5913 1 1.90435 1 2.23077V12.0769C1 12.4033 1.11238 12.7164 1.31242 12.9472C1.51246 13.178 1.78377 13.3077 2.06667 13.3077H4.73333L7.93333 17V13.3077H15.9333C16.2162 13.3077 16.4875 13.178 16.6876 12.9472C16.8876 12.7164 17 12.4033 17 12.0769V2.23077C17 1.90435 16.8876 1.5913 16.6876 1.36048C16.4875 1.12967 16.2162 1 15.9333 1Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.2002 4.69231H13.8002"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.2002 7.15387H13.8002"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.2002 9.61537H13.8002"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
