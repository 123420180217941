import { Popup } from "semantic-ui-react";
import TooltipText from "components/TooltipText";
import { findLabelById } from "utils/utils";
import "./style.scss";

interface IProps {
  profilePictureUrl: string;
  profileName: string | undefined;
  followers: any;
  following: any;
  posts: any;
  accountReach?: any;
  handle?: string;
  state?: string;
  city?: string;
  genres?: Array<string>;
  genreOptions?: any;
  bio?: string;
}

interface IInfo {
  title: string;
  value: any;
  subTitle?: boolean;
}

const InfoCard = ({ title, value, subTitle }: IInfo) => {
  return (
    <div className="flex flex-column flex-align-center width-33 padding-1">
      <div className="profileHeaderValues padding-b-2">{value}</div>
      <div className="profileHeaderKeys">{title}</div>
      {subTitle && (
        <div className="info-subTitle text-grey-shade4">{"(last 30 days)"}</div>
      )}
    </div>
  );
};

function ProfileHeaderComponent({
  profilePictureUrl,
  profileName,
  followers,
  following,
  posts,
  accountReach,
  handle,
  state,
  city,
  genres,
  bio,
  genreOptions,
}: IProps) {
  const renderGenres = () => {
    if (!genres || genres.length === 0) return null;
    // const isMultiWord = (genre: string) => findLabelById(genreOptions, genre).split(' ').length > 1;
    // const isMultiWord = (genre: string) =>
    //   findLabelById(genreOptions, genre).trim().length > 20;
    // const firstGenreIsMultiWord =
    //   isMultiWord(genres[0]) || isMultiWord(genres[1]);
    // const visibleGenres = firstGenreIsMultiWord
    //   ? genres.slice(0, 1)
    //   : genres.slice(0, 2);
    // const hiddenGenres = firstGenreIsMultiWord
    //   ? genres.slice(1)
    //   : genres.slice(2);
    const visibleGenres = genres.slice(0, 1);
    const hiddenGenres = genres.slice(1);

    return (
      <div className="width-97 flex flex-wrap margin-b-2">
        {visibleGenres.map((genre, index) => (
          <div
            key={`genre${index}`}
            className="flex flex-align-center  text-grey-shade-4 border-1 border-grey-shade-1 margin-1 profile-genres"
          >
            {findLabelById(genreOptions, genre)}
          </div>
        ))}
        {hiddenGenres.length > 0 && (
          <Popup
            content={
              <div className="width-95 flex flex-column flex-wrap">
                {hiddenGenres.map((genre) => (
                  <div className="flex flex-align-center flex-justify-center text-3 text-align-center text-grey-shade-4 border-1 border-grey-shade-1 margin-1 profile-genres profile-genres-hidden">
                    {findLabelById(genreOptions, genre)}
                  </div>
                ))}
              </div>
            }
            trigger={
              <div className="text-3 text-grey-shade-4 border-1 border-grey-shade-1  margin-1 profile-genres">
                +{hiddenGenres.length}
              </div>
            }
            position="bottom center"
          />
        )}
      </div>
    );
  };

  return (
    <div className="flex text-black-shade1 background-secondary-color profileHeader-container">
      <div className="width-35 flex flex-row flex-justify-center ">
        <div className="position-absolute profileHeader-container-img">
          <img
            src={profilePictureUrl || "/images/NoProfile.png"}
            style={{ width: "100%", height: "100%" }}
            alt="profile"
          />
        </div>
        <div className=" width-80 flex flex-column flex-justify-center padding-t-3 padding-b-3 padding-r-2 profileHeader-container-left overflow-hidden">
          {profileName && (
            <div className="profileHeader-container-text margin-b-2">
              {profileName}
            </div>
          )}
          <div className="text-3 text-bold margin-b-2">
            {handle ? `@${handle}` : "-"}
          </div>
          {renderGenres()}
          {city && state && (
            <div className="text-3 text-grey-shade-4 ">{`${city} , ${state}`}</div>
          )}
        </div>
      </div>
      <div className="border-1 border-grey-shade-1 margin-1"></div>
      <div className="width-65 flex flex-column flex-align-center  flex-justify-center padding-2">
        <TooltipText
          value={bio || ""}
          baseClassName="width-90 text-3 padding-b-3 text-grey-shade-4 text-align-center"
        />
        <div className=" width-100 flex ">
          <InfoCard title="Posts" value={posts} />
          <InfoCard title="Followers" value={followers} />
          <InfoCard title="Following" value={following} />
          <InfoCard title="Account Reached" value={accountReach} subTitle />
        </div>
      </div>
    </div>
  );
}

export default ProfileHeaderComponent;
