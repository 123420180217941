export default () => {
    return (
      // <svg
      //   width="100%"
      //   height="100%"
      //   viewBox="0 0 512 512"
      //   fill="none"
      //   xmlns="http://www.w3.org/2000/svg"
      // >
      //   <path
      //     d="M35.484 45.131C45.168 51.076 459.072 168.913 472.326 179.308C485.58 189.702 500.556 192.951 521.244 192.951C541.932 192.951 556.908 189.702 570.162 179.308C583.416 168.913 497.148 51.076 506.832 45.131C512.504 41.749 526.452 34.657 530.117 26.432C535.258 15.674 531.726 0 498.396 0H13.6059C-19.887 0 -23.4191 15.674 0.721383 26.432C4.74259 30.537 29.8278 41.749 35.484 45.131Z"
      //     fill="currentColor"
      //   />
      //   <path
      //     d="M505.833 52.869C478.363 63.788 317.742 138.188 246.94 179.076L393.755 332.513C398.995 337.167 401.605 344.081 394.798 347.432C389.429 350.008 375.337 346.85 366.354 335.446L203.446 182.763C178.679 195.554 159.67 202.505 157.716 203.156C150.9 205.006 148.567 202.951 141.73 197.11L75.0191 144.663C69.7791 141.72 64.913 137.05 64.5791 132.646C64.2784 128.982 66.1953 126.67 69.0391 126.646L174.924 127.585C144.996 104.471 105.471 78.451 81.1336 57.117C49.0375 28.206 37.7074 53.2188 32.0674 55.4995C24.445 58.7947 6.16088 58.224 1.06638 64.112C-4.91413 70.324 -0.211625 87.978 10.8474 96.735C19.1094 103.345 33.9264 111.663 51.0659 113.823C75.1741 117.389 106.139 110.262 133.566 98.795C145.086 94.675 157.462 90.974 167.724 88.641C185.627 84.909 195.596 87.652 206.224 90.453C209.725 91.455 213.422 92.483 217.25 93.535C219.669 94.316 222.074 95.11 224.465 95.922C235.819 100.674 247.439 105.41 258.821 109.056C274.8 114.568 290.827 118.899 308.174 122.041C340.758 128.374 375.31 131.103 410.47 128.593C430.32 127.387 450.019 122.846 469.101 115.827C483.065 110.636 497.023 103.662 506.832 96.736C524.971 83.617 510.38 60.98 505.833 52.869Z"
      //     fill="currentColor"
      //   />
      // </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100%"
        height="100%"
         fill="none"
        viewBox="0 0 256 256"
      >
        <defs></defs>
        <g
          // style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
          strokeLinecap="round"
            strokeWidth={1}
            strokeDasharray={"none"}
            stroke="none"
            fill="currentColor"
        >
          <path
            d="M 45 51.815 l 45 -33.87 v -1.967 c 0 -2.03 -1.646 -3.676 -3.676 -3.676 H 3.676 C 1.646 12.302 0 13.948 0 15.978 v 1.967 L 45 51.815 z"
            // style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"
            transform=" matrix(1 0 0 1 0 0) "
            strokeLinecap="round"
            strokeWidth={1}
            strokeDasharray={"none"}
            stroke="none"
            fill="currentColor"
            strokeMiterlimit={10}
          />
          <path
            d="M 47.405 60.019 c -0.712 0.536 -1.559 0.804 -2.405 0.804 s -1.693 -0.268 -2.405 -0.804 L 0 27.958 v 46.064 c 0 2.03 1.646 3.676 3.676 3.676 h 82.648 c 2.03 0 3.676 -1.646 3.676 -3.676 V 27.958 L 47.405 60.019 z"
            // style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"
            transform=" matrix(1 0 0 1 0 0) "
            strokeLinecap="round"
            strokeWidth={1}
            strokeDasharray={"none"}
            stroke="none"
            fill="currentColor"
            strokeMiterlimit={10}
          />
        </g>
      </svg>
    );
  };
  