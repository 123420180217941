import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import AuthLayout from "components/AuthLayout";
import CustomButton from "components/CustomButton";
import { HookFormInput } from "components/FormInputs";
import { hookEmailPatternRequired, hookformRequired } from "utils/FormValidations";
import {  getInvitedUserDetails, requestSignUpByEmail } from "store/rext";
import {  getInvitedUserState, getSignUpByEmailState } from "store/selectors";
import "./style.scss";
import UsePrevious from "HOC/UsePrevious";

const requiredValidation = {
 ...hookformRequired("Required"),
 ...hookEmailPatternRequired("Invalid email address"),
}

function EmailSignUp() {
  const { id: inviteId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: userdetail } = useSelector(getInvitedUserState);
  const {
    handleSubmit,
    formState: { errors , isSubmitSuccessful },
    control,
    reset,
    getValues
  } = useForm({
    defaultValues: {
      email: inviteId ? userdetail.email : ""
    },
  });

  const { data: emailState, fetching, error, message } = useSelector(getSignUpByEmailState);
  const wasFetching = UsePrevious(fetching);
  const handleOnSubmit = (data: any) => {
    if(inviteId) {
      dispatch(requestSignUpByEmail({email: data.email , inviteId: inviteId}))
    } else {
      dispatch(requestSignUpByEmail(data))
    }
    toast.info(emailState.message);
  };

  useEffect(() => {
    if (emailState?.id && !fetching && !error && isSubmitSuccessful) {
      toast.success(message);
      setTimeout(() => {
        navigate(`/verifyOTP/${emailState.id}/VERIFY_EMAIL`);
      }, 2000);
    } else if (isSubmitSuccessful && error) {
      toast.error(message);
    }
  }, [emailState, message, fetching, error]);

  useEffect(()=> {
    if(inviteId) {
      dispatch(getInvitedUserDetails({ id: inviteId }));
      reset({
        email: inviteId ? userdetail.email : "" 
      });
    }
  }, [inviteId, dispatch, reset, userdetail.email]);


  return (
    <AuthLayout
      image="/images/womanSunglasses.png"
      title="Discover the world’s top Influencers."
      titlePosition="top"
      contentClassName="flex-justify-center flex-align-center"
      bannerClassName="background-linear1"
      imagePosition="right"
    >
      <div className="width-45 padding-b-10">
        <img src="/images/logo.png" style={{ width: "100%", height: "100%" }} />
      </div>
      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        className="width-55"
      >
        <HookFormInput
          validation={requiredValidation}
          control={control}
          errors={errors}
          label="Email"
          id="email"
          name="email"
          placeholder="Enter your email here..."
          baseClassName="margin-b-4"
          labelClassName="padding-l-2"
          required
        />
        <CustomButton
          type="submit"
          buttonText="SEND OTP"
          round
          primaryButton
          baseclassname={"width-100"}
          loading={fetching}
        />
      </form>
    </AuthLayout>
  );
}

export default EmailSignUp;
