import { Fragment, useEffect, useMemo, useRef } from "react";
import { Pagination } from "semantic-ui-react";
import classNames from "classnames";
import Loading from "components/Loading";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import CustomButton from "components/CustomButton";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import { debounce } from "utils/utils";
import "./style.scss";

export interface IColumns {
  key: string;
  header: string;
  customValue?: any;
  sort?: boolean;
  sortKey?: string;
  columnClassName?: string;
}

export interface ISortingCriteria {
  sortBy: string;
  sortOrder: "ascending" | "descending";
}

export interface IActionButtons {
  label?: string;
  icon: keyof typeof CUSTOM_SVG_ICON;
  iconColor?: string;
  handleClick: (rowData: any) => void;
}

interface ICustomTable {
  columns: IColumns[];
  tableData: any[];
  paginationData?: any;
  hasPagination: boolean;
  fetching: boolean;
  actionButtons?: Array<IActionButtons>;
  handlePaginationChange?: any;
  currentPage?: any;
  sortTable?: boolean;
  sortingCriteria?: ISortingCriteria;
  onSortChange?: (criteria: ISortingCriteria) => void;
  showActionButtonsConditionally?: boolean;
  children?: any;
  contentRowClassName: string;
  tableClassName: string;
  fetchData?: (criteria: ISortingCriteria) => void;
  tableError: boolean;
  tableMessage: string | undefined;
  useInfiniteScroll?: boolean;
  selectAllChecked?: boolean;
  onSelectAll?: (value: any) => void;
  disablePagination?:boolean;
}

function CustomTable(props: ICustomTable) {
  const {
    columns,
    tableData,
    hasPagination,
    fetching,
    actionButtons,
    sortTable,
    showActionButtonsConditionally,
    children,
    sortingCriteria,
    contentRowClassName,
    tableClassName,
    currentPage,
    paginationData,
    handlePaginationChange,
    tableError,
    tableMessage,
    useInfiniteScroll,
    fetchData,
    onSortChange,
    selectAllChecked,
    onSelectAll,
    disablePagination
  } = props;
  const listInnerRef = useRef<HTMLDivElement>(null);

  const handleSortColumn = (sortKey: string) => {
    if (sortTable) {
      const newSortingCriteria: ISortingCriteria = {
        sortBy: sortKey,
        sortOrder:
          sortKey === sortingCriteria?.sortBy
            ? sortingCriteria?.sortOrder === "ascending"
              ? "descending"
              : "ascending"
            : "ascending",
      };
      onSortChange && onSortChange(newSortingCriteria);
      if (typeof fetchData === "function") {
        fetchData(newSortingCriteria);
      }
    }
  };

  const handleAction = (action: IActionButtons, rowData: any) => {
    if (typeof action.handleClick === "function") {
      action.handleClick(rowData);
    } else {
      console.error("action.onClick is not a function");
    }
  };

  const renderColumnHeader = (column: IColumns) => {
    const isSortable = sortTable && column.sort;
    const isSorted = sortingCriteria?.sortBy === column.sortKey;
    const arrowIcon = isSortable ? (
      isSorted ? (
        sortingCriteria?.sortOrder === "ascending" ? (
          <SvgIcon
            svgType={SVGType.SEMANTIC}
            name={"arrow up"}
            size={"small"}
          />
        ) : (
          <SvgIcon
            svgType={SVGType.SEMANTIC}
            name={"arrow down"}
            size={"small"}
          />
        )
      ) : (
        <SvgIcon svgType={SVGType.SEMANTIC} name={"arrow up"} size={"small"} />
      )
    ) : (
      undefined!
    );
  
    return (
      <th
        key={column.key}
        onClick={() => isSortable && handleSortColumn(column.sortKey || "")}
        className={isSortable ? "cursor-pointer" : ""}
      >
        {column.key === "selectCheckbox" && (
          <input
            type="checkbox"
            checked={selectAllChecked || false}
            onChange={(e) => onSelectAll && onSelectAll(e.target.checked)}
            className="flex flex-row flex-align-center width-100"
          />
        )}
        {column.header} {arrowIcon}
      </th>
    );
  };

  const MemoizedRow = ({ rowData, index }: { rowData: any; index: number }) => {
    return useMemo(
      () => (
        <tr key={`${index}-row`}>
          {columns.map((column) => (
            <td
              key={column.key}
              className={column.columnClassName || contentRowClassName}
            >
              {column.customValue
                ? column.customValue(rowData)
                : rowData[column.key]}
            </td>
          ))}
          {showActionButtonsConditionally && actionButtons && (
            <td key={`${index}-action`} className={contentRowClassName}>
              {actionButtons.map((action, actionIndex) => (
                <CustomButton
                  key={actionIndex}
                  type="button"
                  buttonText={action.label ? action.label : ""}
                  greyButton
                  transparent
                  handleClick={() => handleAction(action, rowData)}
                  baseclassname={"cursor-pointer"}
                  iconPosition={ICON_POSITION.RIGHT}
                  iconProps={{
                    name: CUSTOM_SVG_ICON[action.icon] as string,
                    svgType: SVGType.CUSTOM,
                    size: "small",
                    baseclassname: `text-${action.iconColor}-color`,
                  }}
                />
              ))}
            </td>
          )}
        </tr>
      ),
      [rowData, columns]
    );
  };

  const renderData = (rowData: any, index: number) => {
    return (
      <MemoizedRow rowData={rowData} index={index} key={`index-${index}`} />
    );
  };

  const handleScroll = () => {
    if (
      listInnerRef.current &&
      listInnerRef.current.scrollTop + listInnerRef.current.clientHeight >=
        listInnerRef.current.scrollHeight
    ) {
      if (typeof fetchData === "function") {
        fetchData(sortingCriteria as ISortingCriteria);
      }
    }
  };

  useEffect(() => {
    if (listInnerRef.current && useInfiniteScroll) {
      const handleScrollDebounced = debounce(handleScroll, 300);
      listInnerRef.current.addEventListener("scroll", handleScrollDebounced);
      return () => {
        listInnerRef.current?.removeEventListener(
          "scroll",
          handleScrollDebounced
        );
      };
    }
  }, [handleScroll, useInfiniteScroll]);

  console.log("", tableData);

  return (
    <Fragment>
      {children && <Fragment>{children}</Fragment>}
      <div
        ref={listInnerRef}
        className={classNames([
          "custom-table-container width-100",
          tableClassName,
        ])}
      >
        {fetching && <Loading />}
        {!fetching && !tableError && tableData?.length > 0 && (
          <table>
            <thead>
              <tr>
                {columns.map(renderColumnHeader)}
                {showActionButtonsConditionally && actionButtons && (
                  <th>Action</th>
                )}
              </tr>
            </thead>

            <tbody>{tableData?.map(renderData)}</tbody>
          </table>
        )}
        {/* {tableData?.length <= 0 && (
          <div className="text-5 flex flex-justify-center flex-align-center height-100 text-primary-color">
            No Records Available!
          </div>
        )} */}
        {(tableError || tableData?.length <= 0) && (
          <div className="text-5 flex flex-justify-center flex-align-center height-100 text-primary-color">
            {tableMessage || "No Records Available!"}
          </div>
        )}
      </div>
      {hasPagination && (
        <div
          className={classNames([
            "custom-pagination-container width-100 padding-b-1",
          ])}
        >
          <div className="flex flex-row">
            <Pagination
            disabled={disablePagination || false}
              activePage={currentPage}
              onPageChange={handlePaginationChange}
              totalPages={paginationData?.totalPages || 1}
              pointing
              firstItem={null}
              lastItem={null}
              secondary
            />
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default CustomTable;
