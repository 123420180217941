export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 26 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8.3857V8.98099V19.6429H6.03947V18.1372L6.06514 18.1462C7.64312 18.6733 9.8996 19.1425 12.1303 19.9438L12.296 20L12.4595 19.9634C13.1602 19.7996 15.0493 19.2146 17.3013 18.488C19.5533 17.7613 22.124 16.9021 24.0341 16.2398C24.9475 15.9223 25.4367 14.8935 25.101 13.9824H25.0917C24.739 13.0503 23.7266 12.6398 22.8552 12.9084V12.8993C22.1289 13.1228 18.7181 14.1805 16.4796 14.844C16.5007 14.2033 16.6683 13.5105 16.1504 13.1025L16.1224 13.0751L16.0874 13.0568C13.5714 11.5496 13.3564 11.367 9.81912 9.47855L9.6557 9.385L9.4736 9.40324C8.25831 9.53416 6.19307 9.87734 6.03948 9.90289V8.38542L0 8.3857ZM1.18828 9.58798H4.85119V18.45H1.18828V9.58798ZM9.41757 10.6244C12.6487 12.3529 13.0216 12.6165 15.4103 14.047C15.7902 14.3512 15.8994 14.7292 15.6671 15.0648C15.5414 15.2463 15.4109 15.3185 15.2283 15.3495C15.0479 15.3814 14.7999 15.3461 14.5162 15.2213H14.5069C13.4284 14.7207 12.8464 14.3952 11.6564 13.7248L11.1358 13.4306L10.5498 14.4764L11.0704 14.7707C12.2665 15.4445 12.8993 15.786 14.012 16.3021L14.0213 16.3113C14.4757 16.5148 14.9577 16.6037 15.429 16.5239C15.6237 16.492 15.7533 16.2982 15.9309 16.2019L15.9496 16.258L16.5239 16.0923C18.7652 15.4331 22.4532 14.2774 23.2007 14.0472C23.484 13.9601 23.8642 14.1034 23.9781 14.4045C24.0842 14.706 23.947 15.0026 23.6396 15.1095C21.7423 15.7673 19.1792 16.6149 16.9348 17.339C14.7557 18.0421 12.9225 18.6075 12.3311 18.7537C10.0796 17.9668 7.84133 17.4775 6.441 17.0098L6.03945 16.8789V11.1173L6.13984 11.099C6.13984 11.099 8.20228 10.7676 9.41757 10.6244Z"
        fill="currentColor"
      />
      <path
        d="M15.21 8.68V4.3L15.41 3.98V0.11H15.99V4.11L15.8 4.53V8.68H15.21ZM17.39 2.06C17.1434 1.93333 16.8734 1.82333 16.58 1.73C16.2867 1.63667 16 1.59 15.72 1.59C15.3334 1.59 15.0234 1.68 14.79 1.86C14.5634 2.04 14.45 2.28 14.45 2.58C14.45 2.80667 14.5234 3 14.67 3.16C14.8167 3.31333 15.01 3.44667 15.25 3.56C15.49 3.67333 15.74 3.78 16 3.88C16.2267 3.96667 16.45 4.06333 16.67 4.17C16.89 4.27667 17.0867 4.41 17.26 4.57C17.44 4.73 17.58 4.92667 17.68 5.16C17.7867 5.39333 17.84 5.68333 17.84 6.03C17.84 6.41 17.7434 6.75667 17.55 7.07C17.3634 7.38333 17.0934 7.63 16.74 7.81C16.3867 7.99 15.9667 8.08 15.48 8.08C15.16 8.08 14.8534 8.04333 14.56 7.97C14.2734 7.89667 14.0067 7.80333 13.76 7.69C13.5134 7.57 13.2934 7.44667 13.1 7.32L13.53 6.56C13.69 6.67333 13.8734 6.78333 14.08 6.89C14.2867 6.99 14.5034 7.07333 14.73 7.14C14.9567 7.2 15.1767 7.23 15.39 7.23C15.6167 7.23 15.84 7.19 16.06 7.11C16.2867 7.02333 16.4734 6.89333 16.62 6.72C16.7667 6.54667 16.84 6.31667 16.84 6.03C16.84 5.78333 16.7734 5.58 16.64 5.42C16.5134 5.25333 16.3434 5.11333 16.13 5C15.9167 4.88 15.69 4.77333 15.45 4.68C15.2234 4.59333 14.9934 4.5 14.76 4.4C14.5267 4.29333 14.31 4.16667 14.11 4.02C13.91 3.86667 13.75 3.68 13.63 3.46C13.51 3.24 13.45 2.97333 13.45 2.66C13.45 2.28 13.54 1.95 13.72 1.67C13.9067 1.38333 14.16 1.16 14.48 1C14.8 0.833333 15.1667 0.743333 15.58 0.729999C16.0467 0.729999 16.4567 0.786666 16.81 0.9C17.17 1.01333 17.4867 1.15333 17.76 1.32L17.39 2.06Z"
        fill="currentColor"
      />
    </svg>
  );
};
