import { RequestType, StandardAction, defineRequestType } from '../common';

export const AUTH_ACTION: RequestType = defineRequestType(`AUTH_ACTION`);
export const CURRENT_USER_ACTION = defineRequestType(`CURRENT_USER_ACTION`);
export const LOGOUT_ACTION = "LOGOUT_ACTION";

export const SIGN_IN_WITH_GOOGLE: RequestType = defineRequestType("SIGN_IN_WITH_GOOGLE")
export const SIGN_UP_WITH_GOOGLE: RequestType = defineRequestType("SIGN_UP_WITH_GOOGLE")

export const SIGN_IN_WITH_FACEBOOK: RequestType = defineRequestType("SIGN_IN_WITH_FACEBOOK")
export const SIGN_UP_WITH_FACEBOOK: RequestType = defineRequestType("SIGN_UP_WITH_FACEBOOK")

export const AUTHENTICATE_WITH_FACEBOOK: RequestType = defineRequestType("AUTHENTICATE_WITH_FACEBOOK")


export const requestLogin = (payload: any): StandardAction => {
  return {
    type: AUTH_ACTION.REQUEST,
    payload,
  };
};

export const requestUserDetail = (): StandardAction => {
  return {
    type: CURRENT_USER_ACTION.REQUEST,
    payload: {},
  };
};

export const requestLogout = (): StandardAction => {
  return {
    type: LOGOUT_ACTION,
    payload: {},
  };
};

export const requestLoginWithGoogle = (payload: any): StandardAction => ({
  type: SIGN_IN_WITH_GOOGLE.REQUEST,
  payload,
});

export const requestSignupWithGoogle = (payload: any) => ({
  type: SIGN_UP_WITH_GOOGLE.REQUEST,
  payload,
});


export const requestLoginWithFacebook = (payload: any): StandardAction => ({
  type: SIGN_IN_WITH_FACEBOOK.REQUEST,
  payload,
});

export const requestSignupWithFacebook = (payload: any) => ({
  type: SIGN_UP_WITH_FACEBOOK.REQUEST,
  payload,
});

export const requestAuthenticationWithFacebook = (payload: any) => ({
  type: AUTHENTICATE_WITH_FACEBOOK.REQUEST,
  payload,
});

