export default () => {
  return (
    <svg
      width="32"
      height="27"
      viewBox="0 0 32 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 14.3333C15.4281 14.3333 17.6667 12.0948 17.6667 9.33333V6C17.6667 3.23858 15.4281 1 12.6667 1C9.90524 1 7.66667 3.23858 7.66667 6V9.33333C7.66667 12.0948 9.90524 14.3333 12.6667 14.3333ZM12.6667 14.3333C18.1895 14.3333 24.3333 17.809 24.3333 23.1197V24.3333C24.3333 25.2538 23.5871 26 22.6667 26H2.66667C1.74619 26 1 25.2538 1 24.3333V23.1197C1 17.809 7.14382 14.3333 12.6667 14.3333Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3666 1.29639C21.2912 1.99207 22.6667 3.83541 22.6667 6V7.66667C22.6667 9.82847 21.2541 11.9689 19.3334 12.6667C20.4571 11.1354 21.0182 9.19049 21.0167 6.83198C21.0151 4.47348 20.465 2.62828 19.3666 1.29639ZM27.6667 26H29.3334C30.2538 26 31 25.2538 31 24.3333C31 23.9288 31 23.5243 31 23.1197C31 19.5033 28.151 16.7377 24.5891 15.3413C24.5891 15.3413 29.3334 19.3333 27.6667 26Z"
        fill="white"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
