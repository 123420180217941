export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 1H9.5V0.5C9.5 0.367392 9.44732 0.240215 9.35355 0.146447C9.25979 0.0526784 9.13261 0 9 0C8.86739 0 8.74021 0.0526784 8.64645 0.146447C8.55268 0.240215 8.5 0.367392 8.5 0.5V1H3.5V0.5C3.5 0.367392 3.44732 0.240215 3.35355 0.146447C3.25979 0.0526784 3.13261 0 3 0C2.86739 0 2.74021 0.0526784 2.64645 0.146447C2.55268 0.240215 2.5 0.367392 2.5 0.5V1H2C1.46957 1 0.960859 1.21071 0.585786 1.58579C0.210714 1.96086 0 2.46957 0 3L0 10C0 10.5304 0.210714 11.0391 0.585786 11.4142C0.960859 11.7893 1.46957 12 2 12H10C10.5304 12 11.0391 11.7893 11.4142 11.4142C11.7893 11.0391 12 10.5304 12 10V3C12 2.46957 11.7893 1.96086 11.4142 1.58579C11.0391 1.21071 10.5304 1 10 1ZM11 10C11 10.2652 10.8946 10.5196 10.7071 10.7071C10.5196 10.8946 10.2652 11 10 11H2C1.73478 11 1.48043 10.8946 1.29289 10.7071C1.10536 10.5196 1 10.2652 1 10V3C1 2.73478 1.10536 2.48043 1.29289 2.29289C1.48043 2.10536 1.73478 2 2 2H2.5V2.5C2.5 2.63261 2.55268 2.75979 2.64645 2.85355C2.74021 2.94732 2.86739 3 3 3C3.13261 3 3.25979 2.94732 3.35355 2.85355C3.44732 2.75979 3.5 2.63261 3.5 2.5V2H8.5V2.5C8.5 2.63261 8.55268 2.75979 8.64645 2.85355C8.74021 2.94732 8.86739 3 9 3C9.13261 3 9.25979 2.94732 9.35355 2.85355C9.44732 2.75979 9.5 2.63261 9.5 2.5V2H10C10.2652 2 10.5196 2.10536 10.7071 2.29289C10.8946 2.48043 11 2.73478 11 3V10Z"
        fill="currentColor"
      />
      <path
        d="M9.49988 3.50006H2.49988C2.36727 3.50006 2.24009 3.55274 2.14632 3.64651C2.05256 3.74028 1.99988 3.86745 1.99988 4.00006C1.99988 4.13267 2.05256 4.25985 2.14632 4.35361C2.24009 4.44738 2.36727 4.50006 2.49988 4.50006H9.49988C9.63249 4.50006 9.75966 4.44738 9.85343 4.35361C9.9472 4.25985 9.99988 4.13267 9.99988 4.00006C9.99988 3.86745 9.9472 3.74028 9.85343 3.64651C9.75966 3.55274 9.63249 3.50006 9.49988 3.50006Z"
        fill="currentColor"
      />
      <path
        d="M3.49988 6H2.49988C2.36727 6 2.24009 6.05268 2.14632 6.14645C2.05256 6.24021 1.99988 6.36739 1.99988 6.5C1.99988 6.63261 2.05256 6.75979 2.14632 6.85355C2.24009 6.94732 2.36727 7 2.49988 7H3.49988C3.63249 7 3.75966 6.94732 3.85343 6.85355C3.9472 6.75979 3.99988 6.63261 3.99988 6.5C3.99988 6.36739 3.9472 6.24021 3.85343 6.14645C3.75966 6.05268 3.63249 6 3.49988 6Z"
        fill="currentColor"
      />
      <path
        d="M3.49988 8.49994H2.49988C2.36727 8.49994 2.24009 8.55262 2.14632 8.64639C2.05256 8.74015 1.99988 8.86733 1.99988 8.99994C1.99988 9.13255 2.05256 9.25972 2.14632 9.35349C2.24009 9.44726 2.36727 9.49994 2.49988 9.49994H3.49988C3.63249 9.49994 3.75966 9.44726 3.85343 9.35349C3.9472 9.25972 3.99988 9.13255 3.99988 8.99994C3.99988 8.86733 3.9472 8.74015 3.85343 8.64639C3.75966 8.55262 3.63249 8.49994 3.49988 8.49994Z"
        fill="currentColor"
      />
      <path
        d="M6.49988 6H5.49988C5.36727 6 5.24009 6.05268 5.14632 6.14645C5.05256 6.24021 4.99988 6.36739 4.99988 6.5C4.99988 6.63261 5.05256 6.75979 5.14632 6.85355C5.24009 6.94732 5.36727 7 5.49988 7H6.49988C6.63249 7 6.75966 6.94732 6.85343 6.85355C6.9472 6.75979 6.99988 6.63261 6.99988 6.5C6.99988 6.36739 6.9472 6.24021 6.85343 6.14645C6.75966 6.05268 6.63249 6 6.49988 6Z"
        fill="currentColor"
      />
      <path
        d="M6.49988 8.49994H5.49988C5.36727 8.49994 5.24009 8.55262 5.14632 8.64639C5.05256 8.74015 4.99988 8.86733 4.99988 8.99994C4.99988 9.13255 5.05256 9.25972 5.14632 9.35349C5.24009 9.44726 5.36727 9.49994 5.49988 9.49994H6.49988C6.63249 9.49994 6.75966 9.44726 6.85343 9.35349C6.9472 9.25972 6.99988 9.13255 6.99988 8.99994C6.99988 8.86733 6.9472 8.74015 6.85343 8.64639C6.75966 8.55262 6.63249 8.49994 6.49988 8.49994Z"
        fill="currentColor"
      />
      <path
        d="M9.49988 6H8.49988C8.36727 6 8.24009 6.05268 8.14632 6.14645C8.05256 6.24021 7.99988 6.36739 7.99988 6.5C7.99988 6.63261 8.05256 6.75979 8.14632 6.85355C8.24009 6.94732 8.36727 7 8.49988 7H9.49988C9.63249 7 9.75966 6.94732 9.85343 6.85355C9.9472 6.75979 9.99988 6.63261 9.99988 6.5C9.99988 6.36739 9.9472 6.24021 9.85343 6.14645C9.75966 6.05268 9.63249 6 9.49988 6Z"
        fill="currentColor"
      />
      <path
        d="M9.49988 8.49994H8.49988C8.36727 8.49994 8.24009 8.55262 8.14632 8.64639C8.05256 8.74015 7.99988 8.86733 7.99988 8.99994C7.99988 9.13255 8.05256 9.25972 8.14632 9.35349C8.24009 9.44726 8.36727 9.49994 8.49988 9.49994H9.49988C9.63249 9.49994 9.75966 9.44726 9.85343 9.35349C9.9472 9.25972 9.99988 9.13255 9.99988 8.99994C9.99988 8.86733 9.9472 8.74015 9.85343 8.64639C9.75966 8.55262 9.63249 8.49994 9.49988 8.49994Z"
        fill="currentColor"
      />
    </svg>
  );
};
