import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { URLRoutes } from "URLRoutes";
import CustomTable from "components/CustomTable";
import { IActionButtons, IColumns } from "components/CustomTable/CustomTable";
import { CustomInput } from "components/CustomInputs";
import HeaderLayout from "components/HeaderLayout";
import { deleteBrand, requestBrandsList } from "store/rext";
import { getBrandListState, getCurrentBrandState } from "store/selectors";
import UsePrevious from "HOC/UsePrevious";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { useUserDetail } from "HOC/useUserDetail";
import { ROLES } from "utils/Enums";
import { modalOperation } from "store/actions";
import { ModalsType } from "containers/ModalManager/ModalManager";
import "./style.scss";

let columns: IColumns[] = [
  {
    key: "name",
    header: "Brand Name",
    customValue: (data: any) =>
      data.logo_url ? (
        <div className="flex flex-align-center flex-justify-left">
          <div className=" image-name flex flex-align-center flex-justify-center background-secondary-color margin-r-1"> 
            <img
              src={data.logo_url || "/images/NoPreview.jpg"}
              style={{ width: "100%", height: "100%" }}
              onError={handleImageError}
            />
          </div>
          <span>{data.name}</span>
        </div>
      ) : (
        data.name
      ),
  },
  {
    key: "email",
    header: "Email ID",
  },
  {
    key: "phone",
    header: "Phone Number",
  },
  {
    key: "address",
    header: "Location",
  },
];

const handleImageError = (e: any) => {
  e.target.onerror = null; 
  e.target.src = "/images/NoPreview.jpg"; 
}

function BrandList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role } = useUserDetail();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { data: brandsList, fetching , error: listError , message: listMessage } = useSelector(getBrandListState);
  const { message , error , fetching: brandFetching } = useSelector(getCurrentBrandState);
const wasfecthing = UsePrevious(brandFetching)

  const { records, paginationInfo } = brandsList || {};

  const handlePagination = (e: any, { activePage }: any) => {
    setCurrentPage(activePage);
    dispatch(
      requestBrandsList({
        page: activePage,
        pageSize: 10,
      })
    );
  };

  const handleInputChange = (name: string, value: any) => {
    dispatch(
      requestBrandsList({
        page: currentPage,
        pageSize: 10,
        search: value,
      })
    );
  };
  
  const handleEditBrand = (id: any) => {
    navigate(URLRoutes.clients.updateBrand.replace(":brandId", id));
  };

  const handleDeleteBrand = (id: any) => {
    dispatch(
      modalOperation.showModal(ModalsType.AlertModal, {
        onSave: (data: any) => {
          dispatch(modalOperation.hideModal());
          dispatch(deleteBrand({ brandId: id }));
        },
        onClose: () => {
          dispatch(modalOperation.hideModal());
        },
        message: "Are you sure you want to delete this brand?",
        primaryBtnTitle: "Yes" , 
        secondaryBtnTitle: "No", 
        title: "Confirmation"
      })
    );
  };

  const actionButtons: IActionButtons[] = [
    {
      handleClick: (rowData: any) => handleEditBrand(rowData.id),
      icon: "Edit",
      iconColor: "default",
    },
    {
      handleClick: (rowData: any) => handleDeleteBrand(rowData.id),
      icon: "Delete",
      iconColor: "default",
      // label: "Re-Invite",
    },
  ];

  useEffect(() => {
    if (message?.length > 0) {
      if (wasfecthing && !brandFetching && !error) {
        toast.success(message);
      } else if(error) {
        toast.error(message);
      }
    }
  }, [brandFetching, error, message]);

  useEffect(() => {
    dispatch(
      requestBrandsList({
        page: currentPage,
        pageSize: 10,
      })
    );
  }, [message]);


  return (
    <HeaderLayout
      title={"Manage Brand"}
      breadcrumbs={[
        { text: "Dashboard",  url:
          role === ROLES.HOB
            ? URLRoutes.clients.users.replace(":userType", ROLES.INFLUENCER)
            : role === ROLES.INFLUENCER
            ? URLRoutes.clients.instaDashboard
            : URLRoutes.clients.profile
          },
        {
          text: "Manage Brand",
          url: URLRoutes.clients.brandList,
        },
      ]}
      // contentClassName="content-height"
    >
      <CustomTable
        columns={columns}
        tableData={records || []}
        hasPagination={true}
        fetching={fetching || brandFetching || false}
        contentRowClassName={"text-4"}
        tableClassName="brandTable-height"
        actionButtons={actionButtons}
        showActionButtonsConditionally
        paginationData={paginationInfo}
        handlePaginationChange={handlePagination}
        currentPage={currentPage}
        tableError={listError}
        tableMessage={listMessage}
        disablePagination={!brandsList?.records?.length}
      >
        <CustomInput
          name="search"
          id="search"
          onChange={handleInputChange}
          placeholder="Search by Brand Name"
          baseClassName="width-50 search-input padding-2"
          iconProps={{
            name: CUSTOM_SVG_ICON.Search,
            svgType: SVGType.CUSTOM,
            size: "small",
          }}
        />
      </CustomTable>
    </HeaderLayout>
  );
}

export default BrandList;
