export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.12981 18.4615C7.98579 18.462 7.84453 18.422 7.72212 18.3461C7.61126 18.2769 7.51989 18.1805 7.45663 18.0661C7.39338 17.9517 7.36032 17.823 7.36058 17.6923V10.2923L1.42212 3.7C0.786564 2.99233 0.435777 2.07425 0.437506 1.12308V0.76923C0.437506 0.565218 0.51855 0.369561 0.662809 0.225303C0.807067 0.0810438 1.00272 0 1.20674 0H19.6683C19.8723 0 20.0679 0.0810438 20.2122 0.225303C20.3564 0.369561 20.4375 0.565218 20.4375 0.76923V1.12308C20.4392 2.07425 20.0884 2.99233 19.4529 3.7L13.5144 10.2923V14.4308C13.5153 14.8603 13.3962 15.2816 13.1706 15.6472C12.945 16.0128 12.6218 16.3081 12.2375 16.5L8.47596 18.3769C8.36879 18.4318 8.25023 18.4608 8.12981 18.4615ZM2.01443 1.53846C2.09066 1.95944 2.28241 2.35093 2.56827 2.66923L8.72212 9.48461C8.842 9.62906 8.90493 9.81237 8.89904 9.99999V16.4461L11.5529 15.1231C11.6809 15.0586 11.7884 14.9596 11.8631 14.8372C11.9379 14.7149 11.977 14.5741 11.976 14.4308V9.99999C11.9765 9.80935 12.0477 9.62569 12.176 9.48461L18.3298 2.66923C18.6212 2.35274 18.8184 1.96108 18.899 1.53846H2.01443Z"
        fill="currentColor"
      />
    </svg>
  );
};
