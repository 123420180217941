export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6667 17L25 11.6667M25 11.6667L19.6667 6.33333M25 11.6667H6.33333M14.3333 17V18.3333C14.3333 20.5425 12.5425 22.3333 10.3333 22.3333H5C2.79087 22.3333 1 20.5425 1 18.3333V5C1 2.79087 2.79087 1 5 1H10.3333C12.5425 1 14.3333 2.79087 14.3333 5V6.33333"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
