import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import {
  getInvitedUserDetails,
  requestCreateUser,
  requestGenreOptions,
  requestUpdateUser,
} from "store/rext";
import {
  getGenreOptionsState,
  getInvitedUserState,
  getRegisterUserState,
} from "store/selectors";
import AuthLayout from "components/AuthLayout";
import Form1 from "./Form1";
import Form2 from "./Form2";
import UsePrevious from "HOC/UsePrevious";
import { URLRoutes } from "URLRoutes";
import { ROLES } from "utils/Enums";
import "./style.scss";

enum FormViews {
  ProfileDetails = "ProfileDetails",
  PriceDetails = "PriceDetails",
}

function Registration() {
  const { id: paramsId, referrer = "" } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentView, setCurrentView] = useState<FormViews>(
    FormViews.ProfileDetails
  );
  const { data: genreOptions } = useSelector(getGenreOptionsState);
  const {
    data: userSignUpData,
    fetching,
    error,
    message,
  } = useSelector(getRegisterUserState);

  const { data: userdetail } = useSelector(getInvitedUserState);
  const wasfecthing = UsePrevious(fetching);
  const {
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    control,
    trigger,
    reset,
    watch,
    setValue,
  } = useForm({
    mode: "onSubmit",
    defaultValues: useMemo(() => {
      return userdetail || {};
    }, [userdetail]),
  });

  const handleOnSubmit = async (data: any) => {
    const transformedData = {
      name: data.name,
      gener: data.gener,
      gender: data.gender,
      state: data.state,
      city: data.city,
      instaHandle: data.instahandle,
      youtubeChannel: data.youtubechannel,
      reelPrice: data.reelprice,
      staticPostPrice: data.staticpostprice,
      carouselPostPrice: data.carouselpostprice,
      storyPrice: data.storyprice,
      swipeUpStoryPrice: data.swipeupstoryprice,
      dob: moment(data.dob).format("YYYY-MM-DD"),
    };
    if (!userdetail.name) {
      await dispatch(requestCreateUser(transformedData, { id: paramsId }));
    } else if (userdetail.name) {
      await dispatch(requestUpdateUser(transformedData, { id: paramsId }));
    }
  };

  const handleNext = async () => {
    const isValid = await trigger();
    if (isValid) {
      setCurrentView(FormViews.PriceDetails);
    }
  };

  const handleBack = () => {
    setCurrentView(FormViews.ProfileDetails);
  };

  const renderView = (view: FormViews) => {
    switch (view) {
      case FormViews.ProfileDetails:
        return (
          <Form1
            control={control}
            errors={errors}
            handleNext={handleNext}
            genreOptions={genreOptions}
            watch={watch}
            setValue={setValue}
            dispatch={dispatch}
            userId={paramsId}
          />
        );
      case FormViews.PriceDetails:
        return (
          <Form2
            control={control}
            fetching={fetching}
            errors={errors}
            handleBack={handleBack}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (paramsId) {
      reset(userdetail);
    } else {
      reset({});
    }
  }, [reset, paramsId, userdetail]);

  useEffect(() => {
    if (isSubmitSuccessful && !fetching && !error) {
      toast.success(message);
      if (referrer === "invite") {
        navigate(
          URLRoutes.clients.users.replace(":userType", ROLES.INFLUENCER)
        );
      } else {
        navigate(
          URLRoutes.clients.changePassword.replace(":token", paramsId as string)
        );
      }
    } else if (isSubmitSuccessful && error) {
      toast.error(message);
    }
  }, [fetching, error, wasfecthing]);

  useEffect(() => {
    dispatch(requestGenreOptions());
    dispatch(getInvitedUserDetails({ id: paramsId }));
    // toast.dismiss();
  }, []);

  return (
    <AuthLayout
      title="Let’s showcase your talent to the world."
      titlePosition="bottom"
      image="/images/manSunglasses.png"
      contentClassName="flex flex-justify-center flex-align-center"
      bannerClassName="background-linear2"
      imagePosition="center"
    >
      <div className="width-45">
        <img src="/images/logo.png" style={{ width: "100%", height: "100%" }} />
      </div>
      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        className="width-75 height-100 padding-4 registration-form"
      >
        {renderView(currentView)}
      </form>
    </AuthLayout>
  );
}

export default Registration;
