import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { URLRoutes } from "URLRoutes";
import CustomButton from "components/CustomButton";
import { HookFormInput, HookFormFileUploader , HookPhoneInput } from "components/FormInputs";
import HeaderLayout from "components/HeaderLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  requestBrandDetail,
  requestCreateBrand,
  requestUpdateBrand,
} from "store/rext";
import { getCurrentBrandState } from "store/selectors";
import UsePrevious from "HOC/UsePrevious";
import Loading from "components/Loading";
import { hookPhoneNumberValidation, hookformRequired } from "utils/FormValidations";
import { useUserDetail } from "HOC/useUserDetail";
import { ROLES } from "utils/Enums";

export const DEFAULT_VALUE: any = {
  name: "",
  file: "",
  emailAddress: "",
  address: "",
  phone: "",
};

export const validateFile = {
  validate: (file: any) => {
    const allowedMimeTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
    ];
    const maxSizeInMB = 5;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

    if (!file) return undefined!;
    if (!allowedMimeTypes.includes(file.type)) {
      return "Only JPEG, GIF, and PNG files are allowed";
    }
    if (file.size > maxSizeInBytes) {
      return `File size should be less than ${maxSizeInMB} MB`;
    }
    return undefined!;
  },
};

const requiredValidation: any = {
  ...hookformRequired("Required"),
};

const phoneNumberValidation = {
  ...hookformRequired("Required"),
  ...hookPhoneNumberValidation()
}

export default function BrandForm() {
  const { brandId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role } = useUserDetail();
  const updatedUrl = URLRoutes.clients.updateBrand.replace(":brandId", brandId as string);
  const {
    data: brandDetail,
    fetching,
    message = "",
    error = "",
  } = useSelector(getCurrentBrandState);
  const wasfecthing = UsePrevious(fetching);
  const mappedData =
    brandDetail.records && brandDetail.records.length > 0
      ? brandDetail.records[0]
      : {};
  const { id, created_at, updated_at, ...restProps } = mappedData || {};

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      return restProps || DEFAULT_VALUE;
    }, [restProps]),
    mode:"onChange"
  });

  const handleOnSubmit = (data: any) => {
    const formData = new FormData();
    formData.append("image", data.file);
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("address", data.address);
    formData.append("phone", data.phone);

    if (brandId) {
      dispatch(requestUpdateBrand(formData, { id: brandId }));
    } else {
      dispatch(requestCreateBrand(formData));
    }
  
  };
  const handleCancelClick = () => {
    if (brandId) {
      reset();
      navigate(URLRoutes.clients.brandList);
    } else {
      navigate(URLRoutes.clients.brandList);
    }
  };

  useEffect(() => {
    if (message.length > 0) {
      if (wasfecthing && !fetching && !error) {
        toast.success(message);
        setTimeout(() => {
          navigate(URLRoutes.clients.brandList);
        }, 3000);
      } else if(error) {
        toast.error(message);
      }
    }
  }, [fetching, error, message]);

  useEffect(() => {
    if (brandId) {
      reset(restProps);
    } else {
      reset(DEFAULT_VALUE);
    }
  }, [reset, brandId, brandDetail]);

  useEffect(() => {
    if (brandId) {
      dispatch(requestBrandDetail({ id: brandId }));
    }
  }, [brandId]);

  return (
    <HeaderLayout
      title={brandId ? "Update Brand" : "Create Brand"}
      breadcrumbs={[
        {
          text: "Dashboard",
          url:
            role === ROLES.HOB
              ? URLRoutes.clients.users.replace(":userType", ROLES.INFLUENCER)
              : role === ROLES.INFLUENCER
              ? URLRoutes.clients.instaDashboard
              : URLRoutes.clients.profile,
        },
        {
          text: brandId ? "Update Brand" : "Create Brand",
          url: brandId ? updatedUrl : URLRoutes.clients.createBrand,
        },
      ]}
      contentClassName="overflow-auto"
    >
      <div className="text-4 text-grey-shade2 padding-8">
        Kindly complete the form below with the necessary details to initiate
        the creation of your Brand.
      </div>
      {!fetching && (
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          className="width-75 padding-l-8 "
        >
          <HookFormInput
            control={control}
            errors={errors}
            label="Brand Name"
            id="name"
            name="name"
            placeholder="Enter Name"
            baseClassName="width-60"
            required
            validation={requiredValidation}
          />
          <div className="flex width-60 flex-align-center flex-justify-between">
            <HookFormFileUploader
              control={control}
              errors={errors}
              label="Upload File"
              name="file"
              acceptFileType=".jpg, .jpeg , .png , .gif"
              baseClassName="width-45"
              validation={validateFile}
            />
            <div className="text-grey-shade-3 text-3 width-45">
              Maximum file size: 5 MB. <br />
              Allowed file type: Jpg, Gif and PNG
            </div>
          </div>
          <HookFormInput
            control={control}
            errors={errors}
            label="Email Address"
            id="email"
            name="email"
            placeholder="Enter Email Address"
            baseClassName="width-60"
            required
            validation={requiredValidation}
          />
          <HookFormInput
            control={control}
            errors={errors}
            label="Location"
            id="address"
            name="address"
            placeholder="Enter Full Address"
            baseClassName="width-60"
            //validation={requiredValidation}
          />
          <HookPhoneInput
            control={control}
            errors={errors}
            label="Number"
            id="phone"
            name="phone"
            placeholder="Enter Phone Number"
            baseClassName="width-60"
            validation={phoneNumberValidation}
            required
          />
          <div className="padding-t-4 padding-b-6">
            <CustomButton
              buttonText="Cancel"
              secondaryButton
              handleClick={handleCancelClick}
              baseclassname={"cursor-pointer margin-r-4"}
              textSmall
              circular
            />
            <CustomButton
              type="submit"
              buttonText={brandId ? "Update" : "Save"}
              primaryButton
              baseclassname={" cursor-pointer"}
              textSmall
              circular
            />
          </div>
        </form>
      )}
      {fetching && <Loading />}
    </HeaderLayout>
  );
}
