import { IAllInfluencersFeature, IAllUserFeature, IBrandFeature, IBrandUserFeature, ICampaignFeature, IInfluencerDetailFeature, IProfileFeature, ISocialProfileFeature } from "./interface";
import { FEATURES } from "./constant";

const findFeatures = (srcFeatures: Array<FEATURES>, destinationFeatures: Array<FEATURES>): any => {
  const result: { [key: string]: boolean } = {};
  let isAllowed: boolean = false;
  destinationFeatures.forEach(feature => {
    const isFound = srcFeatures.findIndex(item => item === feature) >= 0;
    result[feature] = isFound;
    if (isFound && !isAllowed) {
      isAllowed = true
    }
  })
  return {
    ...result,
    isAllowed
  }
}


class Feature {
  private _allUsersFeature: IAllUserFeature;
  private _campaignFeature: ICampaignFeature;
  private _socialProfileFeature: ISocialProfileFeature;
  private _profileFeature: IProfileFeature;
  private _brandFeature: IBrandFeature;
  private _brandUserFeature: IBrandUserFeature;
  private _influencerDetailFeature: IInfluencerDetailFeature;
  private _allInfluencersFeature: IAllInfluencersFeature;

  constructor(features: Array<FEATURES>) {
    this._allUsersFeature = this.setAllUsersFeatures(features);
    this._campaignFeature = this.setCampaignFeatures(features);
    this._profileFeature = this.setProfileFeature(features);
    this._socialProfileFeature = this.setSocialProfileFeatures(features);
    this._brandFeature = this.setBrandFeatures(features);
    this._brandUserFeature = this.setBrandUserFeatures(features);
    this._influencerDetailFeature = this.setInfluencerDetailFeatures(features);
    this._allInfluencersFeature = this.setAllInfluencersFeature(features);
  }

  private setAllUsersFeatures = (features: Array<FEATURES>): IAllUserFeature => {
    const result = findFeatures(features, [FEATURES.ALL_USERS, FEATURES.INVITE_USER, FEATURES.BULK_INVITE_USER]);
    return {
      isAllowed: result[FEATURES.ALL_USERS],
      inviteUser: result[FEATURES.INVITE_USER],
      bulkInviteUsers: result[FEATURES.BULK_INVITE_USER],
      addFavourite: result[FEATURES.ADD_FAVOURITE]
    }
  }

  private setCampaignFeatures = (features: Array<FEATURES>): ICampaignFeature => {
    const result = findFeatures(features, [FEATURES.ALL_CAMPAIGNS , FEATURES.MANAGE_INFLUENCERS , FEATURES.UPLOAD_VIDEO , FEATURES.APPLY_FOR_CAMPAIGN , FEATURES.ACCEPT_CAMPAIGN , FEATURES.REJECT_CAMPAIGN , FEATURES.CREATE_CAMPAIGN , FEATURES.UPDATE_CAMPAIGN , FEATURES.UPLOAD_GUIDELINE , FEATURES.VIEW_GUIDELINE , FEATURES.DELETE_GUIDELINE]);
    return {
      addCampaign: result[FEATURES.CREATE_CAMPAIGN],
      editCampaign: result[FEATURES.UPDATE_CAMPAIGN],
      manageInfluencers: result[FEATURES.MANAGE_INFLUENCERS],
      applyCampign: result[FEATURES.APPLY_FOR_CAMPAIGN],
      uploadVideo:result[FEATURES.UPLOAD_VIDEO], 
      acceptCampaign: result[FEATURES.ACCEPT_CAMPAIGN],
      rejectCampaign: result[FEATURES.REJECT_CAMPAIGN] ,
      uploadGuideLine: result[FEATURES.UPLOAD_GUIDELINE],
      viewGuideLines: result[FEATURES.VIEW_GUIDELINE],
      deleteGuideline: result[FEATURES.DELETE_GUIDELINE],
      isAllowed: result[FEATURES.ALL_CAMPAIGNS],
    }
  }

  private setSocialProfileFeatures = (features: Array<FEATURES>): ISocialProfileFeature => {
    const result = findFeatures(features, [FEATURES.SOCIAL_PROFILE, FEATURES.SOCIAL_PROFILE_DETAIL]);
    return {
      isAllowed: result[FEATURES.SOCIAL_PROFILE],
      detail: result[FEATURES.SOCIAL_PROFILE_DETAIL]
    }
  }

  private setProfileFeature = (features: Array<FEATURES>): IProfileFeature => {
    const result = findFeatures(features, [FEATURES.PROFILE , FEATURES.EDIT_PROFILE]);
    return {
      isAllowed: result[FEATURES.PROFILE] , 
      editProfile: result[FEATURES.EDIT_PROFILE]
    }
  }
  private setAllInfluencersFeature = (features: Array<FEATURES>): IProfileFeature => {
    const result = findFeatures(features, [FEATURES.ALL_INFLUENCERS]);
    return {
      isAllowed: result.isAllowed , 
    }
  }
  
  private setBrandFeatures = (features: Array<FEATURES>): IBrandFeature => {
    const result = findFeatures(features, [FEATURES.BRAND_LIST, FEATURES.CREATE_BRAND, FEATURES.UPDATE_BRAND]);
    return {
      createBrand: result[FEATURES.CREATE_BRAND],
      editBrand: result[FEATURES.UPDATE_BRAND],
      isAllowed: result[FEATURES.BRAND_LIST]
    }
  }

  private setBrandUserFeatures = (features: Array<FEATURES>): IBrandUserFeature => {
    const result = findFeatures(features, [FEATURES.BRAND_USERS_LIST, FEATURES.CREATE_BRAND_USER, FEATURES.UPDATE_BRAND_USER]);
    return {
      createBrandUser: result[FEATURES.CREATE_BRAND_USER],
      editBrandUser: result[FEATURES.UPDATE_BRAND_USER],
      isAllowed: result[FEATURES.BRAND_USERS_LIST]
    }
  }

  private setInfluencerDetailFeatures = (features: Array<FEATURES>): IInfluencerDetailFeature => {
    const result = findFeatures(features, [FEATURES.INFLUENCER_DETAIL , FEATURES.POST_TO_BRAND , FEATURES.POST_TO_INFLUENCER , FEATURES.BUDGET_EDIT , FEATURES.BUDGET_VIEW , FEATURES.QUOTE_EDIT , FEATURES.QUOTE_VIEW , FEATURES.ADD_INFLUENCER , FEATURES.FINAL_EDIT , FEATURES.FINAL_VIEW , FEATURES.TAKES_EDIT , FEATURES.TAKES_VIEW , FEATURES.MARKUP_EDIT , FEATURES.MARKUP_VIEW ]);
    return {
      addInfluencer: result[FEATURES.ADD_INFLUENCER],
      postToBrand: result[FEATURES.POST_TO_BRAND],
      postToInfluencers: result[FEATURES.POST_TO_INFLUENCER], 
      budget_edit: result[FEATURES.BUDGET_EDIT] , 
      budget_view: result[FEATURES.BUDGET_VIEW] , 
      quote_edit: result[FEATURES.QUOTE_EDIT],
      quote_view: result[FEATURES.QUOTE_VIEW] , 
      takes_edit: result[FEATURES.TAKES_EDIT] , 
      takes_view: result[FEATURES.TAKES_VIEW] ,
      final_edit: result[FEATURES.FINAL_EDIT], 
      final_view: result[FEATURES.FINAL_VIEW] ,
      markup_edit: result[FEATURES.MARKUP_EDIT] , 
      markup_view: result[FEATURES.MARKUP_VIEW] ,
      isAllowed: result[FEATURES.INFLUENCER_DETAIL]
    }
  }


  getAllUserFeatures = (): IAllUserFeature => {
    return this._allUsersFeature;
  }

  getCampaignFeatures = (): ICampaignFeature => {
    return this._campaignFeature;
  }

  getSocialProfileFeature = (): ISocialProfileFeature => {
    return this._socialProfileFeature;
  }

  getProfileFeature = (): IProfileFeature => {
    return this._profileFeature;
  }

  getBrandFeatures = (): IBrandFeature => {
    return this._brandFeature;
  }

  getBrandUserFeatures = (): IBrandUserFeature => {
    return this._brandUserFeature;
  }

  getInfluencerDetailFeature = (): IInfluencerDetailFeature => {
    return this._influencerDetailFeature;
  }

  getAllInfluencersFeature = (): IProfileFeature => {
    return this._allInfluencersFeature;
  }
}

export { Feature };