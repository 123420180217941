import React, { useRef } from "react";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import classNames from "classnames";

interface FileControllerProps {
  field: {
    onChange: (file: File | null) => void;
    value: File | null;
    ref: any;
  };
  id: string;
  accept: string;
  disabled?: boolean;
  placeholder?: string;
  defaultValue?: string;
}

const FileController: React.FC<FileControllerProps> = ({
  field: { onChange, value },
  id,
  accept,
  disabled,
  placeholder,
  defaultValue,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const drop = useRef<HTMLDivElement | null>(null);

  const uploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (drop.current) {
      drop.current.classList.add("file-dragging");
    }
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (drop.current) {
      drop.current.classList.remove("file-dragging");
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (drop.current) {
      drop.current.classList.remove("file-dragging");

      const files = event.dataTransfer.files;
      if (files && files.length > 0) {
        const file = files[0];
        onChange(file);
        // const previewUrl = URL.createObjectURL(file);
        // setImagePreview(previewUrl);
        // setFileName(file.name);
      }
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      onChange(file);
      // const previewUrl = URL.createObjectURL(file);
      // setImagePreview(previewUrl);
      // setFileName(file.name);
    }
  };

  return (
    <div
      ref={drop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      draggable
      onClick={uploadButtonClick}
      className={classNames([
        "flex flex-column flex-align-center width-100 flex-justify-center margin-t-3 height-100 uploadFile-container position-relative",
        { "padding-1": defaultValue },
        { "padding-t-4 padding-b-4 padding-l-2 padding-r-2": value?.name || !value },
      ])}
    >
      <SvgIcon
        name={CUSTOM_SVG_ICON.Edit}
        svgType={SVGType.CUSTOM}
        size={"small"}
        baseclassname={"position-absolute edit-icon"}
        onClick={uploadButtonClick}
      />
      <input
        ref={fileInputRef}
        onChange={handleFileChange}
        type="file"
        id={id}
        accept={accept}
        disabled={disabled}
        className="file-input"
        hidden
      />
      {!value && !placeholder && (
        <div className="text-3 line-h-4 text-align-center text-grey-shade-2 text-bold">
          Drag & Drop, or
          <span className="text-blue-shade-6 margin-l-1">browse</span> your file
        </div>
      )}
      {!value && placeholder && <div dangerouslySetInnerHTML={{__html:placeholder }} className="text-3 line-h-4 text-align-center text-grey-shade-2 text-bold" />}
      {defaultValue && !value?.name && (
        <div
          className="flex flex-align-center"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        >
          <img
            src={defaultValue}
            alt="Preview"
            className="uploaded-image-preview"
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </div>
      )}
      {!defaultValue && !value && (
        <SvgIcon
          name={CUSTOM_SVG_ICON.Upload}
          svgType={SVGType.CUSTOM}
          size={"huge"}
          baseclassname={"text-grey-shade-4"}
        />
      )}
      {value?.name && <div className="file-name">{value.name}</div>}
    </div>
  );
};

export default FileController;
