export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 9.29032C24 7.42106 22.668 5.85706 20.9032 5.49739V2.7214C20.9032 1.99294 20.6207 1.3091 20.1075 0.795741C19.5945 0.282762 18.9108 0 18.182 0C17.8333 0 17.4819 0.0680444 17.1706 0.194682L10.7448 2.76487L11.3197 4.20287L17.7501 1.6308C18.1593 1.46371 18.6871 1.56464 19.0125 1.8905C19.2333 2.11127 19.3548 2.4065 19.3548 2.7214V5.41935V13.1613V14.698C19.3548 15.3448 18.8286 15.871 18.182 15.871C18.0318 15.871 17.8822 15.8426 17.7456 15.7867L8.90323 12.2499V3.87097H4.37809C3.34495 3.87097 2.3738 4.27319 1.64327 5.00391L1.45785 5.18914C0.991368 5.65562 0.62752 6.19846 0.375567 6.80444C0.126449 7.41003 0 8.05116 0 8.70968C0 10.0396 0.517704 11.2897 1.45766 12.2298L2.3808 13.1531L3.76474 20.4186C3.90367 21.1482 4.54328 21.6774 5.28572 21.6774H8.74181C9.20489 21.6774 9.63981 21.4729 9.93485 21.1161C10.2301 20.7596 10.3495 20.2942 10.263 19.8395L9.55324 16.1133L8.03207 16.4028L8.74181 20.129H5.28591L4.03241 13.5484H7.97952L17.1661 17.2228C17.4819 17.3513 17.8333 17.4194 18.182 17.4194C19.6826 17.4194 20.9032 16.1987 20.9032 14.698V13.0833C22.668 12.7236 24 11.1596 24 9.29032ZM7.35484 12H3.41753L2.55261 11.1351C1.90505 10.4875 1.54839 9.62601 1.54839 8.70968C1.54839 8.25416 1.63552 7.81149 1.80658 7.39642C1.97915 6.98097 2.23015 6.60673 2.55299 6.2839L2.73841 6.09829C3.17635 5.66053 3.75869 5.41935 4.37809 5.41935H7.35484V12ZM20.9032 11.4802V7.10043C21.8044 7.41986 22.4516 8.281 22.4516 9.29032C22.4516 10.2996 21.8044 11.1608 20.9032 11.4802Z"
        fill="currentColor"
      />
    </svg>
  );
};
