import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getAllMatrixState } from "store/selectors";
import Loading from "components/Loading";
import { requestAgeMatrixDetails } from "store/rext";

const genderMap: any = {
  "M": "Male",
  "F": "Female",
};

const StatsCard = ({
  title,
  name,
  value,
}: {
  title: string;
  name: string;
  value: string;
}) => (
  <div className="flex flex-column width-30 margin-b-2 margin-r-2">
    <div className="text-3 line-h-5 text-grey-shade-4">{title}</div>
    <div className="text-4 line-h-5 text-bold">{genderMap[name] || name}</div>
    <div className="text-4 text-grey-shade-4">{value ? `${value}%` : "-"}</div>
  </div>
);

function getAgeGroupData(data: any[]) {
  const transformedData = data?.reduce((acc: any, curr: any) => {
    const { age_group, gender, count } = curr;
    if (!acc[age_group]) {
      acc[age_group] = { M: 0, F: 0, U: 0 };
    }
    acc[age_group][gender] += parseInt(count, 10);
    return acc;
  }, {});

  const totalCounts: any = Object.values(transformedData).reduce(
    (totals: any, ageGroupData: any) => {
      totals.M += ageGroupData.M;
      totals.F += ageGroupData.F;
      return totals;
    },
    { M: 0, F: 0 }
  );

  return { transformedData, totalCounts };
}

export function FollowersByAge({ instaHandle }: { instaHandle: string }) {
  const dispatch = useDispatch();
  const { data, fetching, error, message } = useSelector(getAllMatrixState);
  const {
    ageGroupData,
    topAgeGroup,
    topAgeGroupPercentage,
    topGender,
    topGenderPercentage,
  } = data || {};

  const transformedValues = useMemo(() => {
    if (ageGroupData && ageGroupData.length > 0) {
      const ageGroupCounts: any = {};
      let totalCount = 0; // Total count excluding unspecified gender
      let totalMaleCount = 0;
      let totalFemaleCount = 0;

      // Step 2: Aggregate counts
      ageGroupData.forEach((item: any) => {
        const { age_group, gender, count } = item;
        const countNum = parseInt(count, 10); // Convert count to number

        if (!ageGroupCounts[age_group]) {
          ageGroupCounts[age_group] = { M: 0, F: 0, U: 0, total: 0 };
        }

        if (gender === "M") {
          ageGroupCounts[age_group].M += countNum;
          totalMaleCount += countNum;
          totalCount += countNum; // Include in total count
        } else if (gender === "F") {
          ageGroupCounts[age_group].F += countNum;
          totalFemaleCount += countNum;
          totalCount += countNum; // Include in total count
        }
        //  else if(gender === "U") {
        //   ageGroupCounts[age_group].U += countNum;
        //   totalCount += countNum
        // }
        // We do not include 'U' in the total count calculation
      });

      const ageGroups = Object.keys(ageGroupCounts);
      const malePercentages: number[] = [];
      const femalePercentages: number[] = [];
      let otherPercentages: number[] = [];
      ageGroups.forEach((ageGroup) => {
        const counts = ageGroupCounts[ageGroup];
        //const totalCountForAgeGroup = counts.M + counts.F;

        const malePercentage = totalCount
          ? Math.round((counts.M / totalCount) * 100)
          : 0;
        const femalePercentage = totalCount
          ? Math.round((counts.F / totalCount) * 100)
          : 0;
        // const otherPercentage = totalCount ? Math.round((counts.U / totalCount) * 100) : 0;
        malePercentages.push(malePercentage);
        femalePercentages.push(femalePercentage);
        // otherPercentages.push(otherPercentage);
      });

      return {
        ageGroups: ageGroups,
        maleCount: malePercentages,
        femaleCount: femalePercentages,
        // otherCount: otherPercentages,
      };
    }

    return {
      ageGroups: [],
      maleCount: [],
      femaleCount: [],
    };
  }, [data]);

  const ageGroupChartOptions = (male: any, female: any, others?: any) => ({
    chart: {
      type: "column",
    },
    title: {
      text: undefined,
    },
    xAxis: {
      categories: transformedValues.ageGroups || [],
    },
    yAxis: {
      title: {
        text: "Percentage",
      },
      labels: {
        format: "{value}%",
      },
      max: 80,
    },
    series: [
      {
        type: "column",
        name: "Male",
        data: male || [],
      },
      {
        type: "column",
        name: "Female",
        data: female || [],
      },
      // {
      //   type: "column",
      //   name: "Unknown",
      //   data: others || [],
      // },
    ],
    tooltip: {
      enabled: true,
      valueSuffix: "%",
    },
    credits: {
      enabled: false,
    },
  });

  useEffect(() => {
    dispatch(requestAgeMatrixDetails({ instaHandle }, { handle: instaHandle }));
  }, []);

  return (
    <div className="width-100 height-100 flex flex-column flex-align-center padding-2">
      {!fetching && data?.ageGroupData?.length > 0 && (
        <>
          <div className="flex flex-justify-center width-100 margin-t-2 margin-b-2">
            <StatsCard
              title="TOP GENDER"
              value={topGenderPercentage}
              name={topGender}
            />
            <StatsCard
              title="TOP AGE GROUP"
              value={topAgeGroupPercentage}
              name={topAgeGroup}
            />
          </div>
          <HighchartsReact
            highcharts={Highcharts}
            options={ageGroupChartOptions(
              transformedValues?.maleCount,
              transformedValues?.femaleCount
            )}
            // options={ageGroupChartOptions(transformedValues?.maleCount , transformedValues?.femaleCount, transformedValues?.otherCount)}
            containerProps={{ className: "height-50 width-90 margin-t-3" }}
          />
        </>
      )}
      {!fetching && (data?.ageGroupData?.length === undefined || error) && (
        <div className="padding-t-4 padding-b-4 text-4 text-bold text-grey-shade4">
          {message}
        </div>
      )}
      {fetching && <Loading inverted />}
    </div>
  );
}
