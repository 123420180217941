import { Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalOperation } from "../../store/actions";
import { getModalState } from "../../store/selectors";
import AlertModalView from "./AlertModal";
import AddInfluencerModalView from "./AddInfluencerModal";
import BulkInviteModalView from "./BulkInviteModal";
import ApplyCampaignModalView from "./ApplyCampaignModal";
import VideoModalView from "./VideoModal";
import ChatModalView from "./ChatModal";
import ProfileModalView from "./ProfileModal";
import DetailedTermModalView from "./DetailedTermsModal";
import "./modal.scss";


export enum ModalsType {
  AlertModal = "AlertModalView",
  AddInfluencerModal = "AddInfluencerModalView",
  BulkInviteModal = "BulkInviteModalView",
  ApplyCampaignModal = "ApplyCampaignModalView",
  VideoModal = "VideoModalView", 
  ChatModal = "ChatModalView",
  ProfileModal = "ProfileModalView",
  DetailedTermModal = "DetailedTermModalView"
}
const modalComponentLookupTable: any = {
  AlertModalView,
  AddInfluencerModalView,
  BulkInviteModalView,
  ApplyCampaignModalView,
  VideoModalView,
  ChatModalView,
  ProfileModalView, 
  DetailedTermModalView
};


function ModalManager() {
  const dispatch = useDispatch();
  const modalState = useSelector(getModalState);
  const hideModal = () => {
    dispatch(modalOperation.hideModal());
  }

  const modal = useMemo(() => {
    const { name, modalProps = {} } = modalState || {};
    if (name) {
      const ModalComponent = modalComponentLookupTable[name];
      return (
        <ModalComponent {...modalProps} hideModal={hideModal} />
      );
    } else {
      return <Fragment />
    }

  }, [modalState]);

  return <Fragment>{modal}</Fragment>;
}

export default ModalManager;
