import ExpandCollapse from "components/ExpandCollapse";
import HeaderLayout from "components/HeaderLayout";
import React, { useState } from "react";
import { URLRoutes } from "URLRoutes";

interface IFAQ {
  title: string;
  description: React.ReactNode;
}

const FAQDetails: Array<IFAQ> = [
  {
    title: "What is BuzzPRIME?",
    description: (
      <div className="padding-4">
        BuzzPRIME is an AI-powered Influencer Marketing Platform developed by{" "}
        <span className="text-bold">Highonbuzz</span> that helps brands find the
        right influencers, manage campaigns, and track performance in real-time.
        Our platform simplifies the entire influencer marketing process, making
        it easier for both brands and influencers to collaborate seamlessly.
      </div>
    ),
  },
  {
    title: "How does BuzzPRIME help brands?",
    description: (
      <div className="padding-4">
        BuzzPRIME enables brands to:
        <li> Discover influencers that align with their target audience.</li>
        <li> Manage influencer marketing campaigns effortlessly.</li>
        <li>
          Gain valuable campaign insights through performance tracking in
          real-time.
        </li>
        <li>Optimize campaigns using data-driven insights to maximize ROI.</li>
      </div>
    ),
  },
  {
    title: "What services does BuzzPRIME offer?",
    description: (
      <div className="padding-4">
        BuzzPRIME offers:
        <li>
          <span className="text-bold margin-r-1">Influencer discovery: </span>
          Brands can find and select influencers based on engagement metrics and
          audience alignment.
        </li>
        <li>
          <span className="text-bold margin-r-1">Campaign management: </span>{" "}
          Brands can manage all aspects of their influencer marketing campaigns
          on one platform.
        </li>
        <li>
          <span className="text-bold margin-r-1">Performance tracking: </span>{" "}
          Measure the effectiveness of campaigns using real-time data and
          insights.
        </li>
      </div>
    ),
  },
  {
    title: "Is there a fee to use BuzzPRIME?",
    description: (
      <div className="padding-4">
        Currently, BuzzPRIME does not charge any fees for basic services. As the
        platform evolves, we may introduce premium features, but we will notify
        all users well in advance.
      </div>
    ),
  },
  {
    title: "Who can sign up on BuzzPRIME?",
    description: (
      <div className="padding-4">
        <li>
          <span className="text-bold margin-r-1"> For brands:</span> Any company
          or individual seeking to run influencer marketing campaigns can sign
          up.
        </li>
        <li>
          <span className="text-bold margin-r-1">For influencers: </span>
          Influencers who are at least 18 years old and are based in India can
          register to collaborate with brands through the platform.
        </li>
      </div>
    ),
  },
  {
    title: "How can I become an influencer on BuzzPRIME?",
    description: (
      <div className="padding-4">
        To join as an influencer, simply sign up on the BuzzPRIME platform,
        create a profile, and upload relevant information about your social
        media channels. Brands will then be able to find you based on your
        niche, audience size, and engagement rate.
      </div>
    ),
  },
  {
    title: "What kind of data does BuzzPRIME collect?",
    description: (
      <div className="padding-4">
        We collect personal data such as:
        <li>Name</li>
        <li>Contact information e.g. Email address, phone number etc.</li>
        <li>Social media profiles</li>
        <li>Pricing information</li>
        <li>Campaign performance metrics</li>
        <li>Few more fields, updated from time to time</li>
        <li>
          This data is used to improve collaboration opportunities between
          influencers and brands. We may share relevant data with brands for the
          purpose of campaign selection and management.
        </li>
      </div>
    ),
  },
  {
    title: "How does BuzzPRIME select influencers for campaigns?",
    description: (
      <div className="padding-4">
        BuzzPRIME uses AI algorithms to match influencers with brands based on:
        <li>Niche and content relevance.</li>
        <li>Audience demographics and engagement.</li>
        <li>Previous performance on campaigns.</li>
        Brands can also search for influencers manually through filters based on
        their needs.
      </div>
    ),
  },
  {
    title: "Can influencers choose which brands they collaborate with?",
    description: (
      <div className="padding-4">
        Yes, influencers have the freedom to choose which campaigns they
        participate in. Once a brand sends a collaboration request, influencers
        can review the campaign details and accept or decline the opportunity.
      </div>
    ),
  },
  {
    title: "How does BuzzPRIME ensure transparency?",
    description: (
      <div className="padding-4">
        We strive to create a transparent ecosystem by:
        <li>Providing clear, data-driven metrics for influencer selection.</li>
        <li>Offering real-time insights on campaign performance.</li>
        <li>
          Allowing both brands and influencers to view engagement and reach
          statistics.
        </li>
      </div>
    ),
  },
  {
    title:
      "How do I track the performance of my influencer marketing campaigns?",
    description: (
      <div className="padding-4">
        BuzzPRIME offers real-time performance tracking tools where brands can:
        <li>
          Monitor key metrics such as engagement rate, impressions, and
          conversions.
        </li>
        <li>
          Analyze the success of campaigns through comprehensive dashboards.
        </li>
        <li>Get detailed reports on influencer effectiveness.</li>
      </div>
    ),
  },
  {
    title: "How do I contact BuzzPRIME for support?",
    description: (
      <div className="flex flex-column padding-4">
        <div>For any support or inquiries, you can reach us at:</div>
        <div>
          <span className="text-bold margin-r-1">Email:</span>{" "}
          <a href="mailto:support@highonbuzz.com">support@highonbuzz.com</a>
        </div>
      </div>
    ),
  },
  {
    title:
      "How will I be notified of changes to the platform or Terms of Service?",
    description: (
      <div className="padding-4">
        We regularly update our platform and Terms of Service to improve your
        experience. Notifications about significant updates will be sent to your
        registered email, and changes will also be posted on our website.
      </div>
    ),
  },
  {
    title: "Can I terminate my account?",
    description: (
      <div className="padding-4">
        Yes, both influencers and brands can terminate their accounts at any
        time by contacting customer support. However, any active campaigns must
        be completed before termination.
      </div>
    ),
  },
  {
    title: "What happens if I post offensive or inappropriate content?",
    description: (
      <div className="padding-4">
        We maintain strict community guidelines. Posting offensive or
        inappropriate content will result in account suspension or termination.
        We reserve the right to take appropriate actions, including reporting
        illegal activities to the authorities.
      </div>
    ),
  },
  {
    title: "Do you offer refunds for unused services?",
    description: (
      <div className="padding-4">
        Currently, BuzzPRIME does not charge for its services, so refunds are
        not applicable at this stage. If paid services are introduced in the
        future, refund policies will be established accordingly.
      </div>
    ),
  },
  {
    title: "What if there is a dispute between a brand and an influencer?",
    description: (
      <div className="padding-4">
        In the event of a dispute, both parties should contact{" "}
        <span className="text-bold">Highonbuzz</span> through customer support.
        We encourage both brands and influencers to resolve issues amicably, but
        formal dispute resolution mechanisms, such as arbitration, are in place
        if necessary.
      </div>
    ),
  },
  {
    title: "Are there any location restrictions for users?",
    description: (
      <div className="padding-4">
        Currently, our platform is available to users based in{" "}
        <span className="text-bold">India</span>. Future expansion to other
        regions may be considered as we continue to grow.
      </div>
    ),
  },
  {
    title: "How is data privacy handled on BuzzPRIME?",
    description: (
      <div className="padding-4">
        We prioritize your data privacy and security. All personal information
        is securely stored and is only shared with brands when necessary for
        collaborations. For detailed information, refer to our{" "}
        <span className="text-bold">Privacy Policy</span> included within the
        Terms of Service.
      </div>
    ),
  },
];

function FAQContainer() {
  const [showIndex, setShowIndex] = useState(-1);

  const renderFAQ = ({ description, title }: IFAQ, index: number) => {
    return (
      <ExpandCollapse
        key={`faq-${index}`}
        title={title}
        children={description}
        isActive={index === showIndex ? true : false}
        onToggle={() => setShowIndex(index === showIndex ? -1 : index)}
        isLastItem={index === FAQDetails.length - 1}
      />
    );
  };
  return (
    <HeaderLayout
      breadcrumbs={[
        {
          text: `FAQ's`,
          url: URLRoutes.clients.faq,
        },
      ]}
      title={`FAQ's`}
      contentClassName="flex flex-column padding-4 width-100 overflow-auto"
    >
      {FAQDetails.map(renderFAQ)}
    </HeaderLayout>
  );
}

export default FAQContainer;
