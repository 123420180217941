import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "semantic-ui-react";
import { ModalHeader } from "../Common";
import {
  HookFormInput,
  HookFormRadio,
  HookFormAddressAutoComplete,
} from "components/FormInputs";
import CustomButton from "components/CustomButton";
import { getCity, getPostalCode, getState, getStreet } from "utils/utils";
import { hookformRequired } from "utils/FormValidations";
import { useUserDetail } from "HOC/useUserDetail";
import { CampaignType } from "utils/Enums";

interface Props {
  onClose: () => void;
  onSave: (data: any) => void;
  title?: string;
  toggle?: any;
  campaignType: CampaignType;
}

const requiredValidation: any = {
  ...hookformRequired("Required"),
};

function ApplyCampaignModal(props: Props) {
  const { Addresses } = useUserDetail();
  const { onClose, onSave, title, toggle, campaignType } = props;
  const [showNewAddressFields, setShowNewAddressFields] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<any>();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    trigger,
    reset,
    watch,
  } = useForm({});

  const handleOnSubmit = (data: any) => {
    trigger();
    const transformData = {
      ...data,
    };
    if (
      campaignType === CampaignType.PAID_UGC ||
      campaignType === CampaignType.BARTER_UGC
    ) {
      if (!showNewAddressFields) {
        transformData.address = {
          id: selectedAddress?.id,
          addressLine: selectedAddress?.addressLine,
          city: selectedAddress?.city,
          postalCode: selectedAddress?.postalCode,
          state: selectedAddress?.state,
        };
      }
    }
    console.log(transformData);
    onSave(transformData);
  };

  const updateStateAndCity = (place: any) => {
    const selectedCity = getCity(place);
    const selectedState = getState(place);
    const selectedCode = getPostalCode(place);
    // setValue("address.street", getStreet(place));
    setValue("address.state", selectedState);
    setValue("address.city", selectedCity);
    setValue("address.postalCode", selectedCode);
  };

  const handleInputChange = (place: any) => {
    setValue("address.addressLine", place);
  };

  const handlePlaceSelected = (place: any) => {
    updateStateAndCity(place);
  };

  const handleAddressChange = (address: any) => {
    console.log(address, "selected Adrress");
    setSelectedAddress({
      id: address.id,
      addressLine: address.address_line,
      city: address.city,
      postalCode: address.postal_code,
      state: address.state,
    });
  };

  const handleAddNewAddress = () => {
    setShowNewAddressFields(true);
    setSelectedAddress(undefined!);
    setValue("address", {});
  };

  const handleCancelClick = () => {
    reset();
    setShowNewAddressFields(false);
  };

  return (
    <Modal
      size="tiny"
      dimmer
      open
      onClose={onClose}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      className="add-influencer-modal"
    >
      {title && <ModalHeader title={title} toggle={toggle} />}
      <Modal.Content>
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          className="flex flex-column width-100 height-100"
        >
          <div className="width-100 padding-2 flex flex-justify-between flex-align-center">
            <span>Quote</span>
            <HookFormInput
              name="quote"
              control={control}
              errors={errors}
              id="quote"
              placeholder="Quote"
              validation={requiredValidation}
              required
            />
          </div>
          <div className="width-100 padding-2 flex flex-justify-between flex-align-center">
            <span>Number of Retakes</span>
            <HookFormInput
              name="takes"
              control={control}
              errors={errors}
              id="takes"
              placeholder="Takes"
              validation={requiredValidation}
              required
            />
          </div>
          {(campaignType === CampaignType.BARTER_UGC ||
            campaignType === CampaignType.PAID_UGC) && (
            <div className="width-100 padding-2 flex flex-justify-between flex-align-center">
              <span className="width-60">Your Address</span>
              <div className="flex flex-column">
                {showNewAddressFields ? (
                  <Fragment>
                    <HookFormAddressAutoComplete
                      label="Address"
                      name="address.addressLine"
                      id="address.addressLine"
                      control={control}
                      errors={errors}
                      onSelect={handlePlaceSelected}
                      handleChange={handleInputChange}
                      validation={requiredValidation}
                      required
                    />
                    {/* <HookFormInput
                    name="address.street"
                    control={control}
                    errors={errors}
                    id="address.street"
                    placeholder="Enter Street"
                    validation={requiredValidation}
                  /> */}
                    <HookFormInput
                      name="address.city"
                      control={control}
                      errors={errors}
                      id="address.city"
                      placeholder="Enter City"
                      validation={requiredValidation}
                      required
                    />
                    <HookFormInput
                      id="address.state"
                      name="address.state"
                      control={control}
                      errors={errors}
                      placeholder="Enter State"
                      validation={requiredValidation}
                      required
                    />

                    <HookFormInput
                      name="address.postalCode"
                      control={control}
                      errors={errors}
                      id="address.postalCode"
                      placeholder="Enter Postal code"
                      validation={requiredValidation}
                      required
                    />
                  </Fragment>
                ) : (
                  <HookFormRadio
                    name="address"
                    control={control}
                    errors={errors}
                    id="address"
                    baseClassName="width-100 margin-r-4"
                    optionsClassName="flex-column"
                    options={Addresses?.filter((address: any) => {
                      return (
                        address.state !== null &&
                        address.city !== null &&
                        address.address_line !== null &&
                        address.postal_code !== null
                      );
                    }).map((address: any) => ({
                      value: address,
                      label: `${address.city}, ${address.state}, ${address.postal_code}`,
                    }))}
                    validation={requiredValidation}
                    required
                    triggerOnChange={handleAddressChange}
                  />
                )}
                {!showNewAddressFields && (
                  <CustomButton
                    buttonText={"Add new Address"}
                    handleClick={handleAddNewAddress}
                    primaryButton
                    baseclassname={"cursor-pointer"}
                    textSmall
                    circular
                    // disabled={selectedAddress}
                  />
                )}
              </div>
            </div>
          )}

          <div className="flex flex-justify-end padding-r-3 border-top border-grey-shade-1 padding-t-2">
            <CustomButton
              buttonText="Cancel"
              secondaryButton
              handleClick={handleCancelClick}
              baseclassname={"cursor-pointer margin-r-4"}
              textSmall
              circular
            />

            {/* {showNewAddressFields && ( */}
            <CustomButton
              type="submit"
              buttonText={"Apply"}
              primaryButton
              baseclassname={"cursor-pointer"}
              textSmall
              circular
            />
            {/* )} */}
          </div>
        </form>
      </Modal.Content>
    </Modal>
  );
}

export default ApplyCampaignModal;
