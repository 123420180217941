export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 21 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7653 5.74037e-05C13.7326 0.000996836 13.7001 0.00425985 13.6679 0.00981919H1.96794C0.88881 0.00981919 0 0.856322 0 1.88408V23.1257C0 24.1535 0.88881 25 1.96794 25H19.0234C20.1025 25 20.9913 24.1535 20.9913 23.1257V6.98218C21.0105 6.87102 20.9977 6.757 20.9544 6.65217C20.9111 6.54735 20.8389 6.45563 20.7453 6.38671C20.7428 6.38506 20.7402 6.38344 20.7376 6.38183L14.2983 0.248983C14.2363 0.17051 14.1557 0.107073 14.0631 0.0638227C13.9704 0.0205722 13.8684 -0.00127401 13.7653 5.74037e-05ZM1.96794 1.25933H13.1196V6.88212C13.1196 7.04781 13.1887 7.20671 13.3117 7.32387C13.4347 7.44103 13.6016 7.50686 13.7756 7.50687H19.6794V23.1257C19.6794 23.4774 19.3927 23.7505 19.0234 23.7505H1.96794C1.59866 23.7505 1.31196 23.4774 1.31196 23.1257V1.88408C1.31196 1.53239 1.59866 1.25933 1.96794 1.25933ZM14.4315 2.14277L18.7518 6.25736H14.4315V2.14277Z"
        fill="currentColor"
      />
    </svg>
  );
};
