import { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "semantic-ui-react";
import {
  requestGenreOptions,
  requestInstaMatrixDetails,
  requestSyncInstaMedia,
  requestUserProfile,
} from "store/rext";
import {
  getGenreOptionsState,
  getInvitedUserState,
  getSyncedInstaMatrixState,
} from "store/selectors";
import ExpandCollapse from "components/ExpandCollapse";
import Loading from "components/Loading";
import ProfileHeaderComponent from "components/ProfileHeaderComponent";
import useInstaHandleHook from "HOC/useInstaHandle";
import { formatNumber } from "utils/utils";
import CustomButton from "components/CustomButton";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import {
  InstagramView,
  EngagementView,
  FollowersByAge,
  FollowersByLocation,
} from "containers/DetailedProfile/Views";
import { IPROFILEVIEWS } from "utils/Enums";
import "./style.scss";

interface Props {
  onClose: () => void;
  onSave: (data: any) => void;
  title?: string;
  toggle?: any;
  instaHandle: string;
  userId: string;
}
function ProfileModal(props: Props) {
  const dispatch = useDispatch();
  const { onClose, onSave, instaHandle, userId } = props;
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const { data: userData, fetching } = useSelector(getInvitedUserState);
  const { instaDetails, created_via, is_facebook_authenticated } =
    userData || {};
  const { data, fetching: isLoading } = useSelector(getSyncedInstaMatrixState);
  const { data: genreOptions } = useSelector(getGenreOptionsState);


  const {
    followers_count,
    follows_count,
    media_count,
    media,
    profile_picture_url,
    avgComments,
    avgLikes,
    name,
    biography,
    reach,
  } = data || {};
  
  const handleToggle = useCallback((index: number) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  }, []);

  const renderView = useCallback(
    (view: IPROFILEVIEWS, index: number) => {
      const isLastItem = index === Object.values(IPROFILEVIEWS).length - 1;
      const isActive = index === activeIndex;

      if (
        !(created_via === "facebook" || is_facebook_authenticated) &&
        view !== IPROFILEVIEWS.Content
      ) {
        return null;
      }

      return (
        <ExpandCollapse
          title={view}
          isActive={isActive}
          onToggle={() => handleToggle(index)}
          isLastItem={isLastItem}
        >
          {(() => {
            switch (view) {
              case IPROFILEVIEWS.Content:
                return (
                  <InstagramView
                    profilePictureUrl={profile_picture_url}
                    media={media}
                    // followers={
                    //   instaDetails?.followers_count
                    //     ? formatNumber(instaDetails?.followers_count)
                    //     : "-"
                    // }
                    followers={
                      followers_count ? formatNumber(followers_count) : "-"
                    }
                    avgLikes={avgLikes ? formatNumber(avgLikes) : "-"}
                    avgComments={avgComments ? formatNumber(avgComments) : "-"}
                    // avgLikes={
                    //   instaDetails?.avglikes
                    //     ? formatNumber(instaDetails?.avglikes)
                    //     : "-"
                    // }
                    // avgComments={
                    //   instaDetails?.avgcomments
                    //     ? formatNumber(instaDetails?.avgcomments)
                    //     : "-"
                    // }
                    avgReelViews={formatNumber(instaDetails?.avgreelviews) || "-"}
                    engageRatio={instaDetails?.engagementratio || "-"}
                    impressions={formatNumber(instaDetails?.impressions) || "-"}
                    instaHandle={instaHandle}
                    isLoadingPosts={isLoading || false}
                    baseClassName=""
                    numberOfPosts={instaDetails?.totalposts || "-"}
                    hobScore={instaDetails?.hobscore || "-"}
                    showMetrices={
                      created_via === "facebook" || is_facebook_authenticated
                    }
                  />
                );
              case IPROFILEVIEWS.FollowersByAge:
                return <FollowersByAge instaHandle={instaHandle} />;
              case IPROFILEVIEWS.Engagement:
                return <EngagementView instaHandle={instaHandle} />;
              case IPROFILEVIEWS.FollowersByLocation:
                return <FollowersByLocation instaHandle={instaHandle} />;
              default:
                return null;
            }
          })()}
        </ExpandCollapse>
      );
    },
    [activeIndex, data, userData, instaHandle, isLoading]
  );

  useEffect(() => {
    dispatch(
      requestSyncInstaMedia(
        {},
        {
          instaHandle: instaHandle,
        }
      )
    );
    dispatch(
      requestInstaMatrixDetails({ instaHandle }, { instaHandle: instaHandle })
    );
    dispatch(requestUserProfile({ id: userId }));
    dispatch(requestGenreOptions());
  }, []);

  return (
    <Modal
      size="large"
      dimmer
      open
      onClose={onClose}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      className="profile-modal"
    >
      <Modal.Content className="flex flex-column flex-align-center width-100 flex-justify-center">
        <CustomButton
          handleClick={onClose}
          greyButton
          transparent
          iconPosition={ICON_POSITION.LEFT}
          iconProps={{
            svgType: SVGType.CUSTOM,
            name: CUSTOM_SVG_ICON.Cross,
            size: "huge",
          }}
          noOutline
          baseclassname={"profile-modal-toggle-icon padding-r-2"}
        />
        {!isLoading && !fetching && !data && (
          <div className="text-5 text-bold flex flex-align-center flex-justify-center width-100">
            Unable to fetch data. We are looking into it. Kindly check after
            some time
          </div>
        )}
        {userData && data && !isLoading && !fetching && (
          <Fragment>
            <ProfileHeaderComponent
              posts={media_count ? formatNumber(media_count) : "-"}
              profilePictureUrl={profile_picture_url}
              profileName={userData.name || "-"}
              followers={followers_count ? formatNumber(followers_count) : "-"}
              following={follows_count ? formatNumber(follows_count) : "-"}
              accountReach={formatNumber(reach) || "-"}
              city={userData.city}
              state={userData.state}
              handle={instaHandle || "-"}
              genreOptions={genreOptions}
              genres={userData.gener}
              bio={biography}
              // isLoading={isLoading && fetching}
            />

            <div className="width-97 background-secondary-color margin-t-5 padding-3 social-media-view">
              {Object.values(IPROFILEVIEWS).map(renderView)}
            </div>
          </Fragment>
        )}
        {(isLoading || fetching) && <Loading isGlobal />}
      </Modal.Content>
    </Modal>
  );
}

export default ProfileModal;
