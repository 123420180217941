import React from "react";
import classNames from "classnames";
import { Controller } from "react-hook-form";
import FileController from "./FileController";

interface HookFormFileUploaderProps {
  control: any;
  errors: any;
  name: string;
  label?: string;
  baseClassName?: string;
  labelClassName?: string;
  validation?: any;
  defaultValue?: any;
  acceptFileType?: string;
  maxSize?: any;
  clearErrors?:any;
  setError?:any;
  required?: boolean;
  placeholder?: string;
}

export function HookFormFileUploader({
  control,
  errors,
  name,
  label,
  baseClassName,
  labelClassName,
  validation,
  defaultValue,
  acceptFileType,
  required, 
  placeholder
}: HookFormFileUploaderProps) {

  return (
    <div
      className={classNames([
        "flex flex-column position-relative",
        baseClassName,
      ])}
    >
      {label && (
        <div className={classNames(["input-label", labelClassName])}>
          {label}
          { required && <span className={classNames(["position-absolute input-asterik text-danger-color "])}>&#8727;</span>}
        </div>
      )}
      {control && (
        <Controller
          control={control}
          name={name}
          rules={validation || {}}
          defaultValue={defaultValue}
          render={({ field }) => (
            <FileController
              field={field}
              id={name}
              accept={acceptFileType as string}
              defaultValue={defaultValue}
              placeholder={placeholder}
            />
          )}
        />
      )}
       {errors[name] && (
        <div className="text-3 text-danger-color padding-b-1 padding-l-1">
          {errors[name].message}
        </div>
      )}
    </div>
  );
};

