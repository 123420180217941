import { Fragment, useEffect, useState } from "react";
import useClickOutside from "HOC/useClickOutside";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
  requestCreateFavouriteCategory,
  requestFavouritesList,
  requestMarkFavourite,
} from "store/rext";
import { getFavouriteListState, getFavouriteState } from "store/selectors";
import { CustomInput } from "components/CustomInputs";
import CustomButton from "components/CustomButton";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import Loading from "components/Loading";
import UsePrevious from "HOC/UsePrevious";
import { toast } from "react-toastify";
import { FAVROITE_ACTION_TYPE } from "utils/Enums";
import "./style.scss";

interface IFavourites {
  openOverlay: any;
  onCloseOverlay: any;
  influencerId: string[];
}

function FavouritesOverlay({ openOverlay, onCloseOverlay, influencerId }: IFavourites) {
  const dispatch = useDispatch();
  const outsideView = useClickOutside(onCloseOverlay);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [newCategory, setNewCategory] = useState<string>("");
  const { data, fetching , message , error } = useSelector(getFavouriteListState);
  const { records, paginationInfo } = data || {};
  const {
    data: favouriteData,
    error : favoritesError,
    fetching: favouriteFetching,
    message : favoritesMessage,
  } = useSelector(getFavouriteState);
  const wasFetching = UsePrevious(favouriteFetching);
  const wasListFetching = UsePrevious(fetching);


  const handleCategorySelect = (record: any) => {
    const isSelected = selectedCategories.includes(record.id);
    if (isSelected) {
      setSelectedCategories(selectedCategories.filter(id => id !== record.id));
    } else {
      setSelectedCategories([...selectedCategories, record.id]);
    }
  };

  const handleCategoryNameChange = (name: string, value: any) => {
    setNewCategory(value);
    dispatch(requestFavouritesList({ page: 1, pageSize: 10, search: value }));
  };

  const handleCreateFavourite = () => {
    if (newCategory.length > 0) {
      dispatch(
        requestCreateFavouriteCategory({
          category_name: newCategory,
        })
      );
    }
  };

  const handleMarkFavourite = () => {
    if (selectedCategories.length > 0 && influencerId) {
      dispatch(
        requestMarkFavourite({
          influencer_ids: influencerId,
          category_ids: selectedCategories,
          action_type: FAVROITE_ACTION_TYPE.MARK_FAVROITE,
        })
      );
    }
  };


  useEffect(() => {
    if (wasFetching && !favouriteFetching && !favoritesError) {
      toast.success(favoritesMessage);
      // onCloseOverlay();
    } else if (favoritesError) {
      toast.error(favoritesMessage);
    }
  }, [favouriteFetching, favoritesError, favoritesMessage]);

  useEffect(() => {
    dispatch(
      requestFavouritesList({
        page: 1,
        pageSize: 10,
      })
    );
  }, [favoritesMessage]);

  if (!openOverlay) {
    return null;
  }

  return (
    <Fragment>
      {openOverlay && <div className="fav-overlay-background"></div>}
      <div
        ref={outsideView}
        className={classNames(
          "position-absolute fav-overlay-container height-100 padding-4 flex flex-column"
        )}
      >
        <div className="width-100 flex flex-justify-end">
         <CustomButton
            handleClick={onCloseOverlay}
            greyButton
            transparent
            iconPosition={ICON_POSITION.LEFT}
            iconProps={{
              svgType: SVGType.CUSTOM,
              name: CUSTOM_SVG_ICON.Cross,
              size: "small",
            }}
            noOutline
            textSmall
            // baseclassname={"add-influencer-modal-toggle-icon"}
          />
          </div>
        <div className="text-bold text-5 margin-b-2 text-align-center padding-1">
          Add To Favourites
        </div>
        <span className="text-grey-shade-4 text-3 line-h-4 text-align-center">
          Create a new favorites list or add the influencer to the existing
          list.
        </span>

        <div className="flex width-100 margin-t-2 flex-justify-center flex-align-center border-bottom border-grey-shade-1">
          <CustomInput
            name="category_name"
            id="category_name"
            placeholder="Enter Name"
            label="Create a new Favorite list"
            baseClassName="width-80 margin-2"
            onChange={handleCategoryNameChange}
            validate
          />
          <div className="flex flex-align-center padding-t-6 padding-l-2 width-20">
            <CustomButton
              type="button"
              transparent
              greyButton
              iconProps={{
                name: CUSTOM_SVG_ICON.Plus,
                svgType: SVGType.CUSTOM,
                size: "small",
                baseclassname: "text-default-color",
              }}
              outline
              circular
              iconPosition={ICON_POSITION.LEFT}
              handleClick={handleCreateFavourite}
              disabled={!newCategory}
            />
          </div>
        </div>
        <div className="flex flex-column margin-t-3">
          <div className="flex flex-justify-end">
            <CustomButton
              type="button"
              primaryButton
              textSmall
              buttonText="Mark as Favourite"
              circular
              handleClick={handleMarkFavourite}
              disabled={!influencerId.length || !selectedCategories.length}
            />
          </div>
          <div className="width-100 flex flex-column margin-t-2 overflow-auto height-100">
            {!fetching &&
              records?.length > 0 &&
              records?.map((record: any) => (
                <div key={record.id} className="checkbox-container flex width-100 padding-2">
                  <input
                    type="checkbox"
                    checked={selectedCategories.includes(record.id)}
                    onChange={() => handleCategorySelect(record)}
                    className="height-50 margin-r-2"
                  />
                  <span className="flex flex-column width-70">
                    <span className="text-4">{record.name}</span>
                    <span className="text-3 text-grey-shade-2 padding-t-1">{`${record.influencers_count} Influencers`}</span>
                  </span>
                </div>
              ))}
          </div>
            {(fetching || favouriteFetching) && <Loading inverted centered />}
        </div>
      </div>
    </Fragment>
  );
}

export default FavouritesOverlay;
