import ExpandCollapse from "components/ExpandCollapse";
import HeaderLayout from "components/HeaderLayout";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { URLRoutes } from "URLRoutes";
import { handleTermsModal } from "utils/utils";

interface IHelpProps {
  title: string;
  content: React.ReactNode;
}

const helpData: Array<IHelpProps> = [
  {
    title: "Getting Started with BuzzPRIME",
    content: (
      <div className="flex-flex-column padding-4">
        <div className="text-bold padding-b-1"> What is BuzzPRIME?</div>
        <div className="padding-b-2">
          BuzzPRIME is an AI-powered influencer marketing platform that helps
          brands discover influencers, manage campaigns, and track performance
          in real-time.
        </div>
        <div className="text-bold padding-b-1">Creating an Account</div>
        <div className="padding-b-2">
          <li>
            <strong>For Brands:</strong> Go to the BuzzPRIME homepage and click
            <span className="text-bold margin-l-1">'Sign Up'</span>. Choose 'Sign up as a
            Brand'. Fill in the required details (Name, Email, Company Name,
            etc.). Confirm your email and start exploring influencers and
            managing campaigns.
          </li>
          <li>
            <strong>For Influencers:</strong> Click on
            <span className="text-bold margin-l-1">'Sign Up'</span> on the homepage. Choose
            'Sign up as an Influencer'. Provide your details including links to
            your social media profiles. Complete the sign-up process and start
            connecting with brands.
          </li>
        </div>
      </div>
    ),
  },
  {
    title: "How to Use BuzzPRIME",
    content: (
      <div className="flex-flex-column padding-4">
        <li>
          <strong>Searching for Influencers (For Brands) -</strong> Use the
          influencer discovery feature to search for influencers by categories
          like niche, audience demographics, engagement rate, and location.
        </li>
        <li>
          <strong>Managing Campaigns -</strong> Track campaign progress, monitor
          deliverables, and gather performance data using BuzzPRIME’s real-time
          tracking tool.
        </li>
        <li>
          <strong>Accepting Campaigns (For Influencers) -</strong> Influencers
          can view incoming collaboration requests from brands and accept or
          decline them based on availability or interest.
        </li>
      </div>
    ),
  },
  {
    title: "Frequently Asked Questions (FAQs)",
    content: (
      <div className="flex-flex-column padding-4">
        For a comprehensive list of frequently asked questions, please refer to
        our <Link to={URLRoutes.clients.faq}>FAQ page</Link>.
      </div>
    ),
  },
  {
    title: "Troubleshooting",
    content: (
      <div className="flex-flex-column padding-4">
        <li>
          <strong>Forgot Password -</strong> If you’ve forgotten your password,
          click 'Forgot Password' on the login page and follow the instructions
          to reset it.
        </li>
        <li>
          <strong>Issues with Logging In -</strong> Ensure you're using the
          correct email and password. Try clearing your browser cache or
          switching to a different browser.
        </li>
        <li>
          <strong>Campaign Tracking Issues -</strong> Ensure that the correct
          social media accounts are linked and that influencers have fulfilled
          their obligations.
        </li>
        <li>
          <strong>Unable to Upload Content (For Influencers) -</strong> Check
          that the content format meets the platform's size and quality
          guidelines.
        </li>
      </div>
    ),
  },
  {
    title: "Contacting Support",
    content: (
      <div className="flex-flex-column padding-4">
        <li>
          <strong>Email Support -</strong> For general queries, troubleshooting,
          or account-related issues, contact us at{" "}
          <a href="mailto:support@highonbuzz.com">support@highonbuzz.com</a>.
        </li>
        <li>
          <strong>Live Chat (Coming Soon) -</strong> We are working on adding a
          live chat feature for real-time assistance.
        </li>
      </div>
    ),
  },
  {
    title: "Reporting Issues & Bugs",
    content: (
      <div className="flex-flex-column padding-4">
        <li>
          <strong>Reporting a Technical Issue -</strong> Take a screenshot or
          note of the problem and email it to{" "}
          <a href="mailto:support@highonbuzz.com" className="margin-r-1">support@highonbuzz.com</a>
          with your account details, device, browser, and issue description.
        </li>
        <li>
          <strong>Reporting a Problem with a Campaign -</strong> For
          campaign-related issues like delayed content or performance metrics
          not updating, contact your campaign manager or email
          <a href="mailto:support@highonbuzz.com" className="margin-l-1">support@highonbuzz.com</a>.
        </li>
      </div>
    ),
  },
  {
    title: "Terms of Service & Privacy Policy",
    content: (
      <div className="flex-flex-column padding-4">
        For more detailed information about your rights and obligations while
        using our platform, please refer to the
        <span className="text-underline text-blue-shade1 cursor-pointer margin-l-1" onClick={handleTermsModal}>
          Terms of Service & Privacy Policy .
        </span>
      </div>
    ),
  },
  {
    title: "Additional Resources",
    content: (
      <div className="flex-flex-column padding-4">
        <li>
          <strong>Tutorials & Guides -</strong> Check out our series of
          tutorials and step-by-step guides to get the most out of BuzzPRIME.
        </li>
      </div>
    ),
  },
  {
    title: "Feedback & Suggestions",
    content: (
      <div className="flex-flex-column padding-4">
        <div>
          At <span className="text-bold">Highonbuzz</span>, we’re constantly
          working to improve BuzzPRIME and your experience on the platform. If
          you have feedback, suggestions, or feature requests, we’d love to hear
          from you!
        </div>
        <div>
          Please fill out our Feedback Form or email us directly at{" "}
          <a href="mailto:support@highonbuzz.com">support@highonbuzz.com</a>
        </div>
      </div>
    ),
  },
  {
    title: "Glossary of Terms",
    content: (
      <div className="flex-flex-column padding-4">
        Here are some common terms you’ll come across on BuzzPRIME:
        <li>
          <strong>Influencer Discovery:</strong> The process of finding
          influencers based on their niche, audience, and engagement.
        </li>
        <li>
          <strong>Campaign Management:</strong> Managing all aspects of an
          influencer marketing campaign, from briefing influencers to tracking
          their performance.
        </li>
        <li>
          <strong>Performance Tracking:</strong> Real-time data monitoring to
          evaluate the success of an influencer marketing campaign.
        </li>
      </div>
    ),
  },
  {
    title: "Future Features (Coming Soon)",
    content: (
      <div className="flex-flex-column padding-4">
        At BuzzPRIME, we’re continuously working to enhance your experience.
        Here are some of the features coming soon:
        <li>
          <strong> Live Chat Support: </strong> Get real-time help from our
          support team.
        </li>
        <li>
          <strong> Advanced Analytics:</strong> In-depth reports and insights
          into influencer performance.
        </li>
        <li>
          <strong> Collaboration Marketplace:</strong> A space where brands can
          post collaboration opportunities, and influencers can apply directly.
        </li>
      </div>
    ),
  },
];

function HelpContainer() {
  const [showIndex, setShowIndex] = useState<number>(-1);

  const renderHelpTerms = ({ content, title }: any, index: number) => {
    return (
      <ExpandCollapse
        key={`faq-${index}`}
        title={title}
        children={content}
        isActive={index === showIndex ? true : false}
        onToggle={() => setShowIndex(index === showIndex ? -1 : index)}
        isLastItem={index === helpData.length - 1}
      />
    );
  };

  return (
    <HeaderLayout
      breadcrumbs={[
        {
          text: "Help & Support",
          url: URLRoutes.clients.help,
        },
      ]}
      title="Help & Support"
      contentClassName="flex flex-column width-100 overflow-auto padding-4"
    >
      <div className="text-5 text-orange-shade1 text-italic padding-l-2">
        Welcome to the Help & Support page of BuzzPRIME, your go-to place for
        assistance with using our Influencer Marketing Platform.
      </div>
      {helpData.map(renderHelpTerms)}
      <div
        // style={{ position: "absolute", bottom: 0 }}
        className="flex flex-column width-100"
      >
        <span className="text-bold">Need More Help?</span>
        If you didn’t find the answer you’re looking for here, feel free to
        contact us. Our team is ready to assist you with any questions or
        concerns.
        <span className="text-bold">
          Email:{" "}
          <a href="mailto:support@highonbuzz.com">support@highonbuzz.com</a>
        </span>
      </div>
    </HeaderLayout>
  );
}

export default HelpContainer;
