import { useSelector } from "react-redux";
import { getAuthState } from "store/selectors";
import { ROLES } from "utils/Enums";
import { Feature } from "utils/Features/Features";

interface UserState {
  isAuthenticated: boolean;
  // user: {
  //   role: ROLES;
  //   is_change_password?: boolean;
  //   id: string;
  //   email: string;
  //   instaDetails: any;
  //   addresses: Array<any>;
  // } | null;
  user: any;
  features: Feature;
}

export const useUserDetail = () => {
  const { isAuthenticated, user, features } = useSelector(
    getAuthState
  ) as UserState;
  
  return {
    isAuthenticated,
    instaDetails: user?.instaDetails[0],
    userEmail: user?.email,
    role: user?.role,
    isChangePassword: user?.is_change_password,
    userId: user?.id,
    Addresses: user?.addresses,
    user,
    instaHandle: user?.instahandle || "",
    userName: user?.name,
    features,
  };
};
