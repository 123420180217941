import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { requestLogin, requestLoginWithFacebook, requestLoginWithGoogle } from "store/actions";
import { HookFormCheckbox, HookFormInput } from "components/FormInputs";
import CustomButton from "components/CustomButton";
import { hookformRequired } from "utils/FormValidations";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { URLRoutes } from "URLRoutes";
import { getAuthState } from "store/selectors";
import AuthLayout from "components/AuthLayout";
import { handleFacebookConfirmation, requestNotificationPermission } from "utils/utils";
import { toast } from "react-toastify";
import { auth, facebookProvider, googleProvider } from "firebaseConfig";
import { AES, enc } from "crypto-js";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import UsePrevious from "HOC/UsePrevious";
import classNames from "classnames";
import { updateFCMToken } from "store/rext/action";
import { ROLES } from "utils/Enums";
import { FacebookAuthProvider, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import "./style.scss";

const requiredValidation = {
  ...hookformRequired("Required"),
};

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [secretKey, setSecretKey] = useState("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const { isAuthenticated, user, message, fetching, error } = useSelector(getAuthState);
  const wasFetching = UsePrevious(fetching);
  const {
    handleSubmit,
    formState: { errors , isSubmitSuccessful },
    control,
    setValue,
    getValues,
  } = useForm({});
  const { email, password, rememberMe } = getValues();

  const handleEyeIconClick = () => setShowPassword(!showPassword);

  const handleOnSubmit = async (data: any) => dispatch(requestLogin(data));

  const handlePassword = () => navigate(URLRoutes.clients.forgotPassword);

  const handleGoogleLogin = () => {
    setShowToast(false)
    googleProvider.setCustomParameters({
      prompt: 'consent'
    });
    signInWithPopup(auth, googleProvider)
    .then(async (result) => {
        try {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential?.idToken;
          dispatch(requestLoginWithGoogle(token));
          setShowToast(true)
        } catch (error: any) {
          toast.error(error.message || "Login failed");
        }
      })
      .catch((error) => {
        if(error.code === "auth/account-exists-with-different-credential") {
          toast.error("User already exist with this google account email. Kindly try login using email and password",  { autoClose: 20000,});
        } else if (error.code === "auth/cancelled-popup-request") {
          console.log("Popup closed by the user");
        }
      });
  };


    const handleFacebookLogin = () => {
      setShowToast(false)
      facebookProvider.setCustomParameters({ config_id: '1373830100684881', response_type: 'code' , display: "popup"});
      signInWithPopup(auth, facebookProvider)
      .then(async (result) => {
        try {
        const credential = FacebookAuthProvider.credentialFromResult(result) as any;
        const token = credential.accessToken;
        const user = result.user;
        
          dispatch(requestLoginWithFacebook(token));
          setShowToast(true)
          // toast.info(message);
        } catch (error: any) {
          toast.error(error.message || "User already exist with this email");
        }
  
      }).catch ((error) => {
        if(error.code === "auth/account-exists-with-different-credential") {
          toast.error("User already exist with this facebook account email. Kindly try login using email and password", { autoClose: 20000,});
        } else if (error.code === "auth/cancelled-popup-request") {
          console.log("Popup closed by the user");
        } else if(error.code === "auth/invalid-credential"){
          toast.error("Facebook application request limit reached. Kindly try to login after sometime.", { autoClose: 20000,});
        }
        console.error('Error during Facebook login:', error);
      })
    };

  const handleRememberMe = () => {
    if (rememberMe) {
      const encryptedEmail = AES.encrypt(email, secretKey).toString();
      const encryptedPassword = AES.encrypt(password, secretKey).toString();
      const encryptedData = AES.encrypt(
        JSON.stringify({ encryptedEmail, encryptedPassword }),
        secretKey
      ).toString();

      localStorage.setItem(
        "hob_rememberMe_ls",
        JSON.stringify({ data: encryptedData, rememberMe })
      );
    } else {
      localStorage.removeItem("hob_rememberMe_ls");
    }
  };

  const updateNotificationToken = () => {
    requestNotificationPermission((token: string) => {
      dispatch(updateFCMToken({ token }));
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      // updateNotificationToken();
      handleRememberMe();

      switch (user?.role) {
        case ROLES.HOB:
          navigate(URLRoutes.clients.users.replace(":userType", ROLES.INFLUENCER));
          break;
        case ROLES.INFLUENCER:
          navigate(URLRoutes.clients.instaDashboard);
          break;
        case ROLES.BRAND:
          if (user.is_change_password) {
            navigate(URLRoutes.clients.resetPassword.replace(":token", user.id));
          } else {
            navigate(URLRoutes.clients.campaignList);
          }
          break;
        default:
          navigate(URLRoutes.clients.login);
      }
    } else {
      localStorage.removeItem("token");
      navigate(URLRoutes.clients.login);
    }
  }, [isAuthenticated, user, navigate, message, secretKey]);

  useEffect(() => {
    if (isSubmitSuccessful && !fetching && !error) {
      toast.success(message);
    } else if (isSubmitSuccessful && error) {
      toast.error(message, {autoClose: 20000 ,  style: {
        position: "absolute",
        maxWidth: '500px', 
        right: 0,       
        margin: '0',   
      }});
    }
  }, [fetching, error, message]);


  useEffect(() => {
    if (showToast && !fetching && !error) {
      toast.success(message);
    } else if (showToast && error) {
      toast.error(message, {autoClose: 20000 ,  style: {
        position: "absolute",
        maxWidth: '500px', 
        right: 0,       
        margin: '0',   
      }});
    }
  }, [fetching, error, message]);

  useEffect(() => {
    const savedData = localStorage.getItem("hob_rememberMe_ls");
    if (savedData && secretKey) {
      try {
        const { data: encryptedData, rememberMe } = JSON.parse(savedData);
        const decryptedData = AES.decrypt(encryptedData, secretKey).toString(enc.Utf8);
        const { encryptedEmail, encryptedPassword } = JSON.parse(decryptedData);

        const email = AES.decrypt(encryptedEmail, secretKey).toString(enc.Utf8);
        const password = AES.decrypt(encryptedPassword, secretKey).toString(enc.Utf8);

        setValue("email", email);
        setValue("password", password);
        setValue("rememberMe", rememberMe);
      } catch (error) {
        console.error("Failed to parse or decrypt localStorage data:", error);
        localStorage.removeItem("hob_rememberMe_ls");
      }
    }
  }, [secretKey, setValue]);

  useEffect(() => {
    const generateSecretKey = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      setSecretKey(result.visitorId);
    };
    generateSecretKey();
    // toast.dismiss();
  }, []);

  return (
    <AuthLayout
      image="/images/womanSunglasses.png"
      title="Discover the world’s top Influencers."
      titlePosition="top"
      contentClassName="flex-justify-center flex-align-center"
      bannerClassName="background-linear1"
      imagePosition="right"
      isLoginPage
    >
      <Fragment>
        <div className="width-45">
          <img src="images/logo.png" style={{ width: "100%", height: "100%" }} alt="Logo" />
        </div>
        <form onSubmit={handleSubmit(handleOnSubmit)} className="width-55 padding-t-10 padding-b-10">
          <HookFormInput
            control={control}
            errors={errors}
            label="Email"
            id="email"
            name="email"
            iconProps={{
              name: CUSTOM_SVG_ICON.Mail,
              svgType: SVGType.CUSTOM,
              size: "small",
              baseclassname: "text-grey-shade2",
            }}
            placeholder="Enter your email here..."
            validation={requiredValidation}
            required
          />
          <div className="position-relative">
            <SvgIcon
              name={showPassword ? CUSTOM_SVG_ICON.EyeSVG : CUSTOM_SVG_ICON.HideEye}
              svgType={SVGType.CUSTOM}
              size={"small"}
              baseclassname={classNames(["login-password-icon" ,
              //   {
              //   "login-password-icon": !errors.password?.message,
              //   "password-required-login": errors.password?.type === "required",
              //   "password-stable-login": errors.password?.type === "pattern",
              // }
            ])}
              onClick={handleEyeIconClick}
            />
            <HookFormInput
              control={control}
              errors={errors}
              type={showPassword ? "text" : "password"}
              label="Password"
              id="password"
              name="password"
              placeholder="Enter your Password here..."
              validation={requiredValidation}
              required
              iconProps={{
                name: CUSTOM_SVG_ICON.Password,
                svgType: SVGType.CUSTOM,
                size: "small",
                baseclassname: "text-grey-shade2",
              }}
            />
          </div>
          <div className="flex flex-row flex-justify-between margin-b-4">
            <HookFormCheckbox
              control={control}
              errors={errors}
              label="Remember Me"
              id="rememberMe"
              name="rememberMe"
            />
            <div className="text-3 cursor-pointer" onClick={handlePassword}>
              Forgot Password?
            </div>
          </div>
          <CustomButton
            buttonText="Login"
            type="submit"
            primaryButton
            round
            baseclassname={"width-100 margin-b-4"}
            loading={fetching}
          />
        </form>
        <div className="text-4 text-bold margin-b-3">Or Sign-in with</div>
        <div className="flex flex-justify-between flex-align-center width-18 margin-b-4">
          <SvgIcon
            svgType={SVGType.CUSTOM}
            name={CUSTOM_SVG_ICON.Google}
            size={"large"}
            baseclassname={"cursor-pointer"}
            onClick={handleGoogleLogin}
          />
           <SvgIcon
            svgType={SVGType.CUSTOM}
            name={CUSTOM_SVG_ICON.facebook}
            size={"large"}
            baseclassname={"cursor-pointer"}
            onClick={handleFacebookLogin}
          />
        </div>
        <div className="text-3">
          Don’t have an account?
          <Link to={URLRoutes.clients.signUp} className="text-default-color margin-l-1">
            Sign up
          </Link>
        </div>
      </Fragment>
    </AuthLayout>
  );
}

export default Login;
