// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { getFirestore } from "firebase/firestore";
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCrCeVk2gwmgiUSf7RCs0JaP3jHGB02288",
  authDomain: "highonbuzz-6b228.firebaseapp.com",
  projectId: "highonbuzz-6b228",
  storageBucket: "highonbuzz-6b228.appspot.com",
  messagingSenderId: "437564469991",
  appId: "1:437564469991:web:700203f2eabb46f93aa842",
  measurementId: "G-JKNL8GFHF2",
  databaseURL: "https://highonbuzz-6b228-default-rtdb.firebaseio.com",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth();
const facebookProvider = new FacebookAuthProvider();
const googleProvider = new GoogleAuthProvider();
const db = getFirestore(app);
const messaging = getMessaging(app);

export const requestForToken = async (cb: (token: string) => void) => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_PUSH_CERT,
    });
    if (currentToken) {
      console.log("current token for client: ", currentToken);
      cb(currentToken);
    } else {
      // Show permission request UI
      console.log(
        "No registration token available. Request permission to generate one."
      );
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }
};

export { auth, facebookProvider, googleProvider, db, messaging, app };
