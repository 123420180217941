import { Fragment } from "react";
import CustomButton from "components/CustomButton";
import {
  HookFormAddressAutoComplete,
  HookFormDatePicker,
  HookFormInput,
  HookFormSelect,
} from "components/FormInputs";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { hookformRequired } from "utils/FormValidations";
import { GenderOptions, NewGenderOptions, transformOptionsToKeyValue } from "utils/utils";

interface IFormProps {
  handleSubmitForm: (data: any) => void;
  control: any;
  errors: any;
  genreOptions: any[];
  handlePlaceSelected: any;
  handlePermanentLocation: any;
  showNewAddressFields: boolean;
  renderShipmentAddresses: any;
  handleShipmentSelect: any;
  handleInputChange: any;
  handleAddNewAddress: any;
  handleCancelClick: any;
  isSubmitting: boolean;
}

const requiredValidation = {
  ...hookformRequired("Required"),
};

export function ProfileForm(props: IFormProps) {
  const {
    handleSubmitForm,
    control,
    errors,
    genreOptions,
    handlePermanentLocation,
    handlePlaceSelected,
    showNewAddressFields,
    renderShipmentAddresses,
    handleInputChange,
    handleShipmentSelect,
    handleAddNewAddress,
    handleCancelClick,
    isSubmitting,
  } = props;
  return (
    <form onSubmit={handleSubmitForm} className="width-75 padding-l-8">
      <div className="flex flex-justify-between width-100">
        <HookFormSelect
          control={control}
          errors={errors}
          id="gender"
          name="gender"
          label="Gender"
          baseClassName="width-45"
          options={NewGenderOptions}
          inputClassName="width-100"
          placeholder={"Select"}
        />
        <HookFormDatePicker
          control={control}
          errors={errors}
          id="dob"
          name="dob"
          label="Date of Birth"
          baseClassName="width-45"
          placeholder="Select Date"
          minDate={new Date(1900, 0, 1)}
          validation={requiredValidation}
          required
        />
      </div>
      <HookFormSelect
        // validation={requiredValidation}
        options={transformOptionsToKeyValue(genreOptions)}
        control={control}
        errors={errors}
        label="Genre"
        id="gener"
        name="gener"
        multiSelect
        placeholder="Select the genre(s)..."
        baseClassName="width-100 geners-dropdown"
      />

      <HookFormInput
        control={control}
        errors={errors}
        label="Reel"
        id="reelprice"
        name="reelprice"
        placeholder="Price per reel..."
        iconProps={{
          name: CUSTOM_SVG_ICON.Cost,
          svgType: SVGType.CUSTOM,
          size: "small",
          baseclassname: "text-default-color",
        }}
        baseClassName="width-100"
      />
      <div className="flex flex-justify-between width-100">
        <HookFormInput
          control={control}
          errors={errors}
          label="Static Post"
          id="staticpostprice"
          name="staticpostprice"
          placeholder="Price per static post..."
          iconProps={{
            name: CUSTOM_SVG_ICON.Cost,
            svgType: SVGType.CUSTOM,
            size: "small",
            baseclassname: "text-default-color",
          }}
          baseClassName="width-45"
        />
        <HookFormInput
          control={control}
          errors={errors}
          label="Carousel Post"
          id="carouselpostprice"
          name="carouselpostprice"
          placeholder="Price per carousel post..."
          iconProps={{
            name: CUSTOM_SVG_ICON.Cost,
            svgType: SVGType.CUSTOM,
            size: "small",
            baseclassname: "text-default-color",
          }}
          baseClassName="width-45"
        />
      </div>
      <div className="flex flex-justify-between width-100">
        <HookFormInput
          control={control}
          errors={errors}
          label="Story"
          id="storyprice"
          name="storyprice"
          placeholder="Price per story..."
          iconProps={{
            name: CUSTOM_SVG_ICON.Cost,
            svgType: SVGType.CUSTOM,
            size: "small",
            baseclassname: "text-default-color",
          }}
          baseClassName="width-45"
        />
        <HookFormInput
          control={control}
          errors={errors}
          label="Swipe-Up Story"
          id="swipeupstoryprice"
          name="swipeupstoryprice"
          placeholder="Price per story..."
          iconProps={{
            name: CUSTOM_SVG_ICON.Cost,
            svgType: SVGType.CUSTOM,
            size: "small",
            baseclassname: "text-default-color",
          }}
          baseClassName="width-45"
        />
      </div>

      <HookFormAddressAutoComplete
        name="location"
        id="location"
        onSelect={handlePlaceSelected}
        control={control}
        errors={errors}
        handleChange={handlePermanentLocation}
        label="Permanent Address"
        // watch={watch}
        placeholder="Enter Permanent Address"
      />

      <div className="flex flex-row flex-justify-between width-100">
        <HookFormInput
          control={control}
          errors={errors}
          id="city"
          name="city"
          placeholder="City"
          baseClassName="width-45"
        />

        <HookFormInput
          control={control}
          errors={errors}
          id="state"
          name="state"
          placeholder="State"
          baseClassName="width-45"
        />
      </div>

      <div className="width-100 padding-2 flex flex-column">
        <span className="text-3 text-bold line-h-4">Shipment Address</span>
        <span className="text-3 text-grey-shade-4">
          {renderShipmentAddresses()}
        </span>

        {showNewAddressFields && (
          <Fragment>
            <HookFormAddressAutoComplete
              name="address.addressLine"
              id="address.addressLine"
              onSelect={handleShipmentSelect}
              control={control}
              errors={errors}
              handleChange={handleInputChange}
              placeholder="Enter Shipment Address"
            />
            <div className="flex width-100 flex-justify-between">
              <HookFormInput
                name="address.state"
                control={control}
                errors={errors}
                id="state"
                placeholder="Shipment State"
                baseClassName="width-30"
              />
              <HookFormInput
                name="address.city"
                control={control}
                errors={errors}
                id="city"
                placeholder="Shipment City"
                baseClassName="width-30"
              />
              <HookFormInput
                name="address.postalCode"
                control={control}
                errors={errors}
                id="postalCode"
                baseClassName="width-30"
                placeholder="Shipment Postal Code"
              />
            </div>
          </Fragment>
        )}

        <CustomButton
          buttonText={"Add new Address"}
          handleClick={handleAddNewAddress}
          primaryButton
          baseclassname={"cursor-pointer"}
          textSmall
          circular
          disabled={showNewAddressFields}
        />
      </div>

      <div className="padding-t-4 padding-b-6">
        <CustomButton
          buttonText={"Cancel"}
          secondaryButton
          baseclassname={" cursor-pointer"}
          textSmall
          circular
          handleClick={handleCancelClick}
        />
        <CustomButton
          type="submit"
          buttonText={"Save Profile"}
          primaryButton
          baseclassname={"cursor-pointer"}
          textSmall
          circular
          disabled={isSubmitting}
        />
      </div>
    </form>
  );
}
