import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAuthState } from "store/selectors";
import { URLRoutes } from "URLRoutes";
import Loading from "components/Loading";

interface IProps {
  children: any;
  allowedFeatures: boolean;
  isFetching: boolean;
}

const ProtectedRoute = ({ children, allowedFeatures, isFetching }: IProps) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector(getAuthState);
  
  useEffect(() => {
    if (!isFetching && !isAuthenticated) {
      navigate(URLRoutes.clients.login);
    } 
  }, [allowedFeatures, isAuthenticated, isFetching, navigate]);

  if (isFetching) {
    return <Loading inverted />;
  } else if (allowedFeatures) {
    return children;
  } else if (!allowedFeatures) {
    return (
      <div className="flex flex-row flex-justify-center width-100 height-100">
        <img
          src="/images/notAllowed.jpg"
          style={{ objectFit: "contain", alignItems: "center" }}
        />
      </div>
    );
  }
};

export default ProtectedRoute;
