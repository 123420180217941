export default () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7754 25C10.7754 23.3762 11.0449 21.8191 11.5266 20.359L3.10079 13.925C1.4586 17.259 0.5336 21.016 0.5336 25C0.5336 28.9808 1.45782 32.7351 3.09727 36.0672L11.5184 29.6207C11.0414 28.1672 10.7754 26.616 10.7754 25Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5691 10.2273C29.0969 10.2273 32.2832 11.4773 34.7867 13.5227L42.0695 6.25C37.6316 2.38633 31.9418 0 25.5691 0C15.6754 0 7.17225 5.65781 3.10077 13.925L11.5262 20.359C13.4676 14.466 19.0015 10.2273 25.5691 10.2273Z"
        fill="#EA4335"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5691 39.7726C19.0019 39.7726 13.468 35.534 11.5266 29.641L3.10077 36.0738C7.17225 44.3422 15.6754 50 25.5691 50C31.6754 50 37.5055 47.8316 41.8808 43.7691L33.8832 37.5863C31.6265 39.0078 28.7848 39.7726 25.5691 39.7726Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.4664 25C49.4664 23.5227 49.2387 21.9317 48.8973 20.4547H25.5692V30.1137H38.9973C38.3258 33.4071 36.4984 35.9387 33.8832 37.5864L41.8809 43.7692C46.477 39.5035 49.4664 33.1489 49.4664 25Z"
        fill="#4285F4"
      />
    </svg>
  );
};
