export default () => {
 return( <svg
    width="100%"
    height="100%"
    viewBox="0 0 31 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.6366 6.61952H27.1149C28.9887 6.61952 30.519 8.14946 30.519 10.0233V16.9405C30.519 18.8144 28.9887 20.3447 27.1149 20.3447H25.814L23.6819 25.5303C23.4219 26.163 22.5302 26.1501 22.2754 25.5303L20.1434 20.3447H14.2866C12.4128 20.3447 10.8828 18.8144 10.8828 16.9405V13.7251H10.3757L8.24365 18.9111C7.98879 19.5306 7.09712 19.5439 6.83745 18.9111L4.70544 13.7251H3.40414C1.53031 13.7251 0 12.1952 0 10.3214V3.40377C0 1.53031 1.53031 0 3.40414 0H16.2324C18.1063 0 19.6366 1.52994 19.6366 3.40377V6.61952ZM3.37311 6.29337C2.36989 6.29337 2.36989 4.76786 3.37311 4.76786H16.2635C17.2667 4.76786 17.2667 6.29337 16.2635 6.29337H3.37311ZM12.4083 13.7251V16.9405C12.4083 17.9722 13.2553 18.8192 14.2866 18.8192L20.652 18.8214C20.9508 18.8217 21.2345 18.999 21.3553 19.2927L22.9787 23.242L24.578 19.3518C24.6756 19.043 24.9644 18.8192 25.3053 18.8192H27.1149C28.1465 18.8192 28.9932 17.9722 28.9932 16.9405V10.0233C28.9932 8.992 28.1462 8.14503 27.1149 8.14503H19.6366V10.3214C19.6366 12.1952 18.1063 13.7251 16.2324 13.7251H12.4083ZM16.2324 1.52551H3.40414C2.37285 1.52551 1.52588 2.37248 1.52588 3.40377V10.3214C1.52588 11.3527 2.37285 12.1996 3.40414 12.1996H5.21369C5.55462 12.1996 5.84347 12.4235 5.94136 12.7323L7.54037 16.6225L9.16413 12.6732C9.28454 12.3795 9.56822 12.2022 9.86705 12.2018L16.2324 12.1996C17.2641 12.1996 18.1107 11.3527 18.1107 10.3214V3.40377C18.1107 2.37248 17.2641 1.52551 16.2324 1.52551Z"
      fill="currentColor"
    />
  </svg>)
};
