export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 37 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.5598 25H6.81894C3.05648 25 0 21.9435 0 18.1811C0 15.1246 2.02658 12.4751 4.91694 11.6445C5.60631 9.77575 7.21761 8.42193 9.15282 8.05648C9.66777 3.5299 13.5216 0 18.1894 0C21.902 0 25.2492 2.30066 26.6196 5.69767C30.6146 5.93023 33.8206 9.12791 34.0864 13.1146C35.5482 14.4103 36.3787 16.2292 36.3787 18.1894C36.3787 21.9352 33.3223 25 29.5598 25ZM10.2326 10.2159C8.64618 10.2159 7.28405 11.2957 6.9186 12.8488L6.75249 13.5797L6.01329 13.7126C3.84552 14.103 2.27575 15.9801 2.27575 18.1728C2.27575 20.6811 4.31894 22.7243 6.82724 22.7243H29.5681C32.0764 22.7243 34.1196 20.6811 34.1196 18.1728C34.1196 16.7525 33.4552 15.4319 32.3007 14.5598L31.8522 14.2193V13.5631C31.8522 10.49 29.3023 7.9402 26.1628 7.9402L25 7.99834L24.7425 7.14286C23.8953 4.2691 21.2043 2.25914 18.206 2.25914C14.4435 2.25914 11.387 5.31561 11.387 9.07807V10.2159H10.2326Z"
        fill="currentColor"
      />
      <path
        d="M19.3265 10.706H17.0508V20.4485H19.3265V10.706Z"
        fill="currentColor"
      />
      <path
        d="M21.9345 15.573L18.1887 11.8271L14.4428 15.573L12.8398 13.9617L18.1887 8.61282L23.5375 13.9617L21.9345 15.573Z"
        fill="currentColor"
      />
    </svg>
  );
};
