import { Fragment, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import classNames from "classnames";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { Pagination, Popup } from "semantic-ui-react";
import { ROLES } from "utils/Enums";
import { useUserDetail } from "HOC/useUserDetail";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { modalOperation } from "store/actions";
import { ModalsType } from "containers/ModalManager/ModalManager";
import {
  getChatNotificationListState,
  getChatNotificationState,
  getNotificationListState,
  getNotificationState,
} from "store/selectors";
import {
  getChatNotificationCount,
  getNotificationCount,
  requestChatNotificationsList,
  requestNotificationList,
  updateReadChatNotification,
  updateReadNotification,
} from "store/rext";
import moment from "moment";
import CustomButton from "components/CustomButton";
import { URLRoutes } from "URLRoutes";

interface IBreadcrumb {
  text: string;
  url: string;
}

interface IPagination {
  totalPages: any;
  currentPage: number;
  paginationChange: any;
  disabled?:boolean;
}

interface ICampaign {
  children: any;
  title: string;
  contentClassName?: string;
  breadcrumbs: IBreadcrumb[];
  showPagination?: boolean;
  paginationProps?: IPagination;
}

function BreadcrumbHeader({ breadcrumbs }: { breadcrumbs: IBreadcrumb[] }) {
  const activeIndex = breadcrumbs.findIndex((breadcrumb) => {
    return window.location.pathname === breadcrumb.url;
  });

  return (
    <div className="width-100 flex flex-align-center background-blue-shade3 text-secondary-color text-3 padding-l-8 header-layout-bottom">
      {breadcrumbs.map((breadcrumb, index) => (
        <Fragment key={index}>
          <NavLink
            to={breadcrumb.url}
            className={classNames("breadcrumb-item", {
              active: index === activeIndex,
            })}
          >
            {breadcrumb.text}
          </NavLink>
          {index !== breadcrumbs.length - 1 && (
            <span className="breadcrumb-divider">&#x003E;</span>
          )}
        </Fragment>
      ))}
    </div>
  );
}

export default function HeaderLayout({
  children,
  title,
  contentClassName,
  breadcrumbs,
  showPagination,
  paginationProps,
}: ICampaign) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role, userEmail, instaDetails } = useUserDetail();
  const { data , message: notificationMessage } = useSelector(getNotificationListState);
  const { records } = data || {};
  const notifyRecords = records?.slice(0, 5);
  const { data: notification } = useSelector(getNotificationState);
  const { data: chatList , message } = useSelector(getChatNotificationListState);
  const chatRecords = chatList?.records?.slice(0, 5) || [];
  const { data: chatNotification } = useSelector(getChatNotificationState);

  const handleReadNotification = (id: any) => { 
    dispatch(updateReadNotification({ id }));
  };

  const handleReadChatNotification = (id: any) => { 
    dispatch(updateReadChatNotification({ id }));
  };


  // const handleNavigate = (id: any) => {
  //   navigate(`${URLRoutes.clients.campaignList}`);
  //   // window.open("http://localhost:3000/campaign/list" , "_blank")
  // };

  const renderNotifications = (record: any, index: number) => {
    const {
      title: notificationTitle,
      body: notificationBody,
      notified_on: notificationTime,
      id: notificationId,
      is_read,
      mark_read,
      campaign_id,
      type,
    } = record;

    const renderTextWithBoldQuotes = (text: string) => {
      const parts = text.split(/(".*?")/);
      return parts.map((part, index) => {
        if (part.startsWith('"') && part.endsWith('"')) {
          return (
            <span
              key={index}
              className="text-bold text-blue-shade1"
              // onClick={() => handleNavigate(campaign_id)}
            >
              {part}
            </span>
          );
        } else {
          return <span key={index}>{part}</span>;
        }
      });
    };

    return (
      <div
        key={`Notification-${index}`}
        className="flex flex-row  width-100 border-bottom border-grey-shade-1 cursor-pointer margin-b-2 margin-t-2"
        onClick={() => {
          if (!is_read && type !== "CHAT") {
            handleReadNotification(notificationId);
          }
           else if(!mark_read && type === "CHAT") {
            handleReadChatNotification(notificationId)
          }
        }}
      >
        <div className="width-5">
          {!is_read && type !== "CHAT" && (
            <SvgIcon
              name={CUSTOM_SVG_ICON.Ellipse}
              svgType={SVGType.CUSTOM}
              size={"small"}
              baseclassname={"text-blue-color"}
            />
          )}
          {!mark_read && type === "CHAT" && (
            <SvgIcon
              name={CUSTOM_SVG_ICON.Ellipse}
              svgType={SVGType.CUSTOM}
              size={"small"}
              baseclassname={"text-blue-color "}
            />
          )}
        </div>
        <div className={classNames(["flex flex-column width-95"])}>
          <div className="text-bold notification-title padding-b-1">
            {notificationTitle}
          </div>
          <div className="notification-subTitle text-3 line-h-4 padding-b-1">
            {renderTextWithBoldQuotes(notificationBody)}
          </div>
          <div className="notification-date padding-b-1">
            {moment(notificationTime).format("MMMM DD, YYYY h:mm A")}
          </div>
        </div>
      </div>
    );
  };

  const handleImageError = (e: any) => {
    e.target.onerror = null; 
    e.target.src = "/images/NoPreview.jpg"; 
  }

  const handleNotifications = () => {
    navigate(URLRoutes.clients.notifications);
  };

  const handleChatNotifications = () => {
    navigate(URLRoutes.clients.chatNotifications);
  };

  const popupContent = (
    <div className="background-secondary-color width-100">
      <div className="text-blue-shade1 text-bold text-4 padding-b-1 padding-l-2 border-bottom border-grey-shade-1 ">
        Notifications
        {notification.count > 0 && (
          <span className="background-danger-color text-secondary-color margin-l-2 notification-count">
            {notification.count}
          </span>
        )}
      </div>
      {notifyRecords?.map(renderNotifications)}
      <div className="flex width-100 flex-justify-end">
        <CustomButton
          buttonText="View All Notifications"
          buttonTextClass={"text-underline"}
          whiteBlueButton
          textSmall
          transparent
          noOutline
          handleClick={handleNotifications}
        />
      </div>
    </div>
  );

  const chatPopupContent = (
    <div className="background-secondary-color width-100">
      <div className="text-blue-shade1 text-bold text-4 padding-b-1 padding-l-2 border-bottom border-grey-shade-1 ">
        Chat Notifications
        {/* {chatNotification.count > 0 && (
          <span className="background-danger-color text-secondary-color padding-1 margin-l-2 notification-count">
            {chatNotification.count}
          </span>
        )} */}
      </div>
      {chatRecords?.map(renderNotifications)}
      <div className="flex width-100 flex-justify-end">
        <CustomButton
          buttonText="View All Notifications"
          buttonTextClass={"text-underline"}
          whiteBlueButton
          textSmall
          transparent
          noOutline
          handleClick={handleChatNotifications}
        />
      </div>
    </div>
  );

  useEffect(() => {
    dispatch(
      requestNotificationList({
        page: 1,
        pageSize: 10,
      })
    );
    dispatch(
      requestChatNotificationsList({
        page: 1,
        pageSize: 10,
      })
    );
    dispatch(getChatNotificationCount());
    dispatch(getNotificationCount());
  }, [dispatch]);

  return (
    <div className="flex flex-column width-100 height-100">
      <div className="width-100 flex flex-column header-layout">
        <div className="width-100 flex flex-align-center flex-justify-between padding-6 header-layout-top">
          <div className="text-blue-shade1 text-bold">{title}</div>
          <div className="flex flex-align-center flex-justify-end padding-r-3 width-60">
            {/* <SvgIcon
              name={CUSTOM_SVG_ICON.Search}
              svgType={SVGType.CUSTOM}
              baseclassname={"text-grey-shade-4 margin-r-4"}
              size={"huge"}
              // onClick={handleOpenChat}
            /> */}
            <Popup
              wide
              hoverable
              position="bottom center"
              disabled={!chatRecords?.length}
              content={chatRecords && chatRecords.length > 0 ? chatPopupContent: message}
              trigger={
                <div className="position-relative margin-r-6 ">
                  <SvgIcon
                    name={CUSTOM_SVG_ICON.Chat}
                    svgType={SVGType.CUSTOM}
                    baseclassname={"text-black-shade1 width-100"}
                    size={"huge"}
                  />
                  {chatNotification.count > 0 && (
                    <span className="position-absolute chatNotification-count text-bold">
                      {chatNotification.count}
                    </span>
                  )}
                </div>
              }
            />
            <Popup
              wide
              hoverable
              position="bottom center"
              disabled={!notifyRecords?.length}
              content={notifyRecords && notifyRecords.length > 0 ? popupContent : notificationMessage}
              trigger={
                <div className="position-relative margin-r-6 ">
                  <SvgIcon
                    name={CUSTOM_SVG_ICON.Bell}
                    svgType={SVGType.CUSTOM}
                    baseclassname={"text-grey-shade-4 width-100"}
                    size={"huge"}
                  />
                   {notification.count > 0 && (
                    <span className="position-absolute chatNotification-count text-bold">
                      {notification.count}
                    </span>
                  )}
                  {/* {notification.count > 0 && (
                    <SvgIcon
                      name={CUSTOM_SVG_ICON.Ellipse}
                      svgType={SVGType.CUSTOM}
                      size={"small"}
                      baseclassname={
                        "text-danger-color position-absolute notification-icon"
                      }
                    />
                  )} */}
                </div>
              }
            />
            <div className="header-layout-top-profile margin-r-2">
              {role === ROLES.INFLUENCER && (
                <img
                  src={
                    instaDetails?.profile_picture_url ||
                    "/images/InfluencerProfile.png"
                  }
                  className="width-100 height-100"
                  onError={handleImageError}
                  alt="No Image"
                />
              )}
              {role !== ROLES.INFLUENCER && (
                <img
                  src="/images/NoProfile.png"
                  style={{ width: "100%", height: "100%" }}
                  className="width-100"
                  onError={handleImageError}
                />
              )}
            </div>
            <div className="text-primary-color text-4 text-bold">
              {instaDetails?.name ? instaDetails.name : userEmail}
            </div>
          </div>
        </div>
        <BreadcrumbHeader breadcrumbs={breadcrumbs} />
      </div>
      <div
        className={classNames([
          { "header-layout-pagination": showPagination },
          { "header-layout-content": !showPagination },
          contentClassName || "",
        ])}
      >
        {children}
      </div>
      {showPagination && (
        <div className="header-layout-pagination-height width-100 flex flex-align-center flex-justify-center padding-t-2">
          <Pagination
          disabled={paginationProps?.disabled || false}
            onPageChange={paginationProps?.paginationChange}
            pointing
            firstItem={null}
            lastItem={null}
            secondary
            totalPages={paginationProps?.totalPages}
            activePage={paginationProps?.currentPage}
          />
        </div>
      )}
    </div>
  );
}
