import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { Modal, Popup } from "semantic-ui-react";
import CustomButton from "components/CustomButton/CustomButton";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { ModalHeader } from "../Common";
import "./style.scss";

interface Props {
  hideModal: () => void;
  onClose: () => void;
  onSave: (data: any) => void;
  title?: string;
  toggle?: any;
  allowedExtensions: any;
  message: any;
  allowedMimeTypes: any;
  popupMessage: any;
}

export default function BulkInviteModal({
  onClose,
  onSave,
  hideModal,
  title,
  toggle,
  allowedExtensions,
  allowedMimeTypes,
  message,
  popupMessage
}: Props) {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const drop = useRef<HTMLInputElement | null>(null);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
    }
  };

  const uploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (drop.current) {
      drop.current.classList.add("dragging");
    }
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (drop.current) {
      drop.current.classList.remove("dragging");
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (drop.current) {
      drop.current.classList.remove("dragging");
    }

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      setFile(event.dataTransfer.files[0]);
    }
  };

  const handleOnSubmit = () => {
    // // File size validation
    // if (file.size > 5 * 1024 * 1024) {
    //   // File size exceeds 5 MB
    //   alert("File size exceeds 5 MB limit.");
    //   return;
    // }

    if (file && allowedMimeTypes && !allowedMimeTypes?.includes(file.type)) {
      toast.error("Invalid Video file format.");
      setFile(null);
      onClose();
      return;
    }

    // File format validation (checking extension)
    if(file && allowedExtensions?.length > 0) {
      const extension = file.name.split('.').pop();
      if (!allowedExtensions.includes(extension as string)) {
        toast.error("Invalid file format.");
        setFile(null);
        onClose();
        return;
      }
    }

    onSave(file);
  };

  return (
    <Modal open={true} onClose={onClose} className="invite-modal" size="small">
      {title && <ModalHeader title={title} toggle={toggle} />}
      <Modal.Content>
        {message && (
          <div className="text-align-center text-bold text-blue-shade1">
            {message}
          </div>
        )}
        {popupMessage && (
          <div className="flex flex-align-center flex-justify-center  text-bold text-blue-shade1">
            Allowed File Types
            <Popup
              content={popupMessage}
              trigger={
                <div className="margin-l-3 margin-b-4">
                  <SvgIcon
                    name={CUSTOM_SVG_ICON.Info}
                    svgType={SVGType.CUSTOM}
                    size={"small"}
                    baseclassname={"text-blue-shade1"}
                  />
                </div>
              }
            />
          </div>
        )}
        <div
          ref={drop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          draggable
          onClick={uploadButtonClick}
          className="flex flex-column flex-align-center flex-justify-center padding-8 margin-t-3 height-100 dragDrop-container"
        >
          {!file && (
            <>
              <input
                ref={fileInputRef}
                type={"file"}
                onChange={handleOnChange}
                hidden
                // accept={allowedExtensions}
              />
              <div className="text-4 text-bold">
                Drag & Drop, or browse your file
              </div>
            </>
          )}

          {file && (
            <>
              <div>{file && <span>{file.name}</span>}</div>
              <div className="margin-t-7">
                <CustomButton
                  type={"submit"}
                  buttonText="Upload"
                  primaryButton
                  handleClick={handleOnSubmit}
                />
              </div>
            </>
          )}
        </div>
      </Modal.Content>
    </Modal>
  );
}
