import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { URLRoutes } from "URLRoutes";
import {
  getInvitedUserDetails,
  requestOTPVerification,
  resendOTPVerify,
} from "store/rext"; // Ensure resendOTP is imported
import {
  getGenreOptionsState,
  getInvitedUserState,
  getVerifyOTPState,
} from "store/selectors";
import CustomButton from "components/CustomButton";
import OTPInputs from "components/CustomInputs/OTPInputs";
import AuthLayout from "components/AuthLayout";
import UsePrevious from "HOC/UsePrevious";
import { OTP_EVENT } from "utils/Enums";

function VerifyOTP() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, otpType } = useParams();
  const [otp, setOTP] = useState<any>(undefined!);
  const [resendTimer, setResendTimer] = useState<number>(60);
  const [canResend, setCanResend] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState<boolean>(false); 
  const {
    data: verifyData,
    fetching,
    error,
    message,
  } = useSelector(getVerifyOTPState);
  const wasfecthing = UsePrevious(fetching);
  const { data: userdetail } = useSelector(getInvitedUserState);
  const { message: resendMessage } = useSelector(getGenreOptionsState);

  const handleOnSubmit = (data: any) => {
    setIsSubmitClicked(true)
    dispatch(
      requestOTPVerification(
        { otp, otpType: otpType, otp_resent: isVerified },
        { id: id }
      )
    );
  };

  const handleOTPChange = (newOTP: any) => {
    setOTP(newOTP);
  };

  const handleResendOTP = () => {
    setIsVerified(true);
    dispatch(
      resendOTPVerify({ otpType: otpType, otp_resent: true }, { id: id })
    );
    toast.info(resendMessage);
    setResendTimer(60); // Restart the timer
    setCanResend(false); // Disable resend button until timer ends
  };

  useEffect(() => {
    if (isSubmitClicked && !fetching && !error) {
      toast.success(message);
      if (otpType === OTP_EVENT.VERIFY_EMAIL) {
        navigate(URLRoutes.clients.mobileVerify.replace(":id", id as string));
      } else if (otpType === OTP_EVENT.VERIFY_PHONE) {
        if (
          (userdetail.is_facebook_authenticated && userdetail.created_via === "facebook")
        ) {
          navigate(URLRoutes.clients.registration.replace(":id", id as string));
        } else {
          navigate(URLRoutes.clients.facebookAuth.replace(":id", id as string));
        }
      } else if (otpType === OTP_EVENT.FORGOT_PASSWORD) {
        navigate(
          URLRoutes.clients.resetPassword.replace(":token", id as string)
        );
      }
    } else if (isSubmitClicked && error) {
      toast.error(message);
    }
  }, [fetching, error, message]);

  useEffect(() => {
    if (id) {
      dispatch(getInvitedUserDetails({ id: id }));
    }
  }, [id]);

  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setInterval(() => {
        setResendTimer((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setCanResend(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [resendTimer]);

  // useEffect(() => {
  //   toast.dismiss();
  // }, []);

  return (
    <AuthLayout
      image="/images/womanSunglasses.png"
      title="Discover the world’s top Influencers."
      titlePosition="top"
      imagePosition="right"
      contentClassName="flex-justify-center flex-align-center"
      bannerClassName="background-linear1"
    >
      <div className="width-45 margin-b-8">
        <img
          src="/images/logo.png"
          style={{ width: "100%", height: "100%" }}
          alt="HighOnBuzz"
        />
      </div>
      <div className="width-55 padding-t-10 padding-b-10">
        <OTPInputs
          value={otp}
          length={6}
          onChange={handleOTPChange}
          label="Enter OTP"
        />

        <CustomButton
          buttonText="VERIFY"
          type="submit"
          handleClick={handleOnSubmit}
          primaryButton
          round
          baseclassname={"width-100 margin-b-4"}
          loading={fetching}
        />
      </div>
      {/* <div className="text-4 text-bold line-h-6"> */}
      {canResend ? (
        <span
          className="text-4 text-bold line-h-6 text-black-shade-1 text-link cursor-pointer"
          onClick={handleResendOTP}
        >
          Didn’t receive OTP? <span style={{ color: "#069aff" }}>Resend</span>
        </span>
      ) : (
        <span className="text-4 text-bold line-h-6 text-grey-shade-2 cursor-notAllowed">
          Didn’t receive OTP? Resend in {resendTimer} seconds
        </span>
      )}
      {/* </div> */}
    </AuthLayout>
  );
}

export default VerifyOTP;
