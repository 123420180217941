import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import NavLinkComponent, {
  adminHOBNavLinks,
  brandNavLinks,
  sidebarLinks,
  influencerNavLinks,
} from "./utils";
import { ROLES } from "utils/Enums";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { requestLogout } from "store/actions";
import { URLRoutes } from "URLRoutes";
import { useUserDetail } from "HOC/useUserDetail";

import "./style.scss";

export default function LayoutComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { role } = useUserDetail();
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch(requestLogout());
    navigate(URLRoutes.clients.login);
  };

  const getNavLinksByRole = () => {
    if (role === ROLES.HOB) {
      return adminHOBNavLinks;
    } else if (role === ROLES.BRAND) {
      return brandNavLinks;
    } else if (role === ROLES.INFLUENCER) {
      return influencerNavLinks;
    } else {
      return null;
    }
  };
  
  useEffect(() => {
    const isHostUrl = window.location.pathname === '/' && window.location.search === ''
    if (isHostUrl) {
      if (role === ROLES.HOB) {
        navigate(
          URLRoutes.clients.users.replace(":userType", ROLES.INFLUENCER)
        );
      } else if (role === ROLES.INFLUENCER) {
        navigate(URLRoutes.clients.instaDashboard);
      } else if (role === ROLES.BRAND) {
        navigate(URLRoutes.clients.campaignList);
      }
    }
  }, []);

  const navLinks = getNavLinksByRole() || [];

  return (
    <div className="flex flex-row width-100 height-100 layout-container">
      <div className="flex flex-column layout-container-sidebar ">
        <div className="margin-4 layout-container-logo">
          <img
            src="/images/logo.png"
            style={{ width: "100%" }}
            alt="Logo"
          />
        </div>
        <div className="flex flex-column height-80 padding-l-3">
          {navLinks.map((link, index) => (
            <NavLinkComponent
              key={index}
              link={link}
              index={index}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
            />
          ))}
        </div>
        <div className="flex flex-column height-20 flex-justify-center padding-b-2 padding-l-3">
          {sidebarLinks.map((link, index) => (
            <NavLinkComponent
              key={index}
              link={link}
              index={index}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
            />
          ))}
          <div
            className="flex flex-align-center text-secondary-color margin-b-4 padding-1 nav-link"
            onClick={handleLogout}
          >
            <SvgIcon
              svgType={SVGType.CUSTOM}
              name={CUSTOM_SVG_ICON.Logout}
              size={"huge"}
              baseclassname={"margin-l-3 margin-r-2 "}
            />
            {"Logout"}
          </div>
        </div>
      </div>
      <div className="layout-container-content">
        <Outlet />
      </div>
    </div>
  );
}

// const NavLinks = [
//   {
//     to: URLRoutes.clients.users.replace(":userType", ROLES.INFLUENCER),
//     text: "Manage Users",
//     iconName: CUSTOM_SVG_ICON.Dashboard,
//     feature: features.getAllUserFeatures().isAllowed
//   },
//   {
//     to: "",
//     text: "Campaigns",
//     iconName: CUSTOM_SVG_ICON.Campaigns,
//     feature: features.getCampaignFeatures().isAllowed,
//     children: [
//       {
//         to: URLRoutes.clients.createCampaign,
//         text: "Create Campaign",
//         iconName: "",
//         feature: features.getCampaignFeatures().addCampaign
//       },
//       { to: URLRoutes.clients.campaignList, text: "Manage Campaigns", iconName: "" , feature: features.getCampaignFeatures().editCampaign, },
//     ],
//   },
//   // {
//   //   to:  URLRoutes.clients.instaDashboard,
//   //   text: "Dashboard",
//   //   iconName: CUSTOM_SVG_ICON.Dashboard,
//   //   feature: features.getSocialProfileFeature().isAllowed
//   // },
//   // { to: URLRoutes.clients.campaignList, text: "Campaigns", iconName: CUSTOM_SVG_ICON.Campaigns , feature: features.getCampaignFeatures().isAllowed, },
//   { to: URLRoutes.clients.profile, text: "Profile", iconName: CUSTOM_SVG_ICON.Profile , feature: features.getProfileFeature().isAllowed, },
//   // { to: URLRoutes.clients.settings, text: "Settings", iconName: CUSTOM_SVG_ICON.Settings },
//   { to: "", text: "Brands", iconName: CUSTOM_SVG_ICON.Brand1 , feature: features.getBrandFeatures().isAllowed,
//   children: [
//     {
//       to: URLRoutes.clients.createBrand,
//       text: "Create Brand",
//       iconName: "",
//       feature: features.getBrandFeatures().createBrand
//     },
//     { to: URLRoutes.clients.brandList, text: "Manage Brand", iconName: "" , feature: features.getBrandFeatures().editBrand },
//   ],
//    },
//   { to: "", text: "Brand Users", iconName: CUSTOM_SVG_ICON.BrandUser , feature: features.getBrandUserFeatures().isAllowed ,
//   children: [
//     {
//       to: URLRoutes.clients.createBrandUser,
//       text: "Create Brand Users",
//       iconName: "",
//       feature: features.getBrandUserFeatures().createBrandUser
//     },
//     { to: URLRoutes.clients.brandUsersList, text: "Manage Brand Users", iconName: "" , feature: features.getBrandUserFeatures().editBrandUser},
//   ],
//    },
// ];

// const navLinks = getNavLinksByRole() || [];
// const renderSidebarLinks = (links: any[]) => {
//   return links.map((link, index) => {
//     // Check if the feature associated with the link is allowed
//     const isFeatureAllowed = link.feature;

//     // Render NavLinkComponent only if the feature is allowed
//     if (isFeatureAllowed) {
//       return (
//         <NavLinkComponent
//           key={index}
//           link={link}
//           index={index}
//           activeIndex={activeIndex}
//           setActiveIndex={setActiveIndex}
//         />
//       );
//     } else {
//       return null;
//     }
//   });
// };
