import { Fragment, useSyncExternalStore } from 'react';
import { modalStore, IModal } from "modalStore";
import AlertModalView from "./AlertModal";
import DetailedTermModalView from './DetailedTermsModal';

const modalComponents = {
    AlertModalView,
    DetailedTermModalView
}

const ExternalModalManager = () => {
  const modals = useSyncExternalStore(modalStore.subscribe, modalStore.getSnapshot);

  const closeModal = (index: number, handleCloseClick : any, data: any) => {
    modalStore.removeModal(index);
    if(typeof handleCloseClick === "function") {
      handleCloseClick(data);
    }
  }

  const handleSave = (index: number, handleSuccessClick: any, data: any) => {
    modalStore.removeModal(index);
    if(typeof handleSuccessClick === "function") {
      handleSuccessClick(data);
    }
  }

  const renderModal = (modal: IModal, index: number) => {
    const { type, options, otherOptions } = modal;
    const { handleCancelClick, handleSuccessClick } = options;

    const ModalComponent: any = modalComponents[type!] || undefined!;

    return (
      <ModalComponent 
        {...otherOptions}
        {...options}
        key={`modal_${index}`}
        onSave={(data?: any) => handleSave(index, handleSuccessClick, data)}
        onClose={(data?: any) => closeModal(index, handleCancelClick, data)}
      />
    )
  }
  return (<Fragment>
    {modals.map(renderModal)}
  </Fragment>
  )
}

export default ExternalModalManager;