export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4291 1.42864V2.2269C14.6659 2.74076 17.1437 5.54492 17.1437 8.92901V10.4202C17.1437 12.447 17.8357 14.4159 19.0992 16.0008L19.7644 16.8312C20.0233 17.1526 20.0724 17.5946 19.8938 17.9652C19.7153 18.3357 19.3403 18.5723 18.9295 18.5723H1.07149C0.659416 18.5723 0.284174 18.3357 0.105772 17.9652C-0.072674 17.5946 -0.0224929 17.1526 0.234841 16.8312L0.900499 16.0008C2.16708 14.4159 2.85729 12.447 2.85729 10.4202V8.92901C2.85729 5.54492 5.33509 2.74076 8.57186 2.2269V1.42864C8.57186 0.639764 9.21029 0 10.0005 0C10.7907 0 11.4291 0.639764 11.4291 1.42864ZM9.64334 4.28593C7.08072 4.28593 5.00026 6.36639 5.00026 8.92901V10.4202C5.00026 12.5587 4.38058 14.6436 3.22829 16.4294H16.7732C15.6213 14.6436 15.0007 12.5587 15.0007 10.4202V8.92901C15.0007 6.36639 12.9203 4.28593 10.3577 4.28593H9.64334ZM12.8578 20.001C12.8578 20.7198 12.5587 21.4877 12.0229 22.0234C11.4872 22.5592 10.7193 22.8583 10.0005 22.8583C9.24154 22.8583 8.51382 22.5592 7.97808 22.0234C7.44234 21.4877 7.14322 20.7198 7.14322 20.001H12.8578Z"
        fill="currentColor"
      />
    </svg>
  );
};
