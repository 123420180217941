import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import CustomButton from "components/CustomButton";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import {
  CAMPAIGN_COLLABORATION_STATUS,
  CampaignType,
  ROLES,
} from "utils/Enums";
import {
  IconWithTitle,
  InfluencerSummaryCard,
  StatusInfo,
  TitleWithContent,
  formatStartEndDate,
  influencerStatusMap,
  ordinalNumbers,
  renderArray,
  renderDeliverables,
} from "./utils";
import { useDispatch, useSelector } from "react-redux";
import { ModalsType } from "containers/ModalManager/ModalManager";
import { modalOperation } from "store/actions";
import { URLRoutes } from "URLRoutes";
import { getPostedInfluencersState } from "store/selectors";
import Loading from "components/Loading";
import { useUserDetail } from "HOC/useUserDetail";
import { findLabelById, formatStatusString } from "utils/utils";
import classNames from "classnames";
import UsePrevious from "HOC/UsePrevious";
import { requestCampaignDetail } from "store/rext";

interface ICampaignDetail {
  campaignDetail: any;
  handleDeleteGuideline: (id: any) => void;
  handleApplyCampaign: () => void;
  handleReject: () => void;
  handleVideoUpload: () => void;
  handleUploadGuideLines: () => void;
  campaignFetching: boolean | undefined;
  guideLinefetching: boolean | undefined;
  listMessage: string;
  listError: boolean;
  campaignMessage: string;
  campaignError: boolean;
  generOptions: any[];
}

export default function CampaignDetail(props: ICampaignDetail) {
  const {
    campaignDetail,
    handleDeleteGuideline,
    handleApplyCampaign,
    handleReject,
    handleVideoUpload,
    handleUploadGuideLines,
    campaignFetching,
    guideLinefetching,
    campaignError,
    campaignMessage,
    listError,
    listMessage,
    generOptions
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role, features } = useUserDetail();
  const {
    message: postedMessage,
    fetching: postedFetching,
    error: postedError,
  } = useSelector(getPostedInfluencersState);

  const wasfecthing = UsePrevious(postedFetching);


  const renderGuidelines = useMemo(
    () => (item: any) => {
      return (
        <div
          className={classNames([
            "flex flex-row padding-1 flex-align-center  text-align-center",
            { "width-": features.getCampaignFeatures().uploadGuideLine },
            { "width-100": !features.getCampaignFeatures().uploadGuideLine },
          ])}
          key={`Guideline-${item.id}`}
        >
          <SvgIcon
            name={CUSTOM_SVG_ICON.File}
            svgType={SVGType.CUSTOM}
            baseclassname={"text-default-color margin-r-1"}
            size={"small"}
            // onClick={() => handleDeleteGuideline(item.id)}
          />
          <a
            href={item.path || ""}
            className="text-3 text-underline margin-r-1 margin-t-1"
          >
            {item.file_name}
          </a>
          {features.getCampaignFeatures().deleteGuideline && (
            <SvgIcon
              name={CUSTOM_SVG_ICON.Delete}
              svgType={SVGType.CUSTOM}
              baseclassname={"text-default-color"}
              size={"small"}
              onClick={() => handleDeleteGuideline(item.id)}
            />
          )}
        </div>
      );
    },
    [features, handleDeleteGuideline]
  );

  const handleContentUrl = (url: any) => {
    if(url.length > 0) {
      window.open(url , "_blank");
    }
  }

  const handleVideoListModal = () => {
    dispatch(
      modalOperation.showModal(ModalsType.VideoModal, {
        onSave: (data: any) => {
          dispatch(modalOperation.hideModal());
        },
        onClose: () => {
          dispatch(modalOperation.hideModal());
        },
        title: "Video List",
        toggle: () => {
          dispatch(modalOperation.hideModal());
        },
        Influencer_Id: campaignDetail.campaignMappingDetails?.id,
        allowedFileTypes: [".mp4"],
      })
    );
  };

  const handleManageInfluencers = () => {
    navigate(
      URLRoutes.clients.influencerDetail.replace(
        ":campaignId",
        campaignDetail.id
      )
    );
  };
  const handleOpenChat = () => {
    dispatch(
      modalOperation.showModal(ModalsType.ChatModal, {
        onSave: (data: any) => {
          dispatch(modalOperation.hideModal());
        },
        onClose: () => {
          dispatch(modalOperation.hideModal());
          dispatch(
            requestCampaignDetail(
              { id: campaignDetail.id },
              role === ROLES.INFLUENCER
                ? URLRoutes.server.campaignInfluencerDetail
                : URLRoutes.server.campaignDetail
            )
          );
        },
        toggle: () => {
          dispatch(modalOperation.hideModal());
          dispatch(
            requestCampaignDetail(
              { id: campaignDetail?.id },
              role === ROLES.INFLUENCER
                ? URLRoutes.server.campaignInfluencerDetail
                : URLRoutes.server.campaignDetail
            )
          );
        },
        influencerData: campaignDetail,
      })
    );
  };
  const handleEditClick = () => {
    if (campaignDetail?.id) {
      navigate(
        URLRoutes.clients.updateCampaign.replace(":id", campaignDetail.id)
      );
    }
  };

  useEffect(() => {
    if (wasfecthing && !postedFetching && !postedError) {
      toast.success(postedMessage);
    } else if (postedError) {
      toast.error(postedMessage);
    }
  }, [postedError, postedFetching, postedMessage]);

  return (
    <div className="width-55 height-100">
      <div className="width-100 padding-3 flex flex-row flex-justify-end border-bottom border-grey-shade-1 campaign-right-header">
        {features.getCampaignFeatures().editCampaign && (
          <CustomButton
            buttonText="Edit"
            greyButton
            textSmall
            circular
            iconPosition={ICON_POSITION.LEFT}
            iconProps={{
              name: CUSTOM_SVG_ICON.Edit,
              svgType: SVGType.CUSTOM,
              size: "small",
            }}
            handleClick={handleEditClick}
            baseclassname={"text-align-center margin-r-4"}
            disabled={!campaignDetail.id || campaignFetching}
          />
        )}
        {role === ROLES.INFLUENCER && campaignDetail.id && (
          <div className="position-relative margin-t-2 margin-r-4 cursor-pointer">
            <SvgIcon
              name={CUSTOM_SVG_ICON.Chat}
              svgType={SVGType.CUSTOM}
              size={"huge"}
              baseclassname={"text-default-color text-align-center "}
              onClick={handleOpenChat}
            />
            {campaignDetail?.chatCountInfo &&
              campaignDetail?.chatCountInfo?.length > 0 && (
                <div className="position-absolute influencer-chat">
                  {campaignDetail?.chatCountInfo[0]?.count}
                </div>
              )}
          </div>
        )}
        {features.getCampaignFeatures().applyCampign &&
          campaignDetail?.campaignMappingDetails &&
          campaignDetail?.campaignMappingDetails?.collaboration_status ===
            CAMPAIGN_COLLABORATION_STATUS.POST_TO_INFLUENCER && (
            <CustomButton
              buttonText="Apply For Campaign"
              primaryButton
              textSmall
              circular
              // loading={applyFetching}
              handleClick={handleApplyCampaign}
              baseclassname={"text-align-center margin-r-4"}
              disabled={!campaignDetail.id}
            />
          )}
        {features.getCampaignFeatures().uploadVideo &&
          campaignDetail.campaignMappingDetails?.collaboration_status ===
            CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_HOB && (
            <CustomButton
              buttonText="Upload Video"
              primaryButton
              textSmall
              circular
              // loading={videoFetching}
              baseclassname={"text-align-center margin-r-2"}
              handleClick={handleVideoUpload}
              disabled={!campaignDetail.id}
            />
          )}
        {features.getCampaignFeatures().rejectCampaign &&
          campaignDetail.campaignMappingDetails?.collaboration_status !==
            CAMPAIGN_COLLABORATION_STATUS.DECLINE_CAMPAIGN &&
          campaignDetail.campaignMappingDetails?.collaboration_status !==
            CAMPAIGN_COLLABORATION_STATUS.DECLINE_BY_HOB && (
            <CustomButton
              buttonText="Reject"
              dangerButton
              textSmall
              circular
              loading={postedFetching}
              baseclassname={"text-align-center margin-r-4"}
              handleClick={handleReject}
              disabled={!campaignDetail.id}
            />
          )}
        {features.getCampaignFeatures().manageInfluencers && (
          <CustomButton
            buttonText="Manage Influencers"
            primaryButton
            textSmall
            circular
            baseclassname={"text-align-center margin-r-2"}
            handleClick={handleManageInfluencers}
            disabled={!campaignDetail.id || campaignFetching}
          />
        )}
      </div>
      {campaignFetching && <Loading />}
      {(campaignError || listError) && (
        <div className="text-5 text-grey-shade-4 text-align-center padding-4">
          {listMessage || campaignMessage || "No campaign data available"}
        </div>
      )}
      {!campaignFetching && (!campaignError && !listError) && campaignDetail && (
        <div className="campaign-right-content padding-4">
          <div className="flex flex-justify-between flex-align-center margin-b-4">
            <div className="flex flex-align-center flex-justify-center background-secondary-color campaign-image padding-2">
              <img
                src={
                  campaignDetail.brandInfo && campaignDetail.brandInfo.logo_url
                    ? campaignDetail.brandInfo.logo_url
                    : "/images/No_Image.png"
                }
                className="width-100 height-100"
                alt="image"
              />
            </div>
            <div className="width-85 flex flex-column campaign-info padding-l-2 padding-r-2 padding-t-1 flex-justify-center">
              <div className="flex flex-row width-100 flex-justify-between flex-align-center margin-b-2">
                <div className="campaign-detail-name">
                  {campaignDetail.name}
                </div>
                <StatusInfo
                  campaignStatus={
                    role === ROLES.INFLUENCER
                      ? influencerStatusMap[
                          campaignDetail.campaignMappingDetails
                            ?.collaboration_status as CAMPAIGN_COLLABORATION_STATUS
                        ]
                      : campaignDetail.status
                  }
                  baseClassName={"margin-b-3"}
                />
              </div>

              <div className="flex flex-row flex-justify-between width-100 ">
                <div className="campaign-detail-subName">
                  {campaignDetail.brandInfo && campaignDetail.brandInfo.name}
                </div>
                <div className="campaign-type padding-r-1">
                  {formatStatusString(campaignDetail.campaigntype)}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row flex-align-center border-top border-bottom border-grey-shade-1 flex-justify-center margin-b-3 width-100">
            <IconWithTitle
              iconName={CUSTOM_SVG_ICON.Calendar}
              title="End Date"
              subTitle={formatStartEndDate(campaignDetail.enddate)}
              baseClassName="border-right border-grey-shade-1 width-33 flex-justify-center flex-align-center"
            />
            <IconWithTitle
              iconName={CUSTOM_SVG_ICON.Budget}
              title="Budget"
              subTitle={
                "₹" +
                `${
                  role === ROLES.INFLUENCER
                    ? campaignDetail.campaignMappingDetails?.budget
                      ? campaignDetail.campaignMappingDetails?.budget
                      : 0
                    : campaignDetail.budget
                }`
              }
              baseClassName="border-right border-grey-shade-1 width-33 flex-justify-center flex-align-center"
            />
            <IconWithTitle
              iconName={CUSTOM_SVG_ICON.PaymentTerm}
              title="Payment Term"
              subTitle={
                campaignDetail?.paymentterm
                  ? `${campaignDetail?.paymentterm} days`
                  : "NA"
              }
              baseClassName="width-33 flex-justify-center flex-align-center"
            />
          </div>
          {/* {role !== ROLES.INFLUENCER && ( */}
            <div className="flex width-100 flex-justify-between">
              <TitleWithContent
                title="Proposal Deadline"
                content={formatStartEndDate(campaignDetail.startdate)}
              />
              <TitleWithContent
                title="Gender"
                content={formatStatusString(campaignDetail.gender)}
              />
              <TitleWithContent
                title="Genre"
                content={
                  renderArray(campaignDetail.gener, 1, (item: string) =>
                    findLabelById(generOptions, item)
                  )
                }
              />
              <TitleWithContent
                title="Influencer Category"
                content={
                  renderArray(campaignDetail.category, 1, undefined!)
                }
              />
            </div>
          {/* )} */}
          {role !== ROLES.INFLUENCER &&
            campaignDetail?.campaignManagers?.length > 0 && (
              <TitleWithContent
                title="Brand Users"
                content={
                  renderArray(
                    campaignDetail.campaignManagers,
                    5,
                    (item: any) => item.name
                  )
                }
              />
            )}
          <TitleWithContent
            title="#Hashtag"
            content={campaignDetail.hashtags}
          />
          <TitleWithContent
            title="Campaign Brief"
            content={campaignDetail.shortdescription}
          />
          <div className="flex width-100  flex-justify-between flex-align-center">
            <div className="title-with-content margin-b-2 width-80">
              <div className="text-bold text-3">Product/Service Details</div>
              <div className="text-3 text-grey-shade-4">
                {campaignDetail.productdetails || "NA"}
              </div>
            </div>
            {/* <TitleWithContent
            title="Product/Service Details"
            content={campaignDetail.productdetails}
          /> */}
            <div className="flex flex-align-center flex-justify-center background-secondary-color campaign-image padding-2">
              <img
                src={campaignDetail.productfile || "/images/No_Image.png"}
                alt="image"
                className="width-100 height-100"
              />
            </div>
          </div>

          <TitleWithContent
            title="Additional Info"
            content={campaignDetail.othercomments}
          />
         {(campaignDetail.campaigntype === CampaignType.BARTER_UGC ||
            campaignDetail.campaigntype === CampaignType.PAID_UGC) && (
            <div className="flex flex-column">
              <div className="flex flex-row margin-b-1">
                <div className="text-bold text-3 margin-r-2">Visit Type:</div>
                <div className="text-3 text-grey-shade-4">
                  {formatStatusString(campaignDetail.visit_type) || "NA"}
                </div>
              </div>
              {campaignDetail.visit_type === "EVENT" && (
                <div className="flex flex-row margin-b-1">
                  <div className="text-bold text-3 margin-r-2">Date: </div>
                  <div className="text-3 text-grey-shade-4">
                    {campaignDetail.event_time ? moment(campaignDetail.event_time).format("MMMM DD, YYYY") : "NA"}
                  </div>
                </div>
              )}
              {campaignDetail.visit_type === "EVENT" && (
                <div className="flex flex-row margin-b-1">
                  <div className="text-bold text-3 margin-r-2">Time: </div>
                  <div className="text-3 text-grey-shade-4">
                    {campaignDetail.event_time ? moment(campaignDetail.event_time).format("HH:mm A") : "NA"}
                  </div>
                </div>
              )}
              <div className="flex flex-row margin-b-2">
                <div className="text-bold text-3 margin-r-2">Address: </div>
                <div className="text-3 text-grey-shade-4">
                  {campaignDetail.address || "NA"}
                </div>
              </div>
            </div>
          )}
          {campaignDetail.contenturl !== "null" && (campaignDetail.campaigntype === CampaignType.BARTER_REPOST || campaignDetail.campaigntype === CampaignType.PAID_REPOST) && (
              <div className="flex flex-row margin-b-1">
                <div className="text-bold text-3 margin-r-2">Content</div>
                <div className={classNames(["text-3  cursor-pointer" , {"text-underline text-blue-color" : campaignDetail.contenturl}])} onClick={() => handleContentUrl(campaignDetail?.contenturl)}>
                  {campaignDetail?.contenturl || "NA"}
                </div>
              </div>
            )}

          {campaignDetail.deliverables?.length > 0 && 
           <div className="flex flex-column flex-justify-center width-75">
           <div className="text-default-color icon-title margin-b-2">Deliverables</div>
           {renderDeliverables(campaignDetail.deliverables)}
         </div>
         }

          {role === ROLES.INFLUENCER && (
            <div className="flex flex-column width-100">
              <div className="flex width-100">
                <IconWithTitle
                  title="Go Live Date"
                  subTitle={campaignDetail?.golivedate ? `${formatStartEndDate(campaignDetail?.golivedate)}` : "NA"}
                  baseClassName=" width-35"
                />
                <IconWithTitle
                  title="Quote"
                  subTitle={campaignDetail.campaignMappingDetails?.quote || "NA"}
                  baseClassName=" width-30"
                />
                <IconWithTitle
                  title="Number of Retakes"
                  subTitle={campaignDetail.campaignMappingDetails?.takes || "NA"}
                  baseClassName="width-35"
                />
              </div>
              {campaignDetail.videos?.length > 0 && (
                <div className="flex  flex-column width-100">
                  <div className="text-default-color icon-title margin-b-2">
                    Videos
                  </div>
                  <div
                    onClick={handleVideoListModal}
                    className="flex flex-row width-100 cursor-pointer"
                  >
                    <div className="text-3 text-grey-shade-4 padding-b-1 margin-r-4">
                      {ordinalNumbers[campaignDetail.videos.length - 1]} Draft
                    </div>
                    <div className="text-underline text-3 text-blue-color">
                      {
                        campaignDetail.videos[campaignDetail.videos.length - 1]
                          ?.file_name
                      }
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          <div
            className={classNames([
              "flex  flex-align-center  width-100",
              {
                "flex-justify-end margin-1": !campaignDetail.guidelines?.length,
              },
              {
                "flex-justify-between margin-b-2":
                  campaignDetail.guidelines?.length > 0,
              },
            ])}
          >
            {campaignDetail.guidelines?.length > 0 &&
              campaignDetail.guidelines && (
                <div className="flex flex-column ">
                  <span className="text-3 text-bold">Download Documents</span>
                  <div className="flex flex-row flex-wrap width-100">
                    {campaignDetail.guidelines?.map(renderGuidelines)}
                  </div>
                </div>
              )}
            {features.getCampaignFeatures().uploadGuideLine && (
              <CustomButton
                primaryButton
                buttonText="Upload Guidelines"
                circular
                textSmall
                loading={guideLinefetching}
                handleClick={handleUploadGuideLines}
                // baseclassname={"width-30"}
              />
            )}
          </div>

          {role !== ROLES.INFLUENCER && (
            <div className="flex flex-column influencer-summary">
              <div className="influencer-summary-text1">Influencer Summary</div>
              <div className="text-3 text-black-shade1 margin-b-4 margin-t-1">
                Explore the influencers you've shortlisted, finalised, and
                who've completed your campaign.
              </div>
              <div className="flex flex-row width-100 flex-justify-between padding-l-2 padding-r-2">
                <InfluencerSummaryCard
                  iconName={CUSTOM_SVG_ICON.User}
                  count={campaignDetail.Influencers_count?.shortlisted}
                  text="Shortlisted Influencer"
                  baseClassName={"background-gradient-shade-1 padding-l-4"}
                />
                <InfluencerSummaryCard
                  iconName={CUSTOM_SVG_ICON.ClipBoard}
                  count={campaignDetail.Influencers_count?.finalized}
                  text="Finalised Influencer"
                  baseClassName={"background-gradient-shade-2 padding-l-4"}
                />
                <InfluencerSummaryCard
                  iconName={CUSTOM_SVG_ICON.ApproveLike}
                  count={campaignDetail.Influencers_count?.accomplished}
                  text="Accomplished"
                  baseClassName={"background-gradient-shade-3 padding-l-4"}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
