import classNames from "classnames";
import SvgIcon, { SVGType } from "components/SvgIcon";

interface Props {
  title: string;
  isActive: boolean;
  onToggle: () => void;
  isLastItem?: boolean;
  children?: any;
}

function ExpandCollapse({ title, children, isActive, onToggle , isLastItem }: Props) {
  return (
    <div className={classNames([isLastItem ? "" : "border-bottom border-grey-shade-1"])}>
      <div
        className={classNames(
          "flex flex-row flex-align-center flex-justify-between cursor-pointer",
          {
            "border-bottom border-grey-shade-1": isActive,
          }
        )}
        onClick={onToggle}
      >
        <div className="text-4 line-h-8 text-blue-shade1 text-bold padding-4">
          {title}
        </div>
        <div className="cursor-pointer text-blue-shade1 padding-4">
          {!isActive ? (
            <SvgIcon
              name={"angle down"}
              svgType={SVGType.SEMANTIC}
              size={"small"}
            />
          ) : (
            <SvgIcon
              name={"angle up"}
              svgType={SVGType.SEMANTIC}
              size={"small"}
            />
          )}
        </div>
      </div>
      {isActive && children}
    </div>
  );
}

export default ExpandCollapse;