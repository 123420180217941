import classNames from "classnames";
import { Controller } from "react-hook-form";

interface HookFormCheckboxProps {
  label?: string;
  caption?: string;
  labelClassName?: string;
  captionClassName?: string;
  baseClassName?: string;
  labelWrapper?: string;
  name: string;
  validation?: Record<string, any>;
  control: any; 
  errors: any; 
  inputClassName?: string;
  id: string;
  triggerOnChange?: (value: any) => void;
  checkedValue?: any; 
  uncheckedValue?: any; 
  disabled?: boolean;
  required?: boolean;
}

export function HookFormCheckbox(props : HookFormCheckboxProps) {
  const {
    label,
    caption,
    labelClassName,
    captionClassName,
    baseClassName,
    labelWrapper,
    name,
    validation,
    control,
    errors,
    inputClassName,
    id,
    triggerOnChange,
    checkedValue,
    uncheckedValue,
    disabled,
    required
  } = props;
  return (
    <div
      className={classNames(["flex flex-row flex-align-center", baseClassName])}
    >
      {control && (
        <Controller
          control={control}
          name={name}
          rules={validation || {}}
          render={({ field: { onChange, value, ref } }) => (
            <input
              type="checkbox"
              className={inputClassName || ""}
              id={id}
              onChange={async ({ target: { checked } }: any) => {
                onChange(checked);
                if (typeof triggerOnChange === "function") {
                  triggerOnChange(checked ? checkedValue : uncheckedValue);
                }
              }}
              checked={value || false}
              disabled={disabled}
            />
          )}
        />
      )}
      <div className={labelWrapper || ""}>
        {label && (
          <div className={classNames(["text-3 padding-l-2", labelClassName])}>
            {label}
            { required && <span className={classNames(["position-absolute input-asterik text-danger-color "])}>&#8727;</span>}
          </div>
        )}
        {caption && (
          <span
            className={classNames([
              "text-3 flex flex-row padding-t-2",
              captionClassName,
            ])}
          >
            {caption}
          </span>
        )}
      </div>
      {errors[name] && (
        <div className="text-3 text-danger-color padding-b-1 padding-l-1">
          {errors[name].message}
        </div>
      )}
    </div>
  );
}
