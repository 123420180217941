import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Dropdown } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import HeaderLayout from "../../components/HeaderLayout";
import CampaignCard from "./CampaignCard/CampaignCard";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import {
  applyForCampaign,
  deleteGuideline,
  postToInfluencers,
  requestBrandsList,
  requestCampaignDetail,
  requestCampaignsList,
  requestGenreOptions,
  uploadGuidelinesToCampaign,
  uploadVideoToHOB,
} from "store/rext";
import {
  getBillingInfluencersState,
  getBrandListState,
  getCampaignDetailState,
  getCampaignsListData,
  getGenreOptionsState,
  getUploadGuidelinesState,
  getUploadedVideoState,
} from "store/selectors";
import { URLRoutes } from "URLRoutes";
import {
  CAMPAIGN_COLLABORATION_ACTIONS,
  CAMPAIGN_COLLABORATION_STATUS,
  CampaignType,
  ROLES,
} from "utils/Enums";
import Loading from "components/Loading";
import classNames from "classnames";
import { ModalsType } from "containers/ModalManager/ModalManager";
import { modalOperation } from "store/actions";
import { CustomInput } from "components/CustomInputs";
import UsePrevious from "HOC/UsePrevious";
import CampaignDetail from "./CampaignDetail";
import { influencerStatusMap, SortingOptions, sortingOptions } from "./utils";
import { useUserDetail } from "HOC/useUserDetail";
import { useSearchParams } from "react-router-dom";
import "./style.scss";

const sortingOptionsConfig = {
  [SortingOptions.AtoZ]: { sortBy: "name", sortDirection: "ascending" },
  [SortingOptions.ZtoA]: { sortBy: "name", sortDirection: "descending" },
  [SortingOptions.Oldest]: { sortBy: "created_at", sortDirection: "ascending" },
  [SortingOptions.Latest]: {
    sortBy: "created_at",
    sortDirection: "descending",
  },
};

function ManageCampaigns() {
  const dispatch = useDispatch();
  const { role, userId } = useUserDetail();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [activeCampaignId, setActiveCampaignId] = useState<string>(undefined!);
  const cardRefs: MutableRefObject<any[]> = useRef(new Array());

  //Selectors
  const { data: genreOptions } = useSelector(getGenreOptionsState);
  const {
    data,
    fetching: listFetching,
    message: listMessage,
    error: listError,
  } = useSelector(getCampaignsListData);
  const { records, paginationInfo } = data || {};
  const {
    data: brandsList,
    fetching: brandFetching,
    error,
    message,
  } = useSelector(getBrandListState);
  const {
    data: campaignDetail,
    fetching: campaignFetching,
    error: campaignError,
    message: campaignMessage,
  } = useSelector(getCampaignDetailState);
  const {
    message: videoMessage,
    fetching: videoFetching,
    error: videoError,
  } = useSelector(getUploadedVideoState);
  const {
    message: guideLineMessage,
    fetching: guideLinefetching,
    error: guidelineError,
  } = useSelector(getUploadGuidelinesState);
  const {
    error: applyError,
    message: applyMessage,
    fetching: applyFetching,
  } = useSelector(getBillingInfluencersState);
  const wasfecthing = UsePrevious(applyFetching);
  const wasUploading = UsePrevious(videoFetching);
  const wasUploadingGuideLine = UsePrevious(guideLinefetching);
  //filters
  const [filters, setFilters] = useState<{
    search?: string;
    brand?: string;
    sorting?: string;
  }>({});

  const sortingFilterOptions = sortingOptions.map((option) => ({
    key: option.value,
    text: option.label,
    value: option.value,
    icon: option.icon ? { name: option.icon } : undefined,
  }));

  const brandFilterOptions = [
    { key: undefined, text: "Select All", value: undefined },
    ...(brandsList.records?.map((option: any) => ({
      key: option.id,
      text: option.name,
      value: option.id,
    })) || []),
  ];

  const renderCampaignList = useMemo(
    () => (item: any, index: number) => {
      const activeCampaign = (ref: any) => (cardRefs.current[index] = ref);
      return (
        <CampaignCard
          key={`Campaign-${index}`}
          activeCampaignRef={activeCampaign}
          imageSrc={item.brandInfo.logo_url || "/images/No_Image.png"}
          brandName={item.brandInfo.name || ""}
          influencerCount={item.influencersCount || "0"}
          usersCount={item.manageruserid?.length || "0"}
          budget={item.campaignMappingDetails?.budget || "0"}
          name={item?.name}
          campaignStatus={
            role === ROLES.INFLUENCER
              ? influencerStatusMap[
                  item.campaignMappingDetails
                    ?.collaboration_status as CAMPAIGN_COLLABORATION_STATUS
                ]
              : item.status
          }
          handleClick={() => handleCardClick(item.id)}
          startDate={item.startdate || ""}
          endDate={item.enddate || ""}
          shortdescription={item.shortdescription || ""}
          active={item.id === activeCampaignId}
          isEnable={role !== ROLES.INFLUENCER}
        />
      );
    },
    [activeCampaignId, role]
  );
  const handleBrandFilterChange = useCallback(
    (value: string) => {
      setFilters((prevFilters) => ({
        sorting: "",
        brand: value,
        search: "",
      }));

      const filterParams = {
        page: currentPage,
        pageSize: 10,
      } as any;

      if (value !== undefined) {
        filterParams.brandIds = [value];
      }

      dispatch(
        requestCampaignsList(
          role === ROLES.INFLUENCER
            ? URLRoutes.server.getCampaignsInfl
            : URLRoutes.server.getCampaignsList,
          filterParams
        )
      );
      records && setActiveCampaignId(records[0]?.id);
    },
    [currentPage, dispatch, filters.brand, role]
  );

  const handleSortingFilter = useCallback(
    (value: SortingOptions) => {
      setFilters((prevFilters) => ({
        sorting: value,
        brand: "",
        search: "",
      }));

      if (value === undefined) {
        dispatch(
          requestCampaignsList(
            role === ROLES.INFLUENCER
              ? URLRoutes.server.getCampaignsInfl
              : URLRoutes.server.getCampaignsList,
            {
              page: currentPage,
              pageSize: 10,
            }
          )
        );
        return;
      }

      const config = sortingOptionsConfig[value];
      if (!config) return;

      const body: any = {
        page: currentPage,
        pageSize: 10,
        sortBy: config.sortBy,
        sortDirection: config.sortDirection,
      };

      if (role === ROLES.BRAND) {
        body.brand = userId;
      }

      dispatch(
        requestCampaignsList(
          role === ROLES.INFLUENCER
            ? URLRoutes.server.getCampaignsInfl
            : URLRoutes.server.getCampaignsList,
          body
        )
      );
      records && setActiveCampaignId(records[0]?.id);
    },
    [currentPage, dispatch, filters.sorting, role]
  );

  const handleDeleteGuideline = (guidelineId: any) => {
    dispatch(deleteGuideline({ id: guidelineId }));
  };

  const handleCardClick = (id: any) => {
    setActiveCampaignId(id);
    setSearchParams({ campaign_id: id });
    dispatch(
      requestCampaignDetail(
        { id },
        role === ROLES.INFLUENCER
          ? URLRoutes.server.campaignInfluencerDetail
          : URLRoutes.server.campaignDetail
      )
    );
  };

  const handleUploadGuideLines = () => {
    dispatch(
      modalOperation.showModal(ModalsType.BulkInviteModal, {
        onSave: (data: any) => {
          const formData = new FormData();
          formData.append("file", data as File);
          formData.append("campaign_id", campaignDetail.id);
          dispatch(uploadGuidelinesToCampaign(formData));
          dispatch(modalOperation.hideModal());
        },
        onClose: () => {
          dispatch(modalOperation.hideModal());
        },
        title: "Upload Guidelines",
        toggle: () => {
          dispatch(modalOperation.hideModal());
        },
        popupMessage: ".doc | .docx | .pdf | .ppt | .pptx",
        allowedExtensions: ["doc", "docx", "pdf", "ppt", "pptx"],
        // message:"Allowed File Types: .doc | .docx | .pdf | .ppt | .pptx"
      })
    );
  };

  const handleFilterChange = (name: any, value: string) => {
    setFilters((prevFilters) => ({
      // ...prevFilters,
      sorting: "",
      brand: "",
      search: value,
    }));

    const requestParams: any = {
      page: currentPage,
      pageSize: 10,
      search: value,
    };

    if (role === ROLES.BRAND) {
      requestParams.brand = userId;
    }

    dispatch(
      requestCampaignsList(
        role === ROLES.INFLUENCER
          ? URLRoutes.server.getCampaignsInfl
          : URLRoutes.server.getCampaignsList,
        requestParams
      )
    );
    setActiveCampaignId(records[0]?.id);
  };

  const handlePaginationChange = (e: any, { activePage }: any) => {
    setActiveCampaignId(records[0]?.id);
    setCurrentPage(activePage);
    dispatch(
      requestCampaignsList(
        role === ROLES.INFLUENCER
          ? URLRoutes.server.getCampaignsInfl
          : URLRoutes.server.getCampaignsList,
        {
          page: activePage,
          pageSize: 10,
        }
      )
    );
  };

  const handleApplyCampaign = () => {
    dispatch(
      modalOperation.showModal(ModalsType.ApplyCampaignModal, {
        onSave: (data: any) => {
          dispatch(modalOperation.hideModal());
          dispatch(
            applyForCampaign(data, {
              id: campaignDetail?.campaignMappingDetails?.id,
            })
          );
        },
        onClose: () => {
          dispatch(modalOperation.hideModal());
        },
        title: "Apply for Campaign",
        campaignType: campaignDetail.campaigntype as CampaignType,
        toggle: () => {
          dispatch(modalOperation.hideModal());
        },
      })
    );
  };

  const handleReject = async () => {
    dispatch(
      postToInfluencers({
        campaignId: campaignDetail?.id,
        influencerIds: [campaignDetail.campaignMappingDetails.influencer_id],
        action: CAMPAIGN_COLLABORATION_ACTIONS.DECLINE_CAMPAIGN,
      })
    );
  };

  const handleVideoUpload = () => {
    dispatch(
      modalOperation.showModal(ModalsType.BulkInviteModal, {
        onSave: (data: any) => {
          dispatch(modalOperation.hideModal());
          const formData = new FormData();
          formData.append("file", data as File);
          formData.append("id", campaignDetail.campaignMappingDetails?.id);
          debugger;
          dispatch(uploadVideoToHOB(formData));
          toast.success(videoMessage);
        },
        onClose: () => {
          dispatch(modalOperation.hideModal());
        },
        title: "Upload Video",
        toggle: () => {
          dispatch(modalOperation.hideModal());
        },
        popupMessage:
          ".mp4 | .webm | .3gpp | .avi | .mov | .wmv | .flv | .mkv | .mpeg",
        // message:"Allowed File Types: .mp4 , .webm , .3gpp , .avi , .mov , .wmv, .flv, .mkv , .mpeg , .ogg",
        allowedMimeTypes: [
          "video/mp4",
          "video/webm",
          "video/x-msvideo",
          "video/quicktime",
          "video/x-ms-wmv",
          "video/x-flv",
          "video/x-matroska",
          "video/3gpp",
          "video/mpeg",
          "video/ogg",
        ],
      })
    );
  };

  useEffect(() => {
    if (activeCampaignId && cardRefs.current) {
      const index = records?.findIndex(
        (record: any) => record.id === activeCampaignId
      );
      const refElem = cardRefs?.current[index];
      if (refElem && index !== -1) {
        refElem.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [activeCampaignId, records, cardRefs]);

  useEffect(() => {
    if (records && records.length > 0) {
      // Get the campaign ID from search params
      const searchId = searchParams.get("campaign_id");
      const isCampaignActive = records?.findIndex(
        (record: any) => record.id === searchId
      );
      if (isCampaignActive === -1) {
        setActiveCampaignId(records[0]?.id);
      } else if (searchId && isCampaignActive !== -1) {
        setActiveCampaignId(searchId);
      } else if (!activeCampaignId) {
        const firstCampaignId = records[0]?.id;
        setSearchParams({ campaign_id: firstCampaignId });
        setActiveCampaignId(firstCampaignId);
      }

      const idToFetch = activeCampaignId || records[0]?.id;
      dispatch(
        requestCampaignDetail(
          { id: idToFetch },
          role === ROLES.INFLUENCER
            ? URLRoutes.server.campaignInfluencerDetail
            : URLRoutes.server.campaignDetail
        )
      );
    }
  }, [records, dispatch, activeCampaignId, searchParams, role]);

  useEffect(() => {
    setFilters({});
    const requestParams: any = {
      page: currentPage,
      pageSize: 10,
    };

    if (role === ROLES.BRAND) {
      requestParams.brand = userId;
    }
    dispatch(
      requestCampaignsList(
        role === ROLES.INFLUENCER
          ? URLRoutes.server.getCampaignsInfl
          : URLRoutes.server.getCampaignsList,
        requestParams
      )
    );
  }, [message, videoMessage, guideLineMessage, applyMessage]);

  useEffect(() => {
    if (wasfecthing && !applyFetching && !applyError) {
      toast.success(applyMessage);
    } else if (applyError) {
      toast.error(applyMessage);
    }
  }, [applyFetching, applyError, message]);

  useEffect(() => {
    if (wasUploading && !videoFetching && !videoError) {
      toast.success(videoMessage);
    } else if (videoError) {
      toast.error(videoMessage);
    }
  }, [videoFetching, videoError, videoMessage]);

  useEffect(() => {
    if (wasUploadingGuideLine && !guideLinefetching && !guidelineError) {
      toast.success(guideLineMessage);
    } else if (guidelineError) {
      toast.error(guideLineMessage);
    }
  }, [guideLinefetching, guidelineError, guideLineMessage]);

  useEffect(() => {
    if (role !== ROLES.BRAND) {
      if (role === ROLES.INFLUENCER) {
        dispatch(requestBrandsList({ page: 1, pageSize: 50, userid: userId }));
      } else {
        dispatch(requestBrandsList({ page: 1, pageSize: 50 }));
      }
    }
    dispatch(requestGenreOptions());
  }, []);

  return (
    <HeaderLayout
      title={role === ROLES.HOB ? "Manage Campaigns" : "Campaigns"}
      contentClassName="flex width-100"
      breadcrumbs={[
        {
          text: "Dashboard",
          url:
            role === ROLES.HOB
              ? URLRoutes.clients.users.replace(":userType", ROLES.INFLUENCER)
              : role === ROLES.INFLUENCER
              ? URLRoutes.clients.instaDashboard
              : URLRoutes.clients.profile,
        },
        {
          text: role === ROLES.HOB ? "Manage Campaigns" : "Campaigns",
          url: URLRoutes.clients.campaignList,
        },
      ]}
      showPagination
      paginationProps={{
        totalPages: paginationInfo?.totalPages || 1,
        currentPage: currentPage,
        paginationChange: handlePaginationChange,
        disabled: data?.length === 0 || data?.records?.length === 0,
      }}
    >
      <div className="width-45 border-right border-grey-shade-1 height-100">
        <div className="width-100 border-bottom border-grey-shade-1 padding-4 flex flex-justify-between flex-align-center campaign-left-header">
          <CustomInput
            id="filter"
            placeholder="Search for Campaigns, Brands..."
            baseClassName="width-50 filter-input"
            iconProps={{
              name: CUSTOM_SVG_ICON.Search,
              svgType: SVGType.CUSTOM,
              size: "small",
            }}
            iconRight
            name="filter"
            initialValue={filters.search}
            onChange={handleFilterChange}
            disabled={!campaignDetail.id}
          />
          <div className="flex width-50 flex-justify-end ">
            <Dropdown
              placeholder="Sort"
              inline
              options={sortingFilterOptions}
              onChange={(event, data) =>
                handleSortingFilter(data.value as SortingOptions)
              }
              value={filters.sorting}
              className="margin-r-8"
              disabled={!campaignDetail.id && listError && error}
            />
            {role !== ROLES.BRAND && (
              <Dropdown
                placeholder="Filter By Brand"
                inline
                scrolling
                options={brandFilterOptions}
                onChange={(event, data) =>
                  handleBrandFilterChange(data.value as string)
                }
                value={filters.brand}
                disabled={!campaignDetail.id && listError && error}
              />
            )}
          </div>
        </div>
        <div
          className={classNames([
            "flex flex-column flex-align-center background-grey-shade5 campaign-left-content padding-4",
          ])}
        >
          {listFetching ? (
            <Loading isGlobal />
          ) : listError || error ? (
            <div className="text-5 text-grey-shade-4">
              {listMessage || message}
            </div>
          ) : records && records.length > 0 ? (
            records.map(renderCampaignList)
          ) : (
            listMessage
          )}
        </div>
      </div>
      <CampaignDetail
        guideLinefetching={guideLinefetching}
        campaignDetail={campaignDetail}
        campaignError={campaignError}
        campaignFetching={campaignFetching}
        campaignMessage={campaignMessage}
        listError={listError}
        listMessage={listMessage}
        handleApplyCampaign={handleApplyCampaign}
        handleDeleteGuideline={handleDeleteGuideline}
        handleReject={handleReject}
        handleUploadGuideLines={handleUploadGuideLines}
        handleVideoUpload={handleVideoUpload}
        generOptions={genreOptions}
      />
    </HeaderLayout>
  );
}

export default ManageCampaigns;
