import { Fragment } from "react";
import classNames from "classnames";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import TooltipText from "components/TooltipText";
import {
  IconWithTitle,
  StatusInfo,
  formatStartEndDate,
} from "../utils";
import { IStatus } from "utils/Enums";
import "./style.scss";

interface ICard {
  name: string;
  brandName: string;
  imageSrc: string;
  campaignStatus: IStatus;
  startDate: string;
  endDate: string;
  handleClick: () => void;
  usersCount: any;
  influencerCount: any;
  shortdescription: string;
  active?: boolean;
  isEnable: boolean;
  budget: any;
  activeCampaignRef: any;
}

function CampaignCard({
  imageSrc,
  brandName,
  campaignStatus,
  startDate,
  endDate,
  handleClick,
  name,
  shortdescription,
  usersCount,
  influencerCount,
  active,
  isEnable,
  budget,
  activeCampaignRef,
}: ICard) {
  return (
    <div
      ref={activeCampaignRef}
      className={classNames([
        "campaign-card padding-2 margin-b-4 cursor-pointer",
        { "campaign-card-active": active },
      ])}
      onClick={handleClick}
    >
      <div className="flex width-100 border-bottom border-grey-shade-1 padding-b-3">
        <div className="flex flex-align-center flex-justify-center background-secondary-color campaign-imgContainer">
          <img src={imageSrc} alt="image" />
        </div>
        <div className="width-85 flex flex-column campaign-info">
          <div className="flex flex-justify-between  flex-align-center padding-l-3 padding-t-1">
            <div className="campaign-name ">
              {name}
              <div className="campaign-subName">{brandName}</div>
            </div>
            <StatusInfo campaignStatus={campaignStatus} />
          </div>
          <div className="flex flex-justify-between flex-align-center width-100 padding-l-3 padding-t-1">
            <div className="width-50 flex flex-align-center">
              <SvgIcon
                name={CUSTOM_SVG_ICON.CalendarSmall}
                size={"small"}
                svgType={SVGType.CUSTOM}
                baseclassname={"text-default-color margin-r-1"}
              />
              <div className="campaign-date padding-t-2">
                {formatStartEndDate(startDate)} / {formatStartEndDate(endDate)}
              </div>
            </div>
            <div className="width-50 flex flex-align-center flex-justify-end">
              {isEnable && (
                <Fragment>
                  <div className="number-indicator">
                    {usersCount}
                  </div>
                  <span className="campaign-date border-right border-grey-shade-1 padding-l-1 padding-r-2">
                    Users
                  </span>
                  <div className="number-indicator margin-l-2">
                    {influencerCount}
                  </div>
                  <span className="campaign-date padding-l-1 padding-r-2">
                    Influencers
                  </span>
                </Fragment>
              )}
              {!isEnable && (
                <Fragment>
                  <IconWithTitle
                    iconName={CUSTOM_SVG_ICON.Budget}
                    title=""
                    subTitle={"₹" + `${budget}`}
                    baseClassName="width-30 no-padding-card"
                  />
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>

      <TooltipText
        value={shortdescription}
        baseClassName="campaign-description"
      />
    </div>
  );
}

export default CampaignCard;
