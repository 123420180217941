export default () => {
  return (
    <svg
      width="100%"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1107 7.44189C10.7158 6.82785 11.1259 6.04863 11.2895 5.20218C11.453 4.35573 11.3627 3.47982 11.0299 2.68455C10.6971 1.88928 10.1366 1.21014 9.4189 0.73249C8.70121 0.254841 7.85834 0 6.99623 0C6.13413 0 5.29126 0.254841 4.57357 0.73249C3.85588 1.21014 3.2954 1.88928 2.96258 2.68455C2.62976 3.47982 2.53944 4.35573 2.703 5.20218C2.86655 6.04863 3.27666 6.82785 3.88179 7.44189C2.73954 7.89091 1.75865 8.67293 1.06644 9.68644C0.37423 10.7 0.00267351 11.8981 1.40583e-07 13.1255C-6.50039e-05 13.2403 0.0225109 13.3541 0.066437 13.4602C0.110363 13.5663 0.174778 13.6628 0.255999 13.744C0.337219 13.8252 0.433652 13.8896 0.539784 13.9336C0.645917 13.9775 0.759666 14.0001 0.874529 14H13.1179C13.2328 14.0001 13.3466 13.9775 13.4527 13.9336C13.5588 13.8896 13.6552 13.8252 13.7365 13.744C13.8177 13.6628 13.8821 13.5663 13.926 13.4602C13.97 13.3541 13.9925 13.2403 13.9925 13.1255C13.9898 11.8981 13.6182 10.7 12.926 9.68644C12.2338 8.67293 11.2529 7.89091 10.1107 7.44189ZM6.99623 1.75659C7.51513 1.75659 8.02237 1.91046 8.45382 2.19874C8.88527 2.48703 9.22154 2.89678 9.42011 3.37617C9.61869 3.85557 9.67064 4.38309 9.56941 4.89201C9.46818 5.40094 9.21831 5.86842 8.85139 6.23533C8.48448 6.60225 8.017 6.85212 7.50807 6.95335C6.99914 7.05459 6.47163 7.00263 5.99223 6.80406C5.51283 6.60548 5.10308 6.26921 4.8148 5.83777C4.52652 5.40632 4.37265 4.89907 4.37265 4.38018C4.37342 3.6846 4.65008 3.01772 5.14193 2.52587C5.63378 2.03402 6.30065 1.75736 6.99623 1.75659ZM1.83618 12.2509C2.03938 11.264 2.57652 10.3773 3.35712 9.74009C4.13772 9.10292 5.11407 8.75425 6.1217 8.75282H7.87076C8.8784 8.75425 9.85475 9.10292 10.6354 9.74009C11.416 10.3773 11.9531 11.264 12.1563 12.2509H1.83618Z"
        fill="currentColor"
      />
    </svg>
  );
};
