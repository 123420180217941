import { Controller } from 'react-hook-form';
import classNames from "classnames";
import "./HookForms.scss";

export function HookFormTextarea(props: any) {
  const {
    label,
    placeholder,
    labelClassName,
    baseClassName,
    name,
    validation,
    control,
    errors,
    id,
    triggerOnChange,
    inputClassName , 
    required
  } = props;

  return (
    <div className={classNames(['flex flex-column custom-input', baseClassName])}>
      {label && <div className={classNames(["input-label padding-b-1", labelClassName])}>{label}
      { required && <span className={classNames(["position-absolute input-asterik text-danger-color "])}>&#8727;</span>}
        </div>}
     {/* {caption && <div className={classNames(["text-3", captionClassName])}>{caption}</div>} */}
      {control && (
        <Controller
          control={control}
          name={name}
          rules={validation || {}}
          render={({ field: { onChange, value, ref } }) => (
            <textarea
            placeholder={placeholder}
              value={value || ""}
              rows={10}
              id={id}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              className={classNames(
                "padding-2",
                inputClassName || ""
              )}
            />
          )}
        />
      )}
     {errors[name] && (
        <div className="text-3 text-danger-color padding-b-1 padding-l-1">{errors[name].message}</div>
      )}
    </div>
  );
}
