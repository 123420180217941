import { combineReducers } from 'redux';
import { IRextReducer } from '../baseProvider/rext';
import { adminInviteRext, campaignFormRext, createUserByRegistration, forgetPasswordRext, campaignsListRext, getDetailedProfileListRext, getGenreOptions, getInfluencerListRext, influencerDetailListRext, mobileVerifyRext, changePasswordRext, signUpByEmailRext, verifyOTPRext, bulkInviteUsersRext, userDetailRext, brandListRext, brandUserListRext, brandFormRext, brandUsersFormRext, campaignInfluencerDetailRext, allInfluencersListRext, postInfluencerRext, syncInstaMatrixRext, billingCampaignInfluencerRext, uploadGuideLinesRext, uploadVideoRext, videoListRext, favouritesListRext, favouritesRext, notificationRext, notificationListRext, chatNotificationRext, chatNotificationListRext, instaMatrixRext } from 'store/rext';
import authReducer, { IAuthState } from './auth';
import modalState, { ModalState } from './modal';


export interface ApplicationState {
  authState: IAuthState,
  modalState: ModalState;
  sendAdminInviteState: IRextReducer;
  verifyOTPState: IRextReducer;
  forgetPssswordState: IRextReducer;
  resetPasswordState: IRextReducer;
  campaignState: IRextReducer;
  influencerUsersListState: IRextReducer;
  campaignListState: IRextReducer;
  detailedProfileState: IRextReducer;
  influencerListState: IRextReducer;
  mobileVerifyState: IRextReducer;
  genreOptionsState: IRextReducer;
  signUpByEmailState: IRextReducer;
  createUserState: IRextReducer;
  bulkInviteUserState: IRextReducer;
  invitedUserState: IRextReducer;
  brandsListState: IRextReducer;
  currentBrandState: IRextReducer;
  brandUsersListState: IRextReducer;
  brandUserState: IRextReducer;
  campaignInfluencerListState: IRextReducer;
  allInfluencersListState: IRextReducer;
  postInfluencersState: IRextReducer;
  instaMatrixState: IRextReducer;
  billingInfluencersState: IRextReducer;
  uploadGuidelinesState: IRextReducer;
  uploadVideoState: IRextReducer;
  videoListState: IRextReducer;
  favouriteState: IRextReducer;
  favouritesListState: IRextReducer;
  notificationState: IRextReducer;
  notificationListState: IRextReducer;
  chatNotificationsState: IRextReducer;
  chatNotificationListState: IRextReducer;
  allMatrixState: IRextReducer;
}


const rootReducer = () =>
  combineReducers<ApplicationState>({
    authState: authReducer,
    modalState: modalState,
    sendAdminInviteState: adminInviteRext.reducers,
    verifyOTPState: verifyOTPRext.reducers,
    forgetPssswordState: forgetPasswordRext.reducers,
    resetPasswordState: changePasswordRext.reducers,
    campaignState: campaignFormRext.reducers,
    influencerUsersListState: influencerDetailListRext.reducers,
    campaignListState: campaignsListRext.reducers,
    detailedProfileState: getDetailedProfileListRext.reducers,
    influencerListState: getInfluencerListRext.reducers,
    mobileVerifyState: mobileVerifyRext.reducers,
    genreOptionsState: getGenreOptions.reducers,
    signUpByEmailState: signUpByEmailRext.reducers,
    createUserState: createUserByRegistration.reducers,
    bulkInviteUserState: bulkInviteUsersRext.reducers,
    invitedUserState: userDetailRext.reducers,
    brandsListState: brandListRext.reducers,
    brandUsersListState: brandUserListRext.reducers,
    currentBrandState: brandFormRext.reducers,
    brandUserState: brandUsersFormRext.reducers,
    campaignInfluencerListState: campaignInfluencerDetailRext.reducers,
    allInfluencersListState: allInfluencersListRext.reducers,
    postInfluencersState: postInfluencerRext.reducers,
    instaMatrixState: syncInstaMatrixRext.reducers,
    billingInfluencersState: billingCampaignInfluencerRext.reducers,
    uploadGuidelinesState: uploadGuideLinesRext.reducers,
    uploadVideoState: uploadVideoRext.reducers,
    videoListState: videoListRext.reducers,
    favouritesListState: favouritesListRext.reducers,
    favouriteState: favouritesRext.reducers,
    notificationState: notificationRext.reducers,
    notificationListState: notificationListRext.reducers,
    chatNotificationsState: chatNotificationRext.reducers,
    chatNotificationListState: chatNotificationListRext.reducers,
    allMatrixState: instaMatrixRext.reducers
  });

export default rootReducer;
