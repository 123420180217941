import { Fragment, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { URLRoutes } from "./URLRoutes";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getAuthState } from "store/selectors";
import { updateFCMToken } from "store/rext";
import { requestUserDetail } from "store/actions";
import Login from "containers/Login/Login";
import SignUp from "containers/SignUp/SignUp";
import ForgotPassword from "containers/ForgotPassword";
import VerifyOTP from "containers/VerifyOTP/VerifyOTP";
import Registration from "containers/Registration";
import Users from "containers/Users";
import InfluencerProfile from "containers/InfluencerProfile";
import EmailSignUp from "containers/SignUp/EmailSignUp";
import AdminInvite from "containers/Invite/AdminInvite";
import LayoutComponent from "components/LayoutComponent";
import MobileVerify from "containers/MobileVerify";
import ResetPassword from "containers/ResetPassword";
import DetailedProfile from "containers/DetailedProfile";
import ManageCampaigns from "containers/Campaigns/ManageCampaigns";
import ProtectedRoute from "components/ProtectedRoute";
import Loading from "components/Loading";
import InfluencerDetail from "containers/InfluencersDetail/InfluencerDetail";
import CampaignForm from "containers/Campaigns/CampaignForm";
import NotAllowed from "components/NotAllowed";
import BrandList from "containers/Brands/BrandList";
import ProfilePage from "containers/ProfilePage";
import BrandForm from "containers/Brands/BrandForm";
import BrandUserForm from "containers/BrandUsers/BrandUserForm";
import BrandUsersList from "containers/BrandUsers/BrandUsersList";
import { messaging } from "firebaseConfig";
import { useForegroundMessaging } from "HOC/UseForegroundMessage";
import { requestNotificationPermission } from "utils/utils";
import Notifications from "containers/Notifications";
import ExternalModalManager from "containers/ModalManager/ExternalModalManager";
import ModalManager from "containers/ModalManager";
import InfluencerList from "containers/InfluencerList";
import ChatNotifications from "containers/Notifications/ChatNotifications";
import FacebookAuthentication from "containers/SignUp/FacebookAuthentication";
import HelpContainer from "containers/Help";
import FAQContainer from "containers/FAQs";

function App() {
  const [checkToken, setCheckToken] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { token, fetchingUser, features, isAuthenticated, user } = useSelector(getAuthState);

  const updateNotificationToken = () => {
    requestNotificationPermission((token: string) => {
      dispatch(updateFCMToken({ token }));
    });
  };
  useForegroundMessaging(messaging);

  useEffect(() => {
    if (token) {
      dispatch(requestUserDetail());
      updateNotificationToken();
    }
    setCheckToken(true);
  }, []);

  if (fetchingUser) {
    return <Loading isGlobal />;
  }

  return (
    <Fragment>
      {checkToken && (
        <Routes>
          <Route path={URLRoutes.clients.login} element={<Login />} />
          <Route
            path={URLRoutes.clients.forgotPassword}
            element={<ForgotPassword />}
          />
          <Route path={URLRoutes.clients.signUp} element={<SignUp />} />
          <Route path={URLRoutes.clients.signUpWithId} element={<SignUp />} />
          <Route
            path={URLRoutes.clients.signUpEmail}
            element={<EmailSignUp />}
          />
          <Route
            path={URLRoutes.clients.signUpEmailWithId}
            element={<EmailSignUp />}
          />
           <Route
            path={URLRoutes.clients.facebookAuth}
            element={<FacebookAuthentication />}
          />
          <Route path={URLRoutes.clients.verifyOTP} element={<VerifyOTP />} />
          <Route
            path={URLRoutes.clients.registration}
            element={<Registration />}
          />
          <Route
            path={URLRoutes.clients.inviteRegister}
            element={<Registration />}
          />
          <Route
            path={URLRoutes.clients.resetPassword}
            element={<ResetPassword />}
          />
          <Route
            path={URLRoutes.clients.changePassword}
            element={<ResetPassword />}
          />
          <Route
            path={URLRoutes.clients.mobileVerify}
            element={<MobileVerify />}
          />
          <Route
            path={URLRoutes.clients.userInvite}
            element={<AdminInvite />}
          />
          <Route path={URLRoutes.clients.notAllowed} element={<NotAllowed />} />

          <Route
            path={URLRoutes.clients.home}
            element={
              <ProtectedRoute
                isFetching={fetchingUser}
                allowedFeatures={isAuthenticated}
              >
                <LayoutComponent />
              </ProtectedRoute>
            }
          >
            <Route path={URLRoutes.clients.home} />
            <Route
              path={URLRoutes.clients.users}
              element={
                <ProtectedRoute
                  isFetching={fetchingUser}
                  allowedFeatures={features?.getAllUserFeatures().isAllowed}
                >
                  <Users />
                </ProtectedRoute>
              }
            />
            <Route
              path={URLRoutes.clients.instaDashboard}
              element={
                <ProtectedRoute
                  isFetching={fetchingUser}
                  allowedFeatures={
                    features?.getSocialProfileFeature().isAllowed
                  }
                >
                  <InfluencerProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path={URLRoutes.clients.instaDashboardDetail}
              element={
                <ProtectedRoute
                  isFetching={fetchingUser}
                  allowedFeatures={features?.getSocialProfileFeature().detail}
                >
                  <DetailedProfile />
                </ProtectedRoute>
              }
            />
            <Route path={URLRoutes.clients.faq} element={<FAQContainer/>} />
            <Route path={URLRoutes.clients.help} element={<HelpContainer/>} />
            <Route
              path={URLRoutes.clients.createCampaign}
              element={
                <ProtectedRoute
                  isFetching={fetchingUser}
                  allowedFeatures={features?.getCampaignFeatures().addCampaign}
                >
                  <CampaignForm />
                </ProtectedRoute>
              }
            />
            <Route
              path={URLRoutes.clients.updateCampaign}
              element={
                <ProtectedRoute
                  isFetching={fetchingUser}
                  allowedFeatures={features?.getCampaignFeatures().editCampaign}
                >
                  <CampaignForm />
                </ProtectedRoute>
              }
            />
            <Route
              path={URLRoutes.clients.campaignList}
              element={
                <ProtectedRoute
                  isFetching={fetchingUser}
                  allowedFeatures={features?.getCampaignFeatures().isAllowed}
                >
                  <ManageCampaigns />
                </ProtectedRoute>
              }
            />
            <Route
              path={URLRoutes.clients.influencerDetail}
              element={
                <ProtectedRoute
                  isFetching={fetchingUser}
                  allowedFeatures={
                    features?.getInfluencerDetailFeature().isAllowed
                  }
                >
                  <InfluencerDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path={URLRoutes.clients.profile}
              element={
                <ProtectedRoute
                  isFetching={fetchingUser}
                  allowedFeatures={features?.getProfileFeature().isAllowed}
                >
                  <ProfilePage />
                </ProtectedRoute>
              }
            />
             <Route
              path={URLRoutes.clients.profilePage}
              element={
                <ProtectedRoute
                  isFetching={fetchingUser}
                  allowedFeatures={features?.getProfileFeature().isAllowed}
                >
                  <ProfilePage />
                </ProtectedRoute>
              }
            />
            <Route
              path={URLRoutes.clients.createBrand}
              element={
                <ProtectedRoute
                  isFetching={fetchingUser}
                  allowedFeatures={features?.getBrandFeatures().createBrand}
                >
                  <BrandForm />
                </ProtectedRoute>
              }
            />
            <Route
              path={URLRoutes.clients.updateBrand}
              element={
                <ProtectedRoute
                  isFetching={fetchingUser}
                  allowedFeatures={features?.getBrandFeatures().editBrand}
                >
                  <BrandForm />
                </ProtectedRoute>
              }
            />
            <Route
              path={URLRoutes.clients.brandList}
              element={
                <ProtectedRoute
                  isFetching={fetchingUser}
                  allowedFeatures={features?.getBrandFeatures().isAllowed}
                >
                  <BrandList />
                </ProtectedRoute>
              }
            />
            <Route
              path={URLRoutes.clients.createBrandUser}
              element={
                <ProtectedRoute
                  isFetching={fetchingUser}
                  allowedFeatures={
                    features?.getBrandUserFeatures().createBrandUser
                  }
                >
                  <BrandUserForm />
                </ProtectedRoute>
              }
            />
            <Route
              path={URLRoutes.clients.updateBrandUser}
              element={
                <ProtectedRoute
                  isFetching={fetchingUser}
                  allowedFeatures={
                    features?.getBrandUserFeatures().editBrandUser
                  }
                >
                  <BrandUserForm />
                </ProtectedRoute>
              }
            />
            <Route
              path={URLRoutes.clients.brandUsersList}
              element={
                <ProtectedRoute
                  isFetching={fetchingUser}
                  allowedFeatures={features?.getBrandUserFeatures().isAllowed}
                >
                  <BrandUsersList />
                </ProtectedRoute>
              }
            />
            <Route
              path={URLRoutes.clients.notifications}
              element={<Notifications />}
            />
              <Route
              path={URLRoutes.clients.chatNotifications}
              element={<ChatNotifications />}
            />
            <Route
              path={URLRoutes.clients.influencerList}
              element={ <ProtectedRoute
                isFetching={fetchingUser}
                allowedFeatures={features?.getAllInfluencersFeature().isAllowed}
              >
                <InfluencerList />
              </ProtectedRoute>}
            />
          </Route>
        </Routes>
      )}
      <ToastContainer />
      <ModalManager />
      <ExternalModalManager/>
    </Fragment>
  );
}

export default App;
