import { createRext, IRext } from 'store/baseProvider/rext';
import { getBaseUrl, getToken } from 'store/selectors';


export const mobileVerifyRext: IRext = createRext ({
  identity: 'mobileVerifyRext',
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})

export const adminInviteRext: IRext = createRext ({
  identity: 'adminInviteRext',
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})
export const verifyOTPRext: IRext = createRext ({
  identity: 'verifyOTPRext',
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})

export const forgetPasswordRext: IRext = createRext ({
  identity: 'forgetPasswordRext',
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})
export const changePasswordRext: IRext = createRext ({
  identity: 'resetPasswordRext',
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})

export const influencerDetailListRext: IRext = createRext ({
  identity: 'influencerDetailListRext',
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})


export const getDetailedProfileListRext : IRext = createRext ({
  identity: 'getDetailedProfileListRext',
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})

export const getInfluencerListRext : IRext = createRext ({
  identity: 'getInfluencerListRext',
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})

//CampaignRext
export const campaignsListRext : IRext = createRext ({
  identity: 'campaignsListRext',
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})
export const campaignFormRext : IRext = createRext ({
  identity: 'campaignFormRext',
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})
//

export const getGenreOptions : IRext = createRext ({
  identity: 'getGenreOptions',
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})

export const signUpByEmailRext : IRext = createRext ({
  identity: 'signUpByEmailRext',
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})


export const createUserByRegistration : IRext = createRext ({
  identity: 'createUserByRegistration',
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})

export const bulkInviteUsersRext : IRext = createRext ({
  identity: 'bulkInviteUsersRext',
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})

export const userDetailRext : IRext = createRext ({
  identity: 'userDetailRext',
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})

export const brandListRext : IRext = createRext ({
  identity: 'brandListRext',
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})

export const brandFormRext : IRext = createRext ({
  identity: 'brandFormRext',
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})


export const brandUserListRext: IRext = createRext({
  identity: "brandUserListRext",
  getBaseUrl: getBaseUrl,
  getToken: getToken
})

export const brandUsersFormRext : IRext = createRext ({
  identity: 'brandUsersFormRext',
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})


//Campaign InfluencerDetail
export const campaignInfluencerDetailRext : IRext = createRext ({
  identity: 'campaignInfluencerDetailRext',
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})

export const allInfluencersListRext : IRext = createRext ({
  identity: 'allInfluencersListRext',
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})

export const postInfluencerRext : IRext = createRext ({
  identity: 'postInfluencerRext',
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})

export const syncInstaMatrixRext: IRext = createRext({
  identity: "syncInstaMatrixRext" , 
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})

export const billingCampaignInfluencerRext: IRext = createRext({
  identity: "billingCampaignInfluencerRext" , 
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})

export const uploadGuideLinesRext: IRext = createRext({
  identity: "uploadGuideLinesRext" , 
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})

export const uploadVideoRext: IRext = createRext({
  identity: "uploadVideoRext" , 
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})

export const videoListRext: IRext = createRext({
  identity: "videoListRext" , 
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})

export const favouritesRext: IRext = createRext({
  identity: "favouritesRext" , 
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})

export const favouritesListRext: IRext = createRext({
  identity: "favouritesListRext" , 
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})

export const notificationRext: IRext = createRext({
  identity: "notificationRext" , 
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})

export const notificationListRext: IRext = createRext({
  identity: "notificationListRext" , 
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})

export const chatNotificationRext: IRext = createRext({
  identity: "chatNotificationRext" , 
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})

export const chatNotificationListRext: IRext = createRext({
  identity: "chatNotificationListRext" , 
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})

export const instaMatrixRext: IRext = createRext({
  identity: "instaMatrixRext" , 
  getBaseUrl: getBaseUrl,
  getToken: getToken,
})