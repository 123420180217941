export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0C3.13833 0 0 3.13833 0 7C0 10.8617 3.13833 14 7 14C10.8617 14 14 10.8617 14 7C14 3.13833 10.8617 0 7 0ZM7 12.8333C3.78 12.8333 1.16667 10.22 1.16667 7C1.16667 3.78 3.78 1.16667 7 1.16667C10.22 1.16667 12.8333 3.78 12.8333 7C12.8333 10.22 10.22 12.8333 7 12.8333Z"
        fill="currentColor"
      />
      <path
        d="M7.58333 5C7.25667 5 7 5.25667 7 5.58333V9.55C7 9.87667 7.25667 10.1333 7.58333 10.1333C7.91 10.1333 8.16667 9.87667 8.16667 9.55V5.58333C8.16667 5.26833 7.91 5 7.58333 5Z"
        fill="currentColor"
      />
      <path
        d="M7.595 3.00122C7.29167 2.97789 7.01167 3.29289 7 3.60789C7 3.61955 7 3.68955 7 3.70122C7 4.01622 7.245 4.24955 7.57167 4.26122H7.58333C7.89833 4.26122 8.155 3.98122 8.16667 3.67789C8.16667 3.66622 8.16667 3.54955 8.16667 3.54955C8.16667 3.21122 7.92167 3.00122 7.595 3.00122Z"
        fill="currentColor"
      />
    </svg>
  );
};
