export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.50806 9.39994C9.79018 8.59794 10.347 7.92167 11.08 7.4909C11.813 7.06013 12.6747 6.90266 13.5127 7.04639C14.3506 7.19012 15.1106 7.62577 15.6581 8.27617C16.2057 8.92658 16.5053 9.74977 16.5041 10.5999C16.5041 12.9999 12.9041 14.1999 12.9041 14.1999"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 19H13.012"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
