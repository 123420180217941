export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0ZM12 22C10.0222 22 8.08879 21.4135 6.4443 20.3147C4.79981 19.2159 3.51809 17.6541 2.76121 15.8268C2.00433 13.9996 1.8063 11.9889 2.19215 10.0491C2.578 8.10929 3.53041 6.32746 4.92894 4.92893C6.32746 3.53041 8.10929 2.578 10.0491 2.19215C11.9889 1.8063 13.9996 2.00433 15.8268 2.7612C17.6541 3.51808 19.2159 4.79981 20.3147 6.4443C21.4135 8.08879 22 10.0222 22 12C21.9971 14.6513 20.9426 17.1931 19.0679 19.0679C17.1931 20.9426 14.6513 21.9971 12 22Z"
        fill="currentColor"
      />
      <path
        d="M16.293 8.293L9.99998 14.586L7.70698 12.293C7.51838 12.1108 7.26578 12.01 7.00358 12.0123C6.74138 12.0146 6.49057 12.1198 6.30516 12.3052C6.11975 12.4906 6.01458 12.7414 6.01231 13.0036C6.01003 13.2658 6.11082 13.5184 6.29298 13.707L9.29298 16.707C9.48051 16.8945 9.73482 16.9998 9.99998 16.9998C10.2651 16.9998 10.5195 16.8945 10.707 16.707L17.707 9.70699C17.8891 9.51839 17.9899 9.26579 17.9877 9.00359C17.9854 8.7414 17.8802 8.49058 17.6948 8.30518C17.5094 8.11977 17.2586 8.0146 16.9964 8.01232C16.7342 8.01004 16.4816 8.11084 16.293 8.293Z"
        fill="currentColor"
      />
    </svg>
  );
};
