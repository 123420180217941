import CustomButton from "components/CustomButton";
import React from "react";
import { Modal } from "semantic-ui-react";
import { ModalHeader } from "../Common";

interface IProps {
  hideModal: () => void;
  onClose: () => void;
  onSave: () => void;
  title: string;
  message: string;
  primaryBtnTitle?: string;
  secondaryBtnTitle?: string;
}

const TitleDescription = ({
  title,
  desc,
}: {
  title: string;
  desc: React.ReactNode;
}) => {
  return (
    <div className="flex flex-column width-100 padding-b-2">
      <div className="text-bold padding-b-2">{title}</div>
      {desc}
    </div>
  );
};

function DetailedTermModal(props: IProps) {
  const {
    message,
    title,
    secondaryBtnTitle,
    primaryBtnTitle,
    hideModal,
    onSave,
    onClose,
  } = props;

  return (
    <Modal
      size="large"
      dimmer
      open
      onClose={onClose}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <ModalHeader title={title} toggle={onClose} />
      <Modal.Content scrolling>
        <div className="flex flex-column width-100 height-100 ">
          <div className="text-5 text-bold padding-b-4">
            Highonbuzz Engagement Pvt Ltd - Terms of Service
          </div>
          <div className="padding-b-2">
            <span className="text-bold ">Effective Date: </span>Upon user
            registration on BuzzPRIME.
          </div>
          <div className="padding-b-2 ">
            These Terms of Service ("Terms") govern the access to and use of the{" "}
            <span className="text-bold">BuzzPRIME</span> platform, operated by {" "}
            <span className="text-bold">
              Highonbuzz Engagement Pvt Ltd
            </span>{" "}
            ("Company", "We", or "Us"). By accessing or using our services,
            users agree to these Terms, along with any future amendments as
            posted.
          </div>
          <TitleDescription
            title="Applicable Jurisdiction"
            desc={
              <div className="">
                The Terms of Service are governed by and construed in accordance
                with the laws of <span className="text-bold">India</span>, with{" "}
                <span className="text-bold">Haryana</span> as the exclusive
                jurisdiction for all disputes arising from the use of the
                platform
              </div>
            }
          />
          <TitleDescription
            title="Eligibility"
            desc={
              <div>
                <li>
                  Users must be at least{" "}
                  <span className="text-bold">18 years old</span> to sign up for
                  and access the BuzzPRIME platform.
                </li>
                <li>
                  Users must reside within{" "}
                  <span className="text-bold">India</span> and must be legally
                  recognized as adults in their respective locations.
                </li>
              </div>
            }
          />
          <TitleDescription
            title="Services Provided"
            desc={
              <div className="">
                BuzzPRIME offers services related to{" "}
                <span className="text-bold">influencer marketing,</span>{" "}
                including but not limited to:
                <li>Influencer discovery</li>
                <li>Campaign management</li>
                <li>Performance tracking</li>
                Please note that currently, there are no premium or paid
                services, but this may be introduced at a later stage.
              </div>
            }
          />
          <TitleDescription
            title="User Obligations"
            desc={
              <div>
                <li>
                  Users are allowed to upload content, specifically content they
                  create as part of their collaboration with brands for
                  campaigns. Such content may include text, images, and videos,
                  subject to the terms of the brand's campaign brief.
                </li>
                <li>
                  Users are expected to comply with all platform policies,
                  including non-involvement in activities that are illegal,
                  offensive, or violate any terms set forth by BuzzPRIME.
                </li>
              </div>
            }
          />

          <TitleDescription
            title="Subscriptions & Payments"
            desc={
              <div>
                <li>
                  Currently, BuzzPRIME does not charge subscription fees for
                  access or services. In the future, should any fees be
                  introduced, users will be notified in advance.
                </li>
                <li>
                  No refunds will be applicable at this stage due to the absence
                  of paid services.
                </li>
              </div>
            }
          />
          <TitleDescription
            title="Termination Clause"
            desc={
              <div className="">
                We reserve the right to terminate or suspend accounts under the
                following circumstances:
                <li>Posting offensive or inappropriate content</li>
                <li>Violating any applicable policies or legal requirements</li>
                <li>Engaging in illegal activities or misconduct</li>
                <li>
                  At the sole discretion of{" "}
                  <span className="text-bold">
                    Highonbuzz Engagement Pvt Ltd.
                  </span>
                </li>
                Users may or may not be notified of termination, depending on
                the violation's nature. Notification, if sent, will be through
                the registered email provided at sign-up.
              </div>
            }
          />
          <TitleDescription
            title="User Data Collection"
            desc={
              <div>
                <li>
                  We collect personal data, such as name, email address,
                  location, and other identifiers required to facilitate brand
                  collaborations.
                </li>
                <li>
                  This data may be shared with brands seeking to collaborate
                  with influencers on BuzzPRIME. Your data will only be shared
                  with third-party entities (brands) with your consent for
                  potential collaboration opportunities.
                </li>
                <li>
                  Our data collection practices are detailed in our{" "}
                  <span className="text-bold">Privacy Policy,</span> embedded
                  within these Terms of Service.
                </li>
              </div>
            }
          />
          <TitleDescription
            title="Third-Party Links & Tools"
            desc={
              <div className="">
                The platform may integrate third-party services and include
                links to third-party websites. By accessing such services, you
                agree that Highonbuzz is not responsible for the accuracy or
                safety of those external websites.
              </div>
            }
          />
          <TitleDescription
            title="Limitation of Liability"
            desc={
              <div className="">
                Highonbuzz will not be held liable for any losses, damages, or
                interruptions resulting from:
                <li>
                  Downtime or technical failures of the BuzzPRIME platform
                </li>
                <li>User-uploaded content</li>
                <li>Third-party service interactions.</li>
                <li>
                  At the sole discretion of Highonbuzz Engagement Pvt Ltd.
                </li>
                We provide BuzzPRIME on an "as is" basis, with no warranties or
                guarantees of uninterrupted service.{" "}
                <span className="text-bold">
                  Users are fully responsible for their own interactions and
                  campaigns.
                </span>
              </div>
            }
          />
          <TitleDescription
            title="Indemnification"
            desc={
              <div>
                By agreeing to these Terms, users agree to indemnify and hold
                harmless
                <span className="text-bold margin-l-1 margin-r-1">
                  Highonbuzz Engagement Pvt Ltd,
                </span>
                its officers, employees, and affiliates from any legal claims,
                damages, or losses resulting from misuse of the BuzzPRIME
                platform.
              </div>
            }
          />
          <TitleDescription
            title="Dispute Resolution"
            desc={
              <div className="">
                In case of any disputes, users agree to resolve such disputes
                through <span className="text-bold">binding arbitration.</span>{" "}
                All disputes will be governed by the jurisdiction of the courts
                in Haryana, India, unless otherwise mutually agreed.
              </div>
            }
          />
          <TitleDescription
            title="Changes to Terms"
            desc={
              <div className="">
                We reserve the right to modify or update these Terms at any
                time. Any significant changes will be posted on our website, and
                users will be notified via email. The changes will become
                effective <span className="text-bold">one week</span> after
                notification. Continued use of the platform after that period
                implies acceptance of the updated terms.
              </div>
            }
          />
          <TitleDescription
            title="Contact Information"
            desc={
              <div className="flex flex-column  width-100">
                <div>
                  For any questions, concerns, or clarifications regarding these
                  Terms, users may contact us at:
                </div>
                <div>
                  <span className="text-bold margin-r-1">Email:</span>
                  <a href="mailto:support@highonbuzz.com">support@highonbuzz.com</a>
                </div>
              </div>
            }
          />
        </div>
      </Modal.Content>
      <Modal.Actions className="flex flex-justify-end flex-align-center ">

  <CustomButton
    textSmall
    primaryButton
    buttonText={primaryBtnTitle || "OK"}
    handleClick={onSave}
  />
  {onClose && secondaryBtnTitle && (
    <CustomButton
      textSmall
      secondaryButton
      buttonText={secondaryBtnTitle || "Cancel"}
      handleClick={onClose}
      baseclassname={"margin-l-2"}
    />
  )}
{/* </div> */}
</Modal.Actions>
    </Modal>
  );
}

export default DetailedTermModal;
