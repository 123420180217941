import React from "react";
import classNames from "classnames";
import AutoComplete from "react-google-autocomplete";
import { Controller } from "react-hook-form";
import SvgIcon, { SVGType } from "components/SvgIcon";
import "./HookForms.scss";

interface IProps {
  label?: string;
  baseClassName?: string;
  name: string;
  validation?: any;
  control: any;
  errors: any;
  inputClassName?: string;
  id: string;
  placeholder?: string;
  onSelect: (place: any) => void;
  handleChange?: (value: string) => void;
  iconProps?: {
    svgType: SVGType;
    name: any;
    size?: string;
    baseclassname?: string;
    inverted?: boolean;
  };
  required?: boolean;
}

export function HookFormAddressAutoComplete({
  label,
  baseClassName,
  name,
  validation,
  control,
  errors,
  inputClassName,
  id,
  onSelect,
  iconProps,
  handleChange,
  placeholder,
  required
}: IProps) {
  const handleAutoPopulatedAddress = (place: any) => {
    console.log(place);
    const { formatted_address, address_components } = place;
    onSelect(address_components);
    if(typeof handleChange === "function") {
      handleChange(formatted_address);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const getErrorMessage = (field: string) => {
    const errorPath = field.split(".");
    let errorMessage = "";
    let currentError = errors as any;

    for (const path of errorPath) {
      if (currentError && currentError[path]) {
        currentError = currentError[path];
      } else {
        currentError = undefined;
        break;
      }
    }
    if (currentError) {
      errorMessage = currentError.message;
    }
    return errorMessage;
  };

  return (
    <div
      className={classNames([
        "flex flex-column custom-input position-relative",
        baseClassName,
      ])}
    >
      {label && (
        <div className="input-label padding-b-1">
          {label}
          {required && (
            <span
              className={classNames([
                "position-absolute input-asterik text-danger-color ",
              ])}
            >
              &#8727;
            </span>
          )}
        </div>
      )}
      <Controller
        control={control}
        name={name}
        rules={validation || {}}
        render={({ field: { onChange, value } }) => (
          <>
            {iconProps && (
              <SvgIcon
                {...iconProps}
                baseclassname={classNames([
                  { "input-icon-stable": errors[name] },
                  { "input-icon": !errors[name] },
                  iconProps.baseclassname || "",
                ])}
              />
            )}
            <AutoComplete
              name={name}
              value={value || ""}
              apiKey="AIzaSyCszUs2b8Ga3jGiafM0lBKUWcBXXxfcRXk"
              onPlaceSelected={handleAutoPopulatedAddress}
              options={{
                types: [],
                componentRestrictions: { country: "in" },
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChange(e.target.value);
                if (typeof handleChange === "function") {
                  handleChange(e.target.value);
                }
              }}
              inputAutocompleteValue={value || ""}
              placeholder={placeholder || "Enter address"}
              onKeyDown={handleKeyDown}
            />
          </>
        )}
      />
      {getErrorMessage(name) && (
        <div className="text-3 text-danger-color padding-b-1 padding-l-1">
          {getErrorMessage(name)}
        </div>
      )}{" "}
    </div>
  );
}
