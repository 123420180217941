import  { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import AuthLayout from "components/AuthLayout";
import CustomButton from "components/CustomButton";
import { HookFormInput, HookPhoneInput } from "components/FormInputs";
import { requestAdminInvite } from "store/rext";
import { getAdminInviteState } from "store/selectors";
import UsePrevious from "HOC/UsePrevious";
import {
  hookEmailPatternRequired,
  hookPatternRequired,
  hookPhoneNumberValidation,
  hookformRequired,
} from "utils/FormValidations";
import { toast } from "react-toastify";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import "./style.scss";

const requiredValidation = {
  ...hookEmailPatternRequired("Invalid email address"),
};

const phoneNumberValidation = {
  ...hookPhoneNumberValidation(),
};

function AdminInvite() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data: formData,
    fetching,
    error,
    message,
  } = useSelector(getAdminInviteState);
  const wasfecthing = UsePrevious(fetching);

  const {
    handleSubmit,
    formState: { errors , isSubmitSuccessful },
    control,
    watch
  } = useForm({});

  const watchedEmail: boolean = watch("email");
  const watchedPhone: boolean = watch("phone");
  const isEitherFieldFilled = watchedEmail || watchedPhone;

  const handleOnSubmit = (data: any) => { 
    const body: any = {};
    if (watchedEmail && data.email) {
      body.email = data.email;
    }

    if (watchedPhone && data.phone) {
      body.phone = data.phone;
    }
    dispatch(requestAdminInvite(body));
  
  };

  useEffect(() => {
    if (isSubmitSuccessful && !fetching && !error) {
      toast.success(message);
      if (formData?.id) {
        navigate(`/registration/${formData.id}/invite`);
      }
    } else if (error && isEitherFieldFilled && isSubmitSuccessful ) {
      toast.error(message);
    }
  }, [fetching , error , message]);


  return (
    <AuthLayout
      image="/images/womanSunglasses.png"
      imagePosition="right"
      title="Discover the world’s top Influencers."
      titlePosition="top"
      contentClassName="flex-justify-center flex-align-center"
      bannerClassName="background-linear1"
      GoBack
    >
   
      <div className="width-60">
        <img src="/images/logo.png" style={{ width: "100%", height: "100%" }} />
      </div>
      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        className="width-55 padding-t-8"
      >
        <HookFormInput
          validation={requiredValidation}
          control={control}
          errors={errors}
          label="User Email"
          id="email"
          name="email"
          placeholder="Enter your email here..."
        />
        <div className="border-bottom border-grey-shade-1 width-100 margin-t-6 margin-b-6 position-relative">
          <span className="middle-text">or</span>
        </div>
        <HookPhoneInput
          control={control}
          errors={errors}
          label="User WhatsApp Number"
          id="phone"
          name="phone"
          placeholder="Enter your WhatsApp number here..."
          baseClassName="margin-b-3"
          validation={phoneNumberValidation}
        />
        <CustomButton
          type="submit"
          buttonText="SEND INVITATION"
          round
          primaryButton
          baseclassname={"width-100"}
          disabled={!isEitherFieldFilled}
          loading={fetching}
        />
      </form>
    </AuthLayout>
  );
}

export default AdminInvite;
