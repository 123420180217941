import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import {  adminInviteRext, campaignFormRext, createUserByRegistration, forgetPasswordRext, campaignsListRext, getDetailedProfileListRext, getGenreOptions, getInfluencerListRext, influencerDetailListRext, mobileVerifyRext, changePasswordRext, signUpByEmailRext, verifyOTPRext, bulkInviteUsersRext, userDetailRext, brandListRext, brandFormRext, brandUserListRext, brandUsersFormRext, campaignInfluencerDetailRext, allInfluencersListRext, postInfluencerRext, syncInstaMatrixRext, billingCampaignInfluencerRext, uploadGuideLinesRext, uploadVideoRext, videoListRext, favouritesListRext, favouritesRext, notificationRext, notificationListRext, chatNotificationRext, chatNotificationListRext, instaMatrixRext
} from '../rext';
import { watchAuthEvent } from './auth';

export default function* root(): SagaIterator {
  yield all([
    fork(adminInviteRext.saga),
    fork(verifyOTPRext.saga),
    fork(forgetPasswordRext.saga),
    fork(changePasswordRext.saga),
    fork(influencerDetailListRext.saga),
    fork(campaignsListRext.saga),
    fork(getDetailedProfileListRext.saga),
    fork(getInfluencerListRext.saga),
    fork(campaignFormRext.saga),
    fork(mobileVerifyRext.saga),
    fork(getGenreOptions.saga),
    fork(signUpByEmailRext.saga),
    fork(createUserByRegistration.saga),
    fork(bulkInviteUsersRext.saga),
    fork(userDetailRext.saga),
    fork(brandListRext.saga),
    fork(brandFormRext.saga),
    fork(brandUserListRext.saga),
    fork(brandUsersFormRext.saga),
    fork(campaignInfluencerDetailRext.saga),
    fork(allInfluencersListRext.saga),
    fork(postInfluencerRext.saga),
    fork(syncInstaMatrixRext.saga),
    fork(billingCampaignInfluencerRext.saga),
    fork(uploadGuideLinesRext.saga),
    fork(uploadVideoRext.saga),
    fork(videoListRext.saga),
    fork(favouritesListRext.saga),
    fork(favouritesRext.saga),
    fork(notificationRext.saga),
    fork(notificationListRext.saga),
    fork(chatNotificationRext.saga),
    fork(chatNotificationListRext.saga),
    fork(instaMatrixRext.saga),
    fork(watchAuthEvent)
  ]);
}