import { forwardRef } from "react";
import { Controller } from "react-hook-form";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import DateRangeHeader from "./DateRangeHeader";
import "react-datepicker/dist/react-datepicker.css";
import "./HookForms.scss";

interface IProps {
  label: string;
  labelClassName?: string;
  baseClassName?: string;
  name: string;
  validation?: any;
  control: any;
  errors: any;
  inputClassName?: any;
  id: any;
  triggerOnChange?: any;
  placeholder: string;
  dateTimePicker?: boolean;
  isClearable?: boolean;
  minDate?: any;
  maxDate?:any;
  required?: boolean;
}

export function HookFormDatePicker(props: IProps) {
  const {
    label,
    labelClassName,
    baseClassName,
    name,
    validation,
    control,
    errors,
    inputClassName,
    id,
    triggerOnChange,
    placeholder,
    dateTimePicker = false,
    isClearable,
    minDate,
    maxDate,
    required
  } = props;

  const CustomInput = forwardRef(({ value, onClick } : any, ref:any) => (
    <input
      ref={ref}
      value={value || ""}
      onClick={onClick}
      className={classNames("datepicker-input width-100", inputClassName)}
      readOnly
      placeholder={placeholder}
      id={id}
    />
  ));

  return (
    <div
      className={classNames(["flex flex-column custom-input position-relative", baseClassName])}
    >
      {label && (
        <div
          className={classNames(["input-label padding-b-1", labelClassName])}
        >
          {label}
          { required && <span className={classNames(["position-absolute input-asterik text-danger-color "])}>&#8727;</span>}
        </div>
      )}
      {control && (
        <Controller
          control={control}
          name={name}
          rules={validation || {}}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              renderCustomHeader={DateRangeHeader}
              selected={value}
              onChange={(date) => {
                onChange(date);
                if (typeof triggerOnChange === "function") {
                  triggerOnChange(date);
                }
              }}
              showIcon={!value}
              icon={
                <SvgIcon
                  svgType={SVGType.CUSTOM}
                  name={CUSTOM_SVG_ICON.Calendar}
                  size={"small"}
                  baseclassname={"date-icon"}
                />
              }
              toggleCalendarOnIconClick
              shouldCloseOnSelect={false}
              dateFormat={dateTimePicker ? "dd/MM/yyyy HH:mm:aa" : "dd/MM/yyyy"}
              placeholderText={placeholder}
              id={id}
              showTimeInput={dateTimePicker}
              isClearable={value}
              className={classNames(
                "width-100 position-relative",
                inputClassName || ""
              )}
              minDate={minDate}
              maxDate={maxDate}
              customInput={<CustomInput/>}
            />
          )}
        />
      )}
      {errors[name] && (
        <div className="text-3 text-danger-color padding-b-1 padding-l-1">
          {errors[name].message}
        </div>
      )}
    </div>
  );
}
