import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Dropdown } from "semantic-ui-react";
import CustomTable from "components/CustomTable";
import { IColumns, ISortingCriteria } from "components/CustomTable/CustomTable";
import CustomButton from "components/CustomButton";
import {
  emailNumberOptions,
  registrationStatusOptions,
  formatDate,
  formatNumber,
  debounce,
} from "utils/utils";
import { URLRoutes } from "URLRoutes";
import {
  getAdminInviteState,
  getBulkInviteUserState,
  getInfluencerDetailList,
} from "store/selectors";
import {
  requestBulkInviteUsers,
  requestInfluencerUsersList,
  requestReInvite,
  sendInviteToMultipleUsers,
  sendInviteUser,
} from "store/rext";
import { modalOperation } from "store/actions";
import { ModalsType } from "containers/ModalManager/ModalManager";
import { CustomInput } from "components/CustomInputs";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import UsePrevious from "HOC/UsePrevious";
import { profileStatus } from "utils/Enums";
import Loading from "components/Loading";
import "./style.scss";

interface IFilterValues {
  search?: string;
  userStatus?: string;
  isEmailAvailable?: string;
  isWhatsAppAvailable?: string;
}

function Users() {
  const navigate = useNavigate();
  const { userType } = useParams();
  const dispatch = useDispatch();
  const {
    data,
    fetching,
    error: listError,
    message: listMessage,
  } = useSelector(getInfluencerDetailList);
  const {records , paginationInfo} = data || {};
  const {
    data: bulkData,
    message,
    fetching: bulkFetching,
    error,
  } = useSelector(getBulkInviteUserState);
  const wasfecthing = UsePrevious(bulkFetching);
  const {
    message: adminMessage,
    fetching: adminFetching,
    error: adminError,
  } = useSelector(getAdminInviteState);
  const wasInviting = UsePrevious(adminFetching);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [loadingRowId, setLoadingRowId] = useState<string | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<IFilterValues>({});
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
  const [currentPage , setCurrentPage] = useState<number>(1);

  const allProfilesCompleted = records?.every((item: any) => item.profilestatus === profileStatus.COMPLETED);
  
  const columns: IColumns[] = [
    {
      key: allProfilesCompleted ? "" :  "selectCheckbox",
      header: "",
      customValue: (data: any) =>
        data.profilestatus === profileStatus.COMPLETED ? (
          <div></div>
        ) : (
          <div className="flex flex-row flex-align-center width-100">
            <input
              type="checkbox"
              checked={selectedRows.includes(data.id)}
              onChange={() => handleCheckboxSelect(data)}
              className="width-100"
            />
          </div>
        ),
    },
    {
      key: "name",
      header: "Name",
      sort: true,
      sortKey: "name",
      customValue: (data: any) =>
        data.instahandle && data.isvalidated ? (
          <Link to={URLRoutes.clients.profilePage.replace(":id", data.id)}>
            {data.name}
          </Link>
        ) : (
          <Link className="text-danger-color" to={URLRoutes.clients.profilePage.replace(":id", data.id)}>
            {data.name}
          </Link>
          // <div className="text-danger-color">{data.name}</div>
        ),
    },
    {
      key: "instahandle",
      header: "Instagram ID",
      sort: true,
      sortKey: "instahandle",
      customValue: (data: any) => 
        !data.isvalidated && data.instahandle ? (
          <div className="text-danger-color">{`@${data.instahandle}`}</div>
        ) : (
          `@${data.instahandle}` || "-"
        ) 
      },
    {
      key: "followers_count",
      header: "Followers",
      sort: true,
      sortKey: "followers_count",
      customValue: (data: any) => 
        !data.isvalidated  ? (
          <div className="text-danger-color">{formatNumber(data.followers_count)}</div>
        ) : (
          formatNumber(data.followers_count) || "-"
        ) 
      // customValue: (data: any) =>
      //   data.followers_count ? formatNumber(data.followers_count) : "-",
    },
    {
      key: "avglikes",
      header: "Avg. Likes",
      sort: true,
      sortKey: "avglikes",
      // customValue: (data: any) =>
      //   data.avglikes ? formatNumber(data.avglikes) : "-",
      customValue: (data: any) => 
        !data.isvalidated  ? (
          <div className="text-danger-color">{formatNumber(data.avglikes)}</div>
        ) : (
          formatNumber(data.avglikes) || "-"
        ) 
      },
    {
      key: "reelprice",
      header: "Cost Per Reel",
      sort: true,
      sortKey: "reelprice",
      customValue: (data: any) => 
        !data.isvalidated  ? (
          <div className="text-danger-color">{data.reelprice || "-"}</div>
        ) : (data.reelprice) || "-"
      },
      // customValue: (data: any) => data.reelprice || 0,
    {
      key: "created_at",
      header: "Invited On",
      sort: true,
      sortKey: "created_at",
      customValue: (data: any) => 
        !data.isvalidated && data.created_at ? (
          <div className="text-danger-color">{formatDate(data.created_at)}</div>
        ) :  formatDate(data.created_at) || "-"
      },
      // customValue: (data: any) =>
      //   data.created_at ? formatDate(data.created_at) : "-",
    {
      key: "profileStatus",
      header: "Action",
      sortKey: "profileStatus",
      sort: true,
      customValue: (data: any) => {
        if (data.profilestatus === profileStatus.INVITED) {
          return (
            <CustomButton
              buttonText="ReInvite"
              greyButton
              textSmall
              loading={adminFetching && loadingRowId === data.id}
              handleClick={() => handleReInviteUser(data.id)}
            />
          );
        } else if (data.profilestatus === profileStatus.PENDING) {
          return (
            <CustomButton
              buttonText="Send Invite"
              // greyButton
              secondaryButton
              textSmall
              loading={adminFetching && loadingRowId === data.id}
              handleClick={() => sendInviteToUser(data.id)}
            />
          );
        } else {
          return (
            <SvgIcon
              name={"check"}
              baseclassname={"text-green-shade-2 padding-l-8"}
              svgType={SVGType.SEMANTIC}
              size={"small"}
            />
          );
        }
      },
    },
  ];

  const fetchAllUsers = (page?: number) => {
    // const filteredParams = Object.entries(filterParams).reduce((acc: any, [key, value]) => {
    //   if (value !== undefined && value !== "") {
    //     acc[key] = value;
    //   }
    //   return acc;
    // }, {});

    dispatch(
      requestInfluencerUsersList(
        { userType: userType?.toUpperCase() },
        {
          page: page ? page : currentPage,
          pageSize: 50,
          sortBy: sortingCriteria.sortBy,
          sortDirection: sortingCriteria.sortOrder,
          search: "",
        }
      )
    );
  };

  const [sortingCriteria, setSortingCriteria] = useState<ISortingCriteria>({
    sortBy: "name",
    sortOrder: "ascending",
  });

  const handleSortChange = (criteria: ISortingCriteria) => {
    setSortingCriteria(criteria);
  };

  const handleInviteUser = () => {
    toast.dismiss();
    navigate(URLRoutes.clients.userInvite);
  };

  const handleReInviteUser = (id: any) => {
    setLoadingRowId(id);
    dispatch(requestReInvite({ id: id }));
  };
  const sendInviteToUser = (id: any) => {
    setLoadingRowId(id);
    dispatch(sendInviteUser({ id: id }));
  };

  const sendMultipleUsersInvite = () => {
    dispatch(sendInviteToMultipleUsers({ userIds: selectedRows }));
  };

  const handleSelectAll = (value: boolean) => {
    setSelectAllChecked(value);

    if (value) {
      const idsToSelect = records?.filter((item: any) => item.profilestatus !== profileStatus.COMPLETED)
        .map((item: any) => item.id);
      setSelectedRows(idsToSelect);
    } else {
      setSelectedRows([]);
      setSelectAllChecked(false)
    }
  };

  const handleCheckboxSelect = (data: any) => {
    const isSelected = selectedRows.some((row) => row === data.id);

    if (!isSelected) {
      setSelectedRows([...selectedRows, data.id]);
    } else {
      setSelectAllChecked(false)
      setSelectedRows(selectedRows.filter((row) => row !== data.id));
    }
  };

  const handleFetchDataForSorting = (criteria: ISortingCriteria) => {
    setSortingCriteria(criteria);
     dispatch(
      requestInfluencerUsersList(
        { userType: userType?.toUpperCase() },
        {
          page: currentPage,
          pageSize: 50,
          sortBy: criteria.sortBy,
          sortDirection: criteria.sortOrder,
          search: selectedFilters.search || "",
        }
      )
    );
  };

  const handleChange = (name: string, value: string) => {
    setSelectedFilters({
      ...selectedFilters,
      [name]: value,
    });
    const updatedFilters = {
      ...selectedFilters,
      [name]: value,
    };

    dispatch(
      requestInfluencerUsersList(
        { userType: userType?.toUpperCase() },
        {
          page: currentPage,
          pageSize: 50,
          sortBy: sortingCriteria.sortBy,
          sortDirection: sortingCriteria.sortOrder,
          ...updatedFilters,
        }
      )
    );
    setSelectedRows([]);
    setSelectAllChecked(false)
  };

  const handleClearAllFilters = () => {
    setSelectedFilters({
      search: "",
      userStatus: "",
      isEmailAvailable: "",
      isWhatsAppAvailable: "",
    });
   fetchAllUsers();
  };

  const handleBulkInviteUser = () => {
    dispatch(
      modalOperation.showModal(ModalsType.BulkInviteModal, {
        onSave: (data: any) => {
          dispatch(modalOperation.hideModal());
          const formData = new FormData();
          formData.append("file", data as File);
          dispatch(requestBulkInviteUsers(formData));
        },
        onClose: () => {
          dispatch(modalOperation.hideModal());
        },
        toggle: () => {
          dispatch(modalOperation.hideModal());
        },
        title: "Bulk Invite Users",
      })
    );
  };

  const handlePagination = (e: any, { activePage }: any) => {
    setCurrentPage(activePage);
   fetchAllUsers(activePage);
  };
  
  useEffect(() => {
    if (Object.keys(selectedFilters).length > 0) {
      dispatch(
        requestInfluencerUsersList(
          { userType: userType?.toUpperCase() },
          {
            page: currentPage , 
            pageSize: 50 ,
            sortBy: sortingCriteria.sortBy,
            sortDirection: sortingCriteria.sortOrder,
            ...selectedFilters,
          }
        )
      );
    } else {
     fetchAllUsers();
    }
    setSelectedRows([]);
    setSelectAllChecked(false)
    setLoadingRowId(null)
  }, [message ]);

  useEffect(() => {
    if (wasfecthing && !bulkFetching && !error) {
      if (bulkData.messagetype === "success") {
        toast.success(message , { autoClose: 10000});
      } else if (bulkData.messagetype === "error") {
        toast.warning(message , { autoClose: 10000});
      }
      if (bulkData?.notValidMobileUsers?.length) {
        toast.warning(
          "Invitation sent successfully. Some users might not received messages due to invalid mobile." ,
          { autoClose: 30000, style: { width: 500 }, position: "top-center" }
        );
      }
    } else if (error) {
      toast.error(message);
    }
  }, [bulkFetching, error, message]);

  useEffect(() => {
    if (!adminFetching && !adminError && loadingRowId?.length) {
      toast.success(adminMessage);
          fetchAllUsers();

    } else if ( loadingRowId?.length && adminError) {
      toast.error(adminMessage);
          fetchAllUsers();

    }
    
  }, [adminFetching, adminError, adminMessage]);

  return (
    <div className="user-content width-100 height-100">
      <div className="flex flex-row flex-justify-between padding-t-3 padding-l-3 padding-b-4 padding-r-3 width-100 flex-align-center">
        <div className="text-blue-shade1 text-6 text-bold width-50">
          Influencers
        </div>
        <div className="width-100 flex flex-justify-end  width-50">
          {selectedRows.length > 0 && (
            <CustomButton
              buttonText="SEND INVITE"
              primaryButton
              round
              textSmall
              handleClick={sendMultipleUsersInvite}
              baseclassname={"margin-r-2"}
            />
          )}
          <CustomButton
            buttonText="INVITE USER"
            primaryButton
            round
            textSmall
            handleClick={handleInviteUser}
            baseclassname={"margin-r-2"}
          />
          <CustomButton
            buttonText="BULK INVITE"
            primaryButton
            round
            textSmall
            // loading={bulkFetching}
            handleClick={handleBulkInviteUser}
          />
        </div>
      </div>
      <div className="flex width-100 flex-justify-between  flex-align-center padding-l-3 padding-b-4 padding-r-3">
        <CustomInput
          initialValue={selectedFilters.search}
          baseClassName="search-users width-30 flex flex-align-center"
          onChange={handleChange}
          placeholder="Search through Name or Instagram Id.."
          id="search"
          name="search"
          inputClassName="width-100"
          iconProps={{
            name: CUSTOM_SVG_ICON.Search,
            svgType: SVGType.CUSTOM,
            size: "small",
          }}
        />
        <Dropdown
          placeholder="Registration"
          selection
          clearable
          options={registrationStatusOptions}
          onChange={(event, data) =>
            handleChange("userStatus", data.value as string)
          }
          value={selectedFilters.userStatus}
          className="users-dropdown width-15"
        />
        <Dropdown
          placeholder="Is Email Available"
          selection
          clearable
          options={emailNumberOptions}
          onChange={(event, data) =>
            handleChange("isEmailAvailable", data.value as string)
          }
          value={selectedFilters.isEmailAvailable}
          className="users-dropdown width-15"
        />
        <Dropdown
          placeholder="Is WhatsApp Available"
          selection
          clearable
          options={emailNumberOptions}
          onChange={(event, data) =>
            handleChange("isWhatsAppAvailable", data.value as string)
          }
          value={selectedFilters.isWhatsAppAvailable}
          className="users-dropdown width-15"
        />
        <CustomButton
          buttonText="Clear All"
          whiteBlueButton
          noOutline
          transparent
          textSmall
          handleClick={handleClearAllFilters}
        />
      </div>
      <CustomTable
        columns={columns}
        tableData={records || []}
        hasPagination={true}
        sortTable
        fetching={fetching || false}
        onSortChange={handleSortChange}
        sortingCriteria={sortingCriteria}
        contentRowClassName={"text-4"}
        tableClassName="user-table-height"
        fetchData={handleFetchDataForSorting}
        tableError={listError}
        tableMessage={listMessage}
        // useInfiniteScroll
        onSelectAll={handleSelectAll}
        selectAllChecked={selectAllChecked}
        // onRowSelect={handleCheckboxSelect}
        paginationData={paginationInfo}
        handlePaginationChange={handlePagination}
      />
      {bulkFetching && <Loading isGlobal />}
    </div>
  );
}

export default Users;
