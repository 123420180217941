import { useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { requestEngagementMatrixDetails } from "store/rext";
import { getAllMatrixState } from "store/selectors";
import Loading from "components/Loading";

interface StatsCardProp {
  title: string;
  content: any;
}

interface PostData {
  id: string;
  posted_at: string;
  engagementcount: string;
  userid: string;
  posttype: "image" | "reel";
  created_at: string;
  updated_at: string;
}

const StatsCard = ({ title, content }: StatsCardProp) => (
  <div className="flex flex-column width-30 margin-b-2 margin-r-2">
    <div className="info-value">{content}</div>
    <div className="info-title text-grey-shade-4">{title}</div>
  </div>
);

const pieChartOptions = (title: string, data: any[]) => ({
  chart: { type: "pie" },
  title: {
    text: title,
    align: "left",
    x: 5,
    style: { fontSize: "0.75rem" },
  },
  legend: {
    layout: "vertical",
    align: "right",
    verticalAlign: "middle",
    symbolRadius: 0,
  },
  plotOptions: {
    pie: {
      innerSize: "80%",
      cursor: "pointer",
      dataLabels: {
        enabled: true,
        distance: 10,
        formatter: function (this: Highcharts.Point | any) {
          return `${Math.round(this.percentage)}%`;
        },
      },
      showInLegend: true,
    },
  },
  series: [{ name: "Account Reach", data, type: "pie" }],
  credits: { enabled: false },
});

const columnChartOptions = (
  title: string,
  categories: string[],
  data: { count: number; percentage: number }[]
) => ({
  chart: { type: "column" },
  title: { text: title, align: "center", verticalAlign: "bottom" },
  xAxis: { categories },
  yAxis: { title: { text: "" } ,  labels: { format: "{value}%" } ,  max: 100 },
  tooltip: { enabled: true ,  valueSuffix: '%'},
  series: [{ name: title, data: data.map((item: any) => item.percentage), type: "column" }],
  credits: { enabled: false },
  lang: { noData: "No Data Available for this Category!" },
  noData: {
    style: {
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#303030'
    }}
});

const formatDate = (dateString: string): string =>
  moment(dateString).format("MMM-DD");

// const transformData = (data: PostData[]) => {
//   const images: Record<string, number> = {};
//   const reels: Record<string, number> = {};

//   data.forEach((post) => {
//     const date = formatDate(post.posted_at.substring(0, 10));
//     const count = parseInt(post.engagementcount, 10);

//     if (post.posttype === "image") {
//       images[date] = (images[date] || 0) + count;
//     } else if (post.posttype === "reel") {
//       reels[date] = (reels[date] || 0) + count;
//     }
//   });

//   const imageCategories = Object.keys(images).sort();
//   const reelCategories = Object.keys(reels).sort();

//   return { images, reels, imageCategories, reelCategories };
// };

const transformData = (data: PostData[]) => {
  const images: Record<string, number> = {};
  const reels: Record<string, number> = {};

  data.forEach((post) => {
    const date = formatDate(post.posted_at.substring(0, 10));
    const count = parseInt(post.engagementcount, 10);

    if (post.posttype === "image") {
      images[date] = (images[date] || 0) + count;
    } else if (post.posttype === "reel") {
      reels[date] = (reels[date] || 0) + count;
    }
  });

  const imageCategories = Object.keys(images).sort();
  const reelCategories = Object.keys(reels).sort();


  const totalImageEngagements = imageCategories.reduce((total, date) => total + images[date], 0);
  const totalReelEngagements = reelCategories.reduce((total, date) => total + reels[date], 0);
  
  // Calculate data and percentages
  const imageData = imageCategories.map((date) => ({
    count: images[date] || 0,
    percentage: totalImageEngagements > 0 
      ? Math.round((images[date] || 0) / totalImageEngagements * 100) 
      : 0,
  }));

  const reelData = reelCategories.map((date) => ({
    count: reels[date] || 0,
    percentage: totalReelEngagements > 0 
      ? Math.round((reels[date] || 0) / totalReelEngagements * 100) 
      : 0,
  }));

  return {
    images: imageData,
    reels: reelData,
    imageCategories,
    reelCategories,
    totalImageEngagements, 
    totalReelEngagements
  };
};



export function EngagementView({ instaHandle }: { instaHandle : string }) {
  const dispatch = useDispatch();
  const { data, fetching, error, message } = useSelector(getAllMatrixState);
  const { engagementMatrix, engagementChart } = data || {};

  const ImageData = [
    {
      name: "Followers",
      y: parseInt(engagementMatrix?.img_followreach || "0"),
      color: "#36A2EB",
    },
    {
      name: "Non-Followers",
      y: parseInt(engagementMatrix?.img_nonfollowreach || "0"),
      color: "#FF6384",
    },
  ];

  const AccountsData = [
    {
      name: "Followers",
      y: parseInt(engagementMatrix?.reels_followreach || "0"),
      color: "#36A2EB",
    },
    {
      name: "Non-Followers",
      y: parseInt(engagementMatrix?.reels_nonfollowreach || "0"),
      color: "#FF6384",
    },
  ];

  const { images, reels, imageCategories, reelCategories , totalImageEngagements , totalReelEngagements } = transformData(
    engagementChart || []
  );


  useEffect(() => {
    dispatch(
      requestEngagementMatrixDetails({ instaHandle }, { handle: instaHandle })
    );
  }, [dispatch, instaHandle]);

  if (fetching || !engagementMatrix) {
    return <Loading inverted />;
  }

  const hasEngagementMatrixData =
    engagementMatrix?.reels_followreach &&
    engagementMatrix?.reels_nonfollowreach &&
    engagementMatrix?.img_nonfollowreach &&
    engagementMatrix?.img_followreach;

  return (
    <div className="flex flex-column width-100">
      <div className="flex flex-row width-100">
        <div className="width-50 padding-2">
          <SvgIcon
            name={CUSTOM_SVG_ICON.InstaIcon}
            svgType={SVGType.CUSTOM}
            size={"huge"}
            baseclassname={"margin-3"}
          />
          <div className="padding-2 text-bold text-5">Image Posts</div>
          {engagementMatrix && (
            <div className="flex flex-row flex-align-center flex-wrap width-100 padding-l-2 padding-t-1">
              <StatsCard
                title="Avg Likes"
                content={engagementMatrix?.img_avglikes || "-"}
              />
              <StatsCard
                title="Avg Comments"
                content={engagementMatrix?.img_avgcomments || "-"}
              />
              <StatsCard
                title="Post Interactions (Likes)"
                content={engagementMatrix?.img_interactioncontent || "-"}
              />
              <StatsCard
                title="Engagement Rate"
                content={
                  engagementMatrix?.img_engagerate
                    ? `${engagementMatrix?.img_engagerate}%`
                    : "-"
                }
              />
              <StatsCard
                title="Post Frequency"
                content={engagementMatrix?.img_postfrequency || "-"}
              />
              <StatsCard
                title="Likes To Comments Ratio"
                content={`${Math.round(engagementMatrix?.img_likestocomments_ratio)}%` || "-"}
              />
            </div>
          )}
        </div>
        <div className="width-50 padding-2">
          <SvgIcon
            name={CUSTOM_SVG_ICON.InstaIcon}
            svgType={SVGType.CUSTOM}
            size={"huge"}
            baseclassname={"margin-3"}
          />
          <div className="padding-2 text-bold text-5">Reel Posts</div>
          {engagementMatrix && (
            <div className="flex flex-row flex-align-center flex-wrap width-100 padding-l-2 padding-t-1">
              <StatsCard
                title="Avg Likes"
                content={engagementMatrix?.reels_avglikes || "-"}
              />
              <StatsCard
                title="Avg Views"
                content={engagementMatrix?.avgreelviews || "-"}
              />
              <StatsCard
                title="Avg Comments"
                content={engagementMatrix?.reels_avgcomments || "-"}
              />
              <StatsCard
                title="Engagement Rate"
                content={
                  engagementMatrix?.reels_engagerate
                    ? `${engagementMatrix?.reels_engagerate}%`
                    : "-"
                }
              />
              <StatsCard
                title="Reel Interactions"
                content={engagementMatrix?.reels_interactioncontent || "-"}
              />
              <StatsCard
                title="Reel Frequency"
                content={engagementMatrix?.reels_postfrequency || "-"}
              />
              <StatsCard
                title="Likes To Comments Ratio"
                content={`${Math.round(engagementMatrix?.reels_likestocomments_ratio)}%` || "-"}
              />
            </div>
          )}
        </div>
      </div>
      {hasEngagementMatrixData && (
        <div className="flex flex-row width-100 flex-justify-around">
          <HighchartsReact
            highcharts={Highcharts}
            options={pieChartOptions("Accounts Reached", ImageData)}
            containerProps={{ className: "engagement-chart1" }}
          />
          <HighchartsReact
            highcharts={Highcharts}
            options={pieChartOptions("Accounts Reached", AccountsData)}
            containerProps={{ className: "engagement-chart1" }}
          />
        </div>
      )}
      {engagementChart.length > 0 && (
        <div className="flex flex-row width-100">
          <HighchartsReact
            highcharts={Highcharts}
            options={columnChartOptions(
              "Image Engagement",
              imageCategories,
              images
            )}
            containerProps={{ className: "engagement-chart2" }}
          />
          <HighchartsReact
            highcharts={Highcharts}
            options={columnChartOptions(
              "Reel Engagement",
              reelCategories,
              reels
            )}
            containerProps={{ className: "engagement-chart2" }}
          />
        </div>
      )}
      {(engagementChart.length === 0 && !hasEngagementMatrixData)  && (
        <div className="flex flex-justify-center text-5 margin-t-5 margin-b-5">
          {"Data not available!"}
        </div>
      )}
    </div>
  );
}
