import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import moment from "moment";
import { getGenreOptionsState } from "store/selectors";
import { requestGenreOptions, updateUserProfile } from "store/rext";
import {
  findLabelById,
  formatDate,
  formatStatusString,
  getCity,
  getPostalCode,
  getState,
} from "utils/utils";
import CustomButton from "components/CustomButton";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import { TitleValue } from "./ProfilePage";
import { ProfileForm } from "./ProfileForm";

interface IProps {
  userData: any;
  isEditable: boolean | undefined;
  isEnable: boolean | undefined;
}

function PersonalInfo({ userData, isEditable, isEnable }: IProps) {
  const dispatch = useDispatch();
  const { data: genreOptions } = useSelector(getGenreOptionsState);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showNewAddressFields, setShowNewAddressFields] =
    useState<boolean>(false);
  const [selectedAddressId, setSelectedAddressId] = useState<string | null>(
    null
  );
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    setValue,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      return userData;
    }, [userData]),
  });

  const handleOnSubmit = (data: any) => {
    const {
      city,
      state,
      gender,
      gener,
      address,
      reelprice,
      staticpostprice,
      storyprice,
      swipeupstoryprice,
      carouselpostprice,
    } = data || {};

    const transformData = {
      city: city,
      state: state,
      dob: moment(data.dob).format("YYYY-MM-DD"),
      gender,
      gener,
      reelprice: reelprice?.toString(),
      storyprice: storyprice?.toString(),
      staticpostprice: staticpostprice?.toString(),
      swipeupstoryprice: swipeupstoryprice?.toString(),
      carouselpostprice: carouselpostprice?.toString(),
    };

    const transformAddress: any = {
      addressLine: address?.addressLine,
      city: address?.city,
      postalCode: address?.postalCode,
      state: address?.state,
    };
    if (selectedAddressId) {
      transformAddress.id = selectedAddressId;
    }
    dispatch(
      updateUserProfile({ profile: transformData, address: transformAddress })
    );
    setSelectedAddressId(null);
    setShowNewAddressFields(false);
  };

  const renderShipmentAddresses = () => {
    return (
      <div className="flex flex-column flex-wrap width-100">
        {userData.addresses?.map((item: any, index: number) => {
          return (
            item.state &&
            item.city && (
              <div className="flex flex-align-center width-100 ">
                <div className="text-3 text-grey-shade-2">
                  {item.address_line}
                </div>
                <CustomButton
                  // buttonText={"Edit"}
                  iconProps={{
                    svgType: SVGType.CUSTOM,
                    name: CUSTOM_SVG_ICON.Edit,
                    size: "small",
                    baseclassname: "text-grey-shade-2",
                  }}
                  iconPosition={ICON_POSITION.RIGHT}
                  handleClick={() => handleEditAddress(index)}
                  greyButton
                  transparent
                  baseclassname={" cursor-pointer"}
                  textSmall
                  circular
                />
              </div>
            )
          );
        })}
      </div>
    );
  };

  const handleAddNewAddress = () => {
    setShowNewAddressFields(true);
    setSelectedAddressId(null);
  };

  const handleEditAddress = useCallback(
    (index: number) => {
      const selectedAddress = userData.addresses[index];
      setSelectedAddressId(selectedAddress.id);
      setValue("address.addressLine", selectedAddress.address_line || "");
      setValue("address.city", selectedAddress.city || "");
      setValue("address.state", selectedAddress.state || "");
      setValue("address.postalCode", selectedAddress.postal_code || "");
      setShowNewAddressFields(true);
    },
    [setValue, userData.addresses]
  );

  const handleInputChange = (place: any) => {
    setValue("address.addressLine", place);
  };

  const handlePlaceSelected = (place: any) => {
    const selectedCity = getCity(place);
    const selectedState = getState(place);
    const selectedPostCode = getPostalCode(place);

    setValue("state", selectedState);
    setValue("city", selectedCity);
    setValue("postalCode", selectedPostCode);
  };

  const handleShipmentSelect = (place: any) => {
    const selectedCity = getCity(place);
    const selectedState = getState(place);
    const selectedPostCode = getPostalCode(place);

    setValue("address.city", selectedCity);
    setValue("address.state", selectedState);
    setValue("address.postalCode", selectedPostCode);
  };

  const handleEditButtonClick = () => {
    setEditMode(!editMode);
  };

  const handlePermanentLocation = (value: string) => {};

  useEffect(() => {
    if (userData) {
      reset(userData);
      setValue("address", userData?.adresses);
      setValue("reelprice", userData.instaDetails?.reelprice);
      setValue("staticpostprice", userData.instaDetails?.staticpostprice);
      setValue("carouselpostprice", userData.instaDetails?.carouselpostprice);
      setValue("storyprice", userData.instaDetails?.storyprice);
      setValue("swipeupstoryprice", userData.instaDetails?.swipeupstoryprice);
    }
  }, [reset, userData]);

  useEffect(() => {
    dispatch(requestGenreOptions());
  }, []);

  return (
    <div className="flex flex-column width-100 padding-1 height-100 personal-container overflow-auto">
      <div className="flex flex-justify-between width-100 padding-2">
        <div className="profile-title text-grey-shade-2 margin-r-2 width-70">
          Detailed information about the user's profile, including personal
          details and preferences.
        </div>
        {isEditable && !editMode && (
          <div className="width-25">
            <CustomButton
              secondaryButton
              buttonText="Edit Profile"
              textSmall
              circular
              handleClick={handleEditButtonClick}
              // baseclassname={"width-20"}
            />
          </div>
        )}
      </div>
      {!editMode && (
        <Fragment>
          <div className="flex flex-wrap flex-align-center width-100">
            {isEnable && (
              <TitleValue
                value={"Gender"}
                title={formatStatusString(userData.gender) || ""}
              />
            )}
            {isEnable && (
              <TitleValue
                value={"Date of Birth"}
                title={formatDate(userData.dob) || ""}
              />
            )}
            <TitleValue value={"Phone Number"} title={userData.phone || "-"} isValid={userData.instaDetails?.isvalidated}/>
            <TitleValue value={"Email Address"} title={userData.email || "-"}/>
            {/* {isEnable && (
              <Fragment>
                <TitleValue
                  value={"Reel Price"}
                  title={
                    "₹" +
                    `${
                      userData.instaDetails?.reelprice
                        ? userData.instaDetails?.reelprice
                        : 0
                    }`
                  }
                />
                <TitleValue
                  value={"Static Post Price"}
                  title={
                    "₹" +
                    `${
                      userData.instaDetails?.staticpostprice
                        ? userData.instaDetails?.staticpostprice
                        : 0
                    }`
                  }
                />
                {
                  <TitleValue
                    value={"Carousel Post Price"}
                    title={
                      "₹" +
                      `${
                        userData.instaDetails?.carouselpostprice
                          ? userData.instaDetails?.carouselpostprice
                          : 0
                      }`
                    }
                  />
                }
                <TitleValue
                  value={"Story Price"}
                  title={
                    "₹" +
                    `${
                      userData.instaDetails?.storyprice
                        ? userData.instaDetails?.storyprice
                        : 0
                    }`
                  }
                />
                <TitleValue
                  value={"Swipe Story Price"}
                  title={
                    "₹" +
                    `${
                      userData.instaDetails?.swipeupstoryprice
                        ? userData.instaDetails?.swipeupstoryprice
                        : 0
                    }`
                  }
                />
              </Fragment>
            )} */}
          </div>
          {isEnable && userData?.gener?.length > 0 && (
            <div className="flex flex-column  width-95 text-bold margin-2">
              Genre
              <div className="flex flex-row flex-align-center width-100 flex-wrap ">
                {userData.gener?.map((item: string) => {
                  return (
                    <div className="text-align-center profile-gener text-3 text-grey-shade-4 border-1 border-grey-shade-1 padding-1 margin-r-2 margin-t-2">
                      {findLabelById(genreOptions, item)}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {isEnable && (
            <TitleValue
              value={"Permanant Address"}
              title={(userData.city && userData.state) ? `${userData.city} , ${userData.state}`: "-"}
            />
          )}
          {isEnable && userData?.addresses?.length > 0 && (
            <Fragment>
              <span className="text-4 text-bold margin-2">
                Shipment Addresses
              </span>
              <div className="flex flex-column flex-wrap width-95 margin-l-2">
                {userData.addresses?.map((item: any, index: number) => {
                  return (
                    item.state &&
                    item.city && (
                      <div className="profile-title text-grey-shade-2">
                        <span className="margin-r-2">{index + 1}.</span>
                        {`${item.address_line}`}
                      </div>
                    )
                  );
                })}
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
      {editMode && isEditable && (
        <ProfileForm
          isSubmitting={isSubmitting}
          control={control}
          errors={errors}
          genreOptions={genreOptions}
          renderShipmentAddresses={renderShipmentAddresses}
          handleAddNewAddress={handleAddNewAddress}
          handleCancelClick={() => setEditMode(false)}
          handleInputChange={handleInputChange}
          handlePermanentLocation={handlePermanentLocation}
          handlePlaceSelected={handlePlaceSelected}
          handleShipmentSelect={handleShipmentSelect}
          handleSubmitForm={handleSubmit(handleOnSubmit)}
          showNewAddressFields={showNewAddressFields}
        />
      )}
    </div>
  );
}

export default PersonalInfo;
