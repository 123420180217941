import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AuthLayout from "components/AuthLayout";
import CustomButton from "components/CustomButton";
import { HookPhoneInput } from "components/FormInputs";
import {
  hookPhoneNumberValidation,
  hookformRequired,
} from "utils/FormValidations";
import { getInvitedUserDetails, requestMobileVerification } from "store/rext";
import { getInvitedUserState, getMobileVerifyState } from "store/selectors";
import UsePrevious from "HOC/UsePrevious";

const phoneNumberValidation = {
  ...hookformRequired("Required"),
  ...hookPhoneNumberValidation(),
};

function MobileVerify() {
  const { id: verifyId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    data: mobileVerify,
    fetching,
    error,
    message,
  } = useSelector(getMobileVerifyState);
  const wasfecthing = UsePrevious(fetching);
  const { data: userdetail } = useSelector(getInvitedUserState);

  const {
    handleSubmit,
    formState: { errors , isSubmitSuccessful },
    control,
    trigger,
    reset,
  } = useForm({
    mode: "onChange",
  });

  const handleOnSubmit = async (data: any) => {
    await trigger();
    await dispatch(
      requestMobileVerification({ phone: data.phone }, { id: verifyId })
    );
  };

  useEffect(() => {
    if (isSubmitSuccessful && !fetching && !error) {
      toast.success(message);
      navigate(`/verifyOTP/${verifyId}/VERIFY_PHONE`);
    } else if (isSubmitSuccessful && error) {
      toast.error(message);
    }
  }, [fetching, error, message, isSubmitSuccessful]);

  useEffect(() => {
    if (verifyId) {
      dispatch(getInvitedUserDetails({ id: verifyId }));
      reset({
        phone: verifyId ? userdetail.phone : "",
      });
    }
  }, [verifyId, dispatch, reset, userdetail.email]);
  
  // useEffect(() => {
  //   toast.dismiss();
  // }, []);

  return (
    <AuthLayout
      image="/images/womanSunglasses.png"
      title="Discover the world’s top Influencers."
      titlePosition="top"
      contentClassName="flex-justify-center flex-align-center"
      bannerClassName="background-linear1"
      imagePosition="right"
    >
      <div className="width-45">
        <img src="/images/logo.png" style={{ width: "100%", height: "100%" }} />
      </div>
      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        className="width-70 padding-t-6"
      >
        <HookPhoneInput
          control={control}
          errors={errors}
          label="WhatsApp Number"
          placeholder="Enter your phone number..."
          name="phone"
          id="phone"
          baseClassName="width-100 margin-b-2 margin-t-2"
          validation={phoneNumberValidation}
        />
        <CustomButton
          type="submit"
          buttonText="SEND OTP"
          round
          primaryButton
          baseclassname={"width-100 margin-b-3"}
          loading={fetching}
        />
      </form>
    </AuthLayout>
  );
}

export default MobileVerify;
