export enum FEATURES {
    PROFILE = "PROFILE", // all 
    EDIT_PROFILE = "EDIT_PROFILE" , //Influecer
    SOCIAL_PROFILE = "SOCIAL_PROFILE" , //Influencer & HOB
    SOCIAL_PROFILE_DETAIL = "SOCIAL_PROFILE_DETAIL", // iNFLUENCER
    ALL_CAMPAIGNS = "ALL_CAMPAIGNS", // ALL userss
    CREATE_CAMPAIGN = "CREATE_CAMPAIGN", // only hob
    UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN", // hob
    MANAGE_INFLUENCERS = "MANAGE_INFLUENCERS", // BRAND, HOB
    ALL_USERS = "ALL_USERS", // hob
    INVITE_USER = "INVITE_USER", //HOB
    BULK_INVITE_USER = "BULK_INVITE_USER", //HOB
    ADD_FAVOURITE = "ADD_FAVOURITES" ,
    MESSAGES = "MESSAGES", 
    SETTINGS = "SETTINGS", // ALL
    BRAND_LIST = "BRAND_LIST",//HOB
    CREATE_BRAND = "CREATE_BRAND" , //HOB
    UPDATE_BRAND = "UPDATE_BRAND" , //HOB
    BRAND_USERS_LIST = "BRAND_USERS_LIST", //HOB
    CREATE_BRAND_USER = "CREATE_BRAND_USER" , //HOB
    UPDATE_BRAND_USER = "UPDATE_BRAND_USER",//HOB

    UPLOAD_GUIDELINE = "UPLOAD_GUIDELINE" , 
    VIEW_GUIDELINE = "VIEW_GUIDELINE",
    DELETE_GUIDELINE = "DELETE_GUIDELINE",

    INFLUENCER_DETAIL = "INFLUENCER_DETAIL",
    ADD_INFLUENCER = "ADD_INFLUENCER",
    POST_TO_BRAND = "POST_TO_BRAND", 
    POST_TO_INFLUENCER = "POST_TO_INFLUENCER",
    UPLOAD_VIDEO = "UPLOAD_VIDEO",
    ACCEPT_CAMPAIGN = "ACCEPT_CAMPAIGN" , 
    REJECT_CAMPAIGN = "REJECT_CAMPAIGN" ,
    APPLY_FOR_CAMPAIGN = "APPLY_FOR_CAMPAIGN",
    BUDGET_EDIT = "BUDGET_EDIT" , 
    FINAL_EDIT = "FINAL_EDIT" ,
    MARKUP_EDIT = "MARKUP_EDIT" ,
    QUOTE_EDIT = "QUOTE_EDIT" , 
    TAKES_EDIT = "TAKES_EDIT" ,
    BUDGET_VIEW = "BUDGET_VIEW" , 
    FINAL_VIEW = "FINAL_VIEW" ,
    MARKUP_VIEW = "MARKUP_VIEW" ,
    QUOTE_VIEW = "QUOTE_VIEW" , 
    TAKES_VIEW = "TAKES_VIEW" ,
    ALL_INFLUENCERS = "ALL_INFLUENCERS"
}