import { IRextState, getRextState } from "../baseProvider/rext";
import { ApplicationState } from 'store/reducers';
import { IAuthState } from "store/reducers/auth";
import { ModalState } from "store/reducers/modal";
import { CONFIG } from "utils/utils";

export function getToken(state: ApplicationState): string {
  const { token } = state.authState;
  return token || "";
}

export function getBaseUrl(state: ApplicationState): string {
  return CONFIG.REACT_APP_BASE_URL!;
}

export function getAuthState(state: ApplicationState): IAuthState {
  return state.authState
}

export function getModalState(state: ApplicationState): ModalState {
  return state.modalState;
}


export const getAdminInviteState = (state: ApplicationState) => getRextState(state.sendAdminInviteState, {});
export const getVerifyOTPState = (state: ApplicationState) => getRextState(state.verifyOTPState, {});
export const getForgetPasswordState = (state: ApplicationState) => getRextState(state.forgetPssswordState, {});
export const getChangePasswordState = (state: ApplicationState) => getRextState(state.resetPasswordState, {});
export const getMobileVerifyState = (state: ApplicationState) : IRextState => getRextState(state.mobileVerifyState, {});
export const getGenreOptionsState = (state: ApplicationState) => getRextState(state.genreOptionsState, [])
export const getSignUpByEmailState = (state: ApplicationState) : IRextState => getRextState(state.signUpByEmailState, {})
export const getRegisterUserState = (state: ApplicationState) : IRextState => getRextState(state.createUserState , {})
export const getBulkInviteUserState = (state: ApplicationState) : IRextState => getRextState(state.bulkInviteUserState , undefined!)
export const getInvitedUserState = (state: ApplicationState) => getRextState(state.invitedUserState, {});

//Influencer and Profile Selectors
export const getInfluencerDetailList = (state: ApplicationState) => getRextState(state.influencerUsersListState, []);
export const getInfluencerList = (state: ApplicationState) => getRextState(state.influencerListState, []);
export const getDetailedProfileList = (state: ApplicationState) => getRextState(state.detailedProfileState, []);

//Campaign Selectors
export const getCampaignsListData = (state: ApplicationState) => getRextState(state.campaignListState, []);
export const getCampaignDetailState = (state: ApplicationState) => getRextState(state.campaignState, {});


export const getBrandListState = (state: ApplicationState) => getRextState(state.brandsListState, []);
export const getCurrentBrandState = (state: ApplicationState) => getRextState(state.currentBrandState, {});
export const getBrandUsersListState = (state: ApplicationState) => getRextState(state.brandUsersListState, []);
export const getBrandUserState = (state: ApplicationState) => getRextState(state.brandUserState, {});
export const getCampaignInfluencerListState = (state: ApplicationState) => getRextState(state.campaignInfluencerListState, []);
export const getAllInfluencersListState = (state: ApplicationState) => getRextState(state.allInfluencersListState, []);

export const getPostedInfluencersState = (state: ApplicationState) => getRextState(state.postInfluencersState, undefined!);
export const getSyncedInstaMatrixState = (state: ApplicationState) => getRextState(state.instaMatrixState, undefined!);
export const getBillingInfluencersState = (state: ApplicationState) => getRextState(state.billingInfluencersState, undefined!);

export const getUploadGuidelinesState = (state: ApplicationState) => getRextState(state.uploadGuidelinesState, undefined!);
export const getUploadedVideoState = (state: ApplicationState) => getRextState(state.uploadVideoState, undefined!);
export const getVideoListState = (state: ApplicationState) => getRextState(state.videoListState, []);

export const getFavouriteListState = (state: ApplicationState) => getRextState(state.favouritesListState, []);
export const getFavouriteState = (state: ApplicationState) => getRextState(state.favouriteState, {});

export const getNotificationListState = (state: ApplicationState) => getRextState(state.notificationListState, []);
export const getNotificationState = (state: ApplicationState) => getRextState(state.notificationState, {});

export const getChatNotificationListState = (state: ApplicationState) => getRextState(state.chatNotificationListState, []);
export const getChatNotificationState = (state: ApplicationState) => getRextState(state.chatNotificationsState, {});

export const getAllMatrixState = (state: ApplicationState) => getRextState(state.allMatrixState, undefined!);
