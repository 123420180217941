import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { HookFormInput } from "components/FormInputs";
import AuthLayout from "components/AuthLayout";
import CustomButton from "components/CustomButton";
import {
  hookPasswordPatternRequired,
  hookformPasswordsMatch,
  hookformRequired,
} from "utils/FormValidations";
import { requestResetPassword } from "store/rext";
import UsePrevious from "HOC/UsePrevious";
import { getAuthState, getChangePasswordState } from "store/selectors";
import { URLRoutes } from "URLRoutes";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import classNames from "classnames";
import { requestUserDetail } from "store/actions";
import "./style.scss";

function ResetPassword() {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const { data, fetching, error, message } = useSelector(
    getChangePasswordState
  );
  const wasfecthing = UsePrevious(fetching);
  const { token: storageToken } = useSelector(getAuthState);
  const {
    handleSubmit,
    formState: { errors , isSubmitSuccessful },
    control,
    watch,
  } = useForm({
    mode: "onChange",
  });
  const password = watch("password");

  const handleEyeIconClick = () => {
    setShowPassword(!showPassword);
  };

  const handleHideIconClick = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleOnSubmit = (data: any) => {
    const body = {
      token,
      ...data,
    };
    dispatch(requestResetPassword(body));
  };
  
  useEffect(() => {
    if (isSubmitSuccessful && !fetching && !error) {
      if (storageToken) {
        dispatch(requestUserDetail());
      }
      toast.success(message);
      navigate(URLRoutes.clients.login);
    } else if (isSubmitSuccessful && error) {
      toast.error(message);
    }
  }, [fetching, error, message]);

  // useEffect(() => {
  //   toast.dismiss();
  // }, []);

  return (
    <AuthLayout
      image="/images/womanSunglasses.png"
      title="Discover the world’s top Influencers."
      titlePosition="top"
      contentClassName="flex-justify-center flex-align-center"
      bannerClassName="background-linear1"
      imagePosition="right"
    >
      <div className="width-45 margin-b-10">
        <img src="/images/logo.png" style={{ width: "100%", height: "100%" }} />
      </div>
      <form onSubmit={handleSubmit(handleOnSubmit)} className="width-55">
        <div className="position-relative">
          <SvgIcon
            name={
              showPassword ? CUSTOM_SVG_ICON.EyeSVG : CUSTOM_SVG_ICON.HideEye
            }
            svgType={SVGType.CUSTOM}
            size={"small"}
            baseclassname={classNames(["password-icon"])}
            onClick={handleEyeIconClick}
          />
          <HookFormInput
            control={control}
            errors={errors}
            type={showPassword ? "text" : "password"}
            label="New Password"
            id="password"
            name="password"
            placeholder="Enter your new password here..."
            validation={{
              ...hookformRequired("Required"),
              ...hookPasswordPatternRequired(),
            }}
          />
        </div>
        <div className="position-relative">
          <SvgIcon
            name={
              showConfirmPassword
                ? CUSTOM_SVG_ICON.EyeSVG
                : CUSTOM_SVG_ICON.HideEye
            }
            svgType={SVGType.CUSTOM}
            size={"small"}
            baseclassname={classNames([
              "password-icon",
              // { "password-icon": !errors["confirmPassword"]?.message },
              // {
              //   "password-required-icon":
              //     errors["confirmPassword"]?.type === "required",
              // },
              // {
              //   "password-stable-icon":
              //     errors["confirmPassword"]?.type === "pattern",
              // },
            ])}
            onClick={handleHideIconClick}
          />

          <HookFormInput
            control={control}
            errors={errors}
            type={showConfirmPassword ? "text" : "password"}
            label="Confirm Password"
            id="confirmPassword"
            name="confirmPassword"
            placeholder="Confirm new password here..."
            baseClassName="margin-b-4"
            validation={{
              ...hookformRequired("Required"),
              ...hookformPasswordsMatch(password),
              ...hookPasswordPatternRequired(),
            }}
          />
        </div>

        <CustomButton
          buttonText="CONFIRM"
          type="submit"
          primaryButton
          round
          baseclassname={"width-100"}
          loading={fetching}
        />
      </form>
    </AuthLayout>
  );
}

export default ResetPassword;
