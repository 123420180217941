import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Dropdown } from "semantic-ui-react";
import { getVideoDetail, getVideoList, updateVideoStatus } from "store/rext";
import { getUploadedVideoState, getVideoListState } from "store/selectors";
// import Vimeo from '@u-wave/react-vimeo';
import moment from "moment";
import Loading from "components/Loading";
import { ModalHeader } from "../Common";
import { INFLUENCER_VIDEO_STATUS, ROLES } from "utils/Enums";
import CustomButton from "components/CustomButton";
import { toast } from "react-toastify";
import { useUserDetail } from "HOC/useUserDetail";

interface Props {
  onClose: () => void;
  onSave: (data: any) => void;
  title?: string;
  toggle?: any;
  Influencer_Id: string;
}

function VideoModal({ onClose, onSave, title, toggle, Influencer_Id }: Props) {
  const dispatch = useDispatch();
  const [comments, setComments] = useState<string>("");
  const { role } = useUserDetail();
  const [showComments, setShowComments] = useState<boolean>(false);
  const {
    data: videoList,
    fetching: videoListFectching,
    error: videoError,
    message: videoMessage,
  } = useSelector(getVideoListState);
  const [selectedVideo, setSelectedVideo] = useState<string>(
    videoList[videoList.length - 1]?.id
  );
  const { data, fetching  } = useSelector(getUploadedVideoState);
  const currentVideo = videoList?.find((video: any) => video.id === selectedVideo)

  const formatDate = (date: any) => {
    const dateToFormat = moment(date);
    return dateToFormat.format("Do MMMM, YYYY hh:mm A");
  };

  const handleVideoChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: any
  ) => {
    const { value } = data;
    setSelectedVideo(value);
    dispatch(getVideoDetail({ id: value }));
  };

  const handleCommentsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setComments(event.target.value);
  };

  const handleReject = () => {
    setShowComments(!showComments);
  };

  const handleSave = () => {
    if (!comments) {
      toast.error("Please enter comments before rejecting the video.");
      return;
    }

    dispatch(
      updateVideoStatus(
        {
          status: INFLUENCER_VIDEO_STATUS.REJECTED,
          reason: comments,
        },
        { id: selectedVideo }
      )
    );
    setComments("");
    setShowComments(false);
    onClose();
  };

  const handleAccept = () => {
    dispatch(
      updateVideoStatus(
        {
          status: INFLUENCER_VIDEO_STATUS.ACCEPTED,
          // reason: comments,
        },
        { id: selectedVideo }
      )
    );
    setComments("");
    onClose();
  };

  const hasRejectedStatus = currentVideo?.status.some((status: any) => status.status === "REJECTED");

  useEffect(() => {
    if (Influencer_Id) {
      dispatch(getVideoList({ id: Influencer_Id }));
    }
  }, [Influencer_Id , dispatch]);

  useEffect(() => {
    if (videoList.length) {
      setSelectedVideo(videoList[videoList.length - 1]?.id);
      dispatch(getVideoDetail({ id: videoList[videoList.length - 1]?.id }));
    }
  }, [videoList , dispatch]);

  return (
    <Modal open={true} onClose={onClose} size="small">
      {title && <ModalHeader title={title} toggle={toggle} />}
      <Modal.Content>
        {videoListFectching && <Loading inverted centered/>}
        {!videoListFectching && videoList.length === 0 && videoError && (
          <div>{videoMessage || "No videos available."}</div>
        )}
        {!videoListFectching && videoList.length > 0 && !videoError && (
          <div className="flex flex-column width-100 height-100 flex-align-center flex-justify-center">
            <div className="flex padding-4 width-100 flex-justify-between">
              <div className="width-50">
                <Dropdown
                  placeholder="Select Video"
                  fluid
                  selection
                  value={selectedVideo as string}
                  options={videoList.map((video: any) => ({
                    key: video.id,
                    value: video.id,
                    text: video.file_name,
                  }))}
                  onChange={handleVideoChange}
                  // className='width-50'
                />
              </div>
              <div className="flex flex-column text-4 width-40">
                <span className="text-bold">Uploaded On: </span>
                <span className="text-3 text-grey-shade2">
                  {formatDate(
                    currentVideo
                      ?.uploaded_on
                  )}
                </span>
              </div>
            </div>
            <div
              className="flex flex-justify-center width-50 padding-4 background-grey-shade5"
              style={{ height: "30rem" , borderRadius: "10px" }}
            >
              {selectedVideo && data && !fetching && (
                <video controls className="width-100 ">
                  <source src={data?.url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
              {fetching && <Loading inverted centered />}
            </div>
            {role !== ROLES.INFLUENCER &&  (
              <div className="flex flex-justify-end  width-100 padding-2">
                <CustomButton
                  buttonText="Reject"
                  dangerButton
                  textSmall
                  circular
                  baseclassname={"text-align-center margin-r-4"}
                  handleClick={handleReject}
                  disabled={showComments || hasRejectedStatus}
                />
                <CustomButton
                  buttonText="Accept"
                  greenButton
                  textSmall
                  circular
                  baseclassname={"text-align-center margin-r-4"}
                  handleClick={handleAccept}
                  disabled={hasRejectedStatus}
                />
              </div>
            )}
            {currentVideo?.status
              .length > 0 && (
              <div className="width-100 flex flex-column padding-4">
                {videoList
                  .find((video: any) => video.id === selectedVideo)
                  ?.status?.map((item: any) => {
                    return item.updated_by === ROLES.HOB ? (
                      <div className="flex flex-column width-100">
                        {item.reason && <div className="text-bold text-3">HOB Comments:</div> }
                        {item.reason && <div className="text-3 text-grey-shade-3 width-90 flex flex-justify-between padding-1">
                          <span>{item.reason}</span> <span>{formatDate(item.updated_on)}</span>
                        </div> }
                      </div>
                    ) : (
                      <div className="flex flex-column width-100">
                         {item.reason && <div className="text-bold text-3">
                          Brand Comments:
                        </div>}
                        {item.reason && <div className="text-3 text-grey-shade-3 width-90 flex flex-justify-between padding-1">
                          <span>{item.reason}</span> <span>{formatDate(item.updated_on)}</span>
                        </div> }
                      </div>
                    );
                  })}
              </div>
            )}

            {role !== ROLES.INFLUENCER && showComments && (
              <div className="flex flex-justify-center width-90 padding-2">
                <textarea
                  value={comments}
                  onChange={handleCommentsChange}
                  placeholder="Enter Comments"
                  rows={5}
                  className="width-100 padding-2 border-1 border-grey-shade-1"
                />
              </div>
            )}
            {role !== ROLES.INFLUENCER && showComments && (
              <div className="flex flex-justify-start width-90 padding-2">
                 <CustomButton
                  buttonText="Cancel"
                  secondaryButton
                  textSmall
                  circular
                  baseclassname={"text-align-center margin-r-4"}
                  handleClick={handleReject}
                />
                <CustomButton
                  buttonText="Submit"
                  primaryButton
                  textSmall
                  circular
                  baseclassname={"text-align-center margin-r-4"}
                  handleClick={handleSave}
                  disabled={!comments}
                />
              </div>
            )}
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
}

export default VideoModal;
