export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.55097 1.65335C10.3444 0.782218 11.715 0.782218 12.5084 1.65335L13.5286 2.77358C13.9295 3.21385 14.5058 3.45255 15.1007 3.42475L16.6142 3.35402C17.7911 3.29901 18.7604 4.26824 18.7054 5.44521L18.6346 6.95872C18.6068 7.55355 18.8455 8.12983 19.2858 8.53078L20.406 9.55097C21.2772 10.3444 21.2772 11.715 20.406 12.5084L19.2858 13.5286C18.8455 13.9295 18.6068 14.5058 18.6346 15.1007L18.7054 16.6142C18.7604 17.7911 17.7911 18.7604 16.6142 18.7054L15.1007 18.6346C14.5058 18.6068 13.9295 18.8455 13.5286 19.2858L12.5084 20.406C11.715 21.2772 10.3444 21.2772 9.55097 20.406L8.53078 19.2858C8.12983 18.8455 7.55355 18.6068 6.95872 18.6346L5.4452 18.7054C4.26824 18.7604 3.29901 17.7911 3.35402 16.6142L3.42475 15.1007C3.45255 14.5058 3.21385 13.9295 2.77358 13.5286L1.65335 12.5084C0.782218 11.715 0.782218 10.3444 1.65335 9.55097L2.77358 8.53078C3.21385 8.12983 3.45255 7.55355 3.42475 6.95872L3.35402 5.4452C3.29901 4.26824 4.26824 3.29901 5.44521 3.35402L6.95872 3.42475C7.55355 3.45255 8.12983 3.21385 8.53078 2.77358L9.55097 1.65335Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M13.2219 11.3156C13.6541 10.877 13.9471 10.3205 14.0639 9.71584C14.1807 9.11124 14.1162 8.48559 13.8785 7.91754C13.6408 7.34949 13.2404 6.86439 12.7278 6.52321C12.2151 6.18203 11.6131 6 10.9973 6C10.3815 6 9.77947 6.18203 9.26684 6.52321C8.7542 6.86439 8.35386 7.34949 8.11613 7.91754C7.8784 8.48559 7.81389 9.11124 7.93071 9.71584C8.04753 10.3205 8.34047 10.877 8.77271 11.3156C7.95681 11.6364 7.25618 12.195 6.76174 12.9189C6.26731 13.6428 6.00191 14.4987 6 15.3753C5.99995 15.4574 6.01608 15.5386 6.04746 15.6144C6.07883 15.6902 6.12484 15.7591 6.18286 15.8171C6.24087 15.8752 6.30975 15.9212 6.38556 15.9525C6.46137 15.9839 6.54262 16 6.62466 16H15.37C15.452 16 15.5333 15.9839 15.6091 15.9525C15.6849 15.9212 15.7537 15.8752 15.8118 15.8171C15.8698 15.7591 15.9158 15.6902 15.9472 15.6144C15.9785 15.5386 15.9947 15.4574 15.9946 15.3753C15.9927 14.4987 15.7273 13.6428 15.2329 12.9189C14.7384 12.195 14.0378 11.6364 13.2219 11.3156ZM10.9973 7.25471C11.368 7.25471 11.7303 7.36461 12.0384 7.57053C12.3466 7.77645 12.5868 8.06913 12.7287 8.41155C12.8705 8.75398 12.9076 9.13078 12.8353 9.4943C12.763 9.85782 12.5845 10.1917 12.3224 10.4538C12.0603 10.7159 11.7264 10.8944 11.3629 10.9667C10.9994 11.039 10.6226 11.0019 10.2802 10.86C9.93774 10.7182 9.64506 10.478 9.43914 10.1698C9.23323 9.86166 9.12332 9.49934 9.12332 9.1287C9.12387 8.63185 9.32148 8.15552 9.67281 7.8042C10.0241 7.45287 10.5005 7.25526 10.9973 7.25471ZM7.31155 14.7507C7.4567 14.0457 7.84037 13.4123 8.39794 12.9572C8.95551 12.5021 9.65291 12.253 10.3726 12.252H11.622C12.3417 12.253 13.0391 12.5021 13.5967 12.9572C14.1543 13.4123 14.5379 14.0457 14.6831 14.7507H7.31155Z"
        fill="currentColor"
      />
    </svg>
  );
};
