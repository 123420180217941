import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { URLRoutes } from "URLRoutes";
import CustomTable from "components/CustomTable";
import { IActionButtons, IColumns } from "components/CustomTable/CustomTable";
import { CustomInput } from "components/CustomInputs";
import HeaderLayout from "components/HeaderLayout";
import { deleteBrandUser, requestALLBrandUsersList } from "store/rext";
import { getBrandUserState, getBrandUsersListState } from "store/selectors";
import UsePrevious from "HOC/UsePrevious";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { useUserDetail } from "HOC/useUserDetail";
import { ROLES } from "utils/Enums";
import CustomButton from "components/CustomButton";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import "./style.scss";
import { ModalsType } from "containers/ModalManager/ModalManager";
import { modalOperation } from "store/actions";

function BrandUsersList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role } = useUserDetail();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const {
    data: brandsList,
    fetching,
    error: listError,
    message: listMessage,
  } = useSelector(getBrandUsersListState);
  const {
    message,
    error,
    fetching: brandFetching,
  } = useSelector(getBrandUserState);
  const { records, paginationInfo } = brandsList || {};
  const wasfecthing = UsePrevious(brandFetching);

  const columns: IColumns[] = [
    {
      key: "brandname",
      header: "Brand Name",
      // sort: true,
      sortKey: "brandname",
      customValue: (data: any) =>
        data.logo_url ? (
          <div className="flex flex-align-center flex-justify-left width-100">
            <div className="user-image flex flex-align-center flex-justify-center background-secondary-color margin-r-1">
              <img
                src={data.logo_url || "/images/NoPreview.jpg"}
                style={{ width: "100%", height: "100%" }}
                onError={handleImageError}
              />
            </div>
            <span>{data.brandname}</span>
          </div>
        ) : (
          data.brandname
        ),
    },
    {
      key: "users",
      header: "User Name",
      // sort: true,
      sortKey: "users",
      customValue: (data: any) => {
        return (
          <div className="flex flex-column">
            {data.users.map((item: any, index: number) => (
              <td key={index}>{item.name}</td>
            ))}
          </div>
        );
      },
    },
    {
      key: "email",
      header: "Email ID",
      // sort: true,
      sortKey: "email",
      customValue: (data: any) => {
        return (
          <div className="flex flex-column">
            {data.users.map((item: any, index: number) => (
              <td key={index}>{item.email}</td>
            ))}
          </div>
        );
      },
    },
    {
      key: "phone",
      header: "Number",
      sortKey: "phone",
      customValue: (data: any) => {
        return (
          <div className="flex flex-column">
            {data.users.map((item: any, index: number) => (
              <td key={index}>{item.phone}</td>
            ))}
          </div>
        );
      },
    },
    {
      key: "action",
      header: "Action",
      sortKey: "action",
      customValue: (data: any) => {
        return (
          <div className="flex flex-column">
            {data.users.map((item: any, index: number) => (
              <td key={index}>
                <CustomButton
                  iconProps={{
                    name: CUSTOM_SVG_ICON.Edit,
                    svgType: SVGType.CUSTOM,
                    size: "small",
                    baseclassname: `text-default-color`,
                  }}
                  iconPosition={ICON_POSITION.RIGHT}
                  greyButton
                  transparent
                  handleClick={() => handleEditBrand(item.branduserid)}
                />
                <CustomButton
                  iconProps={{
                    name: CUSTOM_SVG_ICON.Delete,
                    svgType: SVGType.CUSTOM,
                    size: "small",
                    baseclassname: `text-default-color`,
                  }}
                  iconPosition={ICON_POSITION.RIGHT}
                  greyButton
                  transparent
                  handleClick={() => handleDeleteBrand(item.branduserid)}
                />
              </td>
            ))}
          </div>
        );
      },
    },
  ];

  const handleImageError = (e: any) => {
    e.target.onerror = null; 
    e.target.src = "/images/NoPreview.jpg"; 
  }  

  const handlePagination = (e: any, { activePage }: any) => {
    setCurrentPage(activePage);
    dispatch(
      requestALLBrandUsersList({
        page: activePage,
        pageSize: 10,
      })
    );
  };

  const handleInputChange = (name: string, value: any) => {
    dispatch(
      requestALLBrandUsersList({
        page: currentPage,
        pageSize: 10,
        search: value,
      })
    );
  };

  const handleEditBrand = (id: any) => {
    navigate(URLRoutes.clients.updateBrandUser.replace(":brandUserId", id));
  };

  const handleDeleteBrand = (id: any) => {
    dispatch(
      modalOperation.showModal(ModalsType.AlertModal, {
        onSave: (data: any) => {
          dispatch(modalOperation.hideModal());
          dispatch(deleteBrandUser({ id: id }));
        },
        onClose: () => {
          dispatch(modalOperation.hideModal());
        },
        message: "Are you sure, you want to delete this brand user?",
        primaryBtnTitle: "Yes",
        secondaryBtnTitle: "No",
        title: "Confirmation",
      })
    );
  };

  const groupDataByBrand = (records: any) => {
    return records?.reduce((acc: any, record: any) => {
      const { brandname, brandId, logo_url, ...userDetails } = record;
      const user = { id: record.id, ...userDetails };

      const brandIndex = acc.findIndex(
        (item: any) => item.brandname === brandname
      );

      if (brandIndex === -1) {
        acc.push({
          brandname,
          brandId: brandId,
          logo_url: logo_url,
          users: [user],
        });
      } else {
        acc[brandIndex].users.push(user);
      }

      return acc;
    }, []);
  };
  
  useEffect(() => {
    if (message?.length > 0) {
      if (wasfecthing && !brandFetching && !error) {
        toast.success(message);
      } else if (error) {
        toast.error(message);
      }
    }
  }, [brandFetching, error, message]);

  useEffect(() => {
    dispatch(
      requestALLBrandUsersList({
        page: currentPage,
        pageSize: 10,
      })
    );
  }, [message]);

  return (
    <HeaderLayout
      title={"Manage Brand Users"}
      breadcrumbs={[
        {
          text: "Dashboard",
          url:
            role === ROLES.HOB
              ? URLRoutes.clients.users.replace(":userType", ROLES.INFLUENCER)
              : role === ROLES.INFLUENCER
              ? URLRoutes.clients.instaDashboard
              : URLRoutes.clients.profile,
        },
        {
          text: "Manage Brand Users",
          url: URLRoutes.clients.brandUsersList,
        },
      ]}
    >
      <CustomTable
        columns={columns}
        tableData={groupDataByBrand(records)|| []}
        hasPagination={true}
        fetching={fetching || brandFetching || false}
        contentRowClassName={"text-4"}
        tableClassName="userTable-height"
        paginationData={paginationInfo}
        handlePaginationChange={handlePagination}
        currentPage={currentPage}
        tableError={listError}
        tableMessage={listMessage}
        disablePagination={!brandsList?.records?.length}
      >
        <CustomInput
          name="search"
          id="search"
          onChange={handleInputChange}
          placeholder="Search by Brand or User name"
          baseClassName="width-50 search-input padding-2"
          iconProps={{
            name: CUSTOM_SVG_ICON.Search,
            svgType: SVGType.CUSTOM,
            size: "small",
          }}
        />
      </CustomTable>
    </HeaderLayout>
  );
}

export default BrandUsersList;
